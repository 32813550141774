import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'

import { Breadcrumb, BreadcrumbItem } from 'components/breadcrumb'
import { Table } from 'components/table'
import { SearchInput } from 'components/searchInput'

import { withAdminLayout } from 'hoc/withAdminLayout'

import { useQuery } from 'hooks/useQuery'

import { INSTITUTION_DETAILS_PAGE } from 'navigation/paths'

import { GlobalState } from 'store'
import { searchInstitution } from 'store/institution/actions'

import { InstitutionSearchResult } from 'types/institution/InstitutionSearchResult'
import { TableColumn } from 'types/table/TableColumn'

import { formatCnpj } from 'utils/formatUtils'
import { cnpjRegex } from 'utils/regexUtils'

import { DetailsLink } from './styled'

function Page(): JSX.Element {
  const { t } = useTranslation(['pages', 'common'])

  const query = useQuery()

  const dispatch = useDispatch()

  const { searchInstitutionResult, searchInstitutionInProgress } = useSelector(
    (state: GlobalState) => state.institution
  )

  const [filter, setFilter] = useState<string | undefined>(undefined)

  const columns = useMemo<TableColumn<InstitutionSearchResult>[]>(
    () => [
      {
        label: t('pages:institutionList.table.institution'),
        dataKey: 'name',
        sort: true,
        render: (row) => row.institutionName
      },
      {
        label: t('pages:institutionList.table.cnpj'),
        dataKey: 'cnpj',
        sort: true,
        render: (row) => formatCnpj(row.cnpj)
      },
      {
        label: t('pages:institutionList.table.type'),
        dataKey: 'type',
        thStyle: { width: '10%' },
        tdStyle: { width: '10%' },
        render: (row) => t(`common:institutionType.${row.type}`)
      },
      {
        label: t('pages:institutionList.table.actions'),
        dataKey: 'actions',
        thStyle: { width: '15%' },
        tdStyle: { width: '15%' },
        render: (row) => (
          <DetailsLink
            to={generatePath(INSTITUTION_DETAILS_PAGE, {
              cnpj: row.cnpj
            })}
          >
            + detalhes
          </DetailsLink>
        )
      }
    ],
    []
  )

  useEffect(() => {
    let search: string | undefined = ''

    if (filter?.match(cnpjRegex)) {
      search = filter.replace(/\D/g, '')
    } else {
      search = filter
    }

    dispatch(
      searchInstitution({
        search,
        page: parseInt(query.get('page') ?? '1'),
        limit: 10,
        orderBy: query.get('orderBy') ?? 'name',
        order: query.get('order') ?? 'asc'
      })
    )
  }, [filter, query.get('page'), query.get('orderBy'), query.get('order')])

  document.title = 'Instituições - Open Banking Brasil'

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem
          label={t('pages:institutionList.breadcrumb.institution')}
          isCurrentPage
        />
      </Breadcrumb>

      <SearchInput
        placeholder={t('pages:institutionList.search')}
        onClick={(value) => setFilter(value)}
      />

      <Table
        columns={columns}
        data={searchInstitutionResult?.data ?? []}
        pageCount={searchInstitutionResult?.pagination.lastPage}
        isLoading={searchInstitutionInProgress}
      />
    </>
  )
}

export const InstitutionListPage = withAdminLayout(Page)
