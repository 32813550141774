import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PageLoading } from 'components/pageLoading'

import { useQuery } from 'hooks/useQuery'

import { browserHistory } from 'navigation/browserHistory'
import { LOGIN_PAGE, DASHBOARD_PAGE } from 'navigation/paths'

import { GlobalState } from 'store'
import { setToken } from 'store/session/actions'

export function SSOPage(): JSX.Element {
  const query = useQuery()

  const dispatch = useDispatch()

  const { loggedIn, user } = useSelector((state: GlobalState) => state.session)

  useEffect(() => {
    const token = query.get('token')
    if (!token) {
      browserHistory.push(LOGIN_PAGE)
    } else {
      localStorage.removeItem('sso:state')
      dispatch(setToken({ type: 'bearer', token }))
    }
  }, [])

  useEffect(() => {
    if (loggedIn && user) {
      browserHistory.push(DASHBOARD_PAGE)
    }
  }, [loggedIn, user])

  return <PageLoading />
}
