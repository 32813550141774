import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { FieldSet } from 'components/fieldSet'
import { Form } from 'components/form'
import { Flex, FlexItem } from 'components/flex'
import { MaskedInput } from 'components/maskedInput'
import { TextInput } from 'components/textInput'

import { GlobalState } from 'store'
import { updateInstitution } from 'store/institution/actions'

import { InstitutionType } from 'types/enums/InstitutionType'

export function InstitutionForm(): JSX.Element {
  const { t } = useTranslation(['pages'])

  const dispatch = useDispatch()

  const { institution, updateInstitutionInProgress } = useSelector(
    (state: GlobalState) => state.institution
  )

  const initialValues = {
    id: institution?.id,
    name: institution?.name ?? '',
    cnpj: institution?.cnpj ?? ''
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    cnpj: Yup.string()
  })

  function _handleSubmit(data: FormikValues) {
    dispatch(
      updateInstitution({
        id: data.id,
        name: data.name,
        fantasyName: data.name,
        cnpj: data.cnpj
      })
    )
  }

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => _handleSubmit(values)}
    >
      <FieldSet title={t('pages:institutionUpdate.institutionForm.title')}>
        <Flex>
          <FlexItem grow="1">
            <TextInput
              name="name"
              placeholder={t('pages:institutionUpdate.institutionForm.name')}
              disabled={institution?.type === InstitutionType.DIRECTORY}
            />
          </FlexItem>
        </Flex>
        <Flex>
          <FlexItem grow="1">
            <MaskedInput
              name="cnpj"
              placeholder={t('pages:institutionUpdate.institutionForm.cnpj')}
              disabled={institution?.type === InstitutionType.DIRECTORY}
              options={{
                delimiters: ['.', '.', '/', '-'],
                blocks: [2, 3, 3, 4, 2],
                numericOnly: true
              }}
            />
          </FlexItem>
        </Flex>
      </FieldSet>

      <Flex justify="flex-end">
        <Button
          type="submit"
          small
          inProgress={updateInstitutionInProgress}
          disabled={institution?.type === InstitutionType.DIRECTORY}
        >
          {t('pages:institutionUpdate.institutionForm.submitButton')}
        </Button>
      </Flex>
    </Form>
  )
}
