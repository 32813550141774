import { ChangeEvent } from 'cleave.js/react/props'
import { Form, Formik, FormikValues } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { ReactComponent as CreditCardSVG } from 'assets/images/credit-card.svg'
import { ReactComponent as ReceiptSVG } from 'assets/images/receipt.svg'

import { BackButton } from 'components/backButton'
import { Button } from 'components/button'
import { CreditCard } from 'components/creditCard'
import { Flex, FlexItem } from 'components/flex'
import { MaskedInput } from 'components/maskedInput'
import { PageLoading } from 'components/pageLoading'
import { TextInput } from 'components/textInput'

import { GlobalState } from 'store'
import { payContest, payContestBoleto, getPrices } from 'store/contest/actions'

import { ContestStep } from 'types/enums/ContestStep'
import { ContestUpdateAction } from 'types/enums/ContestUpdateAction'

import { formatCurrencyBR } from 'utils/formatUtils'

import {
  BoletoDisclaimer,
  PaymentStepWrapper,
  PaymentMethod,
  PaymentValuesContainer,
  TabButton,
  BoletoLink
} from './styled'

type PaymentStepProps = {
  goBack: () => void
}

export function PaymentStep({ goBack }: PaymentStepProps): JSX.Element {
  const { t } = useTranslation('pages')

  const { contestId } = useParams<{ contestId: string }>()

  const dispatch = useDispatch()

  const {
    contest: {
      contestData,
      payContestInProgress,
      payContestBoletoInProgress,
      contestPrice,
      getPricesInProgress
    },
    session: { user: sessionUser }
  } = useSelector((state: GlobalState) => state)

  const [currentTab, setCurrentTab] = useState<'card' | 'boleto'>('card')

  const [cardNumber, setCardNumber] = useState<string>('')
  const [cardExpiration, setCardExpiration] = useState<string>('')
  const [cardType, setCardType] = useState<string>('')

  const cardData = {
    name: '',
    number: '',
    expiration: '',
    cvv: ''
  }

  const cardSchema = Yup.object().shape({
    name: Yup.string().required('required'),
    number: Yup.string().required('required'),
    expiration: Yup.string().required('required'),
    cvv: Yup.string().required('required')
  })

  const hasBoleto = useMemo<boolean>(
    () => contestData?.currentStep === ContestStep.WAITING_BOLETO,
    [contestData]
  )

  useEffect(() => {
    dispatch(getPrices())
  }, [])

  useEffect(() => {
    if (hasBoleto) {
      setCurrentTab('boleto')
    }
  }, [hasBoleto])

  function _doCardPayment(data: FormikValues) {
    if (contestId && sessionUser) {
      dispatch(
        payContest({
          contestId: parseInt(contestId),
          action: ContestUpdateAction.PAYMENT,
          data: {
            holder_name: data.name,
            card_number: data.number,
            expiration_month: data.expiration.substr(0, 2),
            expiration_year: data.expiration.substr(2, 2),
            security_code: data.cvv
          }
        })
      )
    }
  }

  function _doBoletoPayment() {
    if (contestId && sessionUser) {
      dispatch(
        payContestBoleto({
          contestId: parseInt(contestId),
          action: ContestUpdateAction.PAYMENT_BOLETO,
          data: null
        })
      )
    }
  }

  function _renderTabValues() {
    return (
      contestPrice && (
        <>
          <span>
            <div>
              {currentTab === 'card' && (
                <h4>{t('contestCreate.paymentStep.cardTab.contestFee')}</h4>
              )}
              {currentTab === 'boleto' && (
                <h4>{t('contestCreate.paymentStep.boletoTab.contestFee')}</h4>
              )}
              <p>{formatCurrencyBR(parseInt(contestPrice.price) / 100)}</p>
            </div>
            <div>
              {currentTab === 'card' && (
                <>
                  <h4>{t('contestCreate.paymentStep.cardTab.paymentFee')}</h4>
                  <p>{formatCurrencyBR(parseInt(contestPrice.credit) / 100)}</p>
                </>
              )}
              {currentTab === 'boleto' && (
                <>
                  <h4>{t('contestCreate.paymentStep.boletoTab.paymentFee')}</h4>
                  <p>{formatCurrencyBR(parseInt(contestPrice.boleto) / 100)}</p>
                </>
              )}
            </div>
          </span>
          <span>
            {currentTab === 'card' && (
              <>
                <h4>{t('contestCreate.paymentStep.cardTab.totalToPay')}</h4>
                <p>
                  {formatCurrencyBR(
                    (parseInt(contestPrice.price) +
                      parseInt(contestPrice.credit)) /
                      100
                  )}
                </p>
              </>
            )}
            {currentTab === 'boleto' && (
              <>
                <h4>{t('contestCreate.paymentStep.boletoTab.totalToPay')}</h4>
                <p>
                  {formatCurrencyBR(
                    (parseInt(contestPrice.price) +
                      parseInt(contestPrice.boleto)) /
                      100
                  )}
                </p>
              </>
            )}
          </span>
        </>
      )
    )
  }

  function _getBoletoLink() {
    const action = contestData?.data.find(
      (item) => item.action === ContestUpdateAction.PAYMENT_BOLETO
    )

    if (!action || !action?.actionResult) return null

    return JSON.parse(action.actionResult).data.detail
  }

  return (
    <PaymentStepWrapper>
      <h4>{t('contestCreate.paymentStep.payment')}</h4>
      <p>{t('contestCreate.paymentStep.descrtiption')}</p>

      {getPricesInProgress ? (
        <PageLoading />
      ) : (
        <>
          <PaymentMethod>
            {t('contestCreate.paymentStep.paymentMethods.title')}
          </PaymentMethod>

          <Flex direction="row" justify="center" gap={6}>
            <FlexItem>
              <TabButton
                type="button"
                active={currentTab === 'card'}
                aria-current={currentTab === 'card'}
                onClick={() => setCurrentTab(hasBoleto ? 'boleto' : 'card')}
                disabled={hasBoleto}
              >
                <CreditCardSVG />
                {t('contestCreate.paymentStep.paymentMethods.card')}
              </TabButton>
            </FlexItem>
            <FlexItem>
              <TabButton
                type="button"
                active={currentTab === 'boleto'}
                aria-current={currentTab === 'boleto'}
                onClick={() => setCurrentTab('boleto')}
              >
                <ReceiptSVG />
                {t('contestCreate.paymentStep.paymentMethods.boleto')}
              </TabButton>
            </FlexItem>
          </Flex>

          {currentTab === 'boleto' && (
            <BoletoDisclaimer>
              {contestData?.currentStep === ContestStep.INACTIVE ? (
                t('contestCreate.paymentStep.boletoTab.disclaimer')
              ) : (
                <>
                  {t('contestCreate.paymentStep.boletoTab.waitingBoleto')}
                  {_getBoletoLink() && (
                    <BoletoLink
                      href={_getBoletoLink()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('contestCreate.paymentStep.boletoTab.boletoLink')}
                    </BoletoLink>
                  )}
                </>
              )}
            </BoletoDisclaimer>
          )}

          {contestData?.currentStep === ContestStep.INACTIVE && (
            <PaymentValuesContainer>
              {_renderTabValues()}
            </PaymentValuesContainer>
          )}

          {currentTab === 'card' && (
            <Formik
              enableReinitialize
              initialValues={cardData}
              validateOnBlur
              validateOnChange
              validateOnMount={false}
              validationSchema={cardSchema}
              onSubmit={(values) => _doCardPayment(values)}
            >
              {({ values }) => (
                <Form>
                  <Flex
                    direction="row"
                    gap={12}
                    justify="center"
                    align="center"
                  >
                    <FlexItem>
                      <CreditCard
                        type={cardType}
                        name={values.name}
                        number={cardNumber}
                        expiration={cardExpiration}
                        cvv={values.cvv}
                      />
                    </FlexItem>
                    <FlexItem basis="380px">
                      <TextInput
                        name="name"
                        placeholder={t(
                          'contestCreate.paymentStep.cardTab.card.name'
                        )}
                        autoComplete="off"
                      />

                      <MaskedInput
                        name="number"
                        placeholder={t(
                          'contestCreate.paymentStep.cardTab.card.number'
                        )}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setCardNumber(event.target.value)
                        }}
                        options={{
                          creditCard: true,
                          onCreditCardTypeChanged: (type) => {
                            setCardType(type)
                          }
                        }}
                        autoComplete="off"
                      />

                      <Flex gap={6} wrap={false}>
                        <FlexItem grow="6">
                          <MaskedInput
                            name="expiration"
                            placeholder={t(
                              'contestCreate.paymentStep.cardTab.card.expiration'
                            )}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              setCardExpiration(event.target.value)
                            }}
                            options={{ date: true, datePattern: ['m', 'y'] }}
                            autoComplete="off"
                          />
                        </FlexItem>
                        <FlexItem grow="6">
                          <MaskedInput
                            name="cvv"
                            placeholder={t(
                              'contestCreate.paymentStep.cardTab.card.cvv'
                            )}
                            options={{ blocks: [3], numericOnly: true }}
                            autoComplete="off"
                          />
                        </FlexItem>
                      </Flex>
                    </FlexItem>
                  </Flex>

                  <Button type="submit" inProgress={payContestInProgress}>
                    {t('contestCreate.paymentStep.cardTab.nextButton')}
                  </Button>
                </Form>
              )}
            </Formik>
          )}

          {currentTab === 'boleto' &&
            contestData?.currentStep === ContestStep.INACTIVE && (
              <Button
                type="button"
                inProgress={payContestBoletoInProgress}
                onClick={_doBoletoPayment}
              >
                {t('contestCreate.paymentStep.boletoTab.nextButton')}
              </Button>
            )}

          <BackButton onClick={goBack} />
        </>
      )}
    </PaymentStepWrapper>
  )
}
