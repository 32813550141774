import { FormikValues } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { Form } from 'components/form'
import { Modal, ModalHeader } from 'components/modal'
import { Select } from 'components/select'
import { Textarea } from 'components/textarea'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'

import { ModalName } from 'types/enums/ModalName'
import { SelectOption } from 'types/SelectOption'

import { showSuccess, showError } from 'utils/alertUtils'

import {
  requestTransferInstitution,
  listRelatedInstitutions
} from 'services/http/contestService'

import { ContestTransferInstitutionConfirmModal } from '../contestTransferInstitutionConfirmModal'

import { ModalBody } from './styled'

export function ContestTransferInstitutionModal(): JSX.Element {
  const { t } = useTranslation(['modal', 'common'])

  const { contestId } = useParams<{ contestId: string }>()

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    modal: { contestTransferInstitutionIsOpen },
    contest: { contestData }
  } = useSelector((state: GlobalState) => state)

  const initialValues = {
    userId: sessionUser?.id,
    contestId,
    institutionToTrasfer: null,
    message: ''
  }

  const [data, setData] = useState<FormikValues>(initialValues)
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  const [instsOptions, setInstsOptions] = useState<SelectOption[]>([])

  const validationSchema = Yup.object().shape({
    institutionToTrasfer: Yup.number().nullable().required('required'),
    message: Yup.string().required('required')
  })

  useEffect(() => {
    if (!contestTransferInstitutionIsOpen) {
      const resetForm = document.getElementById('resetForm')
      if (resetForm) resetForm.click()
    } else {
      if (!contestData) return
      setIsLoading(true)
      listRelatedInstitutions(contestData.defendantId)
        .then((opts) => {
          setInstsOptions(
            opts.map(({ id, name, cnpj }) => ({
              label: `${name} - ${cnpj}`,
              value: id
            }))
          )
        })
        .catch(() => {
          setInstsOptions([])
        })
        .finally(() => setIsLoading(false))
    }
  }, [contestTransferInstitutionIsOpen])

  async function _handleSubmit(data: FormikValues) {
    try {
      if (!contestData) return
      setIsConfirmModalOpen(false)
      setIsLoading(true)
      await requestTransferInstitution(
        contestData.id,
        data.institutionToTrasfer,
        data.message
      )
      showSuccess('transferContestInstitution')
      dispatch(closeModal(ModalName.CONTEST_TRANSFER_INSTITUTION))
      document?.location?.reload?.()
    } catch (err) {
      showError('transferContestInstitution', err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isOpen={contestTransferInstitutionIsOpen}
      onClose={() =>
        dispatch(closeModal(ModalName.CONTEST_TRANSFER_INSTITUTION))
      }
    >
      <ModalHeader separator={false}>
        <h2>{t('modal:contestTransferInstitution.title')}</h2>
      </ModalHeader>

      <ModalBody>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setData(values)
            setIsConfirmModalOpen(true)
          }}
        >
          <Select
            name="institutionToTrasfer"
            placeholder={t(
              'modal:contestTransferInstitution.form.institutions'
            )}
            options={instsOptions}
            onChangeFilter={(search: string) => console.log(search)}
            isLoading={isLoading}
          />

          <Textarea
            name="message"
            placeholder={t('modal:contestTransferInstitution.form.message')}
            style={{ minHeight: 160 }}
          />

          <Button fullWidth small type="submit" inProgress={isLoading}>
            {t('modal:contestTransferInstitution.form.submitButton')}
          </Button>
        </Form>
      </ModalBody>
      <ContestTransferInstitutionConfirmModal
        isOpen={isConfirmModalOpen}
        onAccept={() => _handleSubmit(data)}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    </Modal>
  )
}
