/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContestFile } from 'types/contestFile/ContestFile'
import { ContestFileCreate } from 'types/contestFile/ContestFileCreate'
import { ContestFileUpdateNote } from 'types/contestFile/ContestFileUpdateNote'

import { DELETE, GET, POST, PUT } from 'utils/httpUtils'

const PATH = '/contest/files'

export async function getContestFileUrl(
  storageKey: string
): Promise<{ url: string; file: ContestFile }> {
  try {
    const response = await GET<{ url: string; file: ContestFile }>(
      `${PATH}/getAuthFile`,
      {
        key: storageKey
      }
    )

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchContestFiles(
  contestId: number
): Promise<ContestFile[]> {
  try {
    const data = await GET<any[]>(`${PATH}/${contestId}`)

    return Promise.resolve(
      data.map((item) => ({
        id: item.id,
        contestDataId: item.contest_data_id,
        side: item.side,
        addedBy: {
          id: item.added_by,
          name: item.name,
          lastname: item.lastname
        },
        fileUrl: item.file_url,
        filename: item.filename,
        notes: item.notes,
        storageKey: item.storage_key,
        step: item.step,
        mutable: item.mutable,
        createdAt: item.created_at
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function addContestFiles(
  data: ContestFileCreate
): Promise<ContestFile[]> {
  try {
    const { contestId, documents } = data

    const formData = new FormData()

    documents?.forEach((doc) => {
      formData.append('documents[]', doc)
    })

    const response = await POST<FormData, any[]>(
      `${PATH}/${contestId}`,
      formData
    )

    return Promise.resolve(
      response.map((item) => ({
        id: item.id,
        contestDataId: item.contest_data_id,
        side: item.side,
        addedBy: {
          id: item.added_by,
          name: item.name,
          lastname: item.lastname
        },
        fileUrl: item.file_url,
        filename: item.filename,
        notes: item.notes,
        storageKey: item.storage_key,
        step: item.step,
        mutable: item.mutable,
        createdAt: item.created_at
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function removeContestFile(storageKey: string): Promise<void> {
  try {
    await DELETE<never, any[]>(`${PATH}?key=${storageKey}`)

    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateContestFileNotes(
  data: ContestFileUpdateNote
): Promise<ContestFile[]> {
  try {
    const response = await PUT<ContestFileUpdateNote, any[]>(
      `${PATH}/${data.contestId}`,
      data
    )

    return Promise.resolve(
      response.map((item) => ({
        id: item.id,
        contestDataId: item.contest_data_id,
        side: item.side,
        addedBy: {
          id: item.added_by,
          name: item.name,
          lastname: item.lastname
        },
        fileUrl: item.file_url,
        filename: item.filename,
        notes: item.notes,
        storageKey: item.storage_key,
        step: item.step,
        mutable: item.mutable,
        createdAt: item.created_at
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
