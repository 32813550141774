import styled from 'styled-components'

import { Scroll } from 'styles/Helpers'

type SidebarWrapperProps = {
  isReduced: boolean
}

export const SidebarWrapper = styled.aside<SidebarWrapperProps>`
  height: ${({ theme }) => `calc(100% - ${theme.misc.header.height})`};
  width: ${({ theme, isReduced }) =>
    isReduced ? theme.misc.sidebar.reducedWidth : theme.misc.sidebar.width};

  background-color: ${({ theme }) => theme.colors.paleGrey};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition: 0.3s;

  nav {
    flex: 1;

    display: flex;
    flex-direction: column;

    padding: ${({ theme }) => `${theme.spacings[6]} 0`};

    ${Scroll}

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: ${({ theme }) => theme.spacings[2]};

      padding: ${({ theme }) => `${theme.spacings[2]} ${theme.spacings[4]}`};

      color: ${({ theme }) => theme.colors.greyishBrownFive};

      white-space: nowrap;

      transition: 0.3s;

      svg {
        color: ${({ theme }) => theme.colors.petrol};

        width: ${({ theme }) => theme.fontSizes.lg};
        min-width: ${({ theme }) => theme.fontSizes.lg};

        & + span {
          margin-left: 0;
        }
      }

      span {
        opacity: ${({ isReduced }) => (isReduced ? 0 : 1)};
        white-space: nowrap;
        transition: 0.3s;

        margin-left: ${({ theme }) =>
          `calc(${theme.fontSizes.lg} + ${theme.spacings[2]})`};
      }

      &:focus,
      &:hover {
        background-color: rgba(80, 80, 80, 0.1);
      }

      &.active {
        background-color: rgba(80, 80, 80, 0.1);
      }
    }

    a + a {
      margin-top: ${({ theme }) => theme.spacings[2]};
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    height: 50px;
    padding: ${({ theme }) => theme.spacings[4]};

    background-color: rgba(80, 80, 80, 0.1);

    button {
      line-height: 0;
      border: none;
      color: ${({ theme }) => theme.colors.petrol};
      background-color: transparent;

      :hover {
        opacity: 0.6;
        transition: 0.3s;
      }

      svg {
        width: ${({ theme }) => theme.fontSizes.md};
        transition: 0.3s;
        transform: ${({ isReduced }) =>
          isReduced ? 'rotateY(180deg)' : 'rotateY(0)'};
      }
    }
  }
`
