import styled from 'styled-components'

export const BreadcrumbWrapper = styled.nav.attrs({
  'aria-label': 'breadcrumb'
})`
  margin-bottom: ${({ theme }) => theme.spacings[4]};

  ol {
    list-style: none;
  }
`

export const BreadcrumbItemContainer = styled.li`
  display: inline-block;

  font-size: ${({ theme }) => theme.fontSizes.sm};

  a {
    color: ${({ theme }) => theme.colors.petrol};

    &:hover {
      text-decoration: underline;
      transition: 0.3s;
    }
  }

  a + span {
    margin-left: ${({ theme }) => theme.spacings[1]};
    color: ${({ theme }) => theme.colors.petrol};
  }

  span {
    color: ${({ theme }) => theme.colors.warmGrey};
  }

  & + li {
    margin-left: ${({ theme }) => theme.spacings[1]};
  }

  &:first-of-type {
    span {
      color: ${({ theme }) => theme.colors.petrol};
    }
  }
`
