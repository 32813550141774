export const FETCH_USER_INFO = '@user/FETCH_USER_INFO'
export const FETCH_USER_INFO_SUCCESS = '@user/FETCH_USER_INFO_SUCCESS'
export const FETCH_USER_INFO_FAILURE = '@user/FETCH_USER_INFO_FAILURE'

export const SEARCH_USER = '@user/SEARCH_USER'
export const SEARCH_USER_SUCCESS = '@user/SEARCH_USER_SUCCESS'
export const SEARCH_USER_FAILURE = '@user/SEARCH_USER_FAILURE'

export const FETCH_INVITE_INFO = '@user/FETCH_INVITE_INFO'
export const FETCH_INVITE_INFO_SUCCESS = '@user/FETCH_INVITE_INFO_SUCCESS'
export const FETCH_INVITE_INFO_FAILURE = '@user/FETCH_INVITE_INFO_FAILURE'

export const SUBSCRIBE_BY_INVITE = '@user/SUBSCRIBE_BY_INVITE'
export const SUBSCRIBE_BY_INVITE_SUCCESS = '@user/SUBSCRIBE_BY_INVITE_SUCCESS'
export const SUBSCRIBE_BY_INVITE_FAILURE = '@user/SUBSCRIBE_BY_INVITE_FAILURE'

export const REQUEST_PASSWORD_RESET = '@user/REQUEST_PASSWORD_RESET'
export const REQUEST_PASSWORD_RESET_SUCCESS =
  '@user/REQUEST_PASSWORD_RESET_SUCCESS'
export const REQUEST_PASSWORD_RESET_FAILURE =
  '@user/REQUEST_PASSWORD_RESET_FAILURE'

export const RESET_PASSWORD = '@user/RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = '@user/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = '@user/RESET_PASSWORD_FAILURE'

export const UPDATE_USER = '@user/UPDATE_USER'
export const UPDATE_USER_SUCCESS = '@user/UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = '@user/UPDATE_USER_FAILURE'

export const UPDATE_USER_ADDRESS = '@user/UPDATE_USER_ADDRESS'
export const UPDATE_USER_ADDRESS_SUCCESS = '@user/UPDATE_USER_ADDRESS_SUCCESS'
export const UPDATE_USER_ADDRESS_FAILURE = '@user/UPDATE_USER_ADDRESS_FAILURE'

export const UPDATE_PASSWORD = '@user/UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = '@user/UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = '@user/UPDATE_PASSWORD_FAILURE'
