import { ReactComponent as AmexSVG } from 'assets/images/card/american-express.svg'
import { ReactComponent as DinersSVG } from 'assets/images/card/diners-club.svg'
import { ReactComponent as DiscoverSVG } from 'assets/images/card/discover.svg'
import { ReactComponent as JcbSVG } from 'assets/images/card/jcb.svg'
import { ReactComponent as MasterSVG } from 'assets/images/card/mastercard.svg'
import { ReactComponent as VisaSVG } from 'assets/images/card/visa.svg'
import { ReactComponent as CreditCardChipSVG } from 'assets/images/credit-card-chip.svg'

import { CardContainer, CardBack, CardFront } from './styled'

type CreditCardProps = {
  type?: string
  number?: string
  name?: string
  expiration?: string
  cvv?: string
}

export function CreditCard({
  type,
  number,
  name,
  expiration,
  cvv
}: CreditCardProps): JSX.Element {
  function renderFlag() {
    if (!type) return null

    switch (type) {
      case 'amex':
        return <AmexSVG />

      case 'diners':
        return <DinersSVG />

      case 'jcb':
        return <JcbSVG />

      case 'visa':
        return <VisaSVG />

      case 'mastercard':
        return <MasterSVG />

      case 'discover':
        return <DiscoverSVG />

      default:
        return null
    }
  }

  return (
    <CardContainer>
      <CardFront>
        <div>
          <span>
            <CreditCardChipSVG />
          </span>
          <span>{renderFlag()}</span>
        </div>
        <div>{number || '**** **** **** ****'}</div>
        <div>
          <span>{name || '***************'}</span>
          <span>{expiration || '**/**'}</span>
        </div>
      </CardFront>
      <CardBack>
        <div />
        <div>{cvv || '***'}</div>
      </CardBack>
    </CardContainer>
  )
}
