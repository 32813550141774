import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import formatISO from 'date-fns/formatISO'

import ptBR from 'date-fns/locale/pt-BR'

export function formatDateBR(value: string): string {
  const date = new Date(value)

  if (Number.isNaN(date.getTime())) {
    return value
  }

  return format(date, 'dd/MM/yyyy')
}

export function formatDateTimeBR(value: string): string {
  const date = new Date(value)

  if (Number.isNaN(date.getTime())) {
    return value
  }

  return format(date, "dd/MM/yyyy 'às' HH:mm")
}

export function formatDistanceFromNow(value: string): string {
  const date = new Date(value)

  if (Number.isNaN(date.getTime())) {
    return value
  }

  return formatDistanceToNow(date, {
    addSuffix: true,
    includeSeconds: false,
    locale: ptBR
  })
}

export function formatDateISO(value: Date): string {
  return formatISO(value)
}

export function formatCurrencyBR(value: number): string {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

export function formatCnpj(value: string): string {
  const chars = value.split('')

  chars.splice(2, 0, '.')
  chars.splice(6, 0, '.')
  chars.splice(10, 0, '/')
  chars.splice(15, 0, '-')

  return chars.join('')
}

export function getTextFromHTMLString(data: string): string {
  return new DOMParser().parseFromString(data, 'text/html').documentElement
    .innerText
}
