import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, ModalHeader } from 'components/modal'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'

import { ModalName } from 'types/enums/ModalName'

import { ModalBody, DescriptionItem, Divider } from './styled'

export function ContestTransferInstitutionStatusModal(): JSX.Element {
  const { t } = useTranslation(['modal', 'common'])

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    modal: { contestTransferInstitutionStatusIsOpen },
    contest: { contestData, nominationAnswer }
  } = useSelector((state: GlobalState) => state)

  return (
    <Modal
      isOpen={contestTransferInstitutionStatusIsOpen}
      onClose={() =>
        dispatch(closeModal(ModalName.CONTEST_TRANSFER_INSTITUTION_STATUS))
      }
    >
      <ModalHeader separator={false}>
        {contestData?.nominate?.answer === null && <h2>Nomeação pendente</h2>}
        {contestData?.nominate?.answer === 'false' && (
          <h2>Nomeação rejeitada</h2>
        )}
      </ModalHeader>

      <ModalBody>
        <DescriptionItem>
          <span>Instituição nomeada:</span>
          <span>{contestData?.nominate?.targetInstitutionName}</span>
        </DescriptionItem>
        <DescriptionItem>
          <span>Status da nomeação:</span>
          {contestData?.nominate?.answer === null && (
            <span>Nomeação pendente</span>
          )}
          {contestData?.nominate?.answer === 'false' && (
            <span>Nomeação rejeitada</span>
          )}
        </DescriptionItem>
        {contestData?.nominate?.answer === 'false' && <Divider />}
        {contestData?.nominate?.answer === 'false' && (
          <div>
            <h3>Justificativa</h3>
            <p style={{ textAlign: 'center', marginBottom: '20px' }}>
              {contestData?.nominate?.answerMessage}
            </p>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}
