import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import { PageLoading } from 'components/pageLoading'

import { LOGIN_PAGE } from 'navigation/paths'
import { routes } from 'navigation/routes'

import { GlobalState } from 'store'
import { getUserData } from 'store/session/actions'

export function AppRouter(): JSX.Element {
  const dispatch = useDispatch()

  const { loggedIn, token, user, getUserInProgress } = useSelector(
    (state: GlobalState) => state.session
  )

  useEffect(() => {
    if (loggedIn && token && !user) {
      dispatch(getUserData(token))
    }
  }, [loggedIn])

  function _showLoader(): boolean {
    if (getUserInProgress) return true

    if (loggedIn && token && !user) return true

    return false
  }

  return _showLoader() ? (
    <PageLoading />
  ) : (
    <Switch>
      {routes.map(
        ({ path, page: Page, exact, isPublic, validation }, index) => {
          return isPublic ? (
            <Route key={index} path={path} exact={exact}>
              <Page />
            </Route>
          ) : (
            <Route
              key={index}
              path={path}
              exact={exact}
              render={({ location, history }) => {
                if (loggedIn) {
                  if (!validation || (validation && validation())) {
                    return <Page />
                  }

                  // go back when validation fails
                  history.goBack()
                  return
                }

                // redirect to login page
                return (
                  <Redirect
                    to={{
                      pathname: LOGIN_PAGE,
                      search:
                        location.pathname !== '/'
                          ? `?redirectUrl=${location.pathname}`
                          : undefined
                    }}
                  />
                )
              }}
            />
          )
        }
      )}
    </Switch>
  )
}
