import styled from 'styled-components'

export const MainContainer = styled.div`
  form {
    max-width: 540px;

    margin: auto;
    margin-top: ${({ theme }) => theme.spacings[10]};
  }
`
