import styled from 'styled-components'

import { Colors, Spacings } from 'styles/Theme'

type SeparatorWrapperProps = {
  color: keyof Colors
  marginBottom: keyof Spacings
}

export const SeparatorWrapper = styled.hr<SeparatorWrapperProps>`
  opacity: 0.6;

  margin-bottom: ${({ theme, marginBottom }) => theme.spacings[marginBottom]};

  border-color: ${({ theme, color }) => theme.colors[color]};
  background-color: ${({ theme, color }) => theme.colors[color]};
`
