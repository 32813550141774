import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import { browserHistory } from 'navigation/browserHistory'
import { LOGIN_PAGE } from 'navigation/paths'

import * as userService from 'services/http/userService'

import { getUserDataFailure, getUserDataSuccess } from 'store/session/actions'
import { GET_USER_DATA } from 'store/session/actionTypes'

import { Token } from 'types/session/Token'
import { User } from 'types/user/User'

function* getUserByToken(payload: Token): Generator<StrictEffect, void, User> {
  try {
    const response = yield call(userService.getUserByToken, payload)
    yield put(getUserDataSuccess(response))
  } catch (error) {
    yield call(browserHistory.push, LOGIN_PAGE)
    yield put(getUserDataFailure())
  }
}

export function* sessionSagas(): Generator {
  yield all([takeLatest(GET_USER_DATA, getUserByToken)])
}
