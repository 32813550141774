import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ReactComponent as DownloadSVG } from 'assets/images/download.svg'

import { Button } from 'components/button'
import { Flex } from 'components/flex'
import { IconButton } from 'components/iconButton'
import { Modal, ModalHeader } from 'components/modal'
import { Separator } from 'components/separator'
import { Table } from 'components/table'
import { Tooltip } from 'components/tooltip'

import { confirmationConfig } from 'config/swal'

import { getContestFileUrl } from 'services/http/contestFileService'

import { GlobalState } from 'store'
import { closeModal, openModal } from 'store/modal/actions'
import { acceptAgreement } from 'store/contest/actions'

import Theme from 'styles/Theme'

import { ContestUpdateAction } from 'types/enums/ContestUpdateAction'
import { ModalName } from 'types/enums/ModalName'

import { showError } from 'utils/alertUtils'
import { formatDateBR } from 'utils/formatUtils'

import { ContestHeading, ModalBody, ModalHeaderContent } from './styled'

export function ContestAcceptAgreementModal(): JSX.Element {
  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    modal: { contestAcceptAgreementIsOpen },
    contest: { contestData, acceptAgreementInProgress, availableActions }
  } = useSelector((state: GlobalState) => state)

  const [downloadFileInProgress, setDownloadFileInProgress] = useState<boolean>(
    false
  )

  const canSendNewDocument = useMemo<boolean>(
    () =>
      availableActions.findIndex(
        (action) => action.actionName === ContestUpdateAction.NEW_DOCUMENT
      ) >= 0,
    [availableActions]
  )

  async function _downloadFile(key: string) {
    try {
      setDownloadFileInProgress(true)

      const { url, file } = await getContestFileUrl(key)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.target = '__blank'
      downloadLink.download = file.filename

      document.body.appendChild(downloadLink)

      downloadLink.click()

      downloadLink.parentNode?.removeChild(downloadLink)
    } catch (error) {
      showError('downloadContestFile', error)
    } finally {
      setDownloadFileInProgress(false)
    }
  }

  function _getData() {
    if (!contestData) return null

    const data = [...contestData.data]
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1
        }

        if (a.createdAt < b.createdAt) {
          return 1
        }

        return 0
      })
      .filter(
        ({ action }) =>
          action === ContestUpdateAction.SUBMIT_DOCUMENT ||
          action === ContestUpdateAction.NEW_DOCUMENT
      )
      .shift()

    return data
  }

  function _handleAcceptButtonClick() {
    if (contestData && sessionUser) {
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          title: t('contestAcceptAgreement.confirmation.title'),
          html: (
            <span
              dangerouslySetInnerHTML={{
                __html: t('contestAcceptAgreement.confirmation.message')
              }}
            />
          ),
          confirmButtonText: t(
            'contestAcceptAgreement.confirmation.confirmButton'
          ),
          cancelButtonText: t(
            'contestAcceptAgreement.confirmation.cancelButton'
          )
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            dispatch(
              acceptAgreement({
                contestId: contestData.id,
                action: ContestUpdateAction.ACCEPT_DOCUMENT,
                data: {
                  text: _getData()?.files[0]?.storageKey ?? ''
                }
              })
            )
          }
        })
    }
  }

  return (
    <Modal
      isOpen={contestAcceptAgreementIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_ACCEPT_AGREEMENT))}
    >
      <ModalHeader separator={false}>
        <ModalHeaderContent>
          <h2>{t('contestAcceptAgreement.title')}</h2>
          <h4>
            {t('contestAcceptAgreement.subtitle', {
              contestId: contestData?.id
            })}
          </h4>

          <ContestHeading>
            <dl>
              <dt>{t('contestAcceptAgreement.heading.requester')}</dt>
              <dd>{contestData?.requester}</dd>
              <dt>{t('contestAcceptAgreement.heading.defendant')}</dt>
              <dd>{contestData?.defendant}</dd>
              <dt>{t('contestAcceptAgreement.heading.category')}</dt>
              {!!contestData && contestData?.category.length > 30 ? (
                <Tooltip content={contestData?.category}>
                  <dd>{contestData?.category.substr(0, 30) + '...'}</dd>
                </Tooltip>
              ) : (
                <dd>{contestData?.category}</dd>
              )}
              <dt>{t('contestAcceptAgreement.heading.createdAt')}</dt>
              <dd>{formatDateBR(new Date().toISOString())}</dd>
            </dl>
          </ContestHeading>

          <Separator color="warmGreyThree" />
        </ModalHeaderContent>
      </ModalHeader>

      <ModalBody>
        <Table
          columns={[
            {
              label: t('contestAcceptAgreement.table.filename'),
              dataKey: 'filename',
              tdStyle: { wordBreak: 'break-word' }
            },
            {
              label: t('contestAcceptAgreement.table.createdAt'),
              dataKey: 'createdAt',
              thStyle: { width: '140px' },
              tdStyle: { width: '140px' },
              render: (row) => formatDateBR(row.createdAt)
            },
            {
              label: t('contestAcceptAgreement.table.actions.title'),
              dataKey: 'actions',
              thStyle: { width: '50px' },
              tdStyle: { width: '50px' },
              render: (row) => (
                <Flex direction="row" gap={2}>
                  <IconButton
                    tooltip={t('contestAcceptAgreement.table.actions.download')}
                    color="niceBlue"
                    onClick={() => _downloadFile(row.storageKey)}
                    disabled={downloadFileInProgress}
                  >
                    {downloadFileInProgress ? (
                      <BounceLoader size="16px" color={Theme.colors.niceBlue} />
                    ) : (
                      <DownloadSVG />
                    )}
                  </IconButton>
                </Flex>
              )
            }
          ]}
          data={_getData()?.files ?? []}
          pagination={false}
        />

        <Flex direction="row" gap={6} align="center" justify="center">
          {canSendNewDocument && (
            <Button
              type="button"
              color="niceBlue"
              variant="outline"
              small
              onClick={() =>
                dispatch(openModal(ModalName.CONTEST_SEND_NEW_AGREEMENT))
              }
            >
              {t('contestAcceptAgreement.sendNewButton')}
            </Button>
          )}

          <Button
            type="button"
            color="white"
            backgroundColor="lightTealTwo"
            small
            inProgress={acceptAgreementInProgress}
            onClick={_handleAcceptButtonClick}
          >
            {t('contestAcceptAgreement.acceptButton')}
          </Button>
        </Flex>
      </ModalBody>
    </Modal>
  )
}
