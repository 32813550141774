import { Form, Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { Flex, FlexItem } from 'components/flex'
import { Modal, ModalHeader, ModalBody } from 'components/modal'
import { Select } from 'components/select'
import { Textarea } from 'components/textarea'

import { searchUserContests } from 'services/http/contestService'

import { GlobalState } from 'store'
import { createContestAssociation } from 'store/contestAssociation/actions'
import { closeModal } from 'store/modal/actions'

import { ModalName } from 'types/enums/ModalName'
import { SelectOption } from 'types/SelectOption'

// import { Select } from '../../../contestList/components/select'

import {} from './styled'

export function ContestAddAssociationModal(): JSX.Element {
  const { t } = useTranslation(['modal'])

  const dispatch = useDispatch()

  const {
    modal: { contestAddAssociationIsOpen },
    contest: { contestData },
    contestAssociation: { createContestAssociationInProgress }
  } = useSelector((state: GlobalState) => state)

  const [contestOptions, setContestOptions] = useState<SelectOption[]>([])
  const [
    fetchContestsInProgress,
    setFetchContestsInProgress
  ] = useState<boolean>(false)

  useEffect(() => {
    _fetchContestsRequest('')
  }, [])

  async function _fetchContestsRequest(search: string) {
    try {
      setFetchContestsInProgress(true)

      const { data } = await searchUserContests({
        search,
        page: 1,
        limit: 5,
        orderBy: 'id',
        order: 'asc'
      })

      const options = data
        .filter(({ id }) => id !== contestData?.id)
        .map(({ id, requester, defendant }) => ({
          label: `${id} - Requerente ${requester}, Requerida ${defendant}`,
          value: id
        }))

      setContestOptions(options)
    } catch (error) {
      console.log('failed to fetch contests', error)
      setContestOptions([])
    } finally {
      setFetchContestsInProgress(false)
    }
  }

  function _handleSubmit(data: FormikValues) {
    if (!contestData?.id) return

    dispatch(
      createContestAssociation({
        contestA: contestData.id,
        contestB: data.contestId,
        reason: data.reason
      })
    )
  }

  return (
    <Modal
      isOpen={contestAddAssociationIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_ADD_ASSOCIATION))}
    >
      <ModalHeader separator={false}>
        <h2>{t('modal:contestAddAssociation.title')}</h2>
      </ModalHeader>

      <ModalBody>
        <Formik
          initialValues={{ contestId: null, reason: '' }}
          validationSchema={Yup.object().shape({
            contestId: Yup.mixed().required('required'),
            reason: Yup.string().required('required')
          })}
          onSubmit={_handleSubmit}
        >
          {() => (
            <Form>
              <Flex direction="column" align="center">
                <Select
                  name="contestId"
                  placeholder="Disputa"
                  options={contestOptions}
                  isLoading={fetchContestsInProgress}
                  onInputChange={(
                    value: string,
                    { action }: { action: string }
                  ) => {
                    if (action === 'input-change') {
                      _fetchContestsRequest(value)
                    }
                  }}
                />

                <Textarea name="reason" placeholder="Justificativa" rows={6} />

                <FlexItem>
                  <Button
                    fullWidth
                    small
                    type="submit"
                    inProgress={createContestAssociationInProgress}
                  >
                    {t('modal:contestAddAssociation.saveButton')}
                  </Button>
                </FlexItem>
              </Flex>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}
