import { CleaveOptions } from 'cleave.js/options'
import Cleave from 'cleave.js/react'
import { ChangeEvent } from 'cleave.js/react/props'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { MaskedInputContainer } from './styled'

type TextInputProps = {
  name: string
  placeholder: string
  options: CleaveOptions
  disabled?: boolean
  [key: string]: unknown
}

export function MaskedInput({
  name,
  placeholder,
  options,
  disabled,
  ...rest
}: TextInputProps): JSX.Element {
  const { t } = useTranslation(['validation'])

  const [field, meta, helpers] = useField({ name, type: 'text' })

  function _handleChange(event: ChangeEvent<HTMLInputElement>) {
    helpers.setValue(event.target.rawValue)

    if (rest.onChange)
      (rest as {
        onChange: (event: ChangeEvent<HTMLInputElement>) => void
      }).onChange(event)
  }

  return (
    <MaskedInputContainer hasError={meta.touched && !!meta.error}>
      <Cleave
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        options={options}
        {...field}
        {...rest}
        onChange={_handleChange}
        tabIndex={disabled ? -1 : 0}
      />
      {meta.touched && !!meta.error && (
        <p>
          {t(`validation:${meta.error}`, {
            field: placeholder
          })}
        </p>
      )}
    </MaskedInputContainer>
  )
}
