import { Form, Formik, FormikValues } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Yup from 'yup'

import { ReactComponent as DownloadSVG } from 'assets/images/download.svg'

import { Button } from 'components/button'
import { DateInput } from 'components/dateInput'
import { FieldSet } from 'components/fieldSet'
import { FilesControl } from 'components/filesControl'
import { Flex, FlexItem } from 'components/flex'
import { IconButton } from 'components/iconButton'
import { ErrorListener } from 'components/form'
import { Select } from 'components/select'
import { Separator } from 'components/separator'
import { Table } from 'components/table'
import { TextEditor } from 'components/textEditor'

import { confirmationConfig } from 'config/swal'

import { getContestFileUrl } from 'services/http/contestFileService'
import { fetchDirectoryInstitutions } from 'services/http/institutionService'

import { GlobalState } from 'store'
import { createContest, fetchContestCategories } from 'store/contest/actions'

import Theme from 'styles/Theme'

import { SelectOption } from 'types/SelectOption'

import { showError } from 'utils/alertUtils'
import { formatDateBR, formatDateISO } from 'utils/formatUtils'

import { BasicInfoStepWrapper, Field, TextEditorField } from './styled'
import { FilesControlWrapper } from 'components/filesControl/styled'

type BasicInfoStepProps = {
  goForward: () => void
}

export function BasicInfoStep({ goForward }: BasicInfoStepProps): JSX.Element {
  const { t } = useTranslation('pages')

  const dispatch = useDispatch()

  const {
    contest: {
      getContestByIdInProgress,
      createContestInProgress,
      fetchContestCategoriesInProgress,
      contestCategories,
      contestData
    },
    session: { user }
  } = useSelector((state: GlobalState) => state)

  const { contestId } = useParams<{ contestId: string }>()

  const [
    fetchUserInstitutionsInProgress,
    setFetchUserInstitutionsInProgress
  ] = useState<boolean>(false)
  const [userInstitutions, setUserInstitutions] = useState<SelectOption[]>([])

  const [
    fetchInstitutionsInProgress,
    setFetchInstitutionsInProgress
  ] = useState<boolean>(false)
  const [institutions, setInstitutions] = useState<SelectOption[]>([])

  const [downloadFileInProgress, setDownloadFileInProgress] = useState<boolean>(
    false
  )

  const serviceOptions = useMemo<SelectOption[]>(
    () =>
      contestCategories
        .map((item) => ({
          label: item.description,
          value: item.id
        }))
        .sort((a, b) => {
          if (a.label > b.label) {
            return 1
          }

          if (a.label < b.label) {
            return -1
          }

          return 0
        }),
    [contestCategories]
  )

  const readOnly = useMemo<boolean>(() => {
    return !!contestId && !getContestByIdInProgress && !!contestData
  }, [getContestByIdInProgress, contestData])

  const initialValues = {
    requester: '',
    defendant: '',
    service: undefined,
    scene: undefined,
    category: undefined,
    occurredAt: '',
    description: '',
    documents: []
  }

  const validationSchema = Yup.object().shape({
    requester: Yup.string().required('required'),
    defendant: Yup.string().required('required'),
    service: Yup.mixed().required('required'),
    scene: Yup.mixed().required('required'),
    category: Yup.mixed().required('required'),
    occurredAt: Yup.string().required('required'),
    description: Yup.string().required('required')
  })

  useEffect(() => {
    _fetchUserInstitutionsRequest()
    _fetchInstitutionsRequest()
    dispatch(fetchContestCategories())
  }, [])

  async function _fetchUserInstitutionsRequest() {
    try {
      setFetchUserInstitutionsInProgress(true)

      const response = await fetchDirectoryInstitutions(true)

      const options = response
        .map(({ name, cnpj }) => ({
          label: name,
          value: cnpj
        }))
        .sort((a, b) => {
          if (a.label > b.label) {
            return 1
          }

          if (a.label < b.label) {
            return -1
          }

          return 0
        })

      setUserInstitutions(options)
    } catch (error) {
      console.log('failed to fetch directory institutions', error)
    } finally {
      setFetchUserInstitutionsInProgress(false)
    }
  }

  async function _fetchInstitutionsRequest() {
    try {
      setFetchInstitutionsInProgress(true)
      const response = await fetchDirectoryInstitutions()

      const options = response
        .map(({ name, cnpj }) => ({
          label: name,
          value: cnpj
        }))
        .sort((a, b) => {
          if (a.label > b.label) {
            return 1
          }

          if (a.label < b.label) {
            return -1
          }

          return 0
        })

      setInstitutions(options)
    } catch (error) {
      console.log('failed to fetch directory institutions', error)
    } finally {
      setFetchInstitutionsInProgress(false)
    }
  }

  function _mountSceneOptions(serviceId?: number): SelectOption[] {
    const result: SelectOption[] = []

    if (serviceId) {
      const service = contestCategories.find(({ id }) => id === serviceId)

      service?.scenes.forEach(({ id, description }) => {
        result.push({
          label: description,
          value: id
        })
      })
    }

    return result.sort((a, b) => {
      if (a.label > b.label) {
        return 1
      }

      if (a.label < b.label) {
        return -1
      }

      return 0
    })
  }

  function _mountCategoryOptions(
    serviceId?: number,
    sceneId?: number
  ): SelectOption[] {
    if (serviceId && sceneId) {
      const service = contestCategories.find(({ id }) => id === serviceId)

      if (service) {
        const scene = service.scenes.find(({ id }) => id === sceneId)
        return (
          scene?.categories
            .map(({ id, description }) => ({
              label: description,
              value: id
            }))
            .sort((a, b) => {
              if (a.label > b.label) {
                return 1
              }

              if (a.label < b.label) {
                return -1
              }

              return 0
            }) ?? []
        )
      }
    }

    return []
  }

  function _handleSubmit(data: FormikValues): void {
    if (user?.id) {
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          titleText: t('contestCreate.basicInfoStep.confirmCreation.title'),
          html: t('contestCreate.basicInfoStep.confirmCreation.message'),
          confirmButtonText: t(
            'contestCreate.basicInfoStep.confirmCreation.confirmButton'
          ),
          cancelButtonText: t(
            'contestCreate.basicInfoStep.confirmCreation.cancelButton'
          )
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            dispatch(
              createContest({
                requesterCnpj: data.requester,
                defendantCnpj: data.defendant,
                category: data.category,
                type: 'OPENBANK',
                occurredAt: formatDateISO(data.occurredAt),
                description: data.description,
                documents: data.documents
              })
            )
          }
        })
    }
  }

  async function _downloadFile(key: string) {
    try {
      setDownloadFileInProgress(true)

      const { url, file } = await getContestFileUrl(key)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.target = '__blank'
      downloadLink.download = file.filename

      document.body.appendChild(downloadLink)

      downloadLink.click()

      downloadLink.parentNode?.removeChild(downloadLink)
    } catch (error) {
      showError('downloadContestFile', error)
    } finally {
      setDownloadFileInProgress(false)
    }
  }

  return (
    <BasicInfoStepWrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validateOnMount={false}
        validationSchema={validationSchema}
        onSubmit={(values) => _handleSubmit(values)}
      >
        {({ values, setFieldValue }) => (
          <>
            <Form>
              <FieldSet title="">
                <Flex direction="row" gap={6}>
                  <FlexItem grow="6" basis="0">
                    {readOnly ? (
                      <Field>{contestData?.requester}</Field>
                    ) : (
                      <Select
                        name="requester"
                        placeholder={t(
                          'contestCreate.basicInfoStep.form.requester'
                        )}
                        options={userInstitutions.filter((item) => {
                          if (values.defendant) {
                            return item.value !== values.defendant
                          }

                          return true
                        })}
                        isLoading={fetchUserInstitutionsInProgress}
                      />
                    )}
                  </FlexItem>
                  <FlexItem grow="6" basis="0">
                    {readOnly ? (
                      <Field>{contestData?.defendant}</Field>
                    ) : (
                      <Select
                        name="defendant"
                        placeholder={t(
                          'contestCreate.basicInfoStep.form.defendant'
                        )}
                        options={institutions.filter((item) => {
                          if (values.requester) {
                            return item.value !== values.requester
                          }

                          return true
                        })}
                        isLoading={fetchInstitutionsInProgress}
                      />
                    )}
                  </FlexItem>
                </Flex>

                <Flex direction="row" gap={6}>
                  <FlexItem grow="6" basis="0">
                    {readOnly ? (
                      <Field>{contestData?.service}</Field>
                    ) : (
                      <Select
                        name="service"
                        placeholder={t(
                          'contestCreate.basicInfoStep.form.service'
                        )}
                        options={serviceOptions}
                        isLoading={fetchContestCategoriesInProgress}
                        onChange={() => {
                          setFieldValue('scene', undefined)
                          setFieldValue('category', undefined)
                        }}
                      />
                    )}
                  </FlexItem>
                  <FlexItem grow="6" basis="0">
                    {readOnly ? (
                      <Field>{contestData?.scene}</Field>
                    ) : (
                      <Select
                        name="scene"
                        placeholder={t(
                          'contestCreate.basicInfoStep.form.scene'
                        )}
                        options={_mountSceneOptions(values.service)}
                        isDisabled={!values.service}
                        isLoading={fetchContestCategoriesInProgress}
                        onChange={() => {
                          setFieldValue('category', undefined)
                        }}
                      />
                    )}
                  </FlexItem>
                </Flex>

                <Flex direction="row" gap={6}>
                  <FlexItem grow="6" basis="0">
                    {readOnly ? (
                      <Field>{contestData?.category}</Field>
                    ) : (
                      <Select
                        name="category"
                        placeholder={t(
                          'contestCreate.basicInfoStep.form.category'
                        )}
                        options={_mountCategoryOptions(
                          values.service,
                          values.scene
                        )}
                        isDisabled={!values.scene}
                        isLoading={fetchContestCategoriesInProgress}
                      />
                    )}
                  </FlexItem>
                  <FlexItem grow="6" basis="0">
                    {readOnly ? (
                      <Field>
                        {formatDateBR(contestData?.occurredAt ?? '')}
                      </Field>
                    ) : (
                      <DateInput
                        name="occurredAt"
                        placeholder={t(
                          'contestCreate.basicInfoStep.form.occurredAt'
                        )}
                        maxDate={new Date()}
                      />
                    )}
                  </FlexItem>
                </Flex>

                {readOnly ? (
                  <TextEditorField
                    dangerouslySetInnerHTML={{
                      __html: contestData?.data[0].text ?? ''
                    }}
                  />
                ) : (
                  <TextEditor
                    name="description"
                    placeholder={t(
                      'contestCreate.basicInfoStep.form.description'
                    )}
                  />
                )}
              </FieldSet>

              <Separator color="white" />

              {readOnly ? (
                <FilesControlWrapper hasError={false}>
                  <Flex direction="row">
                    <FlexItem grow="1">
                      <h4>Arquivos</h4>
                    </FlexItem>
                  </Flex>

                  <Table
                    columns={[
                      {
                        label: 'Arquivo',
                        dataKey: 'filename'
                      },
                      {
                        label: 'Ações',
                        dataKey: 'actions',
                        thStyle: { width: '90px', textAlign: 'center' },
                        tdStyle: { width: '90px' },
                        render: (row) => (
                          <Flex
                            direction="row"
                            align="center"
                            justify="center"
                            gap={2}
                          >
                            <IconButton
                              tooltip="Baixar arquivo"
                              color="niceBlue"
                              onClick={() => _downloadFile(row.storageKey)}
                              disabled={downloadFileInProgress}
                            >
                              {downloadFileInProgress ? (
                                <BounceLoader
                                  size="16px"
                                  color={Theme.colors.niceBlue}
                                />
                              ) : (
                                <DownloadSVG />
                              )}
                            </IconButton>
                          </Flex>
                        )
                      }
                    ]}
                    data={contestData?.data[0].files ?? []}
                    pagination={false}
                  />
                </FilesControlWrapper>
              ) : (
                <FilesControl name="documents" />
              )}

              {readOnly ? (
                <Button type="button" onClick={goForward}>
                  {t('contestCreate.basicInfoStep.nextButton')}
                </Button>
              ) : (
                <Button type="submit" inProgress={createContestInProgress}>
                  {t('contestCreate.basicInfoStep.submitButton')}
                </Button>
              )}
            </Form>
            <ErrorListener />
          </>
        )}
      </Formik>
    </BasicInfoStepWrapper>
  )
}
