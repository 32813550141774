import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR'
import DatePicker, { registerLocale } from 'react-datepicker'

import { ReactComponent as CalendarSVG } from 'assets/images/calendar.svg'

import { DateFilterContainer } from './styled'

type DateInputProps = {
  placeholder: string
  value?: Date
  onChange: (date: Date) => void
  [key: string]: unknown
}

registerLocale('pt-BR', ptBR)

export function DateInput({
  placeholder,
  value,
  onChange,
  ...props
}: DateInputProps): JSX.Element {
  return (
    <DateFilterContainer>
      <DatePicker
        locale="pt-BR"
        className="datePicker"
        popperClassName="datePickerPopper"
        showPopperArrow={false}
        dateFormat="P"
        placeholderText={placeholder}
        selected={value}
        onChange={onChange}
        autoComplete="off"
        isClearable
        {...props}
      />
      <CalendarSVG />
    </DateFilterContainer>
  )
}
