import { produce } from 'immer'

import { Action } from 'types/Action'

import { LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS } from './actionTypes'

export type LoginState = {
  loginInProgress: boolean
}

const INITIAL_STATE: LoginState = {
  loginInProgress: false
}

export function loginReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): LoginState {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOGIN: {
        draft.loginInProgress = true
        break
      }

      case LOGIN_FAILURE: {
        draft.loginInProgress = false
        break
      }

      case LOGIN_SUCCESS: {
        draft.loginInProgress = false
        break
      }

      default: {
        break
      }
    }
  })
}
