import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'
import { ContestMessage } from 'types/contestMessage/ContestMessage'

import {
  CHANGE_NEW_MESSAGE,
  FETCH_CONTEST_MESSAGES,
  FETCH_CONTEST_MESSAGES_FAILURE,
  FETCH_CONTEST_MESSAGES_SUCCESS,
  CREATE_CONTEST_MESSAGE,
  CREATE_CONTEST_MESSAGE_FAILURE,
  CREATE_CONTEST_MESSAGE_SUCCESS
} from './actionTypes'

export type ContestMessageState = {
  newMessage: string
  messages: ContestMessage[]
  fetchContestMessagesInProgress: boolean
  createContestMessageInProgress: boolean
}

const INITIAL_STATE: ContestMessageState = {
  newMessage: '',
  messages: [],
  fetchContestMessagesInProgress: false,
  createContestMessageInProgress: false
}

export function contestMessageReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): ContestMessageState {
  return produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_NEW_MESSAGE: {
        draft.newMessage = <string>action.payload
        break
      }

      case FETCH_CONTEST_MESSAGES: {
        draft.fetchContestMessagesInProgress = true
        break
      }

      case FETCH_CONTEST_MESSAGES_FAILURE: {
        draft.fetchContestMessagesInProgress = false
        break
      }

      case FETCH_CONTEST_MESSAGES_SUCCESS: {
        draft.messages = <ContestMessage[]>action.payload
        draft.fetchContestMessagesInProgress = false
        break
      }

      case CREATE_CONTEST_MESSAGE: {
        draft.createContestMessageInProgress = true
        break
      }

      case CREATE_CONTEST_MESSAGE_FAILURE: {
        draft.createContestMessageInProgress = false
        break
      }

      case CREATE_CONTEST_MESSAGE_SUCCESS: {
        draft.newMessage = ''
        draft.messages = <ContestMessage[]>action.payload
        draft.createContestMessageInProgress = false
        break
      }

      case CLEAR_SESSION_DATA: {
        draft.newMessage = ''
        draft.messages = []
        draft.fetchContestMessagesInProgress = false
        draft.createContestMessageInProgress = false
        break
      }

      default: {
        break
      }
    }
  })
}
