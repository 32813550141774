import { FormikValues } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { Form } from 'components/form'
import { Modal, ModalHeader } from 'components/modal'
import { Select } from 'components/select'
import { TextInput } from 'components/textInput'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'
import { updateContestMemberPermission } from 'store/contest/actions'

import { ContestMemberPermission } from 'types/enums/ContestMemberPermission'
import { ModalName } from 'types/enums/ModalName'

import { ModalBody } from './styled'

type Props = {
  initialValues: FormikValues
}

export function ContestEditMemberModal({ initialValues }: Props): JSX.Element {
  const { t } = useTranslation(['modal', 'common'])

  const dispatch = useDispatch()

  const {
    modal: { contestEditMemberIsOpen },
    contest: { updateContestMemberPermissionInProgress }
  } = useSelector((state: GlobalState) => state)

  const validationSchema = Yup.object().shape({
    userToUpdate: Yup.string().email('email').required('required'),
    permission: Yup.string().required('required')
  })

  useEffect(() => {
    if (!contestEditMemberIsOpen) {
      const resetForm = document.getElementById('resetForm')
      if (resetForm) resetForm.click()
    }
  }, [contestEditMemberIsOpen])

  function _handleSubmit(data: FormikValues) {
    dispatch(
      updateContestMemberPermission({
        contestId: data.contestId,
        userToUpdate: data.userToUpdate,
        permission: data.permission
      })
    )
  }

  return (
    <Modal
      isOpen={contestEditMemberIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_EDIT_MEMBER))}
    >
      <ModalHeader separator={false}>
        <h2>{t('modal:contestEditMember.title')}</h2>
      </ModalHeader>

      <ModalBody>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => _handleSubmit(values)}
        >
          <TextInput
            name="userToUpdate"
            placeholder={t('modal:contestEditMember.form.email')}
            disabled
          />

          <Select
            name="permission"
            placeholder={t('modal:contestEditMember.form.permission')}
            options={[
              {
                label: t('common:contestMemberPermissions.EDIT'),
                value: ContestMemberPermission.EDIT
              },
              {
                label: t('common:contestMemberPermissions.READ'),
                value: ContestMemberPermission.READ
              }
            ]}
          />

          <Button
            fullWidth
            small
            type="submit"
            inProgress={updateContestMemberPermissionInProgress}
          >
            {t('modal:contestEditMember.form.submitButton')}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}
