import { useState, KeyboardEvent, useEffect, useRef, MouseEvent } from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CloseSVG } from 'assets/images/close.svg'

import { TagsInputContainer } from './styled'

type TextInputProps = {
  name: string
  placeholder: string
  initialValue?: string[]
  [key: string]: unknown
}

export function TagsInput({
  name,
  placeholder,
  initialValue,
  ...props
}: TextInputProps): JSX.Element {
  const { t } = useTranslation(['validation'])

  const inputRef = useRef<HTMLInputElement>(null)

  const [tags, setTags] = useState<string[]>(initialValue ?? [])

  const [field, meta, helpers] = useField({ name, type: 'text' })

  useEffect(() => {
    if (field.value) {
      const tags = field.value.split(',').map((tag: string) => tag.trim())
      setTags(tags)
    }
  }, [field.value])

  useEffect(() => {
    if (initialValue) helpers.setValue(tags.join(','))
  }, [])

  function _handleKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return

    event.preventDefault()

    const input = event.currentTarget

    const newTag = input.value.trim()

    const alreadyExists = tags.some(
      (tag: string) => tag.toUpperCase() === newTag.toUpperCase()
    )
    if (!alreadyExists) {
      const newTags = [...tags, newTag]

      setTags(newTags)
      helpers.setValue(newTags.join(','))
    }

    input.value = ''
  }

  function _removeTag(event: MouseEvent<HTMLButtonElement>, tag: string) {
    event.preventDefault()

    const newTags = tags.filter((t) => t !== tag)

    setTags(newTags)
    helpers.setValue(newTags.join(','))
  }

  return (
    <TagsInputContainer hasError={meta.touched && !!meta.error}>
      <div>
        <ul>
          {tags.map((tag, index) => (
            <li key={index}>
              <span>{tag}</span>
              <button type="button" onClick={(event) => _removeTag(event, tag)}>
                <CloseSVG />
              </button>
            </li>
          ))}
        </ul>
        <input
          ref={inputRef}
          name={name}
          type="text"
          placeholder={placeholder}
          onBlur={() => helpers.setTouched(true)}
          onKeyDown={_handleKeyPress}
          autoComplete="off"
          {...props}
        />
      </div>
      {meta.touched && !!meta.error && (
        <p>
          {t(`validation:${meta.error}`, {
            field: placeholder
          })}
        </p>
      )}
    </TagsInputContainer>
  )
}
