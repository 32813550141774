export const steps = {
  AWAITING_PAYMENT: 'INACTIVE, WAITING_BOLETO',
  OPEN: 'OPEN',
  ANSWER: 'ANSWER',
  REPLICATION: 'REPLICATION',
  REJOINDER: 'REJOINDER',
  RESOLVED: 'RESOLVED R, RESOLVED D',
  AWAITING_DOCUMENT: 'AWAITING DOCUMENT R, AWAITING DOCUMENT D',
  AWAITING_SUBSCRIBERS:
    'AWAITING SUBSCRIBERS, AWAITING SUBSCRIBERS R, AWAITING SUBSCRIBERS D',
  AWAITING_SIGNATURE: 'AWAITING SIGNATURE',
  ELIGIBLE_FOR_ARBITRATION: 'ELIGIBLE FOR ARBITRATION',
  CANCELED: 'CANCELED',
  FINISHED: 'EXPIRED, ABORTED, FINISHED'
}
