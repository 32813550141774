import styled from 'styled-components'

export const MessagesContainer = styled.div`
  & > ul {
    padding: ${({ theme }) => theme.spacings[6]};

    list-style: none;

    margin-bottom: ${({ theme }) => theme.spacings[10]};

    li {
      position: relative;

      max-width: 600px;

      padding: ${({ theme }) => theme.spacings[4]};

      border: 1px solid ${({ theme }) => theme.colors.paleGrey};

      background-color: ${({ theme }) => theme.colors.paleGreyThree};

      & > h4 {
        color: ${({ theme }) => theme.colors.niceBlue};
        margin-bottom: ${({ theme }) => theme.spacings[0.5]};
      }

      & > h5 {
        font-weight: normal;
        color: ${({ theme }) => theme.colors.greyishBrownFive};
        margin-bottom: ${({ theme }) => theme.spacings[0.5]};
      }

      & > time {
        font-size: ${({ theme }) => theme.fontSizes.xsm};
        color: ${({ theme }) => theme.colors.warmGrey};
      }

      & > p {
        margin-top: ${({ theme }) => theme.spacings[4]};
      }

      &::after {
        content: '';

        position: absolute;
        bottom: -1.25rem;
        left: -1px; // border

        height: 1.25rem;
        width: 1.25rem;

        border-left: 1px solid ${({ theme }) => theme.colors.paleGrey};
        border-top: none;

        background: ${({ theme }) =>
          `linear-gradient(135deg, ${theme.colors.paleGreyThree} 0 48%, ${theme.colors.paleGrey} 48% 52%, #fff 50%)`};
      }
    }

    li[data-side='right'] {
      margin-left: auto;

      border: 1px solid ${({ theme }) => theme.colors.paleBlueTwo};

      background-color: ${({ theme }) => theme.colors.duckEggBlueFour};

      &::after {
        content: '';

        position: absolute;
        bottom: -1.25rem;
        right: -1px; // border
        left: auto; // border

        height: 1.25rem;
        width: 1.25rem;

        border-right: 1px solid ${({ theme }) => theme.colors.paleBlueTwo};
        border-left: none;
        border-top: none;

        background: ${({ theme }) =>
          `linear-gradient(45deg, #fff 0 48%, ${theme.colors.paleBlueTwo} 48% 52%, ${theme.colors.duckEggBlueFour} 52%)`};
      }
    }

    li + li {
      margin-top: ${({ theme }) => theme.spacings[6]};
    }
  }
`

export const InputContainer = styled.div`
  position: relative;

  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[6]}`};

  background-color: ${({ theme }) => theme.colors.paleGrey};

  input {
    width: 100%;

    padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[6]}`};
    padding-right: ${({ theme }) =>
      `calc(${theme.spacings[4]} * 2 + ${theme.fontSizes.sm} + 10px)`};

    color: ${({ theme }) => theme.colors.greyishBrownFour};

    border: 1px solid ${({ theme }) => theme.colors.paleGrey};
    border-radius: 2rem;

    background-color: ${({ theme }) => theme.colors.white};

    transition: 0.3s;

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    &::placeholder {
      color: ${({ theme }) => theme.colors.pinkishGrey};
      opacity: 1; /* Firefox */
    }

    /* Internet Explorer 10-11, Microsoft Edge */
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.pinkishGrey};
    }

    &:focus:not(:read-only):not(:disabled) {
      z-index: 1;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.niceBlue};
      border-color: ${({ theme }) => theme.colors.niceBlue};
    }
  }

  button {
    position: absolute;
    top: 25%;
    right: ${({ theme }) => `calc(${theme.spacings[6]} + 5px)`};

    line-height: 0;

    width: ${({ theme }) =>
      `calc(${theme.spacings[4]} * 2 + ${theme.fontSizes.sm})`};
    height: 50%;

    border: none;
    border-radius: 50%;

    color: ${({ theme }) => theme.colors.white};

    background-color: ${({ theme }) => theme.colors.darkAqua};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    svg {
      height: 50%;
    }

    &:hover:not(:disabled) {
      opacity: 0.9;
      transition: 0.3s;
    }
  }
`
