type UsePaginationProps = {
  page: number
  pageCount?: number
  boundaryCount?: number
  siblingCount?: number
}

type UsePaginationReturn = {
  startPages: number[]
  middlePages: number[]
  endPages: number[]
  showStartEllipsis: boolean
  showEndEllipsis: boolean
}

export function usePagination({
  page,
  pageCount = 1,
  boundaryCount = 2,
  siblingCount = 0
}: UsePaginationProps): UsePaginationReturn {
  function range(start: number, end: number): number[] {
    const length = end - start + 1
    return Array.from({ length }, (_, i: number) => start + i)
  }

  const startPages = range(1, Math.min(boundaryCount, pageCount))

  const endPages = range(
    Math.max(pageCount - boundaryCount + 1, boundaryCount + 1),
    pageCount
  )

  const siblingsStart = Math.max(
    Math.min(
      page - siblingCount,
      pageCount - boundaryCount - siblingCount * 2 - 1
    ),
    boundaryCount + 2
  )

  const siblingsEnd = Math.min(
    Math.max(page + siblingCount, boundaryCount + siblingCount * 2 + 2),
    endPages.length > 0 ? endPages[0] - 2 : pageCount - 1
  )

  const middlePages = range(siblingsStart, siblingsEnd)

  const showStartEllipsis = siblingsStart > boundaryCount + 2

  const showEndEllipsis = siblingsEnd < pageCount - boundaryCount - 1

  if (!showStartEllipsis && boundaryCount + 1 < pageCount - boundaryCount) {
    startPages.push(boundaryCount + 1)
  }

  if (!showEndEllipsis && pageCount - boundaryCount > boundaryCount) {
    endPages.unshift(pageCount - boundaryCount)
  }

  return {
    startPages,
    middlePages,
    endPages,
    showStartEllipsis,
    showEndEllipsis
  }
}
