import { FormikValues } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Modal, ModalHeader } from 'components/modal'
import { Form } from 'components/form'
import { Flex, FlexItem } from 'components/flex'
import { TextInput } from 'components/textInput'
import { Button } from 'components/button'

import { GlobalState } from 'store'
import { requestPasswordReset } from 'store/user/actions'
import { closeModal } from 'store/modal/actions'

import { ModalName } from 'types/enums/ModalName'

import { ModalBody } from './styled'

export function ForgetPasswordModal(): JSX.Element {
  const { t } = useTranslation(['modal'])

  const dispatch = useDispatch()

  const { requestPasswordResetInProgress } = useSelector(
    (state: GlobalState) => state.user
  )
  const { forgetPasswordIsOpen } = useSelector(
    (state: GlobalState) => state.modal
  )

  const initialValues = {
    username: '',
    email: ''
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('required'),
    email: Yup.string().email('email').required('required')
  })

  useEffect(() => {
    if (!forgetPasswordIsOpen) {
      document
        .querySelectorAll<HTMLButtonElement>('#resetForm')
        .forEach((el) => el.click())
    }
  }, [forgetPasswordIsOpen])

  function _handleSubmit(data: FormikValues) {
    dispatch(
      requestPasswordReset({ username: data.username, email: data.email })
    )
  }

  return (
    <Modal
      isOpen={forgetPasswordIsOpen}
      onClose={() => dispatch(closeModal(ModalName.FORGET_PASSWORD))}
    >
      <ModalHeader>
        <h2>{t('modal:forgetPassword.title')}</h2>
      </ModalHeader>

      <ModalBody>
        <p>{t('modal:forgetPassword.text1')}</p>
        <p>{t('modal:forgetPassword.text2')}</p>

        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => _handleSubmit(values)}
        >
          <Flex direction="row" gap={6}>
            <FlexItem grow="6" basis="0">
              <TextInput
                name="username"
                placeholder={t('modal:forgetPassword.form.username')}
                autoComplete="off"
              />
            </FlexItem>
            <FlexItem grow="6" basis="0" />
          </Flex>
          <Flex direction="row" gap={6}>
            <FlexItem grow="1">
              <TextInput
                name="email"
                placeholder={t('modal:forgetPassword.form.email')}
                autoComplete="email"
              />
            </FlexItem>
            <FlexItem>
              <Button
                fullWidth
                small
                type="submit"
                inProgress={requestPasswordResetInProgress}
              >
                {t('modal:forgetPassword.form.submitButton')}
              </Button>
            </FlexItem>
          </Flex>
        </Form>
      </ModalBody>
    </Modal>
  )
}
