import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

import { TDocumentDefinitions } from 'pdfmake/interfaces'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export function downloadPDF(filename: string, dd: TDocumentDefinitions): void {
  pdfMake.createPdf(dd).download(filename)
}

export function openPDF(dd: TDocumentDefinitions): void {
  pdfMake.createPdf(dd).open()
}
