import styled from 'styled-components'

export const TermsAndConditionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  article {
    flex: 1;

    padding: ${({ theme }) => `${theme.spacings[10]} ${theme.spacings[6]}`};
    border: 1px solid ${({ theme }) => theme.colors.warmGreyThree};

    margin-bottom: ${({ theme }) => theme.spacings[2]};

    max-height: 50vh;

    overflow-y: auto;

    /* h2 {
      text-align: center;
      color: ${({ theme }) => theme.colors.greyishBrownFive};
    }

    p {
      color: ${({ theme }) => theme.colors.pinkishGrey};
      margin-bottom: ${({ theme }) => theme.spacings[4]};
    } */
  }

  button {
    margin: 0 auto;
  }

  button + button {
    margin-top: ${({ theme }) => theme.spacings[2]};
  }
`

export const AcceptTermsCheck = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.spacings[2]};

  label {
    order: 2;
    margin-left: ${({ theme }) => theme.spacings[2]};
    /* color: ${({ theme }) => theme.colors.pinkishGrey}; */
  }
`
