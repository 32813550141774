import { PropsWithChildren, useState } from 'react'
import { PulseLoader } from 'react-spinners'

import { Tooltip } from 'components/tooltip'

import Theme, { Colors } from 'styles/Theme'

import { ButtonContainer } from './styled'

type Color = keyof Colors

type ButtonProps = PropsWithChildren<{
  tooltip?: string
  type?: 'button' | 'submit' | 'reset'
  color?: Color
  backgroundColor?: Color
  variant?: 'solid' | 'outline' | 'link'
  fullWidth?: boolean
  disabled?: boolean
  inProgress?: boolean
  small?: boolean
  onClick?: () => void
  [key: string]: unknown
}>

export function Button({
  tooltip,
  type = 'button',
  color = 'white',
  backgroundColor = 'niceBlue',
  variant = 'solid',
  fullWidth = false,
  disabled,
  inProgress,
  small,
  children,
  onClick = () => undefined,
  ...rest
}: ButtonProps): JSX.Element {
  const [showSplash] = useState<boolean>(false)

  return tooltip ? (
    <Tooltip content={tooltip}>
      <ButtonContainer
        type={type}
        color={color}
        backgroundColor={backgroundColor}
        variant={variant}
        fullWidth={fullWidth}
        disabled={disabled || inProgress}
        small={small}
        showSplash={showSplash}
        onClick={() => onClick()}
        {...rest}
      >
        {inProgress ? (
          <PulseLoader color={Theme.colors[color]} size="8px" margin="4px" />
        ) : (
          children
        )}
      </ButtonContainer>
    </Tooltip>
  ) : (
    <ButtonContainer
      type={type}
      color={color}
      backgroundColor={backgroundColor}
      variant={variant}
      fullWidth={fullWidth}
      disabled={disabled || inProgress}
      small={small}
      showSplash={showSplash}
      onClick={() => onClick()}
    >
      {inProgress ? (
        <PulseLoader color={Theme.colors[color]} size="8px" margin="4px" />
      ) : (
        children
      )}
    </ButtonContainer>
  )
}
