import { ReactNode } from 'react'
import { config, useSpring } from 'react-spring'

import { ReactComponent as CloseSvg } from 'assets/images/close-thin.svg'

import {
  ModalBodyContainer,
  ModalCloseButton,
  ModalContainer,
  ModalHeaderContainer,
  ModalWrapper
} from './styled'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
}

export function Modal({ isOpen, onClose, children }: ModalProps): JSX.Element {
  const dialogStyles = useSpring({
    transform: isOpen ? 'translateY(0)' : 'translateY(-100vh)',
    config: config.stiff
  })

  return (
    <ModalWrapper tabIndex={-1} open={isOpen}>
      <ModalContainer style={dialogStyles}>
        <ModalCloseButton onClick={onClose}>
          <CloseSvg />
        </ModalCloseButton>
        {children}
      </ModalContainer>
    </ModalWrapper>
  )
}

type ModalHeaderProps = {
  separator?: boolean
  children?: ReactNode
}

export function ModalHeader({
  separator = true,
  children
}: ModalHeaderProps): JSX.Element {
  return (
    <ModalHeaderContainer separator={separator}>
      {children}
    </ModalHeaderContainer>
  )
}

type ModalBodyProps = {
  children?: ReactNode
}

export function ModalBody({ children }: ModalBodyProps): JSX.Element {
  return <ModalBodyContainer>{children}</ModalBodyContainer>
}
