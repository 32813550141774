import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'

import { OPEN_DOCUMENTS_MENU, CLOSE_DOCUMENTS_MENU } from './actionTypes'

export type DocumentsState = {
  isOpen: boolean
}

const INITIAL_STATE: DocumentsState = {
  isOpen: false
}

export function documentsReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): DocumentsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case OPEN_DOCUMENTS_MENU: {
        draft.isOpen = true
        break
      }

      case CLOSE_DOCUMENTS_MENU: {
        draft.isOpen = false
        break
      }

      case CLEAR_SESSION_DATA: {
        return INITIAL_STATE
      }

      default: {
        break
      }
    }
  })
}
