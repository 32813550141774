import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { TextareaContainer } from './styled'

type TextInputProps = {
  name: string
  placeholder: string
  disabled?: boolean
  [key: string]: unknown
}

export function Textarea({
  name,
  placeholder,
  disabled,
  ...rest
}: TextInputProps): JSX.Element {
  const { t } = useTranslation(['validation'])

  const [field, meta] = useField({ name })

  return (
    <TextareaContainer hasError={meta.touched && !!meta.error}>
      <textarea
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
        {...rest}
        tabIndex={disabled ? -1 : 0}
      />
      {meta.touched && !!meta.error && (
        <p>
          {t(`validation:${meta.error}`, {
            field: placeholder
          })}
        </p>
      )}
    </TextareaContainer>
  )
}
