import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import * as dashboardService from 'services/http/dashboardService'

import { getDataFailure, getDataSuccess } from 'store/dashboard/actions'
import { GET_DATA } from 'store/dashboard/actionTypes'

import { Action } from 'types/Action'
import { DashboardData } from 'types/dashboard/DashboardData'
import { DashboardQuery } from 'types/dashboard/DashboardQuery'

import { showError } from 'utils/alertUtils'

function* getDataRequest({
  payload
}: Action<DashboardQuery>): Generator<StrictEffect, void, DashboardData> {
  try {
    const response = yield call(dashboardService.getData, payload)
    yield put(getDataSuccess(response))
  } catch (error) {
    yield call(showError, 'getDashboardData', error)
    yield put(getDataFailure())
  }
}

export function* dashboardSagas(): Generator {
  yield all([takeLatest(GET_DATA, getDataRequest)])
}
