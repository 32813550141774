import { all } from 'redux-saga/effects'

import { contestSagas } from 'store/contest/sagas'
import { contestAssociationSagas } from 'store/contestAssociation/sagas'
import { contestFileSagas } from 'store/contestFile/sagas'
import { contestMessageSagas } from 'store/contestMessage/sagas'
import { dashboardSagas } from 'store/dashboard/sagas'
import { directorySagas } from 'store/directory/sagas'
import { institutionSagas } from 'store/institution/sagas'
import { loginSagas } from 'store/login/sagas'
import { notificationSagas } from 'store/notification/sagas'
import { precedentSagas } from 'store/precedent/sagas'
import { sessionSagas } from 'store/session/sagas'
import { userSagas } from 'store/user/sagas'

export function* rootSaga(): Generator {
  yield all([
    contestSagas(),
    contestAssociationSagas(),
    contestFileSagas(),
    contestMessageSagas(),
    dashboardSagas(),
    directorySagas(),
    institutionSagas(),
    loginSagas(),
    notificationSagas(),
    precedentSagas(),
    sessionSagas(),
    userSagas()
  ])
}
