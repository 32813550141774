import styled from 'styled-components'

type TextInputContainerProps = {
  hasError?: boolean
}

export const TextInputContainer = styled.div<TextInputContainerProps>`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings[8]};

  & > input {
    width: 100%;

    padding: ${({ theme }) => `${theme.spacings[2.5]} ${theme.spacings[4]}`};

    color: ${({ theme }) => theme.colors.greyishBrownFour};

    border: 1px solid
      ${({ theme, hasError }) => {
        if (hasError) {
          return theme.colors.blush
        }

        return theme.colors.warmGreyThree
      }};
    border-radius: 2rem;

    background-color: ${({ theme }) => theme.colors.white};

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    &::placeholder {
      color: ${({ theme }) => theme.colors.pinkishGrey};
      opacity: 1; /* Firefox */
    }

    /* Internet Explorer 10-11, Microsoft Edge */
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.pinkishGrey};
    }

    &:focus:not(:read-only):not(:disabled) {
      z-index: 1;
      box-shadow: 0 0 0 1px
        ${({ theme, hasError }) => {
          if (hasError) {
            return theme.colors.blush
          }

          return theme.colors.niceBlue
        }};
      border-color: ${({ theme, hasError }) => {
        if (hasError) {
          return theme.colors.blush
        }

        return theme.colors.niceBlue
      }};
    }

    &:disabled,
    &:read-only {
      border-color: ${({ theme }) => theme.colors.paleGrey};
      background-color: ${({ theme }) => theme.colors.paleGreyThree};
    }
  }

  & > p {
    position: absolute;
    top: 105%;

    color: ${({ theme }) => theme.colors.blush};
    margin: ${({ theme }) => `0 ${theme.spacings[4]}`};
  }
`
