import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import * as contestNotificationService from 'services/http/contestNotificationService'
import { store } from 'store'

import {
  getNotificationsFailure,
  getNotificationsSuccess,
  markAsReadFailure,
  markAsReadSuccess
} from 'store/notification/actions'
import { GET_NOTIFICATIONS, MARK_AS_READ } from 'store/notification/actionTypes'

import { Action } from 'types/Action'
import { ContestNotification } from 'types/contestNotification/ContestNotification'

import { showError } from 'utils/alertUtils'

function* getNotificationsRequest(): Generator<
  StrictEffect,
  void,
  ContestNotification[]
> {
  try {
    const response = yield call(contestNotificationService.getNotifications)
    yield put(getNotificationsSuccess(response))
  } catch (error) {
    // yield call(showError, 'getNotifications', error)
    yield put(getNotificationsFailure())
  }
}

function* markAsReadRequest({
  payload
}: Action<number>): Generator<StrictEffect, void, ContestNotification[]> {
  try {
    yield call(contestNotificationService.markAsRead, payload)

    const notifications = store
      .getState()
      .notification.notifications.filter(({ id }) => id !== payload)

    yield put(markAsReadSuccess(notifications))
  } catch (error) {
    yield call(showError, 'markAsRead', error)
    yield put(markAsReadFailure())
  }
}

export function* notificationSagas(): Generator {
  yield all([takeLatest(GET_NOTIFICATIONS, getNotificationsRequest)])
  yield all([takeLatest(MARK_AS_READ, markAsReadRequest)])
}
