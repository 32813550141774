import { useRef, useEffect, useState, ReactNode, KeyboardEvent } from 'react'

import {
  TabsWrapper,
  TabListContainer,
  TabPanelContainer,
  TabContainer
} from './styled'

type TabsProps = {
  tabs: Array<{
    id: string
    title: ReactNode
    content: ReactNode
    marker?: string
  }>
  currentTab?: number
  small?: boolean
  onChange?: (tab: number) => void
}

export function Tabs({
  tabs,
  currentTab,
  small = false,
  onChange
}: TabsProps): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(
    currentTab && currentTab > 0 ? currentTab : 1
  )

  const tabValues = tabs.map(({ id, title, marker }) => ({
    id,
    title,
    marker,
    ref: useRef<HTMLButtonElement>(null)
  }))

  const tabPanelValues = tabs.map(({ id, content }) => ({ id, content }))

  useEffect(() => {
    if (onChange) onChange(selectedTab)
  }, [])

  useEffect(() => {
    if (currentTab && currentTab > 0 && currentTab <= tabValues.length) {
      setSelectedTab(currentTab)
    }
  }, [currentTab])

  function handleClick(index: number) {
    setSelectedTab(index)

    if (onChange) onChange(index)
  }

  function handleKeyPress(event: KeyboardEvent<HTMLUListElement>) {
    const tabCount = tabValues.length

    if (event.key === 'ArrowLeft') {
      const tabToSelect = selectedTab === 1 ? tabCount : selectedTab - 1
      setSelectedTab(tabToSelect)
      tabValues[tabToSelect - 1]?.ref.current?.focus()

      if (onChange) onChange(tabToSelect)
    }

    if (event.key === 'ArrowRight') {
      const tabToSelect = selectedTab === tabCount ? 1 : selectedTab + 1
      setSelectedTab(tabToSelect)
      tabValues[tabToSelect - 1]?.ref.current?.focus()

      if (onChange) onChange(tabToSelect)
    }
  }

  return (
    <TabsWrapper>
      <TabListContainer
        role="tablist"
        aria-label="List of tabs"
        onKeyDown={handleKeyPress}
      >
        {tabValues.map(({ id, title, marker, ref }, index) => (
          <TabContainer
            role="presentation"
            isSelected={selectedTab === index + 1}
            small={small}
            key={id}
            data-marker={marker}
          >
            <button
              role="tab"
              id={id + 'Tab'}
              aria-controls={id + 'TabPanel'}
              aria-selected={selectedTab === index + 1}
              tabIndex={selectedTab === index + 1 ? 0 : -1}
              type="button"
              onClick={() => handleClick(index + 1)}
              ref={ref}
            >
              {title}
            </button>
          </TabContainer>
        ))}
      </TabListContainer>
      {tabPanelValues.map(({ id, content }, index) => {
        return selectedTab === index + 1 ? (
          <TabPanelContainer
            role="tabpanel"
            id={id + 'TabPanel'}
            aria-labelledby={id + 'Tab'}
            hidden={selectedTab !== index + 1}
            tabIndex={0}
            key={id}
          >
            {content}
          </TabPanelContainer>
        ) : null
      })}
    </TabsWrapper>
  )
}
