import { ActionWithoutPayload } from 'types/Action'

import { OPEN_DOCUMENTS_MENU, CLOSE_DOCUMENTS_MENU } from './actionTypes'

export function openDocumentsMenu(): ActionWithoutPayload {
  return { type: OPEN_DOCUMENTS_MENU }
}

export function closeDocumentsMenu(): ActionWithoutPayload {
  return { type: CLOSE_DOCUMENTS_MENU }
}
