import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'
import { ContestFile } from 'types/contestFile/ContestFile'

import {
  FETCH_CONTEST_FILES,
  FETCH_CONTEST_FILES_FAILURE,
  FETCH_CONTEST_FILES_SUCCESS,
  ADD_CONTEST_FILES,
  ADD_CONTEST_FILES_FAILURE,
  ADD_CONTEST_FILES_SUCCESS,
  REMOVE_CONTEST_FILES,
  REMOVE_CONTEST_FILES_FAILURE,
  REMOVE_CONTEST_FILES_SUCCESS,
  UPDATE_CONTEST_FILE_NOTES,
  UPDATE_CONTEST_FILE_NOTES_FAILURE,
  UPDATE_CONTEST_FILE_NOTES_SUCCESS
} from './actionTypes'

export type ContestFileState = {
  files: ContestFile[]
  fetchContestFilesInProgress: boolean
  addContestFilesInProgress: boolean
  removeContestFilesInProgress: boolean
  updateContestFileNotesInProgress: boolean
}

const INITIAL_STATE: ContestFileState = {
  files: [],
  fetchContestFilesInProgress: false,
  addContestFilesInProgress: false,
  removeContestFilesInProgress: false,
  updateContestFileNotesInProgress: false
}

export function contestFileReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): ContestFileState {
  return produce(state, (draft) => {
    switch (action.type) {
      case FETCH_CONTEST_FILES: {
        draft.fetchContestFilesInProgress = true
        break
      }

      case FETCH_CONTEST_FILES_FAILURE: {
        draft.fetchContestFilesInProgress = false
        break
      }

      case FETCH_CONTEST_FILES_SUCCESS: {
        draft.files = <ContestFile[]>action.payload
        draft.fetchContestFilesInProgress = false
        break
      }

      case ADD_CONTEST_FILES: {
        draft.addContestFilesInProgress = true
        break
      }

      case ADD_CONTEST_FILES_FAILURE: {
        draft.addContestFilesInProgress = false
        break
      }

      case ADD_CONTEST_FILES_SUCCESS: {
        draft.files = <ContestFile[]>action.payload
        draft.addContestFilesInProgress = false
        break
      }

      case REMOVE_CONTEST_FILES: {
        draft.removeContestFilesInProgress = true
        break
      }

      case REMOVE_CONTEST_FILES_FAILURE: {
        draft.removeContestFilesInProgress = false
        break
      }

      case REMOVE_CONTEST_FILES_SUCCESS: {
        const storageKey = <string>action.payload
        draft.files = state.files.filter(
          (file) => file.storageKey !== storageKey
        )
        draft.removeContestFilesInProgress = false
        break
      }

      case UPDATE_CONTEST_FILE_NOTES: {
        draft.updateContestFileNotesInProgress = true
        break
      }

      case UPDATE_CONTEST_FILE_NOTES_FAILURE: {
        draft.updateContestFileNotesInProgress = false
        break
      }

      case UPDATE_CONTEST_FILE_NOTES_SUCCESS: {
        draft.files = <ContestFile[]>action.payload
        draft.updateContestFileNotesInProgress = false
        break
      }

      case CLEAR_SESSION_DATA: {
        draft.files = []
        draft.fetchContestFilesInProgress = false
        draft.addContestFilesInProgress = false
        draft.removeContestFilesInProgress = false
        draft.updateContestFileNotesInProgress = false
        break
      }

      default: {
        break
      }
    }
  })
}
