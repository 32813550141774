import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const Toast = styled(ToastContainer)`
  /** Classes for the displayed toast **/
  .Toastify__toast {
    border-width: 1px;
    border-style: solid;
  }

  .Toastify__toast--info {
    color: ${({ theme }) => theme.colors.lightBlue};
    border-color: ${({ theme }) => theme.colors.lightBlue};
    background-color: ${({ theme }) => theme.colors.duckEggBlueTwo};
  }

  .Toastify__toast--success {
    color: ${({ theme }) => theme.colors.lightTeal};
    border-color: ${({ theme }) => theme.colors.lightTeal};
    background-color: ${({ theme }) => theme.colors.duckEggBlue};
  }

  .Toastify__toast--warning {
    /* color: ${({ theme }) => theme.colors.sandy}; */
    color: ${({ theme }) => theme.colors.fadedOrange};
    /* border-color: ${({ theme }) => theme.colors.sandy}; */
    border-color: ${({ theme }) => theme.colors.fadedOrange};
    background-color: ${({ theme }) => theme.colors.beigeTwo};
  }

  .Toastify__toast--error {
    color: ${({ theme }) => theme.colors.blush};
    border-color: ${({ theme }) => theme.colors.blush};
    background-color: ${({ theme }) => theme.colors.whiteFour};
  }

  /** Classes for the progress bar **/
  /* .Toastify__progress-bar {
  }
  .Toastify__progress-bar--animated {
  }
  .Toastify__progress-bar--controlled {
  }
  .Toastify__progress-bar--rtl {
  }
  .Toastify__progress-bar--default {
  }
  .Toastify__progress-bar--dark {
  } */
`

export default Toast
