import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { AuthHeader } from 'components/authHeader'
import { StepProgressBar } from 'components/stepProgressBar'

import { useQuery } from 'hooks/useQuery'

import { browserHistory } from 'navigation/browserHistory'
import { DASHBOARD_PAGE } from 'navigation/paths'

import { GlobalState } from 'store'
import { fetchInviteInfo, subscribeByInvite } from 'store/user/actions'

import { Representant } from 'types/enums/Representant'

import { usernameRegex, fullNameRegex, passwordRegex } from 'utils/regexUtils'
import { validateCNPJ, validateCPF } from 'utils/validationUtils'

import { AccountStep } from './components/accountStep'
import { TermsAndConditionsStep } from './components/termsAndConditionsStep'
import { WelcomeStep } from './components/welcomeStep'

import { SubscriptionWrapper } from './styled'

type SubscriptionData = {
  username: string
  name: string
  lastname: string
  cpf: string
  email: string
  password: string
  passwordConfirmation: string
  representant: string
  jobDescription: string
  institutionName: string
  institutionCnpj: string
}

export function SubscriptionByInvitePage(): JSX.Element {
  const { t } = useTranslation(['pages'])

  const query = useQuery()

  const dispatch = useDispatch()

  const { loggedIn } = useSelector((state: GlobalState) => state.session)
  const { inviteInfo } = useSelector((state: GlobalState) => state.user)

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)

  const steps = [
    t('pages:subscriptionByInvite.steps.welcome'),
    t('pages:subscriptionByInvite.steps.account'),
    t('pages:subscriptionByInvite.steps.termsAndConditions')
  ]

  const [subscriptionData, setSubscriptionData] = useState<SubscriptionData>({
    username: '',
    name: '',
    lastname: '',
    cpf: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    representant: Representant.INNER,
    jobDescription: '',
    institutionName: '',
    institutionCnpj: ''
  })

  const validationSchema = Yup.object({
    username: Yup.string()
      .matches(usernameRegex, 'username')
      .required('required'),
    name: Yup.string().matches(fullNameRegex, 'fullName').required('required'),
    cpf: Yup.string()
      .test('cpf', 'cpf', (value) => validateCPF(value ?? ''))
      .required('required'),
    password: Yup.string()
      .matches(passwordRegex, 'password.rules')
      .required('required'),
    passwordConfirmation: Yup.string()
      .required('required')
      .oneOf([Yup.ref('password')], 'passwordMatch'),
    representant: Yup.string().oneOf(Object.values(Representant)),
    jobDescription: Yup.string().required('required'),

    institutionName: Yup.string().when('representant', {
      is: Representant.OUTER,
      then: Yup.string().required('required')
    }),
    institutionCnpj: Yup.string().when('representant', {
      is: Representant.OUTER,
      then: Yup.string()
        .test('institutionCnpj', 'cnpj', (value) => validateCNPJ(value ?? ''))
        .required('required')
    })
  })

  useEffect(() => {
    const inviteId = query.get('inviteId')

    if (loggedIn || inviteId == null) {
      browserHistory.push(DASHBOARD_PAGE)
      return
    }

    dispatch(fetchInviteInfo(inviteId))
  }, [])

  useEffect(() => {
    if (inviteInfo) {
      const data = subscriptionData

      data.email = inviteInfo.email

      setSubscriptionData(data)
    }
  }, [inviteInfo])

  function _changeStep(direction: number) {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0

    setCurrentStepIndex(currentStepIndex + direction)
  }

  function _createInstitution() {
    const name = subscriptionData.name.split(' ')[0] ?? ''
    const lastname = subscriptionData.name.replace(name, '').trim()

    const inviteId = query.get('inviteId')

    if (!inviteId) return

    dispatch(
      subscribeByInvite({
        inviteId,
        username: subscriptionData.username,
        name,
        lastname,
        cpf: subscriptionData.cpf,
        password: subscriptionData.password,
        representant: subscriptionData.representant,
        jobDescription: subscriptionData.jobDescription,
        institution: {
          name: subscriptionData.institutionName,
          cnpj: subscriptionData.institutionCnpj
        }
      })
    )
  }

  document.title = 'Cadastro - Open Banking Brasil'

  return (
    <SubscriptionWrapper>
      <AuthHeader />
      <main>
        {currentStepIndex === 0 && (
          <h4>{t('pages:subscriptionByInvite.welcomeStep.title')}</h4>
        )}

        {currentStepIndex === 1 && (
          <h4>{t('pages:subscriptionByInvite.accountStep.title')}</h4>
        )}

        {currentStepIndex === 2 && (
          <h4>
            {t('pages:subscriptionByInvite.termsAndConditionsStep.title')}
          </h4>
        )}

        <StepProgressBar steps={steps} currentStepIndex={currentStepIndex} />

        {currentStepIndex === 0 && (
          <WelcomeStep goForward={() => _changeStep(1)} />
        )}

        {currentStepIndex === 1 && (
          <AccountStep
            initialValues={subscriptionData}
            validationSchema={validationSchema}
            goBack={() => _changeStep(-1)}
            goForward={(values) => {
              setSubscriptionData(values as SubscriptionData)
              _changeStep(1)
            }}
          />
        )}

        {currentStepIndex === 2 && (
          <TermsAndConditionsStep
            goBack={() => _changeStep(-1)}
            goForward={() => _createInstitution()}
          />
        )}
      </main>
    </SubscriptionWrapper>
  )
}
