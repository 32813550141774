import styled from 'styled-components'

import { ReactComponent as CrownSVG } from 'assets/images/crown.svg'

export const MembersTabWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings[6]};

  & > button {
    margin: 0 auto;
  }
`

export const AdminIcon = styled(CrownSVG)`
  color: ${({ theme }) => theme.colors.sand};
  width: ${({ theme }) => theme.fontSizes.md};
`

export const IconDisabled = styled.span`
  color: ${({ theme }) => theme.colors.pinkishGrey};
  width: ${({ theme }) => theme.fontSizes.md};
  cursor: not-allowed;
`
