import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: BloggerSans;
    src: local('Blogger Sans'),
      url('/fonts/BloggerSans/BloggerSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: GothamHTF;
    src: local('Gotham HTF Book'),
      url('/fonts/GothamHTF/GothamHTF-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: GothamHTF;
    src: local('Gotham HTF Book'),
      url('/fonts/GothamHTF/GothamHTF-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
  }

  @font-face {
    font-family: GothamHTF;
    src: local('Gotham HTF'),
      url('/fonts/GothamHTF/GothamHTF-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }

  html {
    font-size: 100%;
  }

  html, body, #root {
    height: 100%;
    overflow: hidden;
  }

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    /* outline: none; */
    box-sizing: border-box;
  }

  body {
    background: ${(props) => props.theme.colors.white};
    font-family: ${(props) => props.theme.fonts.gothamHTF};
    font-size: ${(props) => props.theme.fontSizes.sm};
    font-weight: normal;
    color: ${({ theme }) => theme.colors.graphite};
  }

  input,
  textarea,
  select {
    outline: none;
  }

  input,
  textarea,
  select,
  button {
    font-family: ${(props) => props.theme.fonts.gothamHTF};
    font-size: ${(props) => props.theme.fontSizes.sm};
  }

  button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;

    cursor: pointer;

    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  h1 {
    font-family: ${({ theme }) => theme.fonts.bloggerSans};
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: ${({ theme }) => theme.spacings[8]};
  }

  h2 {
    font-family: ${({ theme }) => theme.fonts.bloggerSans};
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: ${({ theme }) => theme.spacings[6]};
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.bloggerSans};
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: ${({ theme }) => theme.spacings[6]};
  }

  h4 {
    font-family: ${({ theme }) => theme.fonts.bloggerSans};
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: ${({ theme }) => theme.spacings[6]};
  }

  h5 {
    font-family: ${({ theme }) => theme.fonts.bloggerSans};
    font-weight: bold;
    font-size: 0.875rem;
    margin-bottom: ${({ theme }) => theme.spacings[6]};
  }

  a {
    text-decoration: none;
  }

  time {
    font-style: italic;
  }

  body.swal2-height-auto {
    height: 100% !important;
  }

  .confirmation {
    top: 32px;
    &__wrapper {
      z-index: ${({ theme }) => theme.zIndex.modal};
      background-color: rgba(236, 240, 241, 0.82) !important;
    }

    &__close-button {
      display: block !important;

      width: auto;
      height: auto;

      color: ${({ theme }) => theme.colors.niceBlue};

      margin: 0 !important;

      line-height: 0;

      border: none;
      background-color: transparent;

      svg {
        width: 1rem;
      }

      :hover {
        color: ${({ theme }) => theme.colors.niceBlue};
        filter: brightness(0.8);
      }

      :focus {
        color: ${({ theme }) => theme.colors.niceBlue};
        outline: 1px solid ${({ theme }) => theme.colors.niceBlue};
        outline-offset: 2px;
        box-shadow: none;
      }
    }

    &__container {
      min-width: 50vw;
      border-radius: 0;
      box-shadow: 0 3px 6px 0 rgba(1, 45, 79, 0.16);

      padding: ${({ theme }) => theme.spacings[8]};
    }

    &__title {
      font-family: ${({ theme }) => theme.fonts.bloggerSans};
      font-weight: bold;
      font-size: 1.5rem !important;
      margin-bottom: ${({ theme }) => theme.spacings[2]};
    }

    &__message {
      font-family: ${(props) => props.theme.fonts.gothamHTF};
      font-size: ${(props) => props.theme.fontSizes.sm} !important;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      gap: ${({ theme }) => theme.spacings[4]} !important;
    }

    &__button {
      font-family: ${(props) => props.theme.fonts.gothamHTF};
      font-size: ${(props) => props.theme.fontSizes.sm} !important;

      width: auto;
      min-width: 160px;

      padding: ${({ theme }) =>
        `${theme.spacings[2.5]} ${theme.spacings[8]}`} !important;

      border-radius: 2rem !important;
    }
  }
`

export default GlobalStyles
