/* eslint-disable @typescript-eslint/no-explicit-any */
import { generatePath } from 'react-router-dom'
import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import { browserHistory } from 'navigation/browserHistory'
import {
  CONTEST_CREATE_PAGE,
  CONTEST_DETAILS_PAGE,
  CONTEST_LIST_PAGE
} from 'navigation/paths'

import * as contestService from 'services/http/contestService'

import { closeModal } from 'store/modal/actions'
import {
  searchUserContestsFailure,
  searchUserContestsSuccess,
  createContestFailure,
  createContestSuccess,
  addContestMemberFailure,
  addContestMemberSuccess,
  updateContestMemberPermissionFailure,
  updateContestMemberPermissionSuccess,
  removeContestMemberFailure,
  removeContestMemberSuccess,
  payContestFailure,
  payContestSuccess,
  payContestBoletoFailure,
  payContestBoletoSuccess,
  getContestById,
  getContestByIdFailure,
  getContestByIdSuccess,
  fetchContestCategoriesFailure,
  fetchContestCategoriesSuccess,
  getContestActionsFailure,
  getContestActionsSuccess,
  replyContestFailure,
  replyContestSuccess,
  declineContestFailure,
  declineContestSuccess,
  acceptContestFailure,
  acceptContestSuccess,
  sendAgreementFailure,
  sendAgreementSuccess,
  acceptAgreementFailure,
  acceptAgreementSuccess,
  sendSubscribersFailure,
  sendSubscribersSuccess,
  askToGiveUpFailure,
  askToGiveUpSuccess,
  answerGiveUpFailure,
  answerGiveUpSuccess,
  desistFailure,
  desistSuccess,
  abortFailure,
  abortSuccess,
  resendFailure,
  resendSuccess,
  getPricesFailure,
  getPricesSuccess,
  resendPaymentFailure,
  resendPaymentSuccess,
  deleteContestFailure,
  deleteContestSuccess
} from 'store/contest/actions'
import {
  SEARCH_USER_CONTESTS,
  CREATE_CONTEST,
  ADD_CONTEST_MEMBER,
  UPDATE_CONTEST_MEMBER_PERMISSION,
  REMOVE_CONTEST_MEMBER,
  PAY_CONTEST,
  PAY_CONTEST_BOLETO,
  GET_CONTEST_BY_ID,
  FETCH_CONTEST_CATEGORIES,
  GET_CONTEST_ACTIONS,
  REPLY_CONTEST,
  DECLINE_CONTEST,
  ACCEPT_CONTEST,
  SEND_AGREEMENT,
  ACCEPT_AGREEMENT,
  SEND_SUBSCRIBERS,
  ASK_TO_GIVE_UP,
  ANSWER_GIVE_UP,
  DESIST,
  ABORT,
  RESEND,
  GET_PRICES,
  RESEND_PAYMENT,
  DELETE_CONTEST
} from 'store/contest/actionTypes'

import { Action } from 'types/Action'
import { Contest } from 'types/contest/Contest'
import { ContestActionsResult } from 'types/contest/ContestActionsResult'
import { ContestCardPayment } from 'types/contest/ContestCardPayment'
import { ContestCategoriesResult } from 'types/contest/ContestCategoriesResult'
import { ContestCreate } from 'types/contest/ContestCreate'
import { ContestGiveUpAnswer } from 'types/contest/ContestGiveUpAnswer'
import { ContestInviteMember } from 'types/contest/ContestInviteMember'
import { ContestMember } from 'types/contest/ContestMember'
import { ContestPrice } from 'types/contest/ContestPrice'
import { ContestRemoveMember } from 'types/contest/ContestRemoveMember'
import { ContestReply } from 'types/contest/ContestReply'
import { ContestSendSubscribers } from 'types/contest/ContestSendSubscribers'
import { ContestSearchResult } from 'types/contest/ContestSearchResult'
import { ContestUpdate } from 'types/contest/ContestUpdate'
import { ContestUpdateMemberPermission } from 'types/contest/ContestUpdateMemberPermission'
import { SearchUserContestsQuery } from 'types/contest/SearchUserContestsQuery'
import { ModalName } from 'types/enums/ModalName'
import { PagedResult } from 'types/PagedResult'

import { showError, showSuccess } from 'utils/alertUtils'
import { ContestResendPaymentResponse } from 'types/contest/ContestResendPaymentResponse'
import { ContestResendPayment } from 'types/contest/ContestResendPayment'
import { HttpError } from 'types/errors/HttpError'

function* searchUserContestsRequest({
  payload
}: Action<{ userId: number; params: SearchUserContestsQuery }>): Generator<
  StrictEffect,
  void,
  PagedResult<ContestSearchResult>
> {
  try {
    const response = yield call(
      contestService.searchUserContests,
      payload.params
    )
    yield put(searchUserContestsSuccess(response))
  } catch (error) {
    yield call(showError, 'searchUserContests', error)
    yield put(searchUserContestsFailure())
  }
}

function* createContestRequest({
  payload
}: Action<ContestCreate>): Generator<StrictEffect, void, Contest> {
  try {
    const response = yield call(contestService.createContest, payload)
    yield call(
      browserHistory.push,
      generatePath(CONTEST_CREATE_PAGE, { contestId: response.id })
    )
    yield call(showSuccess, 'createContest')
    yield put(createContestSuccess(response))
  } catch (error) {
    yield call(showError, 'createContest', error)
    yield put(createContestFailure())
  }
}

function* addContestMemberRequest({
  payload
}: Action<ContestInviteMember>): Generator<
  StrictEffect,
  void,
  ContestMember[]
> {
  try {
    const response = yield call(contestService.addContestMember, payload)
    yield call(showSuccess, 'addContestMember')
    yield put(closeModal(ModalName.CONTEST_ADD_MEMBER))
    yield put(addContestMemberSuccess(response))
  } catch (error) {
    yield call(showError, 'addContestMember', error)
    yield put(addContestMemberFailure())
  }
}

function* updateContestMemberPermissionRequest({
  payload
}: Action<ContestUpdateMemberPermission>): Generator<
  StrictEffect,
  void,
  ContestMember[]
> {
  try {
    const response = yield call(
      contestService.updateContestMemberPermission,
      payload
    )
    yield call(showSuccess, 'updateContestMemberPermission')
    yield put(closeModal(ModalName.CONTEST_EDIT_MEMBER))
    yield put(updateContestMemberPermissionSuccess(response))
  } catch (error) {
    yield call(showError, 'updateContestMemberPermission', error)
    yield put(updateContestMemberPermissionFailure())
  }
}

function* removeContestMemberRequest({
  payload
}: Action<ContestRemoveMember>): Generator<
  StrictEffect,
  void,
  ContestMember[]
> {
  try {
    const response = yield call(contestService.removeContestMember, payload)
    yield call(showSuccess, 'removeContestMember')
    yield put(removeContestMemberSuccess(response))
  } catch (error) {
    yield call(showError, 'removeContestMember', error)
    yield put(removeContestMemberFailure())
  }
}

function* payContestRequest({
  payload
}: Action<ContestUpdate<ContestCardPayment>>): Generator<
  StrictEffect,
  void,
  Contest
> {
  try {
    const response = yield call(contestService.updateContest, payload)
    yield call(
      browserHistory.push,
      generatePath(CONTEST_DETAILS_PAGE, { contestId: response.id })
    )
    yield call(showSuccess, 'payContest')
    yield put(payContestSuccess(response))
  } catch (error) {
    if ((error as any).detail) {
      const code = (error as any).detail.status?.error?.category
      if (code) {
        yield call(showError, 'payContest', { code })
      } else {
        yield call(showError, 'payContest', error)
      }
    } else {
      yield call(showError, 'payContest', error)
    }
    yield put(payContestFailure())
  }
}

function* payContestBoletoRequest({
  payload
}: Action<ContestUpdate<null>>): Generator<StrictEffect, void, Contest> {
  try {
    const response = yield call(contestService.updateContest, payload)
    // yield call(
    //   browserHistory.push,
    //   generatePath(CONTEST_DETAILS_PAGE, { contestId: response.id })
    // )
    yield call(showSuccess, 'payContestBoleto')
    yield put(payContestBoletoSuccess(response))
  } catch (error) {
    yield call(showError, 'payContestBoleto', error)
    yield put(payContestBoletoFailure())
  }
}

function* getContestByIdRequest({
  payload
}: Action<number>): Generator<StrictEffect, void, Contest> {
  try {
    const response = yield call(contestService.getContestById, payload)
    yield put(getContestByIdSuccess(response))
  } catch (error) {
    yield call(showError, 'getContestById', error)
    yield call(browserHistory.goBack)
    yield put(getContestByIdFailure())
  }
}

function* fetchContestCategoriesRequest(): Generator<
  StrictEffect,
  void,
  ContestCategoriesResult[]
> {
  try {
    const response = yield call(contestService.fetchContestCategories)
    yield put(fetchContestCategoriesSuccess(response))
  } catch (error) {
    yield call(showError, 'fetchContestCategories', error)
    yield put(fetchContestCategoriesFailure())
  }
}

function* fetchContestActionsRequest({
  payload
}: Action<number>): Generator<StrictEffect, void, ContestActionsResult[]> {
  try {
    const response = yield call(contestService.getContestActions, payload)
    yield put(getContestActionsSuccess(response))
  } catch (error) {
    // yield call(showError, 'fetchContestAction', error)
    yield put(getContestActionsFailure())
  }
}

function* replyContestRequest({
  payload
}: Action<ContestUpdate<ContestReply>>): Generator<
  StrictEffect,
  void,
  Contest
> {
  try {
    const response = yield call(contestService.updateContest, payload)
    yield call(showSuccess, 'replyContest')
    yield put(closeModal(ModalName.CONTEST_ANSWER))
    yield put(closeModal(ModalName.CONTEST_REPLICATION))
    yield put(closeModal(ModalName.CONTEST_REJOINDER))
    yield put(replyContestSuccess(response))
  } catch (error) {
    yield call(showError, 'replyContest', error)
    yield put(replyContestFailure())
  }
}

function* declineContestRequest({
  payload
}: Action<ContestUpdate<never>>): Generator<StrictEffect, void, Contest> {
  try {
    const response = yield call(contestService.updateContest, payload)
    yield call(showSuccess, 'declineContest')
    yield put(declineContestSuccess(response))
  } catch (error) {
    yield call(showError, 'declineContest', error)
    yield put(declineContestFailure())
  }
}

function* acceptContestRequest({
  payload
}: Action<ContestUpdate<never>>): Generator<StrictEffect, void, Contest> {
  try {
    const response = yield call(contestService.updateContest, payload)
    yield call(showSuccess, 'acceptContest')
    yield put(closeModal(ModalName.CONTEST_ACCEPT))
    yield put(acceptContestSuccess(response))
  } catch (error) {
    yield call(showError, 'acceptContest', error)
    yield put(acceptContestFailure())
  }
}

function* sendAgreementRequest({
  payload
}: Action<ContestUpdate<never>>): Generator<StrictEffect, void, Contest> {
  try {
    const response = yield call(contestService.updateContest, payload)
    yield call(showSuccess, 'sendAgreement')
    yield put(closeModal(ModalName.CONTEST_SEND_AGREEMENT))
    yield put(closeModal(ModalName.CONTEST_ACCEPT_AGREEMENT))
    yield put(closeModal(ModalName.CONTEST_SEND_NEW_AGREEMENT))
    yield put(sendAgreementSuccess(response))
  } catch (error) {
    yield call(showError, 'sendAgreement', error)
    yield put(sendAgreementFailure())
  }
}

function* acceptAgreementRequest({
  payload
}: Action<ContestUpdate<never>>): Generator<StrictEffect, void, Contest> {
  try {
    const response = yield call(contestService.updateContest, payload)
    yield call(showSuccess, 'acceptAgreement')
    yield put(closeModal(ModalName.CONTEST_ACCEPT_AGREEMENT))
    yield put(acceptAgreementSuccess(response))
  } catch (error) {
    yield call(showError, 'acceptAgreement', error)
    yield put(acceptAgreementFailure())
  }
}

function* sendSubscribersRequest({
  payload
}: Action<ContestUpdate<ContestSendSubscribers>>): Generator<
  StrictEffect,
  void,
  Contest
> {
  try {
    const response = yield call(contestService.updateContest, payload)
    yield call(showSuccess, 'sendSubscribers')
    yield put(closeModal(ModalName.CONTEST_SEND_SUBSCRIBERS))
    yield put(sendSubscribersSuccess(response))
  } catch (error) {
    if (error instanceof HttpError && error.detail) {
      yield call(
        showError,
        '',
        `Já existem signatários com o cpf ${error.detail.cpf} ou email ${error.detail.email} cadastrados em outra participante desta disputa, verifique as informações e tente novamente`
      )
    } else {
      yield call(showError, 'sendSubscribers', error)
    }
    yield put(sendSubscribersFailure())
  }
}

function* askToGiveUpRequest({
  payload
}: Action<number>): Generator<StrictEffect, void> {
  try {
    yield call(contestService.askToGiveUp, payload)
    yield call(showSuccess, 'askToGiveUp')
    yield put(getContestById(payload)) // reload contest data
    yield put(askToGiveUpSuccess())
  } catch (error) {
    yield call(showError, 'askToGiveUp', error)
    yield put(askToGiveUpFailure())
  }
}

function* answerGiveUpRequest({
  payload
}: Action<ContestGiveUpAnswer>): Generator<StrictEffect, void> {
  try {
    yield call(contestService.answerGiveUpRequest, payload)
    yield call(showSuccess, 'answerGiveUp')
    yield put(getContestById(payload.contestId)) // reload contest data
    yield put(answerGiveUpSuccess())
  } catch (error) {
    yield call(showError, 'answerGiveUp', error)
    yield put(answerGiveUpFailure())
  }
}

function* desistRequest({
  payload
}: Action<ContestUpdate<never>>): Generator<StrictEffect, void, Contest> {
  try {
    const response = yield call(contestService.updateContest, payload)
    yield call(showSuccess, 'desist')
    yield put(desistSuccess(response))
  } catch (error) {
    yield call(showError, 'desist', error)
    yield put(desistFailure())
  }
}

function* abortRequest({
  payload
}: Action<ContestUpdate<never>>): Generator<StrictEffect, void, Contest> {
  try {
    const response = yield call(contestService.updateContest, payload)
    yield call(showSuccess, 'abort')
    yield put(abortSuccess(response))
  } catch (error) {
    yield call(showError, 'abort', error)
    yield put(abortFailure())
  }
}

function* resendRequest({
  payload
}: Action<ContestUpdate<never>>): Generator<StrictEffect, void, Contest> {
  try {
    const response = yield call(contestService.updateContest, payload)
    yield call(showSuccess, 'resend')
    yield put(resendSuccess(response))
  } catch (error) {
    yield call(showError, 'resend', error)
    yield put(resendFailure())
  }
}

function* getPricesRequest(): Generator<StrictEffect, void, ContestPrice> {
  try {
    const response = yield call(contestService.getPrices)
    yield put(getPricesSuccess(response))
  } catch (error) {
    yield call(showError, 'getPrices', error)
    yield put(getPricesFailure())
  }
}

function* resendPaymentRequest({
  payload
}: Action<ContestResendPayment>): Generator<
  StrictEffect,
  void,
  ContestResendPaymentResponse
> {
  try {
    const response = yield call(contestService.payResend, payload)

    yield put(getContestById(payload.contestId))

    if (payload.paymentType === 'credit') {
      yield put(closeModal(ModalName.CONTEST_RESEND_PAYMENT))
    }

    yield put(resendPaymentSuccess(response))
  } catch (error) {
    yield call(showError, 'resendPayment', error)
    yield put(resendPaymentFailure())
  }
}

function* deleteContestRequest({
  payload
}: Action<number>): Generator<StrictEffect, void, Contest> {
  try {
    yield call(contestService.deleteContest, payload)
    yield call(browserHistory.push, CONTEST_LIST_PAGE)
    yield call(showSuccess, 'deleteContest')
    yield put(deleteContestSuccess())
  } catch (error) {
    yield call(showError, 'deleteContest', error)
    yield put(deleteContestFailure())
  }
}

export function* contestSagas(): Generator {
  yield all([
    takeLatest(SEARCH_USER_CONTESTS, searchUserContestsRequest),
    takeLatest(CREATE_CONTEST, createContestRequest),
    takeLatest(ADD_CONTEST_MEMBER, addContestMemberRequest),
    takeLatest(
      UPDATE_CONTEST_MEMBER_PERMISSION,
      updateContestMemberPermissionRequest
    ),
    takeLatest(REMOVE_CONTEST_MEMBER, removeContestMemberRequest),
    takeLatest(PAY_CONTEST, payContestRequest),
    takeLatest(PAY_CONTEST_BOLETO, payContestBoletoRequest),
    takeLatest(GET_CONTEST_BY_ID, getContestByIdRequest),
    takeLatest(FETCH_CONTEST_CATEGORIES, fetchContestCategoriesRequest),
    takeLatest(GET_CONTEST_ACTIONS, fetchContestActionsRequest),
    takeLatest(REPLY_CONTEST, replyContestRequest),
    takeLatest(DECLINE_CONTEST, declineContestRequest),
    takeLatest(ACCEPT_CONTEST, acceptContestRequest),
    takeLatest(SEND_AGREEMENT, sendAgreementRequest),
    takeLatest(ACCEPT_AGREEMENT, acceptAgreementRequest),
    takeLatest(SEND_SUBSCRIBERS, sendSubscribersRequest),
    takeLatest(ASK_TO_GIVE_UP, askToGiveUpRequest),
    takeLatest(ANSWER_GIVE_UP, answerGiveUpRequest),
    takeLatest(DESIST, desistRequest),
    takeLatest(ABORT, abortRequest),
    takeLatest(RESEND, resendRequest),
    takeLatest(GET_PRICES, getPricesRequest),
    takeLatest(RESEND_PAYMENT, resendPaymentRequest),
    takeLatest(DELETE_CONTEST, deleteContestRequest)
  ])
}
