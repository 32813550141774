import styled, { StyledProps } from 'styled-components'

type StepProps = StyledProps<{
  counter?: string | React.ReactNode
  isActive?: boolean
  isCompleted?: boolean
  isSkiped?: boolean
  dashed?: boolean
}>

export const StepProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-bottom: ${({ theme }) => theme.spacings[10]};
`

export const StepList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;

  list-style: none;
`

export const Step = styled.li<StepProps>`
  position: relative;

  width: 100%;

  font-family: ${({ theme }) => theme.fonts.bloggerSans};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: bold;

  color: ${({ theme, isActive, isCompleted }) => {
    if (isActive) {
      return theme.colors.graphite
    }

    if (isCompleted) {
      return theme.colors.lightTeal
    }

    return theme.colors.pinkishGrey
  }};

  text-align: center;

  & > span {
    display: block;

    width: ${({ theme }) => theme.spacings[16]};
    height: ${({ theme }) => theme.spacings[16]};
    line-height: ${({ theme }) => theme.spacings[16]};

    margin: ${({ theme }) => `0 auto ${theme.spacings[2]}`};

    border-radius: 100%;
    border: 2px solid
      ${({ theme, isActive, isCompleted, isSkiped }) => {
        if (isActive) {
          return theme.colors.petrol
        }

        if (isCompleted) {
          return theme.colors.lightTeal
        }

        if (isSkiped) {
          return theme.colors.pinkishGrey
        }

        return theme.colors.sandy
      }};

    font-size: ${({ theme }) => theme.fontSizes.lg};

    color: ${({ theme, isActive, isCompleted }) => {
      if (isActive) {
        return theme.colors.sandy
      }

      if (isCompleted) {
        return theme.colors.lightTeal
      }

      return theme.colors.pinkishGrey
    }};

    background-color: ${({ theme, isActive, isCompleted }) => {
      if (isActive) {
        return theme.colors.niceBlue
      }

      if (isCompleted) {
        return theme.colors.duckEggBlue
      }

      return theme.colors.white
    }};

    transition: 0.3s;

    svg {
      width: ${({ theme }) => theme.fontSizes.lg};
      margin-top: ${({ theme }) => `calc(50% - ${theme.fontSizes.lg})`};
    }
  }

  &:first-of-type::before {
    display: none;
  }

  &::before {
    content: '';

    z-index: -1;
    position: absolute;
    left: 0;
    top: calc(
      ${({ theme }) =>
        `(${theme.spacings[16]} + ${theme.spacings[2]}) / 2 - 4px`}
    );

    width: 50%;
    height: 2px;

    background: ${({ theme, isActive, isCompleted, isSkiped, dashed }) => {
      if (isActive) {
        return dashed
          ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.niceBlue} 10px, ${theme.colors.niceBlue} 20px);`
          : theme.colors.niceBlue
      }

      if (isCompleted) {
        return dashed
          ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.lightTeal} 10px, ${theme.colors.lightTeal} 20px);`
          : theme.colors.lightTeal
      }

      if (isSkiped) {
        return dashed
          ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.pinkishGrey} 10px, ${theme.colors.pinkishGrey} 20px);`
          : theme.colors.pinkishGrey
      }

      return dashed
        ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.sandy} 10px, ${theme.colors.sandy} 20px);`
        : theme.colors.sandy
    }};

    transition: 0.3s;
  }

  &:last-of-type::after {
    display: none;
  }

  &::after {
    content: '';

    z-index: -1;
    position: absolute;
    right: 0;
    top: calc(
      ${({ theme }) =>
        `(${theme.spacings[16]} + ${theme.spacings[2]}) / 2 - 4px`}
    );

    width: 50%;
    height: 2px;

    background: ${({ theme, isActive, isCompleted, isSkiped, dashed }) => {
      if (isActive) {
        return dashed
          ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.niceBlue} 10px, ${theme.colors.niceBlue} 20px);`
          : theme.colors.niceBlue
      }

      if (isCompleted) {
        return dashed
          ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.lightTeal} 10px, ${theme.colors.lightTeal} 20px);`
          : theme.colors.lightTeal
      }

      if (isSkiped) {
        return dashed
          ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.pinkishGrey} 10px, ${theme.colors.pinkishGrey} 20px);`
          : theme.colors.pinkishGrey
      }

      return dashed
        ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.sandy} 10px, ${theme.colors.sandy} 20px);`
        : theme.colors.sandy
    }};

    transition: 0.3s;
  }

  /* &:first-of-type::after {
    width: 50%;
    left: 50%;

    background: ${({ theme, isActive, isCompleted, dashed }) => {
    if (isActive) {
      return theme.colors.sandy
    }

    if (isCompleted) {
      return dashed
        ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.lightTeal} 10px, ${theme.colors.lightTeal} 20px);`
        : theme.colors.lightTeal
    }

    return dashed
      ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.sandy} 10px, ${theme.colors.sandy} 20px);`
      : theme.colors.sandy
  }};
  }

  &:last-of-type::after {
    width: 50%;
    left: 0;

    background: ${({ theme, isActive, isCompleted, dashed }) => {
    if (isActive) {
      return theme.colors.lightTeal
    }

    if (isCompleted) {
      return dashed
        ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.lightTeal} 10px, ${theme.colors.lightTeal} 20px);`
        : theme.colors.lightTeal
    }

    return dashed
      ? `repeating-linear-gradient(90deg, transparent, transparent 10px, ${theme.colors.sandy} 10px, ${theme.colors.sandy} 20px);`
      : theme.colors.sandy
  }};
  } */
`
