import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BackButton } from 'components/backButton'
import { Button } from 'components/button'

import { TermsOfUse } from '../termsOfUse'
import { PrivacyPolicy } from '../privacyPolicy'

import { AcceptTermsCheck, TermsAndConditionsWrapper } from './styled'

type TermsAndConditionsStepProps = {
  goForward: () => void
  goBack: () => void
}

export function TermsAndConditionsStep({
  goForward,
  goBack
}: TermsAndConditionsStepProps): JSX.Element {
  const { t } = useTranslation('pages')

  const [termsIsChecked, setTermsIsChecked] = useState<boolean>(false)
  const [policiesIsChecked, setPoliciesIsChecked] = useState<boolean>(false)

  const isDisabled = useMemo<boolean>(() => {
    return !termsIsChecked || !policiesIsChecked
  }, [termsIsChecked, policiesIsChecked])

  return (
    <TermsAndConditionsWrapper>
      <article>
        <TermsOfUse />
        <PrivacyPolicy />
      </article>

      <AcceptTermsCheck>
        <label
          htmlFor="acceptTerms"
          dangerouslySetInnerHTML={{
            __html: t('subscriptionByInvite.termsAndConditionsStep.acceptTerms')
          }}
        />
        <input
          id="acceptTerms"
          type="checkbox"
          checked={termsIsChecked}
          onChange={() => setTermsIsChecked(!termsIsChecked)}
        />
      </AcceptTermsCheck>

      <AcceptTermsCheck>
        <label
          htmlFor="acceptPolicies"
          dangerouslySetInnerHTML={{
            __html: t(
              'subscriptionByInvite.termsAndConditionsStep.acceptPolicies'
            )
          }}
        />
        <input
          id="acceptPolicies"
          type="checkbox"
          checked={policiesIsChecked}
          onChange={() => setPoliciesIsChecked(!policiesIsChecked)}
        />
      </AcceptTermsCheck>

      <Button
        tooltip={
          isDisabled
            ? t('subscriptionByInvite.termsAndConditionsStep.finishButtonHint')
            : ''
        }
        onClick={isDisabled ? () => undefined : goForward}
        style={isDisabled ? { opacity: '0.6', cursor: 'not-allowed' } : null}
      >
        {t('subscriptionByInvite.termsAndConditionsStep.finishButton')}
      </Button>

      <BackButton onClick={goBack} />
    </TermsAndConditionsWrapper>
  )
}
