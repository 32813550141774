import { useTranslation } from 'react-i18next'

import { Button } from 'components/button'

import { BackIcon } from './styled'

type ButtonProps = {
  onClick?: () => void
}

export function BackButton({
  onClick = () => undefined
}: ButtonProps): JSX.Element {
  const { t } = useTranslation(['common'])

  return (
    <Button
      // tooltip={t('common:button.back')}
      variant="link"
      color="pinkishGrey"
      onClick={onClick}
    >
      <BackIcon />
      {t('common:button.back')}
    </Button>
  )
}
