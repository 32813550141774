import styled from 'styled-components'

import { Spacings } from 'styles/Theme'

type FlexWrapperProps = {
  flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  justifyContent:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  alignItems: 'flex-start' | 'flex-end' | 'center' | 'stretch'
  flexWrap: boolean
  gap?: keyof Spacings
}

export const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;

  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? 'wrap' : 'nowrap')};

  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};

  gap: ${({ gap, theme }) => (gap ? theme.spacings[gap] : 'none')};
`

type FlexItemWrapperProps = {
  flexGrow: string
  flexBasis: string
  flexShrink: string
  order?: number
}

export const FlexItemWrapper = styled.div<FlexItemWrapperProps>`
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex-shrink: ${({ flexShrink }) => flexShrink};

  order: ${({ order }) => (order != null ? order : 0)};
`
