import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'

import { Button } from 'components/button'

import { browserHistory } from 'navigation/browserHistory'
import {
  INSTITUTION_LIST_PAGE,
  INSTITUTION_DETAILS_PAGE
} from 'navigation/paths'

import { GlobalState } from 'store'

export function Institution(): JSX.Element {
  const { t } = useTranslation('pages')

  const { userInfo } = useSelector((state: GlobalState) => state.user)

  function _navigateToInstitution(): void {
    if (userInfo?.institution?.length === 1) {
      browserHistory.push(
        generatePath(INSTITUTION_DETAILS_PAGE, {
          cnpj: userInfo?.institution[0].cnpj
        })
      )
    } else {
      browserHistory.push(INSTITUTION_LIST_PAGE)
    }
  }

  return (
    <>
      <h5>{t('settings.institution.title')}</h5>

      <Button fullWidth onClick={_navigateToInstitution}>
        {t('settings.institution.seeMoreButton')}
      </Button>
    </>
  )
}
