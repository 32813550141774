import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'
import { PagedResult } from 'types/PagedResult'
import { Precedent } from 'types/precedent/Precedent'

import {
  FETCH_PRECEDENTS,
  FETCH_PRECEDENTS_FAILURE,
  FETCH_PRECEDENTS_SUCCESS,
  CREATE_PRECEDENT,
  CREATE_PRECEDENT_FAILURE,
  CREATE_PRECEDENT_SUCCESS
} from './actionTypes'

export type PrecedentState = {
  precedents: PagedResult<Precedent>
  fetchPrecedentsInProgress: boolean
  createPrecedentInProgress: boolean
}

const INITIAL_STATE: PrecedentState = {
  precedents: {
    pagination: {
      total: 0,
      firstPage: 1,
      lastPage: 1,
      currentPage: 1,
      perPage: 10
    },
    data: []
  },
  fetchPrecedentsInProgress: false,
  createPrecedentInProgress: false
}

export function precedentReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): PrecedentState {
  return produce(state, (draft) => {
    switch (action.type) {
      case FETCH_PRECEDENTS: {
        draft.fetchPrecedentsInProgress = true
        break
      }

      case FETCH_PRECEDENTS_FAILURE: {
        draft.fetchPrecedentsInProgress = false
        break
      }

      case FETCH_PRECEDENTS_SUCCESS: {
        draft.precedents = <PagedResult<Precedent>>action.payload
        draft.fetchPrecedentsInProgress = false
        break
      }

      case CREATE_PRECEDENT: {
        draft.createPrecedentInProgress = true
        break
      }

      case CREATE_PRECEDENT_FAILURE: {
        draft.createPrecedentInProgress = false
        break
      }

      case CREATE_PRECEDENT_SUCCESS: {
        const newPrecedent = <Precedent>action.payload
        draft.precedents = <PagedResult<Precedent>>{
          pagination: state.precedents.pagination,
          data: [...state.precedents.data, newPrecedent]
        }
        draft.createPrecedentInProgress = false
        break
      }

      case CLEAR_SESSION_DATA: {
        return INITIAL_STATE
      }

      default: {
        break
      }
    }
  })
}
