import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ReactComponent as SearchSVG } from 'assets/images/search.svg'
import { ReactComponent as TrashSVG } from 'assets/images/trash.svg'

import { Button } from 'components/button'
import { Flex } from 'components/flex'
import { IconButton } from 'components/iconButton'
import { Table } from 'components/table'
import { Tooltip } from 'components/tooltip'

import { confirmationConfig } from 'config/swal'

import { browserHistory } from 'navigation/browserHistory'
import { CONTEST_DETAILS_PAGE } from 'navigation/paths'

import { GlobalState } from 'store'
import {
  fetchContestAssociations,
  removeContestAssociation
} from 'store/contestAssociation/actions'
import { openModal } from 'store/modal/actions'

import Theme from 'styles/Theme'

import { ContestAssociation } from 'types/contestAssociation/ContestAssociation'
import { ContestMemberPermission } from 'types/enums/ContestMemberPermission'
import { ModalName } from 'types/enums/ModalName'
import { TableColumn } from 'types/table/TableColumn'

import { ContestAddAssociationModal } from '../contestAddAssociationModal'

import { LinksTabWrapper, IconDisabled } from './styled'

type Props = {
  userPermission?: ContestMemberPermission
}

export function AssociationsTab({ userPermission }: Props): JSX.Element {
  const { t } = useTranslation(['pages', 'common'])

  const dispatch = useDispatch()

  const {
    contest: { contestData },
    contestAssociation: {
      associations,
      fetchContestAssociationInProgress,
      removeContestAssociationInProgress
    }
  } = useSelector((state: GlobalState) => state)

  const canAddAssociation = useMemo<boolean>(() => {
    return (
      userPermission === ContestMemberPermission.ADMIN ||
      userPermission === ContestMemberPermission.EDIT
    )
  }, [userPermission])

  const canRemoveAssociation = useMemo<boolean>(() => {
    return (
      userPermission === ContestMemberPermission.ADMIN ||
      userPermission === ContestMemberPermission.EDIT
    )
  }, [userPermission])

  const columns = useMemo<TableColumn<ContestAssociation>[]>(
    () => [
      {
        label: t('pages:contestDetails.tabs.associations.table.id'),
        dataKey: 'contest',
        thStyle: { width: '70px' },
        tdStyle: { width: '70px' }
      },
      {
        label: t('pages:contestDetails.tabs.associations.table.requester'),
        dataKey: 'requester'
      },
      {
        label: t('pages:contestDetails.tabs.associations.table.defendant'),
        dataKey: 'defendant'
      },
      {
        label: t('pages:contestDetails.tabs.associations.table.reason'),
        dataKey: 'reason',
        render: (row) => {
          if (row.reason?.length > 50) {
            return (
              <Tooltip content={row.reason}>
                <span>{row.reason.substr(0, 50) + '...'}</span>
              </Tooltip>
            )
          }
          return <span>{row.reason}</span>
        }
      },
      {
        label: t('pages:contestDetails.tabs.associations.table.actions.title'),
        dataKey: 'action',
        thStyle: { width: '90px' },
        tdStyle: { width: '90px' },
        render: (row) => (
          <Flex direction="row" gap={2}>
            {_renderActions(row)}
          </Flex>
        )
      }
    ],
    [userPermission]
  )

  useEffect(() => {
    if (contestData?.id) {
      dispatch(fetchContestAssociations(contestData.id))
    }
  }, [contestData])

  function _removeLink(contestId: number) {
    if (contestData?.id) {
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          titleText: t(
            'pages:contestDetails.tabs.associations.confirmRemove.title'
          ),
          html: t(
            'pages:contestDetails.tabs.associations.confirmRemove.message'
          ),
          confirmButtonText: t(
            'pages:contestDetails.tabs.associations.confirmRemove.confirmButton'
          ),
          cancelButtonText: t(
            'pages:contestDetails.tabs.associations.confirmRemove.cancelButton'
          )
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            dispatch(
              removeContestAssociation({
                contestA: contestData.id,
                contestB: contestId
              })
            )
          }
        })
    }
  }

  function _renderActions(row: ContestAssociation) {
    return (
      <>
        <IconButton
          tooltip={t(
            'pages:contestDetails.tabs.associations.table.actions.details'
          )}
          color="niceBlue"
          onClick={() =>
            browserHistory.push(
              generatePath(CONTEST_DETAILS_PAGE, {
                contestId: row.contest
              })
            )
          }
        >
          {removeContestAssociationInProgress ? (
            <BounceLoader size="16px" color={Theme.colors.blush} />
          ) : (
            <SearchSVG />
          )}
        </IconButton>
        {canRemoveAssociation ? (
          <IconButton
            tooltip={t(
              'pages:contestDetails.tabs.associations.table.actions.remove'
            )}
            color="blush"
            onClick={() => _removeLink(row.contest)}
          >
            {removeContestAssociationInProgress ? (
              <BounceLoader size="16px" color={Theme.colors.blush} />
            ) : (
              <TrashSVG />
            )}
          </IconButton>
        ) : (
          <Tooltip
            content={t(
              'pages:contestDetails.tabs.associations.table.actions.cantRemove'
            )}
          >
            <IconDisabled>
              <TrashSVG />
            </IconDisabled>
          </Tooltip>
        )}
      </>
    )
  }

  return (
    <LinksTabWrapper>
      <Flex direction="row" justify="flex-end">
        {canAddAssociation && (
          <Button
            small
            type="button"
            color="niceBlue"
            variant="outline"
            onClick={() =>
              dispatch(openModal(ModalName.CONTEST_ADD_ASSOCIATION))
            }
          >
            {t('pages:contestDetails.tabs.associations.addButton')}
          </Button>
        )}
      </Flex>

      <Table
        columns={columns}
        data={associations}
        pagination={false}
        isLoading={fetchContestAssociationInProgress}
      />

      <ContestAddAssociationModal />
    </LinksTabWrapper>
  )
}
