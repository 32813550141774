export const LOGIN_PAGE = '/login'
export const SUBSCRIPTION_BY_INVITE_PAGE = '/createAccount'
export const RESET_PASSWORD_PAGE = '/resetPassword'

export const DASHBOARD_PAGE = '/'

export const CONTEST_LIST_PAGE = '/contests'
export const CONTEST_DETAILS_PAGE = '/contests/:contestId'
export const CONTEST_CREATE_PAGE = '/contests/new/:contestId?'

export const INSTITUTION_LIST_PAGE = '/institutions'
export const INSTITUTION_DETAILS_PAGE = '/institutions/:cnpj'
export const INSTITUTION_UPDATE_PAGE = '/institutions/:cnpj/update'

export const SETTINGS_PAGE = '/settings'
export const SETTINGS_UPDATE_PASSWORD_PAGE = '/settings/updatePassword'

export const PRECEDENTS_PAGE = '/precedents'

export const SSO = '/sso'
export const SSO_CALLBACK = '/sso/callback'
export const DIRECTORY_SIGN_UP = '/directory/:inviteId'
