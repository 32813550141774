import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { BreadcrumbWrapper, BreadcrumbItemContainer } from './styled'

type BreadcrumbProps = {
  children: ReactNode
}

export function Breadcrumb({ children }: BreadcrumbProps): JSX.Element {
  return (
    <BreadcrumbWrapper>
      <ol>{children}</ol>
    </BreadcrumbWrapper>
  )
}

type BreadcrumbItemProps = {
  label: string
  to?: string
  isCurrentPage?: boolean
}

export function BreadcrumbItem({
  label,
  to = '',
  isCurrentPage
}: BreadcrumbItemProps): JSX.Element {
  return (
    <BreadcrumbItemContainer>
      {isCurrentPage ? (
        <span aria-current="location">{label}</span>
      ) : (
        <>
          <Link to={to}>{label}</Link>
          <span role="presentation">/</span>
        </>
      )}
    </BreadcrumbItemContainer>
  )
}
