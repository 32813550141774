import { ReactElement } from 'react'
import { Translation } from 'react-i18next'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ReactComponent as AgreementSVG } from 'assets/images/agreement.svg'
import { ReactComponent as ArbitragemSVG } from 'assets/images/arbitragem.svg'
import { ReactComponent as BadgeSVG } from 'assets/images/badge.svg'
import { ReactComponent as BookmarkSVG } from 'assets/images/bookmark.svg'
import { ReactComponent as CalendarTimesSVG } from 'assets/images/calendar-times.svg'
import { ReactComponent as CheckSVG } from 'assets/images/check-thin.svg'
import { ReactComponent as CommentAltSVG } from 'assets/images/comment-alt.svg'
import { ReactComponent as DeleteSVG } from 'assets/images/delete.svg'
import { ReactComponent as DiplomaSVG } from 'assets/images/diploma.svg'
import { ReactComponent as HammerSVG } from 'assets/images/hammer.svg'
import { ReactComponent as HourglassSVG } from 'assets/images/hourglass.svg'
import { ReactComponent as MegaphoneSVG } from 'assets/images/megaphone.svg'
import { ReactComponent as PaymentSVG } from 'assets/images/payment.svg'
import { ReactComponent as PaperPlaneSVG } from 'assets/images/paper-plane.svg'

import { store } from 'store'
import { declineContest, desist, abort, resend } from 'store/contest/actions'
import { openModal } from 'store/modal/actions'

import Theme from 'styles/Theme'

import { ContestStep } from 'types/enums/ContestStep'
import { ContestUpdateAction } from 'types/enums/ContestUpdateAction'
import { ModalName } from 'types/enums/ModalName'

import { confirmationConfig } from './swal'

export const actionButtons: {
  [key: string]: {
    color: string
    backgroundColor: string
    inProgress?: boolean
    onClick: (payload?: unknown) => void
  }
} = {
  [ContestUpdateAction.ACCEPT]: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.lightTealTwo,
    onClick: () => store.dispatch(openModal(ModalName.CONTEST_ACCEPT))
  },
  [ContestUpdateAction.DECLINE]: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.niceBlue,
    inProgress: store.getState().contest.declineContestInProgress,
    onClick: () =>
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          title: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmDecline.title')}
            </Translation>
          ),
          html: (
            <Translation>
              {(t) => (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('pages:contestDetails.confirmDecline.message')
                  }}
                />
              )}
            </Translation>
          ),
          confirmButtonText: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmDecline.confirmButton')}
            </Translation>
          ),
          cancelButtonText: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmDecline.cancelButton')}
            </Translation>
          )
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            const { contest } = store.getState()

            if (contest.contestData) {
              store.dispatch(
                declineContest({
                  contestId: contest.contestData.id,
                  action: ContestUpdateAction.DECLINE,
                  data: null
                })
              )
            }
          }
        })
  },
  [ContestUpdateAction.DESIST]: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.blush,
    inProgress: store.getState().contest.desistInProgress,
    onClick: () =>
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          title: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmDesist.title')}
            </Translation>
          ),
          html: (
            <Translation>
              {(t) => (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('pages:contestDetails.confirmDesist.message')
                  }}
                />
              )}
            </Translation>
          ),
          confirmButtonText: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmDesist.confirmButton')}
            </Translation>
          ),
          cancelButtonText: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmDesist.cancelButton')}
            </Translation>
          )
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            const { contest } = store.getState()
            if (contest.contestData) {
              store.dispatch(
                desist({
                  contestId: contest.contestData.id,
                  action: ContestUpdateAction.DESIST,
                  data: null
                })
              )
            }
          }
        })
  },
  [ContestUpdateAction.REPLY]: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.niceBlue,
    onClick: () => store.dispatch(openModal(ModalName.CONTEST_ANSWER))
  },
  [ContestUpdateAction.REPLICATION]: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.niceBlue,
    onClick: () => store.dispatch(openModal(ModalName.CONTEST_REPLICATION))
  },
  [ContestUpdateAction.REJOINDER]: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.niceBlue,
    onClick: () => store.dispatch(openModal(ModalName.CONTEST_REJOINDER))
  },
  [ContestUpdateAction.SUBMIT_DOCUMENT]: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.niceBlue,
    onClick: () => store.dispatch(openModal(ModalName.CONTEST_SEND_AGREEMENT))
  },
  [ContestUpdateAction.ACCEPT_DOCUMENT]: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.lightTealTwo,
    onClick: () => store.dispatch(openModal(ModalName.CONTEST_ACCEPT_AGREEMENT))
  },
  [ContestUpdateAction.SUBMIT_SUBSCRIBERS]: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.niceBlue,
    onClick: () => store.dispatch(openModal(ModalName.CONTEST_SEND_SUBSCRIBERS))
  },
  [ContestUpdateAction.ABORT]: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.blush,
    inProgress: store.getState().contest.abortInProgress,
    onClick: () =>
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          title: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmAbort.title')}
            </Translation>
          ),
          html: (
            <Translation>
              {(t) => (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('pages:contestDetails.confirmAbort.message')
                  }}
                />
              )}
            </Translation>
          ),
          confirmButtonText: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmAbort.confirmButton')}
            </Translation>
          ),
          cancelButtonText: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmAbort.cancelButton')}
            </Translation>
          )
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            const { contest } = store.getState()
            if (contest.contestData) {
              store.dispatch(
                abort({
                  contestId: contest.contestData.id,
                  action: ContestUpdateAction.ABORT,
                  data: null
                })
              )
            }
          }
        })
  },
  [ContestUpdateAction.RESEND]: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.niceBlue,
    inProgress: store.getState().contest.resendInProgress,
    onClick: () =>
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          title: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmResend.title')}
            </Translation>
          ),
          html: (
            <Translation>
              {(t) => (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('pages:contestDetails.confirmResend.message')
                  }}
                />
              )}
            </Translation>
          ),
          confirmButtonText: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmResend.confirmButton')}
            </Translation>
          ),
          cancelButtonText: (
            <Translation>
              {(t) => t('pages:contestDetails.confirmResend.cancelButton')}
            </Translation>
          )
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            const { contest } = store.getState()
            if (contest.contestData) {
              store.dispatch(
                resend({
                  contestId: contest.contestData.id,
                  action: ContestUpdateAction.RESEND,
                  data: null
                })
              )
            }
          }
        })
  }
}

export const status: {
  [key: string]: string
} = {
  [ContestStep.INACTIVE]: 'created',
  [ContestStep.WAITING_BOLETO]: 'created',

  [ContestStep.OPEN]: 'active',
  [ContestStep.ANSWER]: 'active',
  [ContestStep.REPLICATION]: 'active',
  [ContestStep.REJOINDER]: 'active',
  [ContestStep.RESOLVED_R]: 'active',
  [ContestStep.RESOLVED_D]: 'active',
  [ContestStep.AWAITING_DOCUMENT_R]: 'active',
  [ContestStep.AWAITING_DOCUMENT_D]: 'active',
  [ContestStep.AWAITING_SUBSCRIBERS]: 'active',
  [ContestStep.AWAITING_SUBSCRIBERS_R]: 'active',
  [ContestStep.AWAITING_SUBSCRIBERS_D]: 'active',
  [ContestStep.AWAITING_SIGNATURE]: 'active',

  [ContestStep.EXPIRED]: 'expired',

  [ContestStep.ELIGIBLE_FOR_ARBITRATION]: 'arbitration',

  [ContestStep.CANCELED]: 'finished',
  [ContestStep.ABORTED]: 'finished',
  [ContestStep.RESOLVED_WITH_SIGNATURE]: 'finished',
  [ContestStep.RESOLVED_WITHOUT_SIGNATURE]: 'finished',
  [ContestStep.FINISHED]: 'finished'
}

export const timelineHeadingIcons: {
  [key: string]: ReactElement
} = {
  [ContestStep.WAITING_BOLETO]: <HourglassSVG />,
  [ContestStep.OPEN]: <HourglassSVG />,
  [ContestStep.ANSWER]: <HourglassSVG />,
  [ContestStep.REPLICATION]: <HourglassSVG />,
  [ContestStep.REJOINDER]: <HourglassSVG />,
  [ContestStep.RESOLVED_D]: <HourglassSVG />,
  [ContestStep.RESOLVED_R]: <HourglassSVG />,
  [ContestStep.AWAITING_DOCUMENT_D]: <HourglassSVG />,
  [ContestStep.AWAITING_DOCUMENT_R]: <HourglassSVG />,
  [ContestStep.AWAITING_SUBSCRIBERS]: <HourglassSVG />,
  [ContestStep.AWAITING_SUBSCRIBERS_D]: <HourglassSVG />,
  [ContestStep.AWAITING_SUBSCRIBERS_R]: <HourglassSVG />,
  [ContestStep.AWAITING_SIGNATURE]: <HourglassSVG />,
  [ContestStep.ELIGIBLE_FOR_ARBITRATION]: <HammerSVG />,
  [ContestStep.EXPIRED]: <CalendarTimesSVG />,
  [ContestStep.RESOLVED_WITH_SIGNATURE]: <CheckSVG />,
  [ContestStep.FINISHED]: <CheckSVG />
}

export const timelineItemIcons: {
  [key: string]: ReactElement
} = {
  CREATED: <BookmarkSVG />,
  [ContestUpdateAction.PAYMENT]: <PaymentSVG />,
  PAYMENT_BOLETO_CONFIRMED: <PaymentSVG />,
  PAY_RESEND: <PaymentSVG />,
  [ContestUpdateAction.REPLY]: <CommentAltSVG />,
  [ContestUpdateAction.REPLICATION]: <MegaphoneSVG />,
  [ContestUpdateAction.REJOINDER]: <MegaphoneSVG />,
  [ContestUpdateAction.ACCEPT]: <AgreementSVG />,
  [ContestUpdateAction.DECLINE]: <ArbitragemSVG />,
  [ContestUpdateAction.DESIST]: <DeleteSVG />,
  [ContestUpdateAction.ABORT]: <DeleteSVG />,
  [ContestUpdateAction.SUBMIT_DOCUMENT]: <PaperPlaneSVG />,
  [ContestUpdateAction.NEW_DOCUMENT]: <PaperPlaneSVG />,
  [ContestUpdateAction.ACCEPT_DOCUMENT]: <AgreementSVG />,
  [ContestUpdateAction.SUBMIT_SUBSCRIBERS]: <BadgeSVG />,
  AGREEMENT_SIGNED: <DiplomaSVG />,
  SIGNED_DOCUMENT: <DiplomaSVG />,
  [ContestUpdateAction.RESEND]: <PaperPlaneSVG />
}
