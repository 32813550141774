/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardEvent, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Pagination } from 'components/pagination'
import { Tooltip } from 'components/tooltip'

import { useQuery } from 'hooks/useQuery'

import { browserHistory } from 'navigation/browserHistory'

import { TableColumn } from 'types/table/TableColumn'

import { TableWrapper } from './styled'

type TableProps = {
  columns: TableColumn<any>[]
  data: any[]
  isLoading?: boolean
  pagination?: boolean
  pageCount?: number
}

export function Table({
  columns,
  data,
  isLoading = false,
  pagination = true,
  pageCount
}: TableProps): JSX.Element {
  const { t } = useTranslation(['common'])

  const query = useQuery()

  function _handleClick(event: MouseEvent<HTMLButtonElement>) {
    const el = event.currentTarget.parentElement?.parentElement

    if (!el || !el.dataset.sort) return

    const orderBy = el.dataset.orderby
    if (orderBy) {
      const currentOrder = query.get('orderBy')

      if (orderBy === currentOrder) {
        if (query.get('order') === 'desc') {
          query.delete('orderBy')
          query.delete('order')
        } else {
          query.set('order', 'desc')
        }
        browserHistory.replace(`${location.pathname}?${query.toString()}`)
      } else {
        query.set('orderBy', orderBy)
        query.set('order', 'asc')
        browserHistory.replace(`${location.pathname}?${query.toString()}`)
      }
    }
  }

  function _handleKeyPress(event: KeyboardEvent<HTMLButtonElement>) {
    const el = event.currentTarget.parentElement?.parentElement

    if (!el || !el.dataset.sort || event.key !== 'Enter') return

    const orderBy = el.dataset.orderby
    if (orderBy) {
      const currentOrder = query.get('orderBy')

      if (orderBy === currentOrder) {
        if (query.get('order') === 'desc') {
          query.delete('orderBy')
          query.delete('order')
        } else {
          query.set('order', 'desc')
        }
        browserHistory.replace(`${location.pathname}?${query.toString()}`)
      } else {
        query.set('orderBy', orderBy)
        query.set('order', 'asc')
        browserHistory.replace(`${location.pathname}?${query.toString()}`)
      }
    }
  }

  return (
    <TableWrapper>
      <table role="table">
        <thead role="rowgroup">
          <tr role="row">
            {columns.map((column) => (
              <th
                key={column.label}
                style={column.thStyle}
                role="columnheader"
                data-sort={column.sort}
                data-orderby={column.dataKey}
                data-order={
                  query.get('orderBy') === column.dataKey
                    ? query.get('order')
                    : ''
                }
              >
                <div>
                  {column.sort && (
                    <Tooltip
                      key={column.label}
                      content={t('common:table.order', {
                        column:
                          column.label === '#' ? 'Nº da Disputa' : column.label
                      })}
                      placement="bottom"
                    >
                      <button
                        onClick={_handleClick}
                        onKeyDown={_handleKeyPress}
                      >
                        <span>▲</span>
                        <span>▼</span>
                      </button>
                    </Tooltip>
                  )}
                  {column.filter ? column.filter() : column.label}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody role="rowgroup">
          {!isLoading &&
            data.map((row, i) => (
              <tr key={i} role="row" data-rowid={row.id}>
                {columns.map((column) => (
                  <td key={column.label + i} role="cell" style={column.tdStyle}>
                    {column.render ? column.render(row) : row[column.dataKey]}
                  </td>
                ))}
              </tr>
            ))}

          {!isLoading && !data.length && (
            <tr role="row">
              <td
                role="cell"
                colSpan={columns.length}
                style={{ textAlign: 'center' }}
              >
                {t('common:table.noData')}
              </td>
            </tr>
          )}

          {isLoading && (
            <tr role="row">
              <td role="cell" colSpan={columns.length}>
                <p style={{ textAlign: 'center' }}>
                  {t('common:table.isLoading')}
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {pagination && <Pagination pageCount={pageCount} />}
    </TableWrapper>
  )
}
