import styled from 'styled-components'

export const MainWrapper = styled.main`
  z-index: 1;

  flex: 1;

  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.spacings[6]};

  height: ${({ theme }) => `calc(100% - ${theme.misc.header.height})`};

  background-color: ${({ theme }) => theme.colors.white};

  overflow-y: auto;
`
