import { PropsWithChildren } from 'react'

import { FieldSetWrapper } from './styled'

type FieldSetProps = PropsWithChildren<{
  title: string
}>

export function FieldSet({ title, children }: FieldSetProps): JSX.Element {
  return (
    <FieldSetWrapper>
      <legend>{title}</legend>
      {children}
    </FieldSetWrapper>
  )
}
