import styled from 'styled-components'

import { ModalBodyContainer } from 'components/modal/styled'

export const ModalBody = styled(ModalBodyContainer)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  font-size: 16px;

  h3 {
    margin: 20px 0;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
  }
`

export const DescriptionItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;

  & > span:first-of-type {
    text-transform: uppercase;
    font-weight: 600;
  }
`

export const Divider = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 2px;
  margin: 15px 0 5px;
  background-color: #d7d3d3;
`
