import { PulseLoader } from 'react-spinners'

import theme from 'styles/Theme'

import { LoadingContainer } from './styled'

export function Loading(): JSX.Element {
  return (
    <LoadingContainer>
      <PulseLoader color={theme.colors.niceBlue} size="8px" margin="4px" />
    </LoadingContainer>
  )
}
