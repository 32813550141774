import FileSaver from 'file-saver'
import { useField } from 'formik'
import { useMemo } from 'react'
import { useDropzone, FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ReactComponent as DownloadSVG } from 'assets/images/download.svg'
import { ReactComponent as TrashSVG } from 'assets/images/trash.svg'

import { maxSize, allowedExtensions } from 'config/fileUpload'

import { Button } from 'components/button'
import { Flex, FlexItem } from 'components/flex'
import { IconButton } from 'components/iconButton'
import { Table } from 'components/table'

import { TableColumn } from 'types/table/TableColumn'

import { FilesControlWrapper } from './styled'

type Props = {
  name: string
  accept?: string[]
  multiple?: boolean
  showAddButton?: boolean
  showDownloadButton?: boolean
  showRemoveButton?: boolean
}

enum RejectionCode {
  INVALID_TYPE = 'file-invalid-type',
  FILE_TOO_LARGE = 'file-too-large'
}

export function FilesControl({
  name,
  accept = allowedExtensions,
  multiple = true,
  showAddButton = true,
  showDownloadButton = true,
  showRemoveButton = true
}: Props): JSX.Element {
  const { t } = useTranslation(['validation'])

  const [field, meta, helpers] = useField({ name, type: 'file' })

  const { getInputProps, open } = useDropzone({
    accept: accept.join(','),
    multiple,
    noClick: true,
    noKeyboard: true,
    maxSize: maxSize,
    onDrop: (files) => addFiles(files),
    onDropRejected: (files) => {
      console.log(files)

      const invalidType: FileRejection[] = []
      const tooLarge: FileRejection[] = []

      files.forEach(({ errors }, index) => {
        const invalidTypeError = errors.find(
          ({ code }) => code === RejectionCode.INVALID_TYPE
        )
        if (invalidTypeError) {
          invalidType.push(files[index])
        }

        const tooLargeError = errors.find(
          ({ code }) => code === RejectionCode.FILE_TOO_LARGE
        )
        if (tooLargeError) {
          tooLarge.push(files[index])
        }
      })

      toast.error(
        <span>
          <p>
            <strong>Erro:</strong> Não foi possível adicionar alguns arquivos.
          </p>
          {invalidType.length > 0 && (
            <>
              <br />
              <ul>
                Arquivos com formato não aceito pela plataforma:
                {invalidType.map(({ file }, index) => (
                  <li key={index} style={{ marginLeft: '16px' }}>
                    {file.name}
                  </li>
                ))}
              </ul>
            </>
          )}
          {tooLarge.length > 0 && (
            <>
              <br />
              <ul>
                Arquivos com mais {maxSize / 1000000}MB:
                {tooLarge.map(({ file }, index) => (
                  <li key={index} style={{ marginLeft: '16px' }}>
                    {file.name}
                  </li>
                ))}
              </ul>
            </>
          )}
        </span>
      )
    }
  })

  const columns = useMemo<TableColumn<File>[]>(
    () => [
      {
        label: 'Arquivo',
        dataKey: 'name'
      },
      {
        label: 'Ações',
        dataKey: 'actions',
        thStyle: { width: '90px', textAlign: 'center' },
        tdStyle: { width: '90px' },
        render: (row) => (
          <Flex direction="row" align="center" justify="center" gap={2}>
            {showDownloadButton && (
              <IconButton
                tooltip="Baixar arquivo"
                color="niceBlue"
                onClick={() => FileSaver.saveAs(row, row.name)}
              >
                <DownloadSVG />
              </IconButton>
            )}
            {showRemoveButton && (
              <IconButton
                tooltip="Remover arquivo"
                color="blush"
                onClick={() => removeFile(row.name)}
              >
                <TrashSVG />
              </IconButton>
            )}
          </Flex>
        )
      }
    ],
    [field.value]
  )

  function addFiles(files: File[]) {
    const newFiles = [...field.value]

    files.forEach((file) => {
      const index = newFiles.findIndex(({ name }) => name === file.name)
      if (index === -1) {
        newFiles.push(file)
      }
    })

    helpers.setValue(newFiles)
  }

  function removeFile(filename: string) {
    const newFiles = field.value.filter((file: File) => file.name !== filename)

    helpers.setValue(newFiles)
  }

  return (
    <FilesControlWrapper hasError={!!meta.touched && !!meta.error}>
      <Flex direction="row">
        <FlexItem grow="1">
          <h4>Arquivos</h4>
        </FlexItem>

        {showAddButton && (
          <Button
            small
            type="button"
            color={!!meta.touched && !!meta.error ? 'blush' : 'niceBlue'}
            variant="outline"
            onClick={open}
            disabled={!multiple && field.value.length > 0}
          >
            Anexar
          </Button>
        )}
      </Flex>

      <Table columns={columns} data={field.value} pagination={false} />

      <input {...getInputProps()} />

      {!!meta.touched && !!meta.error && (
        <p>
          {t(`validation:${meta.error}`, {
            length: 1
          })}
        </p>
      )}
    </FilesControlWrapper>
  )
}
