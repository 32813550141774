import { ReactSweetAlertOptions } from 'sweetalert2-react-content'

import { ReactComponent as CloseSvg } from 'assets/images/close-thin.svg'

import Theme from 'styles/Theme'

export const confirmationConfig: ReactSweetAlertOptions = {
  customClass: {
    container: 'confirmation__wrapper',
    popup: 'confirmation__container',
    closeButton: 'confirmation__close-button',
    title: 'confirmation__title',
    htmlContainer: 'confirmation__message',
    actions: 'confirmation__actions',
    confirmButton: 'confirmation__button',
    cancelButton: 'confirmation__button',
    denyButton: 'confirmation__button'
  },
  confirmButtonColor: Theme.colors.niceBlue,
  showConfirmButton: true,
  focusConfirm: true,

  cancelButtonColor: Theme.colors.blush,
  showCancelButton: true,
  focusCancel: false,

  denyButtonColor: Theme.colors.blush,
  showDenyButton: false,
  focusDeny: false,

  showCloseButton: true,
  closeButtonHtml: <CloseSvg />,

  icon: 'warning'
}
