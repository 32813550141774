import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'
import { PagedResult } from 'types/PagedResult'
import { User } from 'types/user/User'
import { UserInviteInfo } from 'types/user/UserInviteInfo'

import {
  FETCH_USER_INFO,
  FETCH_USER_INFO_FAILURE,
  FETCH_USER_INFO_SUCCESS,
  SEARCH_USER,
  SEARCH_USER_FAILURE,
  SEARCH_USER_SUCCESS,
  FETCH_INVITE_INFO,
  FETCH_INVITE_INFO_FAILURE,
  FETCH_INVITE_INFO_SUCCESS,
  SUBSCRIBE_BY_INVITE,
  SUBSCRIBE_BY_INVITE_FAILURE,
  SUBSCRIBE_BY_INVITE_SUCCESS,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_FAILURE,
  REQUEST_PASSWORD_RESET_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_ADDRESS_FAILURE,
  UPDATE_USER_ADDRESS_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS
} from './actionTypes'

export type UserState = {
  userInfo?: User
  fetchUserInfoInProgress: boolean
  searchUserResult?: PagedResult<User>
  searchUserInProgress: boolean
  inviteInfo?: UserInviteInfo
  fetchInviteInfoInProgress: boolean
  subscriptionByInviteInProgress: boolean
  requestPasswordResetInProgress: boolean
  resetPasswordInProgress: boolean
  updateUserInProgress: boolean
  updateUserAddressInProgress: boolean
  updatePasswordInProgress: boolean
}

const INITIAL_STATE: UserState = {
  userInfo: undefined,
  fetchUserInfoInProgress: false,
  searchUserResult: undefined,
  searchUserInProgress: false,
  inviteInfo: undefined,
  fetchInviteInfoInProgress: false,
  subscriptionByInviteInProgress: false,
  requestPasswordResetInProgress: false,
  resetPasswordInProgress: false,
  updateUserInProgress: false,
  updateUserAddressInProgress: false,
  updatePasswordInProgress: false
}

export function userReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): UserState {
  return produce(state, (draft) => {
    switch (action.type) {
      case FETCH_USER_INFO: {
        draft.fetchUserInfoInProgress = true
        break
      }

      case FETCH_USER_INFO_FAILURE: {
        draft.fetchUserInfoInProgress = false
        break
      }

      case FETCH_USER_INFO_SUCCESS: {
        draft.userInfo = <User>action.payload
        draft.fetchUserInfoInProgress = false
        break
      }

      case SEARCH_USER: {
        draft.searchUserInProgress = true
        break
      }

      case SEARCH_USER_FAILURE: {
        draft.searchUserInProgress = false
        break
      }

      case SEARCH_USER_SUCCESS: {
        draft.searchUserResult = <PagedResult<User>>action.payload
        draft.searchUserInProgress = false
        break
      }

      case FETCH_INVITE_INFO: {
        draft.fetchInviteInfoInProgress = true
        break
      }

      case FETCH_INVITE_INFO_FAILURE: {
        draft.fetchInviteInfoInProgress = false
        break
      }

      case FETCH_INVITE_INFO_SUCCESS: {
        draft.inviteInfo = <UserInviteInfo>action.payload
        draft.fetchInviteInfoInProgress = false
        break
      }

      case SUBSCRIBE_BY_INVITE: {
        draft.subscriptionByInviteInProgress = true
        break
      }

      case SUBSCRIBE_BY_INVITE_FAILURE: {
        draft.subscriptionByInviteInProgress = false
        break
      }

      case SUBSCRIBE_BY_INVITE_SUCCESS: {
        draft.subscriptionByInviteInProgress = false
        break
      }

      case REQUEST_PASSWORD_RESET: {
        draft.requestPasswordResetInProgress = true
        break
      }

      case REQUEST_PASSWORD_RESET_FAILURE: {
        draft.requestPasswordResetInProgress = false
        break
      }

      case REQUEST_PASSWORD_RESET_SUCCESS: {
        draft.requestPasswordResetInProgress = false
        break
      }

      case RESET_PASSWORD: {
        draft.resetPasswordInProgress = true
        break
      }

      case RESET_PASSWORD_FAILURE: {
        draft.resetPasswordInProgress = false
        break
      }

      case RESET_PASSWORD_SUCCESS: {
        draft.resetPasswordInProgress = false
        break
      }

      case UPDATE_USER: {
        draft.updateUserInProgress = true
        break
      }

      case UPDATE_USER_FAILURE: {
        draft.updateUserInProgress = false
        break
      }

      case UPDATE_USER_SUCCESS: {
        draft.updateUserInProgress = false
        break
      }

      case UPDATE_USER_ADDRESS: {
        draft.updateUserAddressInProgress = true
        break
      }

      case UPDATE_USER_ADDRESS_FAILURE: {
        draft.updateUserAddressInProgress = false
        break
      }

      case UPDATE_USER_ADDRESS_SUCCESS: {
        draft.updateUserAddressInProgress = false
        break
      }

      case UPDATE_PASSWORD: {
        draft.updatePasswordInProgress = true
        break
      }

      case UPDATE_PASSWORD_FAILURE: {
        draft.updatePasswordInProgress = false
        break
      }

      case UPDATE_PASSWORD_SUCCESS: {
        draft.updatePasswordInProgress = false
        break
      }

      case CLEAR_SESSION_DATA: {
        draft.userInfo = undefined
        draft.fetchUserInfoInProgress = false
        draft.searchUserResult = undefined
        draft.searchUserInProgress = false
        draft.inviteInfo = undefined
        draft.fetchInviteInfoInProgress = false
        draft.subscriptionByInviteInProgress = false
        draft.requestPasswordResetInProgress = false
        draft.resetPasswordInProgress = false
        draft.updateUserInProgress = false
        draft.updateUserAddressInProgress = false
        draft.updatePasswordInProgress = false
        break
      }

      default: {
        break
      }
    }
  })
}
