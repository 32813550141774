import { Action, ActionWithoutPayload } from 'types/Action'
import { Contest } from 'types/contest/Contest'
import { ContestActionsResult } from 'types/contest/ContestActionsResult'
import { ContestCardPayment } from 'types/contest/ContestCardPayment'
import { ContestCategoriesResult } from 'types/contest/ContestCategoriesResult'
import { ContestCreate } from 'types/contest/ContestCreate'
import { ContestGiveUpAnswer } from 'types/contest/ContestGiveUpAnswer'
import { ContestInviteMember } from 'types/contest/ContestInviteMember'
import { ContestMember } from 'types/contest/ContestMember'
import { ContestNominationAnswer } from 'types/contest/ContestNomination'
import { ContestPrice } from 'types/contest/ContestPrice'
import { ContestRemoveMember } from 'types/contest/ContestRemoveMember'
import { ContestReply } from 'types/contest/ContestReply'
import { ContestResendPayment } from 'types/contest/ContestResendPayment'
import { ContestResendPaymentResponse } from 'types/contest/ContestResendPaymentResponse'
import { ContestSearchResult } from 'types/contest/ContestSearchResult'
import { ContestSendSubscribers } from 'types/contest/ContestSendSubscribers'
import { ContestUpdate } from 'types/contest/ContestUpdate'
import { ContestUpdateMemberPermission } from 'types/contest/ContestUpdateMemberPermission'
import { SearchUserContestsQuery } from 'types/contest/SearchUserContestsQuery'
import { PagedResult } from 'types/PagedResult'

import {
  SEARCH_USER_CONTESTS,
  SEARCH_USER_CONTESTS_FAILURE,
  SEARCH_USER_CONTESTS_SUCCESS,
  CREATE_CONTEST,
  CREATE_CONTEST_FAILURE,
  CREATE_CONTEST_SUCCESS,
  ADD_CONTEST_MEMBER,
  ADD_CONTEST_MEMBER_FAILURE,
  ADD_CONTEST_MEMBER_SUCCESS,
  UPDATE_CONTEST_MEMBER_PERMISSION,
  UPDATE_CONTEST_MEMBER_PERMISSION_FAILURE,
  UPDATE_CONTEST_MEMBER_PERMISSION_SUCCESS,
  REMOVE_CONTEST_MEMBER,
  REMOVE_CONTEST_MEMBER_FAILURE,
  REMOVE_CONTEST_MEMBER_SUCCESS,
  PAY_CONTEST,
  PAY_CONTEST_FAILURE,
  PAY_CONTEST_SUCCESS,
  PAY_CONTEST_BOLETO,
  PAY_CONTEST_BOLETO_FAILURE,
  PAY_CONTEST_BOLETO_SUCCESS,
  GET_CONTEST_BY_ID,
  GET_CONTEST_BY_ID_FAILURE,
  GET_CONTEST_BY_ID_SUCCESS,
  FETCH_CONTEST_CATEGORIES,
  FETCH_CONTEST_CATEGORIES_FAILURE,
  FETCH_CONTEST_CATEGORIES_SUCCESS,
  GET_CONTEST_ACTIONS,
  GET_CONTEST_ACTIONS_FAILURE,
  GET_CONTEST_ACTIONS_SUCCESS,
  REPLY_CONTEST,
  REPLY_CONTEST_FAILURE,
  REPLY_CONTEST_SUCCESS,
  DECLINE_CONTEST,
  DECLINE_CONTEST_FAILURE,
  DECLINE_CONTEST_SUCCESS,
  ACCEPT_CONTEST,
  ACCEPT_CONTEST_FAILURE,
  ACCEPT_CONTEST_SUCCESS,
  SEND_AGREEMENT,
  SEND_AGREEMENT_FAILURE,
  SEND_AGREEMENT_SUCCESS,
  ACCEPT_AGREEMENT,
  ACCEPT_AGREEMENT_FAILURE,
  ACCEPT_AGREEMENT_SUCCESS,
  SEND_SUBSCRIBERS,
  SEND_SUBSCRIBERS_FAILURE,
  SEND_SUBSCRIBERS_SUCCESS,
  ASK_TO_GIVE_UP,
  ASK_TO_GIVE_UP_FAILURE,
  ASK_TO_GIVE_UP_SUCCESS,
  ANSWER_GIVE_UP,
  ANSWER_GIVE_UP_FAILURE,
  ANSWER_GIVE_UP_SUCCESS,
  DESIST,
  DESIST_FAILURE,
  DESIST_SUCCESS,
  ABORT,
  ABORT_FAILURE,
  ABORT_SUCCESS,
  RESEND,
  RESEND_FAILURE,
  RESEND_SUCCESS,
  GET_PRICES,
  GET_PRICES_FAILURE,
  GET_PRICES_SUCCESS,
  RESEND_PAYMENT,
  RESEND_PAYMENT_FAILURE,
  RESEND_PAYMENT_SUCCESS,
  DELETE_CONTEST,
  DELETE_CONTEST_FAILURE,
  DELETE_CONTEST_SUCCESS,
  ANSWER_CONTEST_INSTITUTION_NOMINATION
} from './actionTypes'

export function searchUserContests(
  userId: number,
  params: SearchUserContestsQuery
): Action<{ userId: number; params: SearchUserContestsQuery }> {
  return { type: SEARCH_USER_CONTESTS, payload: { userId, params } }
}

export function searchUserContestsFailure(): ActionWithoutPayload {
  return { type: SEARCH_USER_CONTESTS_FAILURE }
}

export function searchUserContestsSuccess(
  payload: PagedResult<ContestSearchResult>
): Action<PagedResult<ContestSearchResult>> {
  return { type: SEARCH_USER_CONTESTS_SUCCESS, payload }
}

export function createContest(payload: ContestCreate): Action<ContestCreate> {
  return { type: CREATE_CONTEST, payload }
}

export function createContestFailure(): ActionWithoutPayload {
  return { type: CREATE_CONTEST_FAILURE }
}

export function createContestSuccess(payload: Contest): Action<Contest> {
  return { type: CREATE_CONTEST_SUCCESS, payload }
}

export function addContestMember(
  payload: ContestInviteMember
): Action<ContestInviteMember> {
  return { type: ADD_CONTEST_MEMBER, payload }
}

export function addContestMemberFailure(): ActionWithoutPayload {
  return { type: ADD_CONTEST_MEMBER_FAILURE }
}

export function addContestMemberSuccess(
  payload: ContestMember[]
): Action<ContestMember[]> {
  return { type: ADD_CONTEST_MEMBER_SUCCESS, payload }
}

export function updateContestMemberPermission(
  payload: ContestUpdateMemberPermission
): Action<ContestUpdateMemberPermission> {
  return { type: UPDATE_CONTEST_MEMBER_PERMISSION, payload }
}

export function updateContestMemberPermissionFailure(): ActionWithoutPayload {
  return { type: UPDATE_CONTEST_MEMBER_PERMISSION_FAILURE }
}

export function updateContestMemberPermissionSuccess(
  payload: ContestMember[]
): Action<ContestMember[]> {
  return { type: UPDATE_CONTEST_MEMBER_PERMISSION_SUCCESS, payload }
}

export function removeContestMember(
  payload: ContestRemoveMember
): Action<ContestRemoveMember> {
  return { type: REMOVE_CONTEST_MEMBER, payload }
}

export function removeContestMemberFailure(): ActionWithoutPayload {
  return { type: REMOVE_CONTEST_MEMBER_FAILURE }
}

export function removeContestMemberSuccess(
  payload: ContestMember[]
): Action<ContestMember[]> {
  return { type: REMOVE_CONTEST_MEMBER_SUCCESS, payload }
}

export function payContest(
  payload: ContestUpdate<ContestCardPayment>
): Action<ContestUpdate<ContestCardPayment>> {
  return { type: PAY_CONTEST, payload }
}

export function payContestFailure(): ActionWithoutPayload {
  return { type: PAY_CONTEST_FAILURE }
}

export function payContestSuccess(payload: Contest): Action<Contest> {
  return { type: PAY_CONTEST_SUCCESS, payload }
}

export function payContestBoleto(
  payload: ContestUpdate<null>
): Action<ContestUpdate<null>> {
  return { type: PAY_CONTEST_BOLETO, payload }
}

export function payContestBoletoFailure(): ActionWithoutPayload {
  return { type: PAY_CONTEST_BOLETO_FAILURE }
}

export function payContestBoletoSuccess(payload: Contest): Action<Contest> {
  return { type: PAY_CONTEST_BOLETO_SUCCESS, payload }
}

export function getContestById(payload: number): Action<number> {
  return { type: GET_CONTEST_BY_ID, payload }
}

export function getContestByIdFailure(): ActionWithoutPayload {
  return { type: GET_CONTEST_BY_ID_FAILURE }
}

export function getContestByIdSuccess(payload: Contest): Action<Contest> {
  return { type: GET_CONTEST_BY_ID_SUCCESS, payload }
}

export function fetchContestCategories(): ActionWithoutPayload {
  return { type: FETCH_CONTEST_CATEGORIES }
}

export function fetchContestCategoriesFailure(): ActionWithoutPayload {
  return { type: FETCH_CONTEST_CATEGORIES_FAILURE }
}

export function fetchContestCategoriesSuccess(
  payload: ContestCategoriesResult[]
): Action<ContestCategoriesResult[]> {
  return { type: FETCH_CONTEST_CATEGORIES_SUCCESS, payload }
}

export function getContestActions(payload: number): Action<number> {
  return { type: GET_CONTEST_ACTIONS, payload }
}

export function getContestActionsFailure(): ActionWithoutPayload {
  return { type: GET_CONTEST_ACTIONS_FAILURE }
}

export function getContestActionsSuccess(
  payload: ContestActionsResult[]
): Action<ContestActionsResult[]> {
  return { type: GET_CONTEST_ACTIONS_SUCCESS, payload }
}

export function replyContest(
  payload: ContestUpdate<ContestReply>
): Action<ContestUpdate<ContestReply>> {
  return { type: REPLY_CONTEST, payload }
}

export function replyContestFailure(): ActionWithoutPayload {
  return { type: REPLY_CONTEST_FAILURE }
}

export function replyContestSuccess(payload: Contest): Action<Contest> {
  return { type: REPLY_CONTEST_SUCCESS, payload }
}

export function declineContest(
  payload: ContestUpdate<null>
): Action<ContestUpdate<null>> {
  return { type: DECLINE_CONTEST, payload }
}

export function declineContestFailure(): ActionWithoutPayload {
  return { type: DECLINE_CONTEST_FAILURE }
}

export function declineContestSuccess(payload: Contest): Action<Contest> {
  return { type: DECLINE_CONTEST_SUCCESS, payload }
}

export function acceptContest(
  payload: ContestUpdate<null>
): Action<ContestUpdate<null>> {
  return { type: ACCEPT_CONTEST, payload }
}

export function acceptContestFailure(): ActionWithoutPayload {
  return { type: ACCEPT_CONTEST_FAILURE }
}

export function acceptContestSuccess(payload: Contest): Action<Contest> {
  return { type: ACCEPT_CONTEST_SUCCESS, payload }
}

export function sendAgreement(
  payload: ContestUpdate<null>
): Action<ContestUpdate<null>> {
  return { type: SEND_AGREEMENT, payload }
}

export function sendAgreementFailure(): ActionWithoutPayload {
  return { type: SEND_AGREEMENT_FAILURE }
}

export function sendAgreementSuccess(payload: Contest): Action<Contest> {
  return { type: SEND_AGREEMENT_SUCCESS, payload }
}

export function acceptAgreement(
  payload: ContestUpdate<{ text: string }>
): Action<ContestUpdate<{ text: string }>> {
  return { type: ACCEPT_AGREEMENT, payload }
}

export function acceptAgreementFailure(): ActionWithoutPayload {
  return { type: ACCEPT_AGREEMENT_FAILURE }
}

export function acceptAgreementSuccess(payload: Contest): Action<Contest> {
  return { type: ACCEPT_AGREEMENT_SUCCESS, payload }
}

export function sendSubscribers(
  payload: ContestUpdate<ContestSendSubscribers>
): Action<ContestUpdate<ContestSendSubscribers>> {
  return { type: SEND_SUBSCRIBERS, payload }
}

export function sendSubscribersFailure(): ActionWithoutPayload {
  return { type: SEND_SUBSCRIBERS_FAILURE }
}

export function sendSubscribersSuccess(payload: Contest): Action<Contest> {
  return { type: SEND_SUBSCRIBERS_SUCCESS, payload }
}

export function askToGiveUp(payload: number): Action<number> {
  return { type: ASK_TO_GIVE_UP, payload }
}

export function askToGiveUpFailure(): ActionWithoutPayload {
  return { type: ASK_TO_GIVE_UP_FAILURE }
}

export function askToGiveUpSuccess(): ActionWithoutPayload {
  return { type: ASK_TO_GIVE_UP_SUCCESS }
}

export function answerGiveUp(
  payload: ContestGiveUpAnswer
): Action<ContestGiveUpAnswer> {
  return { type: ANSWER_GIVE_UP, payload }
}

export function answerGiveUpFailure(): ActionWithoutPayload {
  return { type: ANSWER_GIVE_UP_FAILURE }
}

export function answerGiveUpSuccess(): ActionWithoutPayload {
  return { type: ANSWER_GIVE_UP_SUCCESS }
}

export function desist(
  payload: ContestUpdate<null>
): Action<ContestUpdate<null>> {
  return { type: DESIST, payload }
}

export function desistFailure(): ActionWithoutPayload {
  return { type: DESIST_FAILURE }
}

export function desistSuccess(payload: Contest): Action<Contest> {
  return { type: DESIST_SUCCESS, payload }
}

export function abort(
  payload: ContestUpdate<null>
): Action<ContestUpdate<null>> {
  return { type: ABORT, payload }
}

export function abortFailure(): ActionWithoutPayload {
  return { type: ABORT_FAILURE }
}

export function abortSuccess(payload: Contest): Action<Contest> {
  return { type: ABORT_SUCCESS, payload }
}

export function resend(
  payload: ContestUpdate<null>
): Action<ContestUpdate<null>> {
  return { type: RESEND, payload }
}

export function resendFailure(): ActionWithoutPayload {
  return { type: RESEND_FAILURE }
}

export function resendSuccess(payload: Contest): Action<Contest> {
  return { type: RESEND_SUCCESS, payload }
}

export function getPrices(): ActionWithoutPayload {
  return { type: GET_PRICES }
}

export function getPricesFailure(): ActionWithoutPayload {
  return { type: GET_PRICES_FAILURE }
}

export function getPricesSuccess(payload: ContestPrice): Action<ContestPrice> {
  return { type: GET_PRICES_SUCCESS, payload }
}

export function resendPayment(
  payload: ContestResendPayment
): Action<ContestResendPayment> {
  return { type: RESEND_PAYMENT, payload }
}

export function resendPaymentFailure(): ActionWithoutPayload {
  return { type: RESEND_PAYMENT_FAILURE }
}

export function resendPaymentSuccess(
  payload: ContestResendPaymentResponse
): Action<ContestResendPaymentResponse> {
  return { type: RESEND_PAYMENT_SUCCESS, payload }
}

export function deleteContest(payload: number): Action<number> {
  return { type: DELETE_CONTEST, payload }
}

export function deleteContestFailure(): ActionWithoutPayload {
  return { type: DELETE_CONTEST_FAILURE }
}

export function deleteContestSuccess(): ActionWithoutPayload {
  return { type: DELETE_CONTEST_SUCCESS }
}

export function answerContestNomination(
  payload: ContestNominationAnswer
): Action<ContestNominationAnswer> {
  return { type: ANSWER_CONTEST_INSTITUTION_NOMINATION, payload }
}
