import LogoWhitePNG from 'assets/images/logo-white.png'

import { SignUpHeaderWrapper } from './styled'

export function AuthHeader(): JSX.Element {
  return (
    <SignUpHeaderWrapper>
      <img src={LogoWhitePNG} alt="Open Banking" />
    </SignUpHeaderWrapper>
  )
}
