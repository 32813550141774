import { Action } from 'types/Action'
import { ModalName } from 'types/enums/ModalName'

import { OPEN_MODAL, CLOSE_MODAL } from './actionTypes'

export function openModal(payload: ModalName): Action<string> {
  return { type: OPEN_MODAL, payload }
}

export function closeModal(payload: ModalName): Action<string> {
  return { type: CLOSE_MODAL, payload }
}
