import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import * as contestFileService from 'services/http/contestFileService'

import { closeModal } from 'store/modal/actions'
import {
  fetchContestFilesFailure,
  fetchContestFilesSuccess,
  addContestFilesFailure,
  addContestFilesSuccess,
  removeContestFilesFailure,
  removeContestFilesSuccess,
  updateContesFileNotesFailure,
  updateContesFileNotesSuccess
} from 'store/contestFile/actions'
import {
  FETCH_CONTEST_FILES,
  ADD_CONTEST_FILES,
  REMOVE_CONTEST_FILES,
  UPDATE_CONTEST_FILE_NOTES
} from 'store/contestFile/actionTypes'

import { Action } from 'types/Action'
import { ContestFile } from 'types/contestFile/ContestFile'
import { ContestFileCreate } from 'types/contestFile/ContestFileCreate'
import { ContestFileUpdateNote } from 'types/contestFile/ContestFileUpdateNote'
import { ModalName } from 'types/enums/ModalName'

import { showError, showSuccess } from 'utils/alertUtils'

function* fetchContestFilesRequest({
  payload
}: Action<number>): Generator<StrictEffect, void, ContestFile[]> {
  try {
    const response = yield call(contestFileService.fetchContestFiles, payload)
    yield put(fetchContestFilesSuccess(response))
  } catch (error) {
    yield call(showError, 'fetchContestFiles', error)
    yield put(fetchContestFilesFailure())
  }
}

function* addContestFilesRequest({
  payload
}: Action<ContestFileCreate>): Generator<StrictEffect, void, ContestFile[]> {
  try {
    const response = yield call(contestFileService.addContestFiles, payload)
    yield call(showSuccess, 'addContestFiles')
    yield put(closeModal(ModalName.CONTEST_ADD_ATTACHMENT))
    yield put(addContestFilesSuccess(response))
  } catch (error) {
    yield call(showError, 'addContestFiles', error)
    yield put(addContestFilesFailure())
  }
}

function* removeContestFilesRequest({
  payload
}: Action<string>): Generator<StrictEffect, void, string> {
  try {
    yield call(contestFileService.removeContestFile, payload)
    yield call(showSuccess, 'removeContestFiles')
    yield put(removeContestFilesSuccess(payload))
  } catch (error) {
    yield call(showError, 'removeContestFiles', error)
    yield put(removeContestFilesFailure())
  }
}

function* updateContestFileNotesRequest({
  payload
}: Action<ContestFileUpdateNote>): Generator<
  StrictEffect,
  void,
  ContestFile[]
> {
  try {
    const response = yield call(
      contestFileService.updateContestFileNotes,
      payload
    )
    yield call(showSuccess, 'updateContestFileNote')
    yield put(closeModal(ModalName.CONTEST_ADD_ATTACHMENT_NOTE))
    yield put(updateContesFileNotesSuccess(response))
  } catch (error) {
    yield call(showError, 'updateContestFileNote', error)
    yield put(updateContesFileNotesFailure())
  }
}

export function* contestFileSagas(): Generator {
  yield all([
    takeLatest(FETCH_CONTEST_FILES, fetchContestFilesRequest),
    takeLatest(ADD_CONTEST_FILES, addContestFilesRequest),
    takeLatest(REMOVE_CONTEST_FILES, removeContestFilesRequest),
    takeLatest(UPDATE_CONTEST_FILE_NOTES, updateContestFileNotesRequest)
  ])
}
