import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const DetailsLink = styled(Link)`
  color: ${({ theme }) => theme.colors.darkAqua};

  &:hover {
    text-decoration: underline;
    transition: 0.3s;
  }
`
