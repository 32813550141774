import styled from 'styled-components'

import { Colors } from 'styles/Theme'

type Color = keyof Colors

type CardContainerProps = {
  color: Color
  borderColor: Color
  backgroundColor: Color
}

export const CardContainer = styled.div<CardContainerProps>`
  min-width: 180px;
  width: 100%;

  color: ${({ theme, color }) => theme.colors[color]};

  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[2]}`};

  border: 2px solid ${({ theme, borderColor }) => theme.colors[borderColor]};
  border-radius: ${({ theme }) => theme.fontSizes.md};

  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor]};

  &:hover {
    opacity: 0.6;
    transition: 0.3s ease;
  }
`
