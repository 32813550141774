import { GET } from 'utils/httpUtils'

const EXPORT_DATABASE_PATH = '/contest/admin/exportContests'

type AdminContest = {
  ID: number
  'DATA CRIACAO': string
  SERVIÇO: string
  CENÁRIO: string
  CATEGORIA: string
  STATUS: string
  ABERTURA: string
  RESPOSTA: string
  REPLICA: string
  TREPLICA: string
  DESISTIDA: string
  REENVIADA: string
  'ACEITA EM': string
  'ACORDO ENVIADO': string
}

export async function exportContests(): Promise<AdminContest[]> {
  try {
    const data = await GET<AdminContest[]>(EXPORT_DATABASE_PATH)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}
