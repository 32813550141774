import { Action, ActionWithoutPayload } from 'types/Action'
import { ContestAssociation } from 'types/contestAssociation/ContestAssociation'
import { ContestAssociationCreate } from 'types/contestAssociation/ContestAssociationCreate'
import { ContestAssociationRemove } from 'types/contestAssociation/ContestAssociationRemove'

import {
  FETCH_CONTEST_ASSOCIATIONS,
  FETCH_CONTEST_ASSOCIATIONS_FAILURE,
  FETCH_CONTEST_ASSOCIATIONS_SUCCESS,
  CREATE_CONTEST_ASSOCIATION,
  CREATE_CONTEST_ASSOCIATION_FAILURE,
  CREATE_CONTEST_ASSOCIATION_SUCCESS,
  REMOVE_CONTEST_ASSOCIATION,
  REMOVE_CONTEST_ASSOCIATION_FAILURE,
  REMOVE_CONTEST_ASSOCIATION_SUCCESS
} from './actionTypes'

export function fetchContestAssociations(contestId: number): Action<number> {
  return { type: FETCH_CONTEST_ASSOCIATIONS, payload: contestId }
}

export function fetchContestAssociationsFailure(): ActionWithoutPayload {
  return { type: FETCH_CONTEST_ASSOCIATIONS_FAILURE }
}

export function fetchContestAssociationsSuccess(
  payload: ContestAssociation[]
): Action<ContestAssociation[]> {
  return { type: FETCH_CONTEST_ASSOCIATIONS_SUCCESS, payload }
}

export function createContestAssociation(
  payload: ContestAssociationCreate
): Action<ContestAssociationCreate> {
  return { type: CREATE_CONTEST_ASSOCIATION, payload }
}

export function createContestAssociationFailure(): ActionWithoutPayload {
  return { type: CREATE_CONTEST_ASSOCIATION_FAILURE }
}

export function createContestAssociationSuccess(): ActionWithoutPayload {
  return { type: CREATE_CONTEST_ASSOCIATION_SUCCESS }
}

export function removeContestAssociation(
  payload: ContestAssociationRemove
): Action<ContestAssociationRemove> {
  return { type: REMOVE_CONTEST_ASSOCIATION, payload }
}

export function removeContestAssociationFailure(): ActionWithoutPayload {
  return { type: REMOVE_CONTEST_ASSOCIATION_FAILURE }
}

export function removeContestAssociationSuccess(): ActionWithoutPayload {
  return { type: REMOVE_CONTEST_ASSOCIATION_SUCCESS }
}
