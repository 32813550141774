import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { FilesControl } from 'components/filesControl'
import { Flex } from 'components/flex'
import { Form } from 'components/form'
import { Modal, ModalHeader } from 'components/modal'
import { Separator } from 'components/separator'
import { Tooltip } from 'components/tooltip'

import { confirmationConfig } from 'config/swal'

import { GlobalState } from 'store'
import { sendAgreement } from 'store/contest/actions'
import { closeModal } from 'store/modal/actions'

import { ContestUpdateAction } from 'types/enums/ContestUpdateAction'
import { ModalName } from 'types/enums/ModalName'

import { ContestHeading, ModalBody, ModalHeaderContent } from './styled'

export function ContestSendNewAgreementModal(): JSX.Element {
  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    modal: { contestSendNewAgreementIsOpen },
    contest: { contestData, sendAgreementInProgress }
  } = useSelector((state: GlobalState) => state)

  const { contestId } = useParams<{ contestId: string }>()

  function _handleSubmit(data: FormikValues) {
    if (contestData?.id) {
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          title: t('contestSendNewAgreement.confirmation.title'),
          html: (
            <span
              dangerouslySetInnerHTML={{
                __html: t('contestSendNewAgreement.confirmation.message')
              }}
            />
          ),
          confirmButtonText: t(
            'contestSendNewAgreement.confirmation.confirmButton'
          ),
          cancelButtonText: t(
            'contestSendNewAgreement.confirmation.cancelButton'
          )
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            dispatch(
              sendAgreement({
                contestId: contestData.id,
                action: ContestUpdateAction.NEW_DOCUMENT,
                data: null,
                documents: data.documents
              })
            )
          }
        })
    }
  }

  return (
    <Modal
      isOpen={contestSendNewAgreementIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_SEND_NEW_AGREEMENT))}
    >
      <ModalHeader separator={false}>
        <ModalHeaderContent>
          <h2>{t('contestSendNewAgreement.title')}</h2>
          <h4>{t('contestSendNewAgreement.subtitle', { contestId })}</h4>

          <ContestHeading>
            <dl>
              <dt>{t('contestSendNewAgreement.heading.requester')}</dt>
              <dd>{contestData?.requester}</dd>
              <dt>{t('contestSendNewAgreement.heading.defendant')}</dt>
              <dd>{contestData?.defendant}</dd>
              <dt>{t('contestAccept.heading.category')}</dt>
              {!!contestData && contestData?.category.length > 30 ? (
                <Tooltip content={contestData?.category}>
                  <dd>{contestData?.category.substr(0, 30) + '...'}</dd>
                </Tooltip>
              ) : (
                <dd>{contestData?.category}</dd>
              )}
            </dl>
          </ContestHeading>

          <Separator color="warmGreyThree" />
        </ModalHeaderContent>
      </ModalHeader>

      <ModalBody>
        <Form
          initialValues={{ documents: [] }}
          validationSchema={Yup.object().shape({
            documents: Yup.array()
              .required('required')
              .length(1, 'files.length')
          })}
          onSubmit={_handleSubmit}
        >
          <FilesControl
            name="documents"
            accept={['application/pdf']}
            multiple={false}
          />

          <Flex direction="row" justify="center" style={{ marginTop: '1rem' }}>
            <Button small type="submit" inProgress={sendAgreementInProgress}>
              {t('contestSendNewAgreement.form.submitButton')}
            </Button>
          </Flex>
        </Form>
      </ModalBody>
    </Modal>
  )
}
