import styled from 'styled-components'

export const TableWrapper = styled.div`
  max-width: 100%;
  margin: ${({ theme }) => `${theme.spacings[4]} 0`};

  min-width: 600px;

  table {
    width: 100%;

    border-collapse: collapse;

    table-layout: fixed;

    tr {
      border-bottom: 1px solid ${({ theme }) => theme.colors.warmGreyThree};

      &.focus {
        background-color: ${({ theme }) => theme.colors.paleGrey};
        box-shadow: 0 0 5px ${({ theme }) => theme.colors.warmGrey};
      }
    }

    thead {
      th {
        position: relative;
        font-size: ${({ theme }) => theme.fontSizes.sm};
        font-weight: normal;

        padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[2]}`};

        color: ${({ theme }) => theme.colors.warmGrey};

        vertical-align: middle;

        text-align: left;

        &[data-sort='true'] {
          & > div {
            display: flex;
            align-items: center;

            button {
              position: relative;

              display: flex;
              flex-direction: column;

              padding: ${({ theme }) => theme.spacings[1]};

              cursor: pointer;

              span {
                font-size: ${({ theme }) => theme.fontSizes.xxsm};
                color: ${({ theme }) => theme.colors.darkAqua};
              }
            }
          }

          &[data-order='asc'] {
            button {
              span:last-of-type {
                opacity: 0;
              }
            }
          }

          &[data-order='desc'] {
            button {
              span:first-of-type {
                opacity: 0;
              }
            }
          }
        }
      }
    }

    tbody {
      td {
        font-size: ${({ theme }) => theme.fontSizes.md};
        font-weight: normal;

        padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[2]}`};

        color: ${({ theme }) => theme.colors.blackTwo};

        word-break: break-all;

        text-align: left;
      }
    }
  }

  /* nav {
    position: sticky;
    bottom: -${({ theme }) => theme.spacings[6]};
  } */
`
