import { FormikValues } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ReactComponent as CreditCardSVG } from 'assets/images/credit-card.svg'
import { ReactComponent as ReceiptSVG } from 'assets/images/receipt.svg'

import { Button } from 'components/button'
import { CreditCardForm } from 'components/creditCardForm'
import { Flex, FlexItem } from 'components/flex'
import { Modal, ModalHeader, ModalBody } from 'components/modal'
import { PageLoading } from 'components/pageLoading'
import { Separator } from 'components/separator'
import { Tooltip } from 'components/tooltip'

import { confirmationConfig } from 'config/swal'

import { GlobalState } from 'store'
import { getPrices, resendPayment } from 'store/contest/actions'
import { closeModal } from 'store/modal/actions'

import { ModalName } from 'types/enums/ModalName'

import { formatCurrencyBR } from 'utils/formatUtils'

import {
  ContestHeading,
  ModalHeaderContent,
  BoletoDisclaimer,
  BoletoLink,
  PaymentValuesContainer,
  TabButton
} from './styled'

export function ContestPaymentModal(): JSX.Element {
  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    modal: { contestResendPaymentIsOpen },
    contest: {
      contestData,
      getContestByIdInProgress,
      contestPrice,
      getPricesInProgress,
      resendPaymentInProgress
    }
  } = useSelector((state: GlobalState) => state)

  const { contestId } = useParams<{ contestId: string }>()

  const [currentTab, setCurrentTab] = useState<'card' | 'boleto'>('card')

  const hasBoleto = useMemo<boolean>(() => {
    if (
      contestData?.resend?.payment &&
      contestData?.resend?.payment?.type === 'boleto'
    ) {
      return true
    }

    return false
  }, [contestData])

  useEffect(() => {
    if (contestResendPaymentIsOpen) {
      dispatch(getPrices())
    }
  }, [contestResendPaymentIsOpen])

  useEffect(() => {
    if (hasBoleto) {
      setCurrentTab('boleto')
    }
  }, [hasBoleto])

  function _doCardPayment(data: FormikValues) {
    if (contestId && sessionUser) {
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          title: t('contestPayment.confirmation.title'),
          html: (
            <span
              dangerouslySetInnerHTML={{
                __html: t('contestPayment.confirmation.message')
              }}
            />
          ),
          confirmButtonText: t('contestPayment.confirmation.confirmButton'),
          cancelButtonText: t('contestPayment.confirmation.cancelButton')
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            dispatch(
              resendPayment({
                contestId: parseInt(contestId),
                paymentType: 'credit',
                card: {
                  holder_name: data.name,
                  card_number: data.number,
                  expiration_month: data.expiration.split('/')[0],
                  expiration_year: data.expiration.split('/')[1],
                  security_code: data.cvv
                }
              })
            )
          }
        })
    }
  }

  function _doBoletoPayment() {
    if (contestId && sessionUser) {
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          title: t('contestPayment.confirmation.title'),
          html: (
            <span
              dangerouslySetInnerHTML={{
                __html: t('contestPayment.confirmation.message')
              }}
            />
          ),
          confirmButtonText: t('contestPayment.confirmation.confirmButton'),
          cancelButtonText: t('contestPayment.confirmation.cancelButton')
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            dispatch(
              resendPayment({
                contestId: parseInt(contestId),
                paymentType: 'boleto',
                card: undefined
              })
            )
          }
        })
    }
  }

  function _renderCardContent() {
    return (
      <>
        {contestPrice && (
          <PaymentValuesContainer>
            <span>
              <div>
                <h4>{t('contestPayment.card.contestFee')}</h4>
                <p>{formatCurrencyBR(parseInt(contestPrice.resend) / 100)}</p>
              </div>
              <div>
                <h4>{t('contestPayment.card.paymentFee')}</h4>
                <p>{formatCurrencyBR(parseInt(contestPrice.credit) / 100)}</p>
              </div>
            </span>
            <span>
              <h4>{t('contestPayment.card.totalToPay')}</h4>
              <p>
                {formatCurrencyBR(
                  (parseInt(contestPrice.price) +
                    parseInt(contestPrice.credit)) /
                    100
                )}
              </p>
            </span>
          </PaymentValuesContainer>
        )}
        <CreditCardForm
          inProgress={resendPaymentInProgress}
          onSubmit={_doCardPayment}
        />
      </>
    )
  }

  function _renderBoletoContent() {
    return (
      <>
        {contestPrice && !hasBoleto && (
          <PaymentValuesContainer>
            <span>
              <div>
                <h4>{t('contestPayment.boleto.contestFee')}</h4>
                <p>{formatCurrencyBR(parseInt(contestPrice.resend) / 100)}</p>
              </div>
              <div>
                <h4>{t('contestPayment.boleto.paymentFee')}</h4>
                <p>{formatCurrencyBR(parseInt(contestPrice.boleto) / 100)}</p>
              </div>
            </span>
            <span>
              <h4>{t('contestPayment.boleto.totalToPay')}</h4>
              <p>
                {formatCurrencyBR(
                  (parseInt(contestPrice.price) +
                    parseInt(contestPrice.boleto)) /
                    100
                )}
              </p>
            </span>
          </PaymentValuesContainer>
        )}

        <BoletoDisclaimer>
          {hasBoleto ? (
            <>
              {t('contestPayment.boleto.waitingBoleto')}
              <BoletoLink
                href={contestData?.resend?.payment?.boletoUrl}
                target="_blank"
                rel="noreferrer"
              >
                {t('contestPayment.boleto.boletoLink')}
              </BoletoLink>
            </>
          ) : (
            t('contestPayment.boleto.disclaimer')
          )}
        </BoletoDisclaimer>

        {!hasBoleto && (
          <Button
            type="button"
            inProgress={resendPaymentInProgress}
            onClick={_doBoletoPayment}
          >
            {t('contestPayment.boleto.submitButton')}
          </Button>
        )}
      </>
    )
  }

  return (
    <Modal
      isOpen={contestResendPaymentIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_RESEND_PAYMENT))}
    >
      <ModalHeader separator={false}>
        <ModalHeaderContent>
          <h2>{t('contestPayment.title')}</h2>
          <h4>{t('contestPayment.subtitle', { contestId })}</h4>

          <ContestHeading>
            <dl>
              <dt>{t('contestPayment.heading.requester')}</dt>
              <dd>{contestData?.requester}</dd>
              <dt>{t('contestPayment.heading.defendant')}</dt>
              <dd>{contestData?.defendant}</dd>
              <dt>{t('contestPayment.heading.category')}</dt>
              {!!contestData && contestData?.category.length > 30 ? (
                <Tooltip content={contestData?.category}>
                  <dd>{contestData?.category.substr(0, 30) + '...'}</dd>
                </Tooltip>
              ) : (
                <dd>{contestData?.category}</dd>
              )}
            </dl>
          </ContestHeading>

          <Separator color="warmGreyThree" marginBottom={0.5} />
        </ModalHeaderContent>
      </ModalHeader>

      <ModalBody>
        <Flex direction="column" align="center" gap={6}>
          <h4 style={{ marginBottom: 0 }}>{t('contestPayment.methods')}</h4>

          <Flex direction="row" justify="center" gap={6}>
            <FlexItem>
              <TabButton
                type="button"
                active={currentTab === 'card'}
                aria-current={currentTab === 'card'}
                onClick={() => setCurrentTab(hasBoleto ? 'boleto' : 'card')}
                disabled={hasBoleto}
              >
                <CreditCardSVG />
                {t('contestPayment.card.title')}
              </TabButton>
            </FlexItem>
            <FlexItem>
              <TabButton
                type="button"
                active={currentTab === 'boleto'}
                aria-current={currentTab === 'boleto'}
                onClick={() => setCurrentTab('boleto')}
              >
                <ReceiptSVG />
                {t('contestPayment.boleto.title')}
              </TabButton>
            </FlexItem>
          </Flex>

          {getPricesInProgress || getContestByIdInProgress ? (
            <PageLoading />
          ) : (
            <>
              {currentTab === 'card' && _renderCardContent()}
              {currentTab === 'boleto' && _renderBoletoContent()}
            </>
          )}
        </Flex>
      </ModalBody>
    </Modal>
  )
}
