import { useField } from 'formik'

import { Flex, FlexItem } from 'components/flex'

import { RadioInputContainer } from './styled'

type Option = {
  label: string
  value: string | number
}

type TextInputProps = {
  name: string
  options: Option[]
  disabled?: boolean
  onChange?: (value: Option['value']) => void
}

export function RadioInput({
  name,
  options,
  disabled,
  onChange
}: TextInputProps): JSX.Element {
  const [field, meta] = useField({ name, type: 'radio' })

  return (
    <RadioInputContainer hasError={meta.touched && !!meta.error}>
      <Flex direction="row" gap={6}>
        {options.map((opt, i) => (
          <FlexItem key={i}>
            <label onClick={() => onChange?.(opt.value)}>
              <input
                disabled={disabled}
                type="radio"
                {...field}
                checked={meta.value === opt.value}
                value={opt.value}
              />
              <span />
              {opt.label}
            </label>
          </FlexItem>
        ))}
      </Flex>
    </RadioInputContainer>
  )
}
