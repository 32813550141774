export const CHANGE_NEW_MESSAGE = '@contestMessage/CHANGE_NEW_MESSAGE'

export const FETCH_CONTEST_MESSAGES = '@contestMessage/FETCH_CONTEST_MESSAGES'
export const FETCH_CONTEST_MESSAGES_SUCCESS =
  '@contestMessage/FETCH_CONTEST_MESSAGES_SUCCESS'
export const FETCH_CONTEST_MESSAGES_FAILURE =
  '@contestMessage/FETCH_CONTEST_MESSAGES_FAILURE'

export const CREATE_CONTEST_MESSAGE = '@contestMessage/CREATE_CONTEST_MESSAGE'
export const CREATE_CONTEST_MESSAGE_SUCCESS =
  '@contestMessage/CREATE_CONTEST_MESSAGE_SUCCESS'
export const CREATE_CONTEST_MESSAGE_FAILURE =
  '@contestMessage/CREATE_CONTEST_MESSAGE_FAILURE'
