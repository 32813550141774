import {
  Form as FormikForm,
  Formik,
  FormikValues,
  useFormikContext
} from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'

// import { showError } from 'utils/alertUtils'
// import { useTranslation } from 'react-i18next'

type FormProps = {
  initialValues: FormikValues
  validationSchema: Yup.AnySchema
  onSubmit: (values: FormikValues) => void
  children?: React.ReactNode
}

export function Form({
  initialValues,
  validationSchema,
  onSubmit,
  children
}: FormProps): JSX.Element {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnBlur
      validateOnChange
      validateOnMount={false}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      <>
        <FormikForm>
          {children}
          <button id="resetForm" type="reset" hidden aria-hidden="true" />
        </FormikForm>
        <ErrorListener />
      </>
    </Formik>
  )
}

export function ErrorListener(): null {
  // const { t } = useTranslation(['messages'])

  const formik = useFormikContext()
  const [errors, setErrors] = useState(formik.errors)

  useEffect(() => {
    const hasChanged = JSON.stringify(errors) !== JSON.stringify(formik.errors)
    if (hasChanged && formik.isSubmitting) {
      // showError('formValidation', t('messages:formValidation.ERROR'))

      const firstError = Object.keys(formik.errors)[0]

      const field = document.querySelector(`[name="${firstError}"]`)
      if (field) {
        field.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
        // TODO: focus on input
      }

      setErrors(errors)
    }
  }, [formik.errors, formik.isSubmitting])

  return null
}
