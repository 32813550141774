import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'
import { ContestNotification } from 'types/contestNotification/ContestNotification'

import {
  OPEN_NOTIFICATION_MENU,
  CLOSE_NOTIFICATION_MENU,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_AS_READ,
  MARK_AS_READ_FAILURE,
  MARK_AS_READ_SUCCESS
} from './actionTypes'

export type NotificationState = {
  isOpen: boolean
  notifications: ContestNotification[]
  getNotificationsInProgress: boolean
  markAsReadInProgress: boolean
}

const INITIAL_STATE: NotificationState = {
  isOpen: false,
  notifications: [],
  getNotificationsInProgress: false,
  markAsReadInProgress: false
}

export function notificationReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): NotificationState {
  return produce(state, (draft) => {
    switch (action.type) {
      case OPEN_NOTIFICATION_MENU: {
        draft.isOpen = true
        break
      }

      case CLOSE_NOTIFICATION_MENU: {
        draft.isOpen = false
        break
      }

      case GET_NOTIFICATIONS: {
        draft.getNotificationsInProgress = true
        break
      }

      case GET_NOTIFICATIONS_FAILURE: {
        draft.notifications = []
        draft.getNotificationsInProgress = false
        break
      }

      case GET_NOTIFICATIONS_SUCCESS: {
        draft.notifications = (<ContestNotification[]>action.payload).sort(
          (a: ContestNotification, b: ContestNotification) => {
            if (a.createdAt < b.createdAt) {
              return 1
            }

            if (a.createdAt > b.createdAt) {
              return -1
            }

            return 0
          }
        )
        draft.getNotificationsInProgress = false
        break
      }

      case MARK_AS_READ: {
        draft.markAsReadInProgress = false
        break
      }

      case MARK_AS_READ_FAILURE: {
        draft.markAsReadInProgress = false
        break
      }

      case MARK_AS_READ_SUCCESS: {
        draft.notifications = <ContestNotification[]>action.payload
        draft.markAsReadInProgress = false
        break
      }

      case CLEAR_SESSION_DATA: {
        return INITIAL_STATE
      }

      default: {
        break
      }
    }
  })
}
