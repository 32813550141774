import styled from 'styled-components'
import Select from 'react-select'

import { Scroll } from 'styles/Helpers'

export const SelectInput = styled(Select)`
  width: 100%;

  .react-select__control {
    color: ${({ theme }) => theme.colors.warmGrey};
    border-radius: 2rem;
    border-color: ${({ theme }) => theme.colors.warmGreyThree};

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.warmGreyThree};
    }

    &--is-focused {
      z-index: 1;
      border-color: ${({ theme }) => theme.colors.niceBlue};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.niceBlue};
    }

    .react-select__value-container {
      font-family: ${(props) => props.theme.fonts.gothamHTF};
      font-size: ${(props) => props.theme.fontSizes.sm};

      /* .react-select__single-value {
        color: inherit;
      } */
    }

    .react-select__indicators {
      color: ${({ theme }) => theme.colors.niceBlue};
      padding: ${({ theme }) => theme.spacings[2]};

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    .react-select__indicator-separator {
      visibility: hidden;
    }

    .react-select__clear-indicator {
      padding: 0;
    }
  }

  .react-select__menu {
    position: absolute;

    padding: ${({ theme }) => `0 ${theme.spacings[2]}`};

    border: 1px solid ${({ theme }) => theme.colors.warmGreyThree};
    border-radius: 4px;

    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.white};

    .react-select__menu-list {
      ${Scroll}

      .react-select__option {
        font-family: ${(props) => props.theme.fonts.gothamHTF};
        font-size: ${(props) => props.theme.fontSizes.sm};
        color: ${({ theme }) => theme.colors.darkAqua};

        margin-top: ${({ theme }) => theme.spacings[1]};
        padding: ${({ theme }) =>
          `${theme.spacings[1.5]} ${theme.spacings[1]}`};

        border-bottom: 1px solid ${({ theme }) => theme.colors.warmGreyThree};

        cursor: pointer;

        &--is-selected {
          background-color: rgba(192, 192, 192, 0.33);
        }

        &--is-focused:not(.react-select__option--is-selected),
        &:hover:not(.react-select__option--is-selected) {
          background-color: rgba(192, 192, 192, 0.33);
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
`
