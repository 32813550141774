import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ReactComponent as EditSVG } from 'assets/images/edit.svg'
import { ReactComponent as TrashSVG } from 'assets/images/trash.svg'

import { BackButton } from 'components/backButton'
import { Button } from 'components/button'
import { ContestAddMemberModal } from 'components/contestAddMemberModal'
import { ContestEditMemberModal } from 'components/contestEditMemberModal'
import { Flex, FlexItem } from 'components/flex'
import { IconButton } from 'components/iconButton'
import { Table } from 'components/table'
import { Tooltip } from 'components/tooltip'

import { confirmationConfig } from 'config/swal'

import { GlobalState } from 'store'
import { removeContestMember } from 'store/contest/actions'
import { openModal } from 'store/modal/actions'

import Theme from 'styles/Theme'

import { ContestMember } from 'types/contest/ContestMember'
import { ContestMemberPermission } from 'types/enums/ContestMemberPermission'
import { ModalName } from 'types/enums/ModalName'
import { TableColumn } from 'types/table/TableColumn'

import { formatDateBR } from 'utils/formatUtils'

import { IconDisabled, MembersStepWrapper, AdminIcon } from './styled'

type MemberStepProps = {
  goBack: () => void
  goForward: () => void
}

export function MembersStep({
  goBack,
  goForward
}: MemberStepProps): JSX.Element {
  const { t } = useTranslation(['pages', 'common'])

  const dispatch = useDispatch()

  const { contestId } = useParams<{ contestId: string }>()

  const {
    session: { user: sessionUser },
    contest: {
      contestData,
      getContestByIdInProgress,
      removeContestMemberInProgress
    }
  } = useSelector((state: GlobalState) => state)

  const sessionUserPermission = useMemo<ContestMemberPermission>(() => {
    const member = contestData?.members.find(
      ({ user }) => user.email === sessionUser?.email
    )

    return member?.permission ?? ContestMemberPermission.READ
  }, [contestData?.members])

  const canAddMembers = useMemo<boolean>(() => {
    return (
      sessionUserPermission === ContestMemberPermission.ADMIN ||
      sessionUserPermission === ContestMemberPermission.EDIT
    )
  }, [sessionUserPermission])

  const canEditMembers = useMemo<boolean>(() => {
    return (
      sessionUserPermission === ContestMemberPermission.ADMIN ||
      sessionUserPermission === ContestMemberPermission.EDIT
    )
  }, [sessionUserPermission])

  const canRemoveMembers = useMemo<boolean>(() => {
    return (
      sessionUserPermission === ContestMemberPermission.ADMIN ||
      sessionUserPermission === ContestMemberPermission.EDIT
    )
  }, [sessionUserPermission])

  const members = useMemo<ContestMember[]>(() => {
    if (!contestData) return []

    const result: ContestMember[] = []

    const owners = contestData.members
      .filter((user) => _isContestAdmin(user))
      .sort((a, b) => {
        const nameA = `${a.user.name || 'zzz'} ${a.user.lastname || 'zzz'}`
        const nameB = `${b.user.name || 'zzz'} ${b.user.lastname || 'zzz'}`

        if (nameA > nameB) {
          return 1
        }

        if (nameA < nameB) {
          return -1
        }

        return 0
      })

    if (owners.length) {
      result.push(...owners)
    }

    const canEdit = contestData.members
      .filter((user) => user.permission === ContestMemberPermission.EDIT)
      .sort((a, b) => {
        const nameA = `${a.user.name || 'zzz'} ${a.user.lastname || 'zzz'}`
        const nameB = `${b.user.name || 'zzz'} ${b.user.lastname || 'zzz'}`

        if (nameA > nameB) {
          return 1
        }

        if (nameA < nameB) {
          return -1
        }

        return 0
      })

    if (canEdit.length) {
      result.push(...canEdit)
    }

    const canRead = contestData.members
      .filter((user) => user.permission === ContestMemberPermission.READ)
      .sort((a, b) => {
        const nameA = `${a.user.name || 'zzz'} ${a.user.lastname || 'zzz'}`
        const nameB = `${b.user.name || 'zzz'} ${b.user.lastname || 'zzz'}`

        if (nameA > nameB) {
          return 1
        }

        if (nameA < nameB) {
          return -1
        }

        return 0
      })

    if (canRead.length) {
      result.push(...canRead)
    }

    return result.map((item) => {
      if (!item.user.name) {
        return {
          ...item,
          user: {
            ...item.user,
            name: item.user.email,
            lastname: t('pages:contestDetails.tabs.members.table.pendingAccess')
          }
        }
      }

      return item
    })
  }, [contestData?.members])

  const columns = useMemo<TableColumn<ContestMember>[]>(
    () => [
      {
        label: t('pages:contestCreate.membersStep.table.name'),
        dataKey: 'name',
        render: (row) => `${row.user?.name ?? ''} ${row.user?.lastname ?? ''}`
      },
      {
        label: t('pages:contestCreate.membersStep.table.role'),
        dataKey: 'role',
        thStyle: { textAlign: 'center' },
        tdStyle: { textAlign: 'center' },
        render: (row) => {
          if (_isContestAdmin(row)) {
            return <AdminIcon />
          }

          return t(`common:contestMemberPermissions.${row.permission}`)
        }
      },
      {
        label: t('pages:contestCreate.membersStep.table.createdAt'),
        dataKey: 'createdAt',
        render: (row) => formatDateBR(row.createdAt)
      },
      {
        label: t('pages:contestCreate.membersStep.table.actions.title'),
        dataKey: 'action',
        thStyle: { width: '10%' },
        tdStyle: { width: '10%' },
        render: (row) => (
          <Flex direction="row" gap={2}>
            {_renderActions(row)}
          </Flex>
        )
      }
    ],
    [sessionUserPermission]
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [contestMemberToEdit, setContestMemberToEdit] = useState<any>(undefined)

  function _removeMember(member: ContestMember) {
    if (contestData?.id && sessionUser?.id) {
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          titleText: t('pages:contestCreate.membersStep.confirmRemove.title'),
          html: t('pages:contestCreate.membersStep.confirmRemove.message'),
          confirmButtonText: t(
            'pages:contestCreate.membersStep.confirmRemove.confirmButton'
          ),
          cancelButtonText: t(
            'pages:contestCreate.membersStep.confirmRemove.cancelButton'
          )
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            dispatch(
              removeContestMember({
                contestId: parseInt(contestId),
                userToRemove: member.user.email
              })
            )
          }
        })
    }
  }

  function _isContestAdmin(user: ContestMember): boolean {
    return user.permission === ContestMemberPermission.ADMIN
  }

  function _renderActions(row: ContestMember) {
    return _isContestAdmin(row) ? (
      <>
        <Tooltip
          content={t('pages:contestCreate.membersStep.table.actions.adminLock')}
        >
          <IconDisabled>
            <EditSVG />
          </IconDisabled>
        </Tooltip>
        <Tooltip
          content={t('pages:contestCreate.membersStep.table.actions.adminLock')}
        >
          <IconDisabled>
            <TrashSVG />
          </IconDisabled>
        </Tooltip>
      </>
    ) : (
      <>
        {canEditMembers && (
          <IconButton
            tooltip={t('pages:contestCreate.membersStep.table.actions.edit')}
            color="niceBlue"
            onClick={() => {
              setContestMemberToEdit({
                contestId: parseInt(contestId),
                userId: sessionUser?.id,
                userToUpdate: row.user.email,
                permission: row.permission
              })
              dispatch(openModal(ModalName.CONTEST_EDIT_MEMBER))
            }}
          >
            <EditSVG />
          </IconButton>
        )}
        {canRemoveMembers && (
          <IconButton
            tooltip={t('pages:contestCreate.membersStep.table.actions.remove')}
            color="blush"
            onClick={() => _removeMember(row)}
          >
            {removeContestMemberInProgress ? (
              <BounceLoader size="16px" color={Theme.colors.blush} />
            ) : (
              <TrashSVG />
            )}
          </IconButton>
        )}
        {sessionUserPermission === ContestMemberPermission.READ && (
          <>
            <Tooltip
              content={t(
                'pages:contestCreate.membersStep.table.actions.cantEdit'
              )}
            >
              <IconDisabled>
                <EditSVG />
              </IconDisabled>
            </Tooltip>
            <Tooltip
              content={t(
                'pages:contestCreate.membersStep.table.actions.cantRemove'
              )}
            >
              <IconDisabled>
                <TrashSVG />
              </IconDisabled>
            </Tooltip>
          </>
        )}
      </>
    )
  }

  return (
    <MembersStepWrapper>
      <Flex direction="row">
        <FlexItem grow="1">
          <h4>{t('pages:contestCreate.membersStep.members')}</h4>
        </FlexItem>

        {canAddMembers && (
          <Button
            small
            type="button"
            color="niceBlue"
            variant="outline"
            onClick={() => dispatch(openModal(ModalName.CONTEST_ADD_MEMBER))}
          >
            {t('pages:contestCreate.membersStep.addMemberButton')}
          </Button>
        )}
      </Flex>

      <Table
        columns={columns}
        data={members}
        pagination={false}
        isLoading={getContestByIdInProgress}
      />

      <Button type="button" onClick={goForward}>
        {t('pages:contestCreate.membersStep.nextButton')}
      </Button>

      <BackButton onClick={goBack} />

      <ContestAddMemberModal />

      <ContestEditMemberModal initialValues={contestMemberToEdit ?? {}} />
    </MembersStepWrapper>
  )
}
