import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ReactComponent as DownloadSVG } from 'assets/images/download.svg'

import { Button } from 'components/button'
import { Flex } from 'components/flex'
import { IconButton } from 'components/iconButton'
import { Modal, ModalHeader } from 'components/modal'
import { Separator } from 'components/separator'
import { Table } from 'components/table'
import { Tabs } from 'components/tabs'
import { Tooltip } from 'components/tooltip'

import { confirmationConfig } from 'config/swal'

import { getContestFileUrl } from 'services/http/contestFileService'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'
import { acceptContest } from 'store/contest/actions'

import Theme from 'styles/Theme'

import { ContestUpdateAction } from 'types/enums/ContestUpdateAction'
import { ModalName } from 'types/enums/ModalName'

import { showError } from 'utils/alertUtils'
import { formatDateBR } from 'utils/formatUtils'

import { ContestHeading, ModalBody, ModalHeaderContent } from './styled'

export function ContestAcceptModal(): JSX.Element {
  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    modal: { contestAcceptIsOpen },
    contest: { contestData, acceptContestInProgress }
  } = useSelector((state: GlobalState) => state)

  const [downloadFileInProgress, setDownloadFileInProgress] = useState<boolean>(
    false
  )

  async function _downloadFile(key: string) {
    try {
      setDownloadFileInProgress(true)

      const { url, file } = await getContestFileUrl(key)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.target = '__blank'
      downloadLink.download = file.filename

      document.body.appendChild(downloadLink)

      downloadLink.click()

      downloadLink.parentNode?.removeChild(downloadLink)
    } catch (error) {
      showError('downloadContestFile', error)
    } finally {
      setDownloadFileInProgress(false)
    }
  }

  function _getData() {
    if (!contestData) return null

    const actions = [
      'CREATED',
      ContestUpdateAction.REPLY,
      ContestUpdateAction.REPLICATION,
      ContestUpdateAction.REJOINDER
    ]

    return [...contestData.data]
      .filter((item) => {
        let action = ''

        if (item.action) {
          action = item.action
        } else if (item.text === 'Solved with signatures') {
          action = 'AGREEMENT_SIGNED'
        } else if (item.text === 'Boleto processado') {
          action = 'PAYMENT_BOLETO_CONFIRMED'
        } else if (item.text === 'Boleto do reenvio processado') {
          action = 'PAY_RESEND'
        } else {
          action = 'CREATED'
        }

        return actions.includes(action)
      })
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1
        }

        if (a.createdAt < b.createdAt) {
          return 1
        }

        return 0
      })
      .shift()
  }

  function _handleAcceptButtonClick() {
    if (contestData && sessionUser) {
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          title: t('contestAccept.confirmation.title'),
          html: (
            <span
              dangerouslySetInnerHTML={{
                __html: t('contestAccept.confirmation.message')
              }}
            />
          ),
          confirmButtonText: t('contestAccept.confirmation.confirmButton'),
          cancelButtonText: t('contestAccept.confirmation.cancelButton')
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            if (contestData) {
              dispatch(
                acceptContest({
                  contestId: contestData.id,
                  action: ContestUpdateAction.ACCEPT,
                  data: null
                })
              )
            }
          }
        })
    }
  }

  return (
    <Modal
      isOpen={contestAcceptIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_ACCEPT))}
    >
      <ModalHeader separator={false}>
        <ModalHeaderContent>
          <h2>{t('contestAccept.title')}</h2>
          <h4>{t('contestAccept.subtitle', { contestId: contestData?.id })}</h4>

          <ContestHeading>
            <dl>
              <dt>{t('contestAccept.heading.requester')}</dt>
              <dd>{contestData?.requester}</dd>
              <dt>{t('contestAccept.heading.defendant')}</dt>
              <dd>{contestData?.defendant}</dd>
              <dt>{t('contestAccept.heading.category')}</dt>
              {!!contestData && contestData?.category.length > 30 ? (
                <Tooltip content={contestData?.category}>
                  <dd>{contestData?.category.substr(0, 30) + '...'}</dd>
                </Tooltip>
              ) : (
                <dd>{contestData?.category}</dd>
              )}
              <dt>{t('contestAccept.heading.createdAt')}</dt>
              <dd>{formatDateBR(new Date().toISOString())}</dd>
            </dl>
          </ContestHeading>

          <Separator color="warmGreyThree" />
        </ModalHeaderContent>
      </ModalHeader>

      <ModalBody>
        <Tabs
          tabs={[
            {
              id: 'description',
              title: t('contestAccept.tabs.description.title'),
              content: (
                <p
                  dangerouslySetInnerHTML={{ __html: _getData()?.text ?? '' }}
                />
              )
            },
            {
              id: 'attachments',
              title: t('contestAccept.tabs.attachments.title'),
              content: (
                <Table
                  columns={[
                    {
                      label: t('contestAccept.tabs.attachments.table.filename'),
                      dataKey: 'filename',
                      tdStyle: { wordBreak: 'break-word' }
                    },
                    {
                      label: t(
                        'contestAccept.tabs.attachments.table.createdAt'
                      ),
                      dataKey: 'createdAt',
                      thStyle: { width: '140px' },
                      tdStyle: { width: '140px' },
                      render: (row) => formatDateBR(row.createdAt)
                    },
                    {
                      label: t(
                        'contestAccept.tabs.attachments.table.actions.title'
                      ),
                      dataKey: 'actions',
                      thStyle: { width: '50px' },
                      tdStyle: { width: '50px' },
                      render: (row) => (
                        <Flex direction="row" gap={2}>
                          <IconButton
                            tooltip={t(
                              'contestAccept.tabs.attachments.table.actions.download'
                            )}
                            color="niceBlue"
                            onClick={() => _downloadFile(row.storageKey)}
                            disabled={downloadFileInProgress}
                          >
                            {downloadFileInProgress ? (
                              <BounceLoader
                                size="16px"
                                color={Theme.colors.niceBlue}
                              />
                            ) : (
                              <DownloadSVG />
                            )}
                          </IconButton>
                        </Flex>
                      )
                    }
                  ]}
                  data={_getData()?.files ?? []}
                  pagination={false}
                />
              )
            }
          ]}
          small
        />

        <Flex direction="row" gap={6} align="center" justify="center">
          <Button
            type="button"
            color="white"
            backgroundColor="lightTealTwo"
            small
            inProgress={acceptContestInProgress}
            onClick={_handleAcceptButtonClick}
          >
            {t('contestAccept.acceptButton')}
          </Button>
        </Flex>
      </ModalBody>
    </Modal>
  )
}
