import styled from 'styled-components'

export const ContestDetailsWrapper = styled.div`
  flex: 1;
`

export const PageTitle = styled.h2`
  position: relative;

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.niceBlue};
  margin-bottom: ${({ theme }) => theme.spacings[1]};
  text-transform: uppercase;

  span:first-of-type {
    margin-left: ${({ theme }) => theme.spacings[2]};

    padding: ${({ theme }) => `${theme.spacings[0.5]} ${theme.spacings[2]}`};

    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: normal;

    color: ${({ theme }) => theme.colors.lightBlue};
    background-color: ${({ theme }) => theme.colors.duckEggBlueTwo};

    border-radius: 1.25rem;

    text-transform: none;
  }

  span:last-of-type {
    display: inline-flex;
    gap: 20px;
    margin-left: auto;
    margin-right: ${({ theme }) => theme.spacings[6]};

    button {
      color: ${({ theme }) => theme.colors.niceBlue};

      svg {
        width: ${({ theme }) => theme.fontSizes.xlg};
      }
    }
  }
`

export const PageSubTitle = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacings[4]};
`

export const ContestHeading = styled.dl`
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  row-gap: ${({ theme }) => theme.spacings[1.5]};

  dt {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.warmGrey};
  }

  dd {
    font-size: ${({ theme }) => theme.fontSizes.md};
    color: ${({ theme }) => theme.colors.greyishBrownFive};

    span {
      border-radius: 1.25rem;

      padding: ${({ theme }) => `${theme.spacings[1]} ${theme.spacings[2]}`};

      font-size: ${({ theme }) => theme.fontSizes.sm};

      /* created */
      color: ${({ theme }) => theme.colors.greyishBrownFive};
      background-color: ${({ theme }) => theme.colors.whiteTwo};

      &[data-status='active'] {
        color: ${({ theme }) => theme.colors.lightBlue};
        background-color: ${({ theme }) => theme.colors.duckEggBlueTwo};
      }

      &[data-status='expired'] {
        color: ${({ theme }) => theme.colors.fadedOrange};
        background-color: ${({ theme }) => theme.colors.beigeTwo};
      }

      &[data-status='arbitration'] {
        color: ${({ theme }) => theme.colors.blush};
        background-color: ${({ theme }) => theme.colors.whiteFour};
      }

      &[data-status='finished'] {
        color: ${({ theme }) => theme.colors.lightTeal};
        background-color: ${({ theme }) => theme.colors.duckEggBlue};
      }
    }
  }

  button {
    justify-self: center;
    grid-row: span 2;
  }
`
