import { Form, Formik, FormikValues } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Yup from 'yup'

import { ReactComponent as CloseSVG } from 'assets/images/close-thin.svg'

import { Button } from 'components/button'
import { FieldSet } from 'components/fieldSet'
import { Flex, FlexItem } from 'components/flex'
import { MaskedInput } from 'components/maskedInput'
import { Modal, ModalHeader } from 'components/modal'
import { Separator } from 'components/separator'
import { TextInput } from 'components/textInput'
import { Tooltip } from 'components/tooltip'

import { confirmationConfig } from 'config/swal'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'
import { sendSubscribers } from 'store/contest/actions'

import { ContestUpdateAction } from 'types/enums/ContestUpdateAction'
import { ModalName } from 'types/enums/ModalName'

import { showError } from 'utils/alertUtils'
import { formatDateBR } from 'utils/formatUtils'
import { fullNameRegex } from 'utils/regexUtils'
import { validateCPF } from 'utils/validationUtils'

import { ContestHeading, ModalBody, ModalHeaderContent } from './styled'

type Subscriber = {
  name: string
  cpf: string
  email: string
}

export function ContestSendSubscribersModal(): JSX.Element {
  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    modal: { contestSendSubscribersIsOpen },
    contest: { contestData, sendSubscribersInProgress }
  } = useSelector((state: GlobalState) => state)

  const initialValues = {
    procurator: [
      {
        name: '',
        cpf: '',
        email: ''
      }
    ],
    witness: [
      {
        name: '',
        cpf: '',
        email: ''
      }
    ]
  }

  const validationSchema = Yup.object().shape({
    procurator: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(fullNameRegex, 'fullName')
          .required('required'),
        cpf: Yup.string()
          .test('cpf', 'cpf', (value) => validateCPF(value ?? ''))
          .required('required'),
        email: Yup.string().email('email').required('required')
      })
    ),
    witness: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(fullNameRegex, 'fullName')
          .required('required'),
        cpf: Yup.string()
          .test('cpf', 'cpf', (value) => validateCPF(value ?? ''))
          .required('required'),
        email: Yup.string().email('email').required('required')
      })
    )
  })

  useEffect(() => {
    if (!contestSendSubscribersIsOpen) {
      const resetButton = document.getElementById('reset')
      if (resetButton) {
        resetButton.click()
      }
    }
  }, [contestSendSubscribersIsOpen])

  function _handleSubmit(data: FormikValues) {
    const cpfs: string[] = [
      ...data.procurator.map(({ cpf }: Subscriber) => cpf),
      ...data.witness.map(({ cpf }: Subscriber) => cpf)
    ]

    const uniqueCpfs = Array.from(new Set<string>(cpfs))

    if (cpfs.length > uniqueCpfs.length) {
      showError('', t('contestSendSubscribers.form.duplicatedCpfs'))
      return
    }

    const emails: string[] = [
      ...data.procurator.map(({ email }: Subscriber) => email),
      ...data.witness.map(({ email }: Subscriber) => email)
    ]

    const uniqueEmails = Array.from(new Set<string>(emails))

    if (emails.length > uniqueEmails.length) {
      showError('', t('contestSendSubscribers.form.duplicatedEmails'))
      return
    }

    if (contestData && sessionUser) {
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          title: t('contestSendSubscribers.confirmation.title'),
          html: (
            <span
              dangerouslySetInnerHTML={{
                __html: t('contestSendSubscribers.confirmation.message')
              }}
            />
          ),
          confirmButtonText: t(
            'contestSendSubscribers.confirmation.confirmButton'
          ),
          cancelButtonText: t(
            'contestSendSubscribers.confirmation.cancelButton'
          )
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            if (sessionUser?.id && contestData?.id) {
              dispatch(
                sendSubscribers({
                  contestId: contestData.id,
                  action: ContestUpdateAction.SUBMIT_SUBSCRIBERS,
                  data: {
                    main: data.procurator,
                    witness: data.witness
                  }
                })
              )
            }
          }
        })
    }
  }

  return (
    <Modal
      isOpen={contestSendSubscribersIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_SEND_SUBSCRIBERS))}
    >
      <ModalHeader separator={false}>
        <ModalHeaderContent>
          <h2>{t('contestSendSubscribers.title')}</h2>

          <ContestHeading>
            <dl>
              <dt>{t('contestSendSubscribers.heading.requester')}</dt>
              <dd>{contestData?.requester}</dd>
              <dt>{t('contestSendSubscribers.heading.defendant')}</dt>
              <dd>{contestData?.defendant}</dd>
              <dt>{t('contestSendSubscribers.heading.category')}</dt>
              {!!contestData && contestData?.category.length > 30 ? (
                <Tooltip content={contestData?.category}>
                  <dd>{contestData?.category.substr(0, 30) + '...'}</dd>
                </Tooltip>
              ) : (
                <dd>{contestData?.category}</dd>
              )}
              <dt>{t('contestSendSubscribers.heading.createdAt')}</dt>
              <dd>{formatDateBR(new Date().toISOString())}</dd>
            </dl>
          </ContestHeading>

          <Separator color="warmGreyThree" />
        </ModalHeaderContent>
      </ModalHeader>

      <ModalBody>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validateOnBlur
          validateOnChange
          validateOnMount={false}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <section>
                {values.procurator.map((_, index) => (
                  <FieldSet
                    key={index}
                    title={t('contestSendSubscribers.form.procurator.title')}
                  >
                    {index > 0 && (
                      <Tooltip
                        content={t(
                          'contestSendSubscribers.form.procurator.removeButton'
                        )}
                      >
                        <button
                          type="button"
                          data-remove
                          onClick={() => {
                            const items = [...values.procurator]
                            items.splice(index, 1)
                            setFieldValue('procurator', items)
                          }}
                        >
                          <CloseSVG />
                        </button>
                      </Tooltip>
                    )}
                    <Flex direction="row" gap={6}>
                      <FlexItem grow="8" basis="0">
                        <TextInput
                          name={`procurator.${index}.name`}
                          placeholder={t(
                            'contestSendSubscribers.form.procurator.name'
                          )}
                        />
                      </FlexItem>
                      <FlexItem grow="4" basis="0">
                        <MaskedInput
                          name={`procurator.${index}.cpf`}
                          placeholder={t(
                            'contestSendSubscribers.form.procurator.cpf'
                          )}
                          options={{
                            delimiters: ['.', '.', '-'],
                            blocks: [3, 3, 3, 2],
                            numericOnly: true
                          }}
                        />
                      </FlexItem>
                    </Flex>
                    <Flex direction="row">
                      <FlexItem grow="12" basis="0">
                        <TextInput
                          name={`procurator.${index}.email`}
                          placeholder={t(
                            'contestSendSubscribers.form.procurator.email'
                          )}
                        />
                      </FlexItem>
                    </Flex>
                  </FieldSet>
                ))}

                <Button
                  variant="outline"
                  color="niceBlue"
                  small
                  onClick={() =>
                    setFieldValue('procurator', [
                      ...values.procurator,
                      {
                        name: '',
                        cpf: '',
                        email: ''
                      }
                    ])
                  }
                >
                  {t('contestSendSubscribers.form.procurator.addButton')}
                </Button>

                {values.witness.map((_, index) => (
                  <FieldSet
                    key={index}
                    title={t('contestSendSubscribers.form.witness.title')}
                  >
                    {index > 0 && (
                      <Tooltip
                        content={t(
                          'contestSendSubscribers.form.procurator.removeButton'
                        )}
                      >
                        <button
                          type="button"
                          data-remove
                          onClick={() => {
                            const items = [...values.witness]
                            items.splice(index, 1)
                            setFieldValue('witness', items)
                          }}
                        >
                          <CloseSVG />
                        </button>
                      </Tooltip>
                    )}
                    <Flex direction="row" gap={6}>
                      <FlexItem grow="8" basis="0">
                        <TextInput
                          name={`witness.${index}.name`}
                          placeholder={t(
                            'contestSendSubscribers.form.witness.name'
                          )}
                        />
                      </FlexItem>
                      <FlexItem grow="4" basis="0">
                        <MaskedInput
                          name={`witness.${index}.cpf`}
                          placeholder={t(
                            'contestSendSubscribers.form.witness.cpf'
                          )}
                          options={{
                            delimiters: ['.', '.', '-'],
                            blocks: [3, 3, 3, 2],
                            numericOnly: true
                          }}
                        />
                      </FlexItem>
                    </Flex>
                    <Flex direction="row">
                      <FlexItem grow="12" basis="0">
                        <TextInput
                          name={`witness.${index}.email`}
                          placeholder={t(
                            'contestSendSubscribers.form.witness.email'
                          )}
                        />
                      </FlexItem>
                    </Flex>
                  </FieldSet>
                ))}

                <Button
                  variant="outline"
                  color="niceBlue"
                  small
                  onClick={() =>
                    setFieldValue('witness', [
                      ...values.witness,
                      {
                        name: '',
                        cpf: '',
                        email: ''
                      }
                    ])
                  }
                >
                  {t('contestSendSubscribers.form.witness.addButton')}
                </Button>
              </section>

              <Button
                type="submit"
                color="white"
                backgroundColor="lightTealTwo"
                small
                inProgress={sendSubscribersInProgress}
              >
                {t('contestSendSubscribers.form.submitButton')}
              </Button>

              <button id="reset" type="reset" hidden aria-hidden="true" />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}
