type Error = {
  field: string
  rule: string
  message: string
}

export class HttpError422 extends Error {
  errors: Error[]

  constructor(errors: Error[]) {
    super('Validation error')
    this.errors = errors
  }
}
