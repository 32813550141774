import styled, { StyledProps } from 'styled-components'

type StepProps = StyledProps<{
  counter?: string | React.ReactNode
  isActive?: boolean
  isCompleted?: boolean
  isClickable?: boolean
}>

export const StepProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-bottom: ${({ theme }) => theme.spacings[10]};
`

export const StepList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;

  list-style: none;
`

export const Step = styled.li<StepProps>`
  position: relative;

  width: 100%;

  font-family: ${({ theme }) => theme.fonts.bloggerSans};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: bold;

  color: ${({ theme, isActive, isCompleted }) => {
    if (isActive) {
      return theme.colors.graphite
    }

    if (isCompleted) {
      return theme.colors.lightTeal
    }

    return theme.colors.pinkishGrey
  }};

  text-align: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};

  & > span {
    display: block;

    width: ${({ theme }) => theme.spacings[16]};
    height: ${({ theme }) => theme.spacings[16]};
    line-height: ${({ theme }) => theme.spacings[16]};

    margin: ${({ theme }) => `0 auto ${theme.spacings[2]}`};

    border-radius: 100%;
    border: 2px solid
      ${({ theme, isActive, isCompleted }) => {
        if (isActive) {
          return theme.colors.petrol
        }

        if (isCompleted) {
          return theme.colors.lightTeal
        }

        return theme.colors.sandy
      }};

    font-size: ${({ theme }) => theme.fontSizes.lg};

    color: ${({ theme, isActive, isCompleted }) => {
      if (isActive) {
        return theme.colors.sandy
      }

      if (isCompleted) {
        return theme.colors.lightTeal
      }

      return theme.colors.pinkishGrey
    }};

    background-color: ${({ theme, isActive, isCompleted }) => {
      if (isActive) {
        return theme.colors.niceBlue
      }

      if (isCompleted) {
        return theme.colors.duckEggBlue
      }

      return theme.colors.white
    }};

    transition: 0.3s;

    svg {
      width: ${({ theme }) => theme.fontSizes.lg};
      /* transform: ${({ theme }) => `translateY(${theme.fontSizes.lg})`}; */
      margin-top: ${({ theme }) => `calc(50% - ${theme.fontSizes.lg})`};
    }
  }

  /* make the line */
  &::after {
    content: '';

    z-index: -1;
    position: absolute;
    left: 0;
    top: calc(
      ${({ theme }) =>
        `(${theme.spacings[16]} + ${theme.spacings[2]}) / 2 - 4px`}
    );

    width: 100%;
    height: 2px;

    background: ${({ theme, isActive, isCompleted }) => {
      if (isActive) {
        return `linear-gradient(to right, ${theme.colors.lightTeal} 0% 50%, ${theme.colors.sandy} 50% 100%)`
        // return theme.colors.niceBlue
      }

      if (isCompleted) {
        return theme.colors.lightTeal
      }

      return theme.colors.sandy
    }};

    transition: 0.3s;
  }

  &:first-of-type::after {
    width: 50%;
    left: 50%;

    background: ${({ theme, isActive, isCompleted }) => {
      if (isActive) {
        return theme.colors.sandy
      }

      if (isCompleted) {
        return theme.colors.lightTeal
      }

      return theme.colors.sandy
    }};
  }

  &:last-of-type::after {
    width: 50%;
    left: 0;

    background: ${({ theme, isActive, isCompleted }) => {
      if (isActive) {
        return theme.colors.lightTeal
      }

      if (isCompleted) {
        return theme.colors.lightTeal
      }

      return theme.colors.sandy
    }};
  }
`
