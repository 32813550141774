import styled from 'styled-components'

export const SearchInputWrapper = styled.div`
  position: relative;

  width: 380px;
  max-width: 100%;

  input {
    width: 100%;

    padding: ${({ theme }) => `${theme.spacings[2.5]} ${theme.spacings[4]}`};
    padding-right: ${({ theme }) =>
      `calc(${theme.spacings[2.5]} * 2 + ${theme.fontSizes.sm} + 10px)`};

    color: ${({ theme }) => theme.colors.greyishBrownFour};

    border: 1px solid ${({ theme }) => theme.colors.paleGrey};
    border-radius: 2rem;

    background-color: ${({ theme }) => theme.colors.paleGrey};

    transition: 0.3s;

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    &::placeholder {
      color: ${({ theme }) => theme.colors.pinkishGrey};
      opacity: 1; /* Firefox */
    }

    /* Internet Explorer 10-11, Microsoft Edge */
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.pinkishGrey};
    }

    &:focus:not(:read-only):not(:disabled) {
      z-index: 1;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.niceBlue};
      border-color: ${({ theme }) => theme.colors.niceBlue};
    }
  }

  button {
    position: absolute;
    top: 10%;
    right: 5px;

    line-height: 0;

    width: ${({ theme }) =>
      `calc(${theme.spacings[2.5]} * 2 + ${theme.fontSizes.sm})`};
    height: 80%;

    border: none;
    border-radius: 50%;

    color: ${({ theme }) => theme.colors.white};

    background-color: ${({ theme }) => theme.colors.darkAqua};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    svg {
      height: 50%;
    }

    &:hover:not(:disabled) {
      opacity: 0.9;
      transition: 0.3s;
    }
  }
`
