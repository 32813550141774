/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContestNotification } from 'types/contestNotification/ContestNotification'

import { GET, PUT } from 'utils/httpUtils'

const PATH = '/contest/notifications'

export async function getNotifications(): Promise<ContestNotification[]> {
  try {
    const result = await GET<any[]>(PATH)

    return Promise.resolve(
      result.map((item) => ({
        id: item.id,
        text: item.text,
        createdAt: item.created_at
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function markAsRead(notificationId: number): Promise<void> {
  try {
    await PUT<null, { message: string }>(`${PATH}/${notificationId}`)

    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}
