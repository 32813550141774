import styled from 'styled-components'

export const SubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  overflow-y: auto;

  main {
    /* flex: 1; */

    /* margin: ${({ theme }) => `${theme.spacings[10]} auto`}; */
    margin: 0 auto;
    padding: ${({ theme }) => `${theme.spacings[10]} 0`};

    width: 70%;
  }
`
