import { Placement } from '@popperjs/core'
import Tippy from '@tippyjs/react/headless'
import { ReactElement, useState } from 'react'
import { config, useSpring } from 'react-spring'

import { PopoverContainer, Arrow } from './styled'

type PopoverProps = {
  content: string | ReactElement
  placement?: Placement
  children: ReactElement
}

export function Popover({
  content,
  placement = 'auto',
  children
}: PopoverProps): JSX.Element {
  const [arrowEl, setArrowEl] = useState<HTMLElement | null>(null)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const styles = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'scale(1)' : 'scale(0.8)',
    config: config.wobbly
  })

  return (
    <Tippy
      render={(attrs) => (
        <PopoverContainer tabIndex={-1} style={styles} {...attrs}>
          {content}
          <Arrow id="arrow" ref={setArrowEl} />
        </PopoverContainer>
      )}
      placement={placement}
      animation
      arrow
      onShow={() => setIsVisible(true)}
      onHide={() => setIsVisible(false)}
      popperOptions={{
        modifiers: [{ name: 'arrow', options: { element: arrowEl } }]
      }}
    >
      {children}
    </Tippy>
  )
}
