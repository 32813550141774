import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import LogoWhitePNG from 'assets/images/marca_negativa_open_finance.svg'
import { ReactComponent as Badge } from 'assets/images/badge.svg'
import { ReactComponent as QuestionCircleSVG } from 'assets/images/question-circle.svg'
import { ReactComponent as BellSVG } from 'assets/images/bell.svg'
import { ReactComponent as SignOutSVG } from 'assets/images/sign-out.svg'

import { Tooltip } from 'components/tooltip'

import { LOGIN_PAGE } from 'navigation/paths'

import { logout } from 'services/http/loginService'

import { GlobalState } from 'store'
import { openAdminMenu, closeAdminMenu } from 'store/admin/actions'
import { openDocumentsMenu, closeDocumentsMenu } from 'store/documents/actions'
import {
  openNotificationMenu,
  closeNotificationMenu
} from 'store/notification/actions'
import { clearSessionData } from 'store/session/actions'

import { HeaderWrapper } from './styled'
import { UserType } from 'types/enums/UserType'
import { useMemo } from 'react'

export function Header(): JSX.Element {
  const { t } = useTranslation('common')

  const dispatch = useDispatch()

  const { user } = useSelector((state: GlobalState) => state.session)

  const { notifications } = useSelector(
    (state: GlobalState) => state.notification
  )

  const canAccessAdmin = useMemo(() => user?.type === UserType.ADMIN, [user])

  return (
    <HeaderWrapper>
      <img src={LogoWhitePNG} alt="Open Banking Brasil logo" />
      <nav>
        {canAccessAdmin && (
          <Tooltip content={t('header.admin')}>
            <button
              type="button"
              onClick={() => {
                dispatch(closeNotificationMenu())
                dispatch(closeDocumentsMenu())
                dispatch(openAdminMenu())
              }}
            >
              <Badge />
            </button>
          </Tooltip>
        )}

        <Tooltip content={t('header.documents')}>
          <button
            type="button"
            onClick={() => {
              dispatch(closeAdminMenu())
              dispatch(closeNotificationMenu())
              dispatch(openDocumentsMenu())
            }}
          >
            <QuestionCircleSVG />
          </button>
        </Tooltip>
        <Tooltip content={t('header.notifications')}>
          <button
            type="button"
            onClick={() => {
              dispatch(closeAdminMenu())
              dispatch(closeDocumentsMenu())
              dispatch(openNotificationMenu())
            }}
            data-count={notifications.length}
          >
            <BellSVG />
          </button>
        </Tooltip>
        <Tooltip content={t('header.logout')}>
          <Link to={LOGIN_PAGE} onClick={async () => {
            await logout()
            dispatch(clearSessionData())
          }}>
            <SignOutSVG />
          </Link>
        </Tooltip>
      </nav>
    </HeaderWrapper>
  )
}
