export enum ContestStep {
  INACTIVE = 'INACTIVE',

  WAITING_BOLETO = 'WAITING_BOLETO',

  OPEN = 'OPEN',

  ANSWER = 'ANSWER',
  REPLICATION = 'REPLICATION',
  REJOINDER = 'REJOINDER',

  ELIGIBLE_FOR_ARBITRATION = 'ELIGIBLE FOR ARBITRATION',
  EXPIRED = 'EXPIRED',
  ABORTED = 'ABORTED',

  RESOLVED_R = 'RESOLVED R',
  RESOLVED_D = 'RESOLVED D',

  AWAITING_DOCUMENT_R = 'AWAITING DOCUMENT R',
  AWAITING_DOCUMENT_D = 'AWAITING DOCUMENT D',

  AWAITING_SUBSCRIBERS = 'AWAITING SUBSCRIBERS',
  AWAITING_SUBSCRIBERS_R = 'AWAITING SUBSCRIBERS R',
  AWAITING_SUBSCRIBERS_D = 'AWAITING SUBSCRIBERS D',

  AWAITING_SIGNATURE = 'AWAITING SIGNATURE',

  RESOLVED_WITH_SIGNATURE = 'RESOLVED_WITH_SIGNATURE',
  RESOLVED_WITHOUT_SIGNATURE = 'RESOLVED_WITHOUT_SIGNATURE',

  FINISHED = 'FINISHED',

  CANCELED = 'CANCELED'
}
