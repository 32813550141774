import styled from 'styled-components'

export const PaginationContainer = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding-top: ${({ theme }) => theme.spacings[4]};
  padding-bottom: ${({ theme }) => theme.spacings[2]};

  background-color: ${({ theme }) => theme.colors.white};

  ol {
    display: flex;
    flex-direction: row;
    align-items: center;

    list-style: none;

    li {
      button[data-link='true'] {
        display: flex;
        align-items: center;
        justify-content: center;

        width: ${({ theme }) => theme.fontSizes.xxlg};
        height: ${({ theme }) => theme.fontSizes.xxlg};
        border-radius: 50%;

        color: ${({ theme }) => theme.colors.darkAqua};

        &.active,
        &:hover {
          color: ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.darkAqua};
          transition: 0.3s;
        }
      }

      span {
        margin-top: auto;
        font-size: ${({ theme }) => theme.fontSizes.xxsm};
        color: ${({ theme }) => theme.colors.darkAqua};
      }
    }

    li:first-of-type,
    li:last-of-type {
      button {
        padding: ${({ theme }) =>
          `${theme.spacings[2.5]} ${theme.spacings[8]}`};

        color: ${({ theme }) => theme.colors.darkAqua};

        border: ${({ theme }) => `2px solid ${theme.colors.darkAqua}`};
        border-radius: 2rem;

        background-color: transparent;

        transition: 0.3s;

        &:disabled {
          color: ${({ theme }) => theme.colors.warmGrey};
          border-color: ${({ theme }) => theme.colors.warmGrey};
        }

        &:hover:not(:disabled) {
          color: ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.darkAqua};
        }
      }
    }

    li + li {
      margin-left: ${({ theme }) => theme.spacings[2.5]};
    }
  }
`
