import { animated } from 'react-spring'
import styled from 'styled-components'

export const TooltipContainer = styled(animated.div)`
  z-index: ${({ theme }) => theme.zIndex.tooltip};

  position: relative;

  font-size: ${({ theme }) => theme.fontSizes.sm};

  max-width: 200px;

  border: none;

  color: ${({ theme }) => theme.colors.white};

  border-radius: 5px;

  background-color: ${({ theme }) => theme.colors.graphite};
  /* background-color: ${({ theme }) => theme.colors.warmGreyThree}; */

  padding: ${({ theme }) => `${theme.spacings[3]} ${theme.spacings[4]}`};

  &[data-placement^='top'] > #arrow {
    bottom: ${({ theme }) => `calc(${theme.fontSizes.sm} / -2)`};
  }

  &[data-placement^='bottom'] > #arrow {
    top: ${({ theme }) => `calc(${theme.fontSizes.sm} / -2)`};
  }

  &[data-placement^='left'] > #arrow {
    right: ${({ theme }) => `calc(${theme.fontSizes.sm} / -2)`};
  }

  &[data-placement^='right'] > #arrow {
    left: ${({ theme }) => `calc(${theme.fontSizes.sm} / -2)`};
  }
`

export const Arrow = styled.div`
  position: absolute;
  width: ${({ theme }) => theme.fontSizes.sm};
  height: ${({ theme }) => theme.fontSizes.sm};
  background: inherit;
  visibility: hidden;

  ::before {
    position: absolute;
    width: ${({ theme }) => theme.fontSizes.sm};
    height: ${({ theme }) => theme.fontSizes.sm};
    background: inherit;
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`
