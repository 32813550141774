import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { TextInputContainer } from './styled'

type TextInputProps = {
  name: string
  placeholder: string
  type?: string
  readOnly?: boolean
  disabled?: boolean
  [key: string]: unknown // workaround to accept html attributes
}

export function TextInput({
  name,
  placeholder,
  type = 'text',
  readOnly,
  disabled,
  ...rest
}: TextInputProps): JSX.Element {
  const { t } = useTranslation(['validation'])

  const [field, meta] = useField({ name, type })

  return (
    <TextInputContainer hasError={meta.touched && !!meta.error}>
      <input
        id={name}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        type={type}
        {...field}
        {...rest}
        tabIndex={readOnly || disabled ? -1 : 0}
      />
      {meta.touched && !!meta.error && (
        <p>
          {t(`validation:${meta.error}`, {
            field: placeholder
          })}
        </p>
      )}
    </TextInputContainer>
  )
}
