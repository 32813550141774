import { Action, ActionWithoutPayload } from 'types/Action'
import { DashboardData } from 'types/dashboard/DashboardData'
import { DashboardQuery } from 'types/dashboard/DashboardQuery'

import { GET_DATA, GET_DATA_FAILURE, GET_DATA_SUCCESS } from './actionTypes'

export function getData(payload: DashboardQuery): Action<DashboardQuery> {
  return { type: GET_DATA, payload }
}

export function getDataFailure(): ActionWithoutPayload {
  return { type: GET_DATA_FAILURE }
}

export function getDataSuccess(payload: DashboardData): Action<DashboardData> {
  return { type: GET_DATA_SUCCESS, payload }
}
