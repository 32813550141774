import styled from 'styled-components'

type DateInputContainerProps = {
  hasError?: boolean
}

export const DateFilterContainer = styled.div<DateInputContainerProps>`
  position: relative;

  width: 100%;

  .react-datepicker-wrapper {
    width: 240px;
  }

  .datePicker {
    width: 100%;

    padding: ${({ theme }) => `${theme.spacings[2.5]} ${theme.spacings[4]}`};

    color: ${({ theme }) => theme.colors.greyishBrownFour};

    border: 1px solid
      ${({ theme, hasError }) => {
        if (hasError) {
          return theme.colors.blush
        }

        return theme.colors.warmGreyThree
      }};
    border-radius: 2rem;

    background-color: ${({ theme }) => theme.colors.white};

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    &::placeholder {
      color: ${({ theme }) => theme.colors.pinkishGrey};
      opacity: 1; /* Firefox */
    }

    /* Internet Explorer 10-11, Microsoft Edge */
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.pinkishGrey};
    }

    &:focus:not(:read-only):not(:disabled) {
      z-index: 1;
      box-shadow: 0 0 0 1px
        ${({ theme, hasError }) => {
          if (hasError) {
            return theme.colors.blush
          }

          return theme.colors.niceBlue
        }};
      border-color: ${({ theme, hasError }) => {
        if (hasError) {
          return theme.colors.blush
        }

        return theme.colors.niceBlue
      }};
    }
  }

  .react-datepicker__close-icon {
    right: 2rem !important;
  }

  .datePickerPopper {
    z-index: ${({ theme }) => theme.zIndex.dropdown};
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: ${({ theme }) => theme.fontSizes.md};
    color: ${({ theme }) => theme.colors.niceBlue};

    transform: translateY(-50%);
  }
`
