import styled, { StyledProps } from 'styled-components'

type ContestActionButtonContainerProps = StyledProps<{
  color: string
  backgroundColor: string
}>

export const ContestActionButtonContainer = styled.button<ContestActionButtonContainerProps>`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-width: 160px;

  padding: ${({ theme }) => `${theme.spacings[2.5]} ${theme.spacings[8]}`};

  color: ${({ color }) => color};

  border: ${({ color }) => `2px solid ${color}`};

  border-radius: 2rem;

  background-color: ${({ backgroundColor }) => backgroundColor};
`
