import styled from 'styled-components'

export const PrecedentsListWrapper = styled.div``

export const Filename = styled.span`
  color: ${({ theme }) => theme.colors.darkAqua};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    transition: 0.3s;
  }
`

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings[2]};

  span {
    font-size: ${({ theme }) => theme.fontSizes.sm};

    padding: ${({ theme }) => `${theme.spacings[1.5]} ${theme.spacings[4]}`};

    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.niceBlue};
    border-radius: 1.25rem;
  }
`

type StatusProps = {
  approved?: boolean
}

export const Status = styled.div<StatusProps>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings[2]};

  span {
    font-size: ${({ theme }) => theme.fontSizes.sm};

    padding: ${({ theme }) => `${theme.spacings[1.5]} ${theme.spacings[4]}`};

    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme, approved }) =>
      typeof approved === 'boolean'
        ? approved
          ? theme.colors.oceanGreen
          : theme.colors.blush
        : theme.colors.fadedOrange};
    border-radius: 1.25rem;
  }
`

export const DetailsLink = styled.button`
  margin: 5px 0;
  color: ${({ theme }) => theme.colors.darkAqua};

  &:hover {
    text-decoration: underline;
    transition: 0.3s;
  }
`
