import { animated } from 'react-spring'
import styled from 'styled-components'

type ModalWrapperProps = {
  open: boolean
}

export const ModalWrapper = styled.div.attrs({
  tabIndex: -1
})<ModalWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;

  display: ${({ open }) => (open ? 'block' : 'none')};

  height: 100%;
  width: 100%;

  border: none;

  background-color: rgba(236, 240, 241, 0.82);

  z-index: ${({ theme }) => theme.zIndex.modal};
`

export const ModalContainer = styled(animated.div)`
  position: relative;

  margin: 15vh auto 0;
  padding: ${({ theme }) => `${theme.spacings[8]} ${theme.spacings[16]}`};

  width: 70vw;
  max-height: 90vh;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 3px 6px 0 rgba(1, 45, 79, 0.16);

  overflow-y: auto;
  overflow-x: hidden;
`

export const ModalCloseButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacings[8]};
  right: ${({ theme }) => theme.spacings[6]};

  color: ${({ theme }) => theme.colors.niceBlue};

  line-height: 0;

  border: none;
  background-color: transparent;

  svg {
    width: 1rem;
  }

  :hover {
    filter: brightness(0.8);
  }

  :focus {
    outline: 1px solid ${({ theme }) => theme.colors.niceBlue};
    outline-offset: 2px;
  }
`
type ModalHeaderContainerProps = {
  separator: boolean
}

export const ModalHeaderContainer = styled.header<ModalHeaderContainerProps>`
  display: flex;
  justify-content: center;

  border-bottom: ${({ theme, separator }) =>
    separator ? `1px solid ${theme.colors.warmGreyThree}` : 'none'};
`

export const ModalBodyContainer = styled.div`
  padding: ${({ theme }) => theme.spacings[2]};
`
