import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { confirmationConfig } from 'config/swal'

import { Breadcrumb, BreadcrumbItem } from 'components/breadcrumb'
import { Button } from 'components/button'
import { Flex } from 'components/flex'
import { StepProgressBar } from 'components/stepProgressBar'

import { withAdminLayout } from 'hoc/withAdminLayout'

import { CONTEST_LIST_PAGE } from 'navigation/paths'

import { GlobalState } from 'store'
import { getContestById, deleteContest } from 'store/contest/actions'

import { BasicInfoStep } from './components/basicInfoStep'
import { MembersStep } from './components/membersStep'
import { PaymentStep } from './components/paymentStep'
import { ContestStep } from 'types/enums/ContestStep'

function Page(): JSX.Element {
  const { t } = useTranslation('pages')

  const dispatch = useDispatch()

  const {
    contest: { contestData, deleteContestInProgress }
  } = useSelector((state: GlobalState) => state)

  const { contestId } = useParams<{ contestId: string }>()

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)

  const steps = useMemo(
    () => [
      t('contestCreate.steps.basicInfo'),
      t('contestCreate.steps.members'),
      t('contestCreate.steps.payment')
    ],
    []
  )

  useEffect(() => {
    if (contestId) {
      setCurrentStepIndex(1)

      if (!contestData || contestData.id !== parseInt(contestId)) {
        dispatch(getContestById(parseInt(contestId)))
      }
    } else {
      setCurrentStepIndex(0)
    }
  }, [contestId])

  useEffect(() => {
    if (contestId && contestData?.currentStep === ContestStep.WAITING_BOLETO) {
      setCurrentStepIndex(2)
    }
  }, [contestData])

  function _changeStep(direction: number) {
    const main = document.body.querySelector('main')
    if (main) {
      main.scrollTop = 0
    }

    setCurrentStepIndex(currentStepIndex + direction)
  }

  function _cancelContest() {
    withReactContent(SweetAlert)
      .fire({
        ...confirmationConfig,
        titleText: t('contestCreate.confirmCancel.title'),
        html: t('contestCreate.confirmCancel.message'),
        confirmButtonText: t('contestCreate.confirmCancel.confirmButton'),
        cancelButtonText: t('contestCreate.confirmCancel.cancelButton')
      })
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          dispatch(deleteContest(parseInt(contestId)))
        }
      })
  }

  document.title = 'Nova Disputa - Open Banking Brasil'

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem
          label={t('contestCreate.breadcrumb.contest')}
          to={CONTEST_LIST_PAGE}
        />
        <BreadcrumbItem
          label={t('contestCreate.breadcrumb.newContest')}
          isCurrentPage
        />
      </Breadcrumb>

      {currentStepIndex === 0 && (
        <h4>{t('contestCreate.basicInfoStep.title')}</h4>
      )}

      {currentStepIndex === 1 && (
        <h4>{t('contestCreate.membersStep.title', { contestId })}</h4>
      )}

      {currentStepIndex === 2 && (
        <h4>{t('contestCreate.paymentStep.title', { contestId })}</h4>
      )}

      {currentStepIndex !== 0 && (
        <>
          <Flex justify="flex-end">
            <Button
              type="button"
              color="white"
              backgroundColor="blush"
              small
              inProgress={deleteContestInProgress}
              onClick={_cancelContest}
            >
              {t('contestCreate.cancelButton')}
            </Button>
          </Flex>

          <br />
          <br />
        </>
      )}

      <StepProgressBar steps={steps} currentStepIndex={currentStepIndex} />

      {currentStepIndex === 0 && (
        <BasicInfoStep goForward={() => _changeStep(1)} />
      )}

      {currentStepIndex === 1 && (
        <MembersStep
          goBack={() => _changeStep(-1)}
          goForward={() => _changeStep(1)}
        />
      )}

      {currentStepIndex === 2 && <PaymentStep goBack={() => _changeStep(-1)} />}
    </>
  )
}

export const ContestCreatePage = withAdminLayout(Page)
