import styled from 'styled-components'

export const WelcomeStepWrapper = styled.div`
  p {
    margin-bottom: ${({ theme }) => theme.spacings[10]};

    line-height: 140%;

    strong {
      color: ${({ theme }) => theme.colors.niceBlue};
      font-weight: bold;
    }
  }

  button {
    display: block;

    margin: 0 auto;
  }
`
