import { PulseLoader } from 'react-spinners'

import theme from 'styles/Theme'

import { PageLoadingContainer } from './styled'

export function PageLoading(): JSX.Element {
  return (
    <PageLoadingContainer>
      <PulseLoader color={theme.colors.niceBlue} size="24px" margin="8px" />
    </PageLoadingContainer>
  )
}
