import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { ReactComponent as ArrowLeftSVG } from 'assets/images/arrow-left.svg'

import { Tooltip } from 'components/tooltip'

import { GlobalState } from 'store'
import { toggleSidebar } from 'store/sidebar/actions'

import { SidebarWrapper } from './styled'

import { Link, links } from './menu'

export function Sidebar(): JSX.Element {
  const { t } = useTranslation(['common'])

  const dispatch = useDispatch()

  const { sidebarIsReduced } = useSelector(
    (state: GlobalState) => state.sidebar
  )

  function _renderNavLink(link: Link) {
    return (
      <Tooltip key={link.to} content={sidebarIsReduced ? t(link.title) : ''}>
        <NavLink to={link.to} exact={link.exact}>
          {link.icon && <link.icon />}
          <span>{t(link.title)}</span>
        </NavLink>
      </Tooltip>
    )
  }

  return (
    <SidebarWrapper isReduced={sidebarIsReduced}>
      <nav>
        {links.map((link) =>
          link.children?.length ? (
            <>
              {_renderNavLink(link)}
              {link.children.map((childLink) => _renderNavLink(childLink))}
            </>
          ) : (
            _renderNavLink(link)
          )
        )}
      </nav>
      <footer>
        <button onClick={() => dispatch(toggleSidebar())}>
          <ArrowLeftSVG />
        </button>
      </footer>
    </SidebarWrapper>
  )
}
