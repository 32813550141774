import { ReactComponent as CheckSVG } from 'assets/images/check.svg'

import { Step, StepList, StepProgressBarWrapper } from './styled'

export type StepProgressBarProps = {
  steps: string[]
  currentStepIndex: number
  onChangeStep?: (index: number) => void
}

export function StepProgressBar({
  steps,
  currentStepIndex,
  onChangeStep
}: StepProgressBarProps): JSX.Element {
  function _isActive(index: number): boolean {
    return index === currentStepIndex
  }

  function _isCompleted(index: number): boolean {
    return index < currentStepIndex
  }

  return (
    <StepProgressBarWrapper>
      <StepList>
        {steps.map((label, i) => (
          <Step
            key={i}
            isActive={_isActive(i)}
            isCompleted={_isCompleted(i)}
            isClickable={!!onChangeStep}
            onClick={() => onChangeStep && onChangeStep(i)}
          >
            <span>{_isCompleted(i) ? <CheckSVG /> : i + 1}</span>
            <p>{label}</p>
          </Step>
        ))}
      </StepList>
    </StepProgressBarWrapper>
  )
}
