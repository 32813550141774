/* eslint-disable @typescript-eslint/no-explicit-any */
import { DashboardData } from 'types/dashboard/DashboardData'
import { DashboardQuery } from 'types/dashboard/DashboardQuery'

import { GET } from 'utils/httpUtils'

const PATH = '/contest/dashboard'

export async function getData(query: DashboardQuery): Promise<DashboardData> {
  try {
    const response = await GET<any>(PATH, query)
    return Promise.resolve({
      openRequester: response.openRequester,
      openDefendant: response.openDefendant,
      onGoingRequester: response.onGoingRequester,
      onGoingDefendant: response.onGoingDefendant,
      finishedRequester: response.finishedRequester,
      finishedDefendant: response.finishedDefendant,
      currentStatusRequester: response.currentStatusRequester.map(
        (item: any) => ({
          currentStep: item.current_step,
          count: parseInt(item.count)
        })
      ),
      currentStatusdefendant: response.currentStatusdefendant.map(
        (item: any) => ({
          currentStep: item.current_step,
          count: parseInt(item.count)
        })
      ),
      lastUpdate: response.lastUpdate.map((item: any) => ({
        id: item.id,
        action: item.action,
        currentStep: item.current_step,
        updatedAt: item.updated_at
      })),
      expiringSla: response.expiringSla.map((item: any) => ({
        id: item.id,
        action: item.action,
        currentStep: item.current_step,
        slaEndAt: item.sla_end_at,
        updatedAt: item.updated_at
      })),
      histogram: response.histogram.map((item: any) => ({
        slaDate: item.sla_date,
        side: item.side,
        qtd: parseInt(item.qtd)
      }))
    })
  } catch (error) {
    return Promise.reject(error)
  }
}
