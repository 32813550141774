import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import * as institutionService from 'services/http/institutionService'

import { closeModal } from 'store/modal/actions'
import {
  fetchInstitutionFailure,
  fetchInstitutionSuccess,
  searchInstitutionFailure,
  searchInstitutionSuccess,
  updateInstitutionFailure,
  updateInstitutionSuccess,
  updateInstitutionAddressFailure,
  updateInstitutionAddressSuccess,
  inviteUserFailure,
  inviteUserSuccess,
  removeUserFailure,
  removeUserSuccess,
  updateUserRoleFailure,
  updateUserRoleSuccess
} from 'store/institution/actions'
import {
  FETCH_INSTITUTION,
  SEARCH_INSTITUTION,
  UPDATE_INSTITUTION,
  UPDATE_INSTITUTION_ADDRESS,
  INVITE_USER,
  REMOVE_USER,
  UPDATE_USER_ROLE
} from 'store/institution/actionTypes'

import { Action } from 'types/Action'
import { ModalName } from 'types/enums/ModalName'
import { Institution } from 'types/institution/Institution'
import { InstitutionInvite } from 'types/institution/InstitutionInvite'
import { InstitutionSearchResult } from 'types/institution/InstitutionSearchResult'
import { InstitutionUpdate } from 'types/institution/InstitutionUpdate'
import { InstitutionUpdateAddress } from 'types/institution/InstitutionUpdateAddress'
import { InstitutionUpdateUserRole } from 'types/institution/InstitutionUpdateUserRole'
import { SearchInstitutionQuery } from 'types/institution/SearchInstitutionQuery'
import { PagedResult } from 'types/PagedResult'

import { showError, showSuccess } from 'utils/alertUtils'

function* fetchInstitutionRequest({
  payload
}: Action<string>): Generator<StrictEffect, void, Institution> {
  try {
    const response = yield call(institutionService.fetchInstitution, payload)
    yield put(fetchInstitutionSuccess(response))
  } catch (error) {
    yield call(showError, 'fetchInstitution', error)
    yield put(fetchInstitutionFailure())
  }
}

function* searchInstitutionRequest({
  payload
}: Action<SearchInstitutionQuery>): Generator<
  StrictEffect,
  void,
  PagedResult<InstitutionSearchResult>
> {
  try {
    const response = yield call(institutionService.searchInstitution, payload)
    yield put(searchInstitutionSuccess(response))
  } catch (error) {
    yield call(showError, 'searchInstitution', error)
    yield put(searchInstitutionFailure())
  }
}

function* updateInstitutionRequest({
  payload
}: Action<InstitutionUpdate>): Generator<StrictEffect, void, Institution> {
  try {
    const response = yield call(institutionService.updateInstitution, payload)
    yield call(showSuccess, 'updateInstitution')
    yield put(updateInstitutionSuccess(response))
  } catch (error) {
    yield call(showError, 'updateInstitution', error)
    yield put(updateInstitutionFailure())
  }
}

function* updateInstitutionAddressRequest({
  payload
}: Action<InstitutionUpdateAddress>): Generator<
  StrictEffect,
  void,
  Institution
> {
  try {
    const response = yield call(
      institutionService.updateInstitutionAddress,
      payload
    )
    yield call(showSuccess, 'updateInstitutionAddress')
    yield put(updateInstitutionAddressSuccess(response))
  } catch (error) {
    yield call(showError, 'updateInstitutionAddress', error)
    yield put(updateInstitutionAddressFailure())
  }
}

function* inviteUserRequest({
  payload
}: Action<InstitutionInvite>): Generator<StrictEffect, void, Institution> {
  try {
    const response = yield call(institutionService.inviteUser, payload)
    yield call(showSuccess, 'inviteUser')
    yield put(closeModal(ModalName.INSTITUTION_ADD_MEMBER))
    yield put(inviteUserSuccess(response))
  } catch (error) {
    yield call(showError, 'inviteUser', error)
    yield put(inviteUserFailure())
  }
}

function* removeUserRequest({
  payload
}: Action<{ cnpj: string; email: string }>): Generator<
  StrictEffect,
  void,
  Institution
> {
  try {
    const response = yield call(institutionService.removeUser, payload)
    yield call(showSuccess, 'removeUser')
    yield put(
      removeUserSuccess({
        ...response,
        users: response.users.filter(({ email }) => email !== payload.email)
      })
    )
  } catch (error) {
    yield call(showError, 'removeUser', error)
    yield put(removeUserFailure())
  }
}

function* updateUserRoleRequest({
  payload
}: Action<InstitutionUpdateUserRole>): Generator<
  StrictEffect,
  void,
  Institution
> {
  try {
    const response = yield call(institutionService.updateUserRole, payload)
    yield call(showSuccess, 'updateUserRole')
    yield put(closeModal(ModalName.INSTITUTION_EDIT_MEMBER))
    yield put(updateUserRoleSuccess(response))
  } catch (error) {
    yield call(showError, 'updateUserRole', error)
    yield put(updateUserRoleFailure())
  }
}

export function* institutionSagas(): Generator {
  yield all([
    takeLatest(FETCH_INSTITUTION, fetchInstitutionRequest),
    takeLatest(SEARCH_INSTITUTION, searchInstitutionRequest),
    takeLatest(UPDATE_INSTITUTION, updateInstitutionRequest),
    takeLatest(UPDATE_INSTITUTION_ADDRESS, updateInstitutionAddressRequest),
    takeLatest(INVITE_USER, inviteUserRequest),
    takeLatest(REMOVE_USER, removeUserRequest),
    takeLatest(UPDATE_USER_ROLE, updateUserRoleRequest)
  ])
}
