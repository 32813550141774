import { PropsWithChildren } from 'react'
import { PulseLoader } from 'react-spinners'

import { Tooltip } from 'components/tooltip'

import { Colors } from 'styles/Theme'

import { IconButtonContainer } from './styled'

type Color = keyof Colors

type ButtonProps = PropsWithChildren<{
  tooltip?: string
  color?: Color
  disabled?: boolean
  inProgress?: boolean
  onClick?: () => void
}>

export function IconButton({
  tooltip,
  color = 'niceBlue',
  disabled,
  inProgress,
  children,
  onClick = () => undefined
}: ButtonProps): JSX.Element {
  return tooltip ? (
    <Tooltip content={tooltip}>
      <IconButtonContainer
        type="button"
        color={color}
        disabled={disabled || inProgress}
        onClick={() => onClick()}
      >
        {inProgress ? (
          <PulseLoader color={color} size="8px" margin="4px" />
        ) : (
          children
        )}
      </IconButtonContainer>
    </Tooltip>
  ) : (
    <IconButtonContainer
      type="button"
      color={color}
      disabled={disabled || inProgress}
      onClick={() => onClick()}
    >
      {inProgress ? (
        <PulseLoader color={color} size="8px" margin="4px" />
      ) : (
        children
      )}
    </IconButtonContainer>
  )
}
