import { ReactComponent as CheckSVG } from 'assets/images/check.svg'

import { Step, StepList, StepProgressBarWrapper } from './styled'

type Step = {
  label: string
  isActive: boolean
  isCompleted: boolean
  isSkiped?: boolean
  dashed?: boolean
}

export type StepProgressBarProps = {
  steps: Step[]
}

export function ContestProgressBar({
  steps
}: StepProgressBarProps): JSX.Element {
  return (
    <StepProgressBarWrapper>
      <StepList>
        {steps.map(({ label, isActive, isCompleted, isSkiped, dashed }, i) => (
          <Step
            key={i}
            isActive={isActive}
            isCompleted={isCompleted}
            isSkiped={isSkiped}
            dashed={dashed}
          >
            <span>{isCompleted ? <CheckSVG /> : i + 1}</span>
            <p>{label}</p>
          </Step>
        ))}
      </StepList>
    </StepProgressBarWrapper>
  )
}
