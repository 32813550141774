import { Action, ActionWithoutPayload } from 'types/Action'
import { PagedResult } from 'types/PagedResult'
import { SearchUserQuery } from 'types/user/SearchUserQuery'
import { User } from 'types/user/User'
import { UserInviteInfo } from 'types/user/UserInviteInfo'
import { UserPasswordReset } from 'types/user/UserPasswordReset'
import { UserPasswordUpdate } from 'types/user/UserPasswordUpdate'
import { UserRequestPasswordReset } from 'types/user/UserRequestPasswordReset'
import { UserSubscription } from 'types/user/UserSubscription'
import { UserUpdate } from 'types/user/UserUpdate'
import { UserUpdateAddress } from 'types/user/UserUpdateAddress'

import {
  FETCH_USER_INFO,
  FETCH_USER_INFO_FAILURE,
  FETCH_USER_INFO_SUCCESS,
  SEARCH_USER,
  SEARCH_USER_FAILURE,
  SEARCH_USER_SUCCESS,
  FETCH_INVITE_INFO,
  FETCH_INVITE_INFO_FAILURE,
  FETCH_INVITE_INFO_SUCCESS,
  SUBSCRIBE_BY_INVITE,
  SUBSCRIBE_BY_INVITE_FAILURE,
  SUBSCRIBE_BY_INVITE_SUCCESS,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_FAILURE,
  REQUEST_PASSWORD_RESET_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_ADDRESS_FAILURE,
  UPDATE_USER_ADDRESS_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS
} from './actionTypes'

export function fetchUserInfo(payload: number): Action<number> {
  return { type: FETCH_USER_INFO, payload }
}

export function fetchUserInfoFailure(): ActionWithoutPayload {
  return { type: FETCH_USER_INFO_FAILURE }
}

export function fetchUserInfoSuccess(payload: User): Action<User> {
  return { type: FETCH_USER_INFO_SUCCESS, payload }
}

export function searchUser(payload: SearchUserQuery): Action<SearchUserQuery> {
  return { type: SEARCH_USER, payload }
}

export function searchUserFailure(): ActionWithoutPayload {
  return { type: SEARCH_USER_FAILURE }
}

export function searchUserSuccess(
  payload: PagedResult<User>
): Action<PagedResult<User>> {
  return { type: SEARCH_USER_SUCCESS, payload }
}

export function fetchInviteInfo(payload: string): Action<string> {
  return { type: FETCH_INVITE_INFO, payload }
}

export function fetchInviteInfoFailure(): ActionWithoutPayload {
  return { type: FETCH_INVITE_INFO_FAILURE }
}

export function fetchInviteInfoSuccess(
  payload: UserInviteInfo
): Action<UserInviteInfo> {
  return { type: FETCH_INVITE_INFO_SUCCESS, payload }
}

export function subscribeByInvite(
  payload: UserSubscription & { inviteId: string }
): Action<UserSubscription> {
  return { type: SUBSCRIBE_BY_INVITE, payload }
}

export function subscribeByInviteFailure(): ActionWithoutPayload {
  return { type: SUBSCRIBE_BY_INVITE_FAILURE }
}

export function subscribeByInviteSuccess(payload: User): Action<User> {
  return { type: SUBSCRIBE_BY_INVITE_SUCCESS, payload }
}

export function requestPasswordReset(
  payload: UserRequestPasswordReset
): Action<UserRequestPasswordReset> {
  return { type: REQUEST_PASSWORD_RESET, payload }
}

export function requestPasswordResetFailure(): ActionWithoutPayload {
  return { type: REQUEST_PASSWORD_RESET_FAILURE }
}

export function requestPasswordResetSuccess(payload: string): Action<string> {
  return { type: REQUEST_PASSWORD_RESET_SUCCESS, payload }
}

export function resetPassword(
  payload: UserPasswordReset
): Action<UserPasswordReset> {
  return { type: RESET_PASSWORD, payload }
}

export function resetPasswordFailure(): ActionWithoutPayload {
  return { type: RESET_PASSWORD_FAILURE }
}

export function resetPasswordSuccess(payload: User): Action<User> {
  return { type: RESET_PASSWORD_SUCCESS, payload }
}

export function updateUser(payload: UserUpdate): Action<UserUpdate> {
  return { type: UPDATE_USER, payload }
}

export function updateUserFailure(): ActionWithoutPayload {
  return { type: UPDATE_USER_FAILURE }
}

export function updateUserSuccess(payload: User): Action<User> {
  return { type: UPDATE_USER_SUCCESS, payload }
}

export function updateUserAddress(
  payload: UserUpdateAddress
): Action<UserUpdateAddress> {
  return { type: UPDATE_USER_ADDRESS, payload }
}

export function updateUserAddressFailure(): ActionWithoutPayload {
  return { type: UPDATE_USER_ADDRESS_FAILURE }
}

export function updateUserAddressSuccess(payload: User): Action<User> {
  return { type: UPDATE_USER_ADDRESS_SUCCESS, payload }
}

export function updatePassword(
  payload: UserPasswordUpdate
): Action<UserPasswordUpdate> {
  return { type: UPDATE_PASSWORD, payload }
}

export function updatePasswordFailure(): ActionWithoutPayload {
  return { type: UPDATE_PASSWORD_FAILURE }
}

export function updatePasswordSuccess(payload: User): Action<User> {
  return { type: UPDATE_PASSWORD_SUCCESS, payload }
}
