import styled from 'styled-components'

export const AccountStepWrapper = styled.div`
  button {
    margin: 0 auto;
  }

  button + button {
    margin-top: ${({ theme }) => theme.spacings[2]};
  }
`
export const InstitutionName = styled.h4`
  color: ${({ theme }) => theme.colors.niceBlue};
`
