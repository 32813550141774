import { Action, ActionWithoutPayload } from 'types/Action'
import { Institution } from 'types/institution/Institution'
import { InstitutionInvite } from 'types/institution/InstitutionInvite'
import { InstitutionSearchResult } from 'types/institution/InstitutionSearchResult'
import { InstitutionUpdate } from 'types/institution/InstitutionUpdate'
import { InstitutionUpdateAddress } from 'types/institution/InstitutionUpdateAddress'
import { InstitutionUpdateUserRole } from 'types/institution/InstitutionUpdateUserRole'
import { SearchInstitutionQuery } from 'types/institution/SearchInstitutionQuery'
import { PagedResult } from 'types/PagedResult'

import {
  FETCH_INSTITUTION,
  FETCH_INSTITUTION_FAILURE,
  FETCH_INSTITUTION_SUCCESS,
  SEARCH_INSTITUTION,
  SEARCH_INSTITUTION_FAILURE,
  SEARCH_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION,
  UPDATE_INSTITUTION_FAILURE,
  UPDATE_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION_ADDRESS,
  UPDATE_INSTITUTION_ADDRESS_FAILURE,
  UPDATE_INSTITUTION_ADDRESS_SUCCESS,
  INVITE_USER,
  INVITE_USER_FAILURE,
  INVITE_USER_SUCCESS,
  REMOVE_USER,
  REMOVE_USER_FAILURE,
  REMOVE_USER_SUCCESS,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_FAILURE,
  UPDATE_USER_ROLE_SUCCESS
} from './actionTypes'

export function fetchInstitution(payload: string): Action<string> {
  return { type: FETCH_INSTITUTION, payload }
}

export function fetchInstitutionFailure(): ActionWithoutPayload {
  return { type: FETCH_INSTITUTION_FAILURE }
}

export function fetchInstitutionSuccess(
  payload: Institution
): Action<Institution> {
  return { type: FETCH_INSTITUTION_SUCCESS, payload }
}

export function searchInstitution(
  payload: SearchInstitutionQuery
): Action<SearchInstitutionQuery> {
  return { type: SEARCH_INSTITUTION, payload }
}

export function searchInstitutionFailure(): ActionWithoutPayload {
  return { type: SEARCH_INSTITUTION_FAILURE }
}

export function searchInstitutionSuccess(
  payload: PagedResult<InstitutionSearchResult>
): Action<PagedResult<InstitutionSearchResult>> {
  return { type: SEARCH_INSTITUTION_SUCCESS, payload }
}

export function updateInstitution(
  payload: InstitutionUpdate
): Action<InstitutionUpdate> {
  return { type: UPDATE_INSTITUTION, payload }
}

export function updateInstitutionFailure(): ActionWithoutPayload {
  return { type: UPDATE_INSTITUTION_FAILURE }
}

export function updateInstitutionSuccess(
  payload: Institution
): Action<Institution> {
  return { type: UPDATE_INSTITUTION_SUCCESS, payload }
}

export function updateInstitutionAddress(
  payload: InstitutionUpdateAddress
): Action<InstitutionUpdateAddress> {
  return { type: UPDATE_INSTITUTION_ADDRESS, payload }
}

export function updateInstitutionAddressFailure(): ActionWithoutPayload {
  return { type: UPDATE_INSTITUTION_ADDRESS_FAILURE }
}

export function updateInstitutionAddressSuccess(
  payload: Institution
): Action<Institution> {
  return { type: UPDATE_INSTITUTION_ADDRESS_SUCCESS, payload }
}

export function inviteUser(
  payload: InstitutionInvite
): Action<InstitutionInvite> {
  return { type: INVITE_USER, payload }
}

export function inviteUserFailure(): ActionWithoutPayload {
  return { type: INVITE_USER_FAILURE }
}

export function inviteUserSuccess(payload: Institution): Action<Institution> {
  return { type: INVITE_USER_SUCCESS, payload }
}

export function removeUser(
  cnpj: string,
  email: string
): Action<{ cnpj: string; email: string }> {
  return { type: REMOVE_USER, payload: { cnpj, email } }
}

export function removeUserFailure(): ActionWithoutPayload {
  return { type: REMOVE_USER_FAILURE }
}

export function removeUserSuccess(payload: Institution): Action<Institution> {
  return { type: REMOVE_USER_SUCCESS, payload }
}

export function updateUserRole(
  payload: InstitutionUpdateUserRole
): Action<InstitutionUpdateUserRole> {
  return { type: UPDATE_USER_ROLE, payload }
}

export function updateUserRoleFailure(): ActionWithoutPayload {
  return { type: UPDATE_USER_ROLE_FAILURE }
}

export function updateUserRoleSuccess(
  payload: Institution
): Action<Institution> {
  return { type: UPDATE_USER_ROLE_SUCCESS, payload }
}
