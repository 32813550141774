import { Action, ActionWithoutPayload } from 'types/Action'
import { PagedResult } from 'types/PagedResult'
import { Precedent } from 'types/precedent/Precedent'
import { PrecedentCreate } from 'types/precedent/PrecedentCreate'
import { PrecedentsQuery } from 'types/precedent/PrecedentsQuery'

import {
  FETCH_PRECEDENTS,
  FETCH_PRECEDENTS_FAILURE,
  FETCH_PRECEDENTS_SUCCESS,
  CREATE_PRECEDENT,
  CREATE_PRECEDENT_FAILURE,
  CREATE_PRECEDENT_SUCCESS
} from './actionTypes'

export function fetchPrecedents(
  payload: PrecedentsQuery
): Action<PrecedentsQuery> {
  return { type: FETCH_PRECEDENTS, payload }
}

export function fetchPrecedentsFailure(): ActionWithoutPayload {
  return { type: FETCH_PRECEDENTS_FAILURE }
}

export function fetchPrecedentsSuccess(
  payload: PagedResult<Precedent>
): Action<PagedResult<Precedent>> {
  return { type: FETCH_PRECEDENTS_SUCCESS, payload }
}

export function createPrecedent(
  payload: PrecedentCreate
): Action<PrecedentCreate> {
  return { type: CREATE_PRECEDENT, payload }
}

export function createPrecedentFailure(): ActionWithoutPayload {
  return { type: CREATE_PRECEDENT_FAILURE }
}

export function createPrecedentSuccess(payload: Precedent): Action<Precedent> {
  return { type: CREATE_PRECEDENT_SUCCESS, payload }
}
