import styled from 'styled-components'

export const Filename = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.darkAqua};

  svg {
    width: ${({ theme }) => theme.fontSizes.md};

    margin-right: ${({ theme }) => theme.spacings[1]};
  }

  span {
    text-decoration: underline;
    cursor: pointer;
  }

  &:hover {
    opacity: 0.6;
    transition: 0.3s;
  }
`

export const TextBox = styled.div`
  margin-top: ${({ theme }) => theme.spacings[6]};

  padding: ${({ theme }) => `${theme.spacings[6]} ${theme.spacings[4]}`};

  border: 1px solid ${({ theme }) => theme.colors.paleGrey};

  background-color: rgba(236, 240, 241, 0.13);

  max-height: 30vh;

  overflow-y: auto;

  p {
    color: ${({ theme }) => theme.colors.pinkishGrey};
  }
`

export const Tags = styled.div`
  margin: ${({ theme }) => `${theme.spacings[6]} 0`};

  span {
    font-size: ${({ theme }) => theme.fontSizes.sm};

    padding: ${({ theme }) => `${theme.spacings[1.5]} ${theme.spacings[4]}`};

    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.niceBlue};
    border-radius: 1.25rem;
  }

  span + span {
    margin-left: ${({ theme }) => theme.spacings[2]};
  }
`
export const Rejected = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings[2]};
  height: fit-content;
  margin-left: 10px;

  span {
    font-size: ${({ theme }) => theme.fontSizes.md};

    padding: ${({ theme }) => `${theme.spacings[1.5]} ${theme.spacings[4]}`};

    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blush};
    border-radius: 1.25rem;
  }
`

export const RejectedDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings[2]};
  height: fit-content;
  margin-bottom: 50px;

  h3 {
    margin-bottom: 10px;
    text-transform: uppercase;
  }
`
