import { Action, ActionWithoutPayload } from 'types/Action'
import { Login } from 'types/login/Login'
import { Session } from 'types/session/Session'

import { LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS } from './actionTypes'

export function login(payload: Login): Action<Login> {
  return { type: LOGIN, payload }
}

export function loginFailure(): ActionWithoutPayload {
  return { type: LOGIN_FAILURE }
}

export function loginSuccess(payload: Session): Action<Session> {
  return { type: LOGIN_SUCCESS, payload }
}
