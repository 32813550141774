import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import Theme from 'styles/Theme'

import { ContestSide } from 'types/enums/ContestSide'

import { formatDateBR } from 'utils/formatUtils'

import { ChartWrapper } from './styled'

type Item = {
  slaDate: string
  [ContestSide.REQUESTER]: number
  [ContestSide.DEFENDANT]: number
}

type Props = {
  data: Item[]
}

export function Histogram({ data }: Props): JSX.Element {
  const { t } = useTranslation('common')

  const Chart = useMemo(
    () => (
      <ChartWrapper>
        <Bar
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom'
              }
            }
          }}
          data={{
            labels: data.map(({ slaDate }) => formatDateBR(slaDate)),
            datasets: [
              {
                label: t('contestSide.REQUESTER'),
                data: data.map(({ REQUESTER }) => REQUESTER),
                backgroundColor: Theme.colors.sandy
              },
              {
                label: t('contestSide.DEFENDANT'),
                data: data.map(({ DEFENDANT }) => DEFENDANT),
                backgroundColor: Theme.colors.niceBlue
              }
            ]
          }}
        />
      </ChartWrapper>
    ),
    []
  )

  return Chart
}
