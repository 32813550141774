/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContestAssociation } from 'types/contestAssociation/ContestAssociation'
import { ContestAssociationCreate } from 'types/contestAssociation/ContestAssociationCreate'
import { ContestAssociationRemove } from 'types/contestAssociation/ContestAssociationRemove'

import { DELETE, GET, POST } from 'utils/httpUtils'

const PATH = '/contest/associate'

export async function fetchContestAssociations(
  contestId: number
): Promise<ContestAssociation[]> {
  try {
    const { associations } = await GET<{ associations: any[] }>(
      `${PATH}/${contestId}`
    )

    return Promise.resolve(
      associations.map((item) => ({
        contest: item.contest,
        requesterId: item.requester_id,
        requester: item.requester,
        defendantId: item.defendant_id,
        defendant: item.defendant,
        reason: item.reason
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function createContestAssociation(
  data: ContestAssociationCreate
): Promise<string> {
  try {
    const { message } = await POST<
      ContestAssociationCreate,
      { message: string }
    >(`${PATH}`, data)

    return Promise.resolve(message)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function removeContestAssociation(
  data: ContestAssociationRemove
): Promise<string> {
  try {
    const { message } = await DELETE<
      ContestAssociationRemove,
      { message: string }
    >(`${PATH}`, data)

    return Promise.resolve(message)
  } catch (error) {
    return Promise.reject(error)
  }
}
