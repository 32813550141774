export const FETCH_INSTITUTION = '@institution/FETCH_INSTITUTION'
export const FETCH_INSTITUTION_SUCCESS =
  '@institution/FETCH_INSTITUTION_SUCCESS'
export const FETCH_INSTITUTION_FAILURE =
  '@institution/FETCH_INSTITUTION_FAILURE'

export const SEARCH_INSTITUTION = '@institution/SEARCH_INSTITUTION'
export const SEARCH_INSTITUTION_SUCCESS =
  '@institution/SEARCH_INSTITUTION_SUCCESS'
export const SEARCH_INSTITUTION_FAILURE =
  '@institution/SEARCH_INSTITUTION_FAILURE'

export const UPDATE_INSTITUTION = '@institution/UPDATE_INSTITUTION'
export const UPDATE_INSTITUTION_SUCCESS =
  '@institution/UPDATE_INSTITUTION_SUCCESS'
export const UPDATE_INSTITUTION_FAILURE =
  '@institution/UPDATE_INSTITUTION_FAILURE'

export const UPDATE_INSTITUTION_ADDRESS =
  '@institution/UPDATE_INSTITUTION_ADDRESS'
export const UPDATE_INSTITUTION_ADDRESS_SUCCESS =
  '@institution/UPDATE_INSTITUTION_ADDRESS_SUCCESS'
export const UPDATE_INSTITUTION_ADDRESS_FAILURE =
  '@institution/UPDATE_INSTITUTION_ADDRESS_FAILURE'

export const INVITE_USER = '@institution/INVITE_USER'
export const INVITE_USER_SUCCESS = '@institution/INVITE_USER_SUCCESS'
export const INVITE_USER_FAILURE = '@institution/INVITE_USER_FAILURE'

export const REMOVE_USER = '@institution/REMOVE_USER'
export const REMOVE_USER_SUCCESS = '@institution/REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAILURE = '@institution/REMOVE_USER_FAILURE'

export const UPDATE_USER_ROLE = '@institution/UPDATE_USER_ROLE'
export const UPDATE_USER_ROLE_SUCCESS = '@institution/UPDATE_USER_ROLE_SUCCESS'
export const UPDATE_USER_ROLE_FAILURE = '@institution/UPDATE_USER_ROLE_FAILURE'
