import { ActionWithoutPayload } from 'types/Action'

import { OPEN_ADMIN_MENU, CLOSE_ADMIN_MENU } from './actionTypes'

export function openAdminMenu(): ActionWithoutPayload {
  return { type: OPEN_ADMIN_MENU }
}

export function closeAdminMenu(): ActionWithoutPayload {
  return { type: CLOSE_ADMIN_MENU }
}
