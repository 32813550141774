import { ChangeEvent, useState } from 'react'

import { ReactComponent as SearchSVG } from 'assets/images/search.svg'

import { SearchInputWrapper } from './styled'

type SearchInputProps = {
  placeholder: string
  value?: string
  onClick?: (value: string) => void
}

export function SearchInput({
  placeholder,
  value = '',
  onClick = () => undefined
}: SearchInputProps): JSX.Element {
  const [innerValue, setInnerValue] = useState<string>(value)

  function _handleChange(event: ChangeEvent<HTMLInputElement>): void {
    setInnerValue(event.target.value)
  }

  return (
    <SearchInputWrapper>
      <input
        placeholder={placeholder}
        value={innerValue}
        onChange={_handleChange}
        onKeyUp={({ key }) => {
          if (key === 'Enter') {
            onClick(innerValue)
          }
        }}
        type="text"
      />
      <button
        type="button"
        // disabled={!innerValue}
        onClick={() => onClick(innerValue)}
      >
        <SearchSVG />
      </button>
    </SearchInputWrapper>
  )
}
