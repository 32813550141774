import styled from 'styled-components'

export const TimelineWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings[6]};
`

export const TimelineContainer = styled.div`
  border-left: 5px solid ${({ theme }) => theme.colors.paleGrey};

  & > header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* margin-bottom: ${({ theme }) => theme.spacings[6]}; */

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      i {
        display: block;
        margin-right: ${({ theme }) => theme.spacings[0.5]};

        svg {
          width: ${({ theme }) => theme.fontSizes.xmd};
          height: ${({ theme }) => theme.fontSizes.xmd};
          color: ${({ theme }) => theme.colors.petrol};
        }
      }

      h4 {
        margin-bottom: ${({ theme }) => theme.spacings[0.5]};

        span {
          color: ${({ theme }) => theme.colors.niceBlue};
        }
      }
    }

    time {
      display: block;
      font-size: ${({ theme }) => theme.fontSizes.sm};
      color: ${({ theme }) => theme.colors.petrol};
      text-align: center;
    }

    p {
      font-size: ${({ theme }) => theme.fontSizes.sm};
      color: ${({ theme }) => theme.colors.greyishBrownFive};
      text-align: center;

      margin-top: ${({ theme }) => theme.spacings[1]};

      span {
        font-weight: bold;
        color: ${({ theme }) => theme.colors.petrol};
      }
    }
  }

  & > ul {
    position: relative;
    list-style: none;

    & > li {
      margin-top: ${({ theme }) => theme.spacings[8]};

      &:first-of-type {
        & > article {
          & > i {
            background-color: ${({ theme }) => theme.colors.niceBlue};

            & > svg {
              color: ${({ theme }) => theme.colors.duckEggBlue};
            }
          }
        }
      }
    }
  }
`

export const TimelineItem = styled.li``

export const TimelineItemHeading = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* margin-top: ${({ theme }) => theme.spacings[6]}; */

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    i {
      display: block;
      margin-right: ${({ theme }) => theme.spacings[0.5]};

      svg {
        width: ${({ theme }) => theme.fontSizes.xmd};
        height: ${({ theme }) => theme.fontSizes.xmd};
        color: ${({ theme }) => theme.colors.petrol};
      }
    }

    h4 {
      margin-bottom: ${({ theme }) => theme.spacings[0.5]};

      span {
        color: ${({ theme }) => theme.colors.niceBlue};
      }
    }
  }

  time {
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.petrol};
    text-align: center;
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.greyishBrownFive};
    text-align: center;

    margin-top: ${({ theme }) => theme.spacings[1]};

    span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.petrol};
    }
  }

  button {
    margin-top: ${({ theme }) => theme.spacings[2]};
  }
`

export const TimelineItemContent = styled.article`
  margin-left: ${({ theme }) => theme.spacings[6]};
  padding: ${({ theme }) => theme.spacings[2]};
  border: 1px solid ${({ theme }) => theme.colors.paleGrey};

  margin-bottom: ${({ theme }) => theme.spacings[6]};

  & > i {
    position: absolute;
    right: ${({ theme }) => `calc(100% - ${theme.fontSizes.xlg} / 2 + 2.5px)`};

    display: flex;
    align-items: center;
    justify-content: center;

    width: ${({ theme }) => theme.fontSizes.xlg};
    height: ${({ theme }) => theme.fontSizes.xlg};

    border-radius: 50%;

    background-color: ${({ theme }) => theme.colors.paleGrey};

    svg {
      width: ${({ theme }) => theme.fontSizes.sm};
      height: ${({ theme }) => theme.fontSizes.sm};
      color: ${({ theme }) => theme.colors.niceBlue};
    }
  }

  & > h3 {
    font-size: ${({ theme }) => theme.fontSizes.xmd};
    margin-bottom: ${({ theme }) => theme.spacings[2]};
  }

  & > h4 {
    color: ${({ theme }) => theme.colors.niceBlue};
    margin-bottom: ${({ theme }) => theme.spacings[0.5]};

    span {
      color: ${({ theme }) => theme.colors.greyishBrownFive};
    }
  }

  & > h5 {
    font-weight: normal;
    color: ${({ theme }) => theme.colors.greyishBrownFive};
    margin-bottom: ${({ theme }) => theme.spacings[0.5]};
  }

  & > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacings[2]};

    time {
      font-size: ${({ theme }) => theme.fontSizes.xsm};
      color: ${({ theme }) => theme.colors.warmGrey};
    }

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: ${({ theme }) => theme.spacings[1]};

      font-size: ${({ theme }) => theme.fontSizes.xsm};
      color: ${({ theme }) => theme.colors.warmGrey};
      text-decoration: underline;

      cursor: pointer;

      &:hover {
        opacity: 0.6;
        transition: 0.3s ease;
      }

      svg {
        color: ${({ theme }) => theme.colors.niceBlue};
        width: ${({ theme }) => theme.fontSizes.sm};
      }
    }
  }

  & > p {
    margin-top: ${({ theme }) => theme.spacings[4]};
  }

  &[data-myside='true'] {
    border: 1px solid ${({ theme }) => theme.colors.paleBlueTwo};
    background-color: ${({ theme }) => theme.colors.duckEggBlueFour};
  }
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings[6]};

  margin-top: ${({ theme }) => theme.spacings[6]};
  margin-bottom: ${({ theme }) => theme.spacings[4]};
`

export const TransferInstitutionNotice = styled.header`
  & > h3 {
    margin: 15px 0 10px;
  }
`
