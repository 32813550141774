import styled from 'styled-components'

import { ModalBodyContainer } from 'components/modal/styled'

export const ContestHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[6]};

  dl {
    flex: 1;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    row-gap: ${({ theme }) => theme.spacings[1.5]};

    margin-bottom: ${({ theme }) => theme.spacings[4]};

    dt {
      font-size: ${({ theme }) => theme.fontSizes.xsm};
      color: ${({ theme }) => theme.colors.warmGrey};
    }

    dd {
      font-size: ${({ theme }) => theme.fontSizes.sm};
      color: ${({ theme }) => theme.colors.greyishBrownFive};
    }
  }
`

export const ModalHeaderContent = styled.div`
  display: block;
  width: 100%;

  h2 {
    margin-bottom: ${({ theme }) => theme.spacings[1]};
    text-align: center;
  }

  h4 {
    color: ${({ theme }) => theme.colors.niceBlue};
    margin-bottom: ${({ theme }) => theme.spacings[4]};
    text-align: center;
  }
`

export const ModalBody = styled(ModalBodyContainer)`
  padding: 0;

  section {
    display: grid;
    grid-template-columns: 1fr 1fr;

    margin-bottom: ${({ theme }) => theme.spacings[6]};

    div {
      h4 {
        color: ${({ theme }) => theme.colors.niceBlue};
        margin-bottom: ${({ theme }) => theme.spacings[4]};

        span {
          color: ${({ theme }) => theme.colors.greyishBrownFour};
        }
      }

      h5 {
        margin-bottom: ${({ theme }) => theme.spacings[2]};
      }

      ul {
        list-style-type: none;

        li {
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            margin-right: ${({ theme }) => theme.spacings[2]};

            color: ${({ theme }) => theme.colors.sandy};

            &[data-success='true'] {
              color: ${({ theme }) => theme.colors.lightTeal};
            }

            svg {
              color: inherit;

              width: ${({ theme }) => theme.fontSizes.md};
            }
          }
        }
      }

      ul + h5 {
        margin-top: ${({ theme }) => theme.spacings[4]};
      }
    }
  }

  button {
    margin: 0 auto;
  }

  /* & > section {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacings[6]};

    section {
      padding: ${({ theme }) => theme.spacings[4]};
    }
  } */
`
