export enum ModalName {
  FORGET_PASSWORD = 'forgetPasswordIsOpen',

  INSTITUTION_ADD_MEMBER = 'institutionAddMemberIsOpen',
  INSTITUTION_EDIT_MEMBER = 'institutionEditMemberIsOpen',

  CONTEST_ADD_MEMBER = 'contestAddMemberIsOpen',
  CONTEST_EDIT_MEMBER = 'contestEditMemberIsOpen',
  CONTEST_ADD_ATTACHMENT = 'contestAddAttachmentIsOpen',
  CONTEST_ADD_ATTACHMENT_NOTE = 'contestAddAttachmentNoteIsOpen',
  CONTEST_ANSWER = 'contestAnswerIsOpen',
  CONTEST_REPLICATION = 'contestReplicationIsOpen',
  CONTEST_REJOINDER = 'contestRejoinderIsOpen',
  CONTEST_ACCEPT = 'contestAcceptIsOpen',
  CONTEST_SEND_AGREEMENT = 'contestSendAgreementIsOpen',
  CONTEST_SEND_NEW_AGREEMENT = 'contestSendNewAgreementIsOpen',
  CONTEST_ACCEPT_AGREEMENT = 'contestAcceptAgreementIsOpen',
  CONTEST_SEND_SUBSCRIBERS = 'contestSendSubscribersIsOpen',
  CONTEST_SIGNATURES = 'contestSignaturesIsOpen',
  CONTEST_ADD_ASSOCIATION = 'contestAddAssociationIsOpen',
  CONTEST_RESEND_PAYMENT = 'contestResendPaymentIsOpen',
  CONTEST_TRANSFER_INSTITUTION = 'contestTransferInstitutionIsOpen',
  CONTEST_TRANSFER_INSTITUTION_STATUS = 'contestTransferInstitutionStatusIsOpen',
  CONTEST_REPLY_INSTITUTION = 'contestReplyInstitutionIsOpen',

  DIRECTORY_INVITE = 'directoryInviteIsOpen',

  PRECEDENT_CREATE = 'precedentCreateIsOpen',
  PRECEDENT_DETAILS = 'precedentDetailsIsOpen',
  PRECEDENT_REVIEW = 'precedentReviewIsOpen'
}
