import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'
import { InviteInfo } from 'types/directory/InviteInfo'

import {
  COMPLETE_SIGN_UP,
  COMPLETE_SIGN_UP_FAILURE,
  COMPLETE_SIGN_UP_SUCCESS,
  GENERATE_INVITE,
  GENERATE_INVITE_FAILURE,
  GENERATE_INVITE_SUCCESS,
  GET_INVITE_INFO,
  GET_INVITE_INFO_FAILURE,
  GET_INVITE_INFO_SUCCESS
} from './actionTypes'

export type DirectoryState = {
  generateInviteInProgress: boolean
  inviteInfo?: InviteInfo
  getInviteInfoInProgress: boolean
  completeSignUpInProgress: boolean
}

const INITIAL_STATE: DirectoryState = {
  generateInviteInProgress: false,
  inviteInfo: undefined,
  getInviteInfoInProgress: false,
  completeSignUpInProgress: false
}

export function directoryReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): DirectoryState {
  return produce(state, (draft) => {
    switch (action.type) {
      case GENERATE_INVITE: {
        draft.generateInviteInProgress = true
        break
      }

      case GENERATE_INVITE_FAILURE: {
        draft.generateInviteInProgress = false
        break
      }

      case GENERATE_INVITE_SUCCESS: {
        draft.generateInviteInProgress = false
        break
      }

      case GET_INVITE_INFO: {
        draft.getInviteInfoInProgress = true
        break
      }

      case GET_INVITE_INFO_FAILURE: {
        draft.getInviteInfoInProgress = false
        break
      }

      case GET_INVITE_INFO_SUCCESS: {
        draft.inviteInfo = <InviteInfo>action.payload
        draft.getInviteInfoInProgress = false
        break
      }

      case COMPLETE_SIGN_UP: {
        draft.completeSignUpInProgress = true
        break
      }

      case COMPLETE_SIGN_UP_FAILURE: {
        draft.completeSignUpInProgress = false
        break
      }

      case COMPLETE_SIGN_UP_SUCCESS: {
        draft.completeSignUpInProgress = false
        break
      }

      case CLEAR_SESSION_DATA: {
        return INITIAL_STATE
      }

      default: {
        break
      }
    }
  })
}
