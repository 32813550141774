import styled from 'styled-components'

import { Scroll } from 'styles/Helpers'

export const TabsWrapper = styled.section`
  display: flex;
  flex-direction: column;

  margin-top: ${({ theme }) => theme.spacings[6]};

  max-height: ${({ theme }) =>
    `calc(100vh - ${theme.misc.header.height} - 2 * ${theme.spacings[6]})`};
`

export const TabListContainer = styled.ul`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings[0.5]};
  list-style: none;
`

type TabContainerProps = {
  isSelected: boolean
  small: boolean
}

export const TabContainer = styled.li<TabContainerProps>`
  display: inline-block;

  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.white : theme.colors.greyishBrownFive};

  border-top-left-radius: 1rem;
  border-top-right-radius: ${({ isSelected }) =>
    isSelected ? '2.5rem' : '1rem'};

  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.niceBlue : theme.colors.paleGrey};

  transition: 0.2s ease;

  position: relative;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.niceBlue};

    &[data-marker]:not([data-marker='']) {
      &::after {
        color: ${({ theme }) => theme.colors.niceBlue};
        background-color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  &[data-marker]:not([data-marker='']) {
    &::after {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: ${({ theme }) => theme.fontSizes.xsm};
      font-weight: bold;

      content: attr(data-marker);

      position: absolute;
      top: ${({ theme }) => theme.spacings[2]};
      right: ${({ theme }) => theme.spacings[4]};

      height: 22px;
      width: 22px;

      border-radius: 50%;

      line-height: 0;

      color: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.niceBlue : theme.colors.white};
      background-color: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.white : theme.colors.niceBlue};

      transition: 0.2s ease;
    }
  }

  button {
    height: 100%;
    width: 100%;

    font-size: ${({ theme, small }) =>
      small ? theme.fontSizes.sm : 'inherit'};

    padding: ${({ theme, small }) =>
      small
        ? `${theme.spacings[1]} ${theme.spacings[3]}`
        : `${theme.spacings[3]} ${theme.spacings[10]}`};

    color: inherit;

    &:hover {
      opacity: 1;
    }
  }
`

export const TabPanelContainer = styled.section`
  /* padding: ${({ theme }) => theme.spacings[6]}; */
  border: 1px solid ${({ theme }) => theme.colors.paleGrey};

  ${Scroll}
`
