import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as FileExcelSVG } from 'assets/images/file-excel.svg'

export const DetailsLink = styled(Link)`
  color: ${({ theme }) => theme.colors.darkAqua};

  &:hover {
    text-decoration: underline;
    transition: 0.3s;
  }
`

export const ExcelIcon = styled(FileExcelSVG)`
  width: ${({ theme }) => theme.fontSizes.lg} !important;
`
