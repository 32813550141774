import { Action, ActionWithoutPayload } from 'types/Action'
import { ContestFile } from 'types/contestFile/ContestFile'
import { ContestFileCreate } from 'types/contestFile/ContestFileCreate'
import { ContestFileUpdateNote } from 'types/contestFile/ContestFileUpdateNote'

import {
  FETCH_CONTEST_FILES,
  FETCH_CONTEST_FILES_FAILURE,
  FETCH_CONTEST_FILES_SUCCESS,
  ADD_CONTEST_FILES,
  ADD_CONTEST_FILES_FAILURE,
  ADD_CONTEST_FILES_SUCCESS,
  REMOVE_CONTEST_FILES,
  REMOVE_CONTEST_FILES_FAILURE,
  REMOVE_CONTEST_FILES_SUCCESS,
  UPDATE_CONTEST_FILE_NOTES,
  UPDATE_CONTEST_FILE_NOTES_FAILURE,
  UPDATE_CONTEST_FILE_NOTES_SUCCESS
} from './actionTypes'

export function fetchContestFiles(contestId: number): Action<number> {
  return { type: FETCH_CONTEST_FILES, payload: contestId }
}

export function fetchContestFilesFailure(): ActionWithoutPayload {
  return { type: FETCH_CONTEST_FILES_FAILURE }
}

export function fetchContestFilesSuccess(
  payload: ContestFile[]
): Action<ContestFile[]> {
  return { type: FETCH_CONTEST_FILES_SUCCESS, payload }
}

export function addContestFiles(
  payload: ContestFileCreate
): Action<ContestFileCreate> {
  return { type: ADD_CONTEST_FILES, payload }
}

export function addContestFilesFailure(): ActionWithoutPayload {
  return { type: ADD_CONTEST_FILES_FAILURE }
}

export function addContestFilesSuccess(
  payload: ContestFile[]
): Action<ContestFile[]> {
  return { type: ADD_CONTEST_FILES_SUCCESS, payload }
}

export function removeContestFiles(payload: string): Action<string> {
  return { type: REMOVE_CONTEST_FILES, payload }
}

export function removeContestFilesFailure(): ActionWithoutPayload {
  return { type: REMOVE_CONTEST_FILES_FAILURE }
}

export function removeContestFilesSuccess(payload: string): Action<string> {
  return { type: REMOVE_CONTEST_FILES_SUCCESS, payload }
}

export function updateContesFileNotes(
  payload: ContestFileUpdateNote
): Action<ContestFileUpdateNote> {
  return { type: UPDATE_CONTEST_FILE_NOTES, payload }
}

export function updateContesFileNotesFailure(): ActionWithoutPayload {
  return { type: UPDATE_CONTEST_FILE_NOTES_FAILURE }
}

export function updateContesFileNotesSuccess(
  payload: ContestFile[]
): Action<ContestFile[]> {
  return { type: UPDATE_CONTEST_FILE_NOTES_SUCCESS, payload }
}
