import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ReactComponent as PrintSVG } from 'assets/images/print.svg'

import { Breadcrumb, BreadcrumbItem } from 'components/breadcrumb'
import { Button } from 'components/button'
import { ContestProgressBar } from 'components/contestProgressBar'
import { PageLoading } from 'components/pageLoading'
import { Tabs } from 'components/tabs'
import { Tooltip } from 'components/tooltip'

import { status } from 'config/contest'
import { confirmationConfig } from 'config/swal'

import { withAdminLayout } from 'hoc/withAdminLayout'

import { browserHistory } from 'navigation/browserHistory'
import { CONTEST_LIST_PAGE } from 'navigation/paths'

import { printContest } from 'services/reports/contestReport'

import { GlobalState } from 'store'
import {
  getContestById,
  getContestActions,
  askToGiveUp,
  answerGiveUp
} from 'store/contest/actions'
import { fetchContestFiles } from 'store/contestFile/actions'
import { fetchContestMessages } from 'store/contestMessage/actions'
import { fetchContestAssociations } from 'store/contestAssociation/actions'
import { openModal } from 'store/modal/actions'

import { ContestFile } from 'types/contestFile/ContestFile'
import { ContestDropOutStatus } from 'types/enums/ContestDropOutStatus'
import { ContestMemberPermission } from 'types/enums/ContestMemberPermission'
import { ContestSide } from 'types/enums/ContestSide'
import { ContestStep } from 'types/enums/ContestStep'
import { ContestUpdateAction } from 'types/enums/ContestUpdateAction'
import { ModalName } from 'types/enums/ModalName'

import { formatDateBR } from 'utils/formatUtils'

import { AssociationsTab } from './components/associationsTab'
import { ContestAcceptModal } from './components/contestAcceptModal'
import { ContestAcceptAgreementModal } from './components/contestAcceptAgreementModal'
import { ContestAnswerModal } from './components/contestAnswerModal'
import { ContestPaymentModal } from './components/contestPaymentModal'
import { ContestRejoinderModal } from './components/contestRejoinderModal'
import { ContestReplicationModal } from './components/contestReplicationModal'
import { ContestSendAgreementModal } from './components/contestSendAgreementModal'
import { ContestSendNewAgreementModal } from './components/contestSendNewAgreementModal'
import { ContestSendSubscribersModal } from './components/contestSendSubscribersModal'
import { ContestSignaturesModal } from './components/contestSignaturesModal'
import { ContestReplyInstitutionModal } from './components/contestReplyInstitutionModal'
import { ContestTransferInstitutionModal } from './components/contestTransferInstitutionModal'
import { ContestTransferInstitutionStatusModal } from './components/contestTransferInstitutionStatusModal'
import { ContestResendStatus } from 'types/enums/ContestResendStatus'
import { FilesTab } from './components/filesTab'
import { MembersTab } from './components/membersTab'
import { MessagesTab } from './components/messagesTab'
import { TimelineTab } from './components/timelineTab'

import {
  ContestDetailsWrapper,
  PageTitle,
  PageSubTitle,
  ContestHeading
} from './styled'

function Page(): JSX.Element {
  const { t } = useTranslation(['pages', 'common'])

  const { contestId } = useParams<{ contestId: string }>()

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    contest: { contestData, getContestByIdInProgress, askToGiveUpInProgress },
    contestFile: { files },
    contestMessage: { messages },
    contestAssociation: { associations }
  } = useSelector((state: GlobalState) => state)

  const sessionUserSide = useMemo<ContestSide | undefined>(() => {
    const member = contestData?.members.find(
      (m) => m.user?.id === sessionUser?.id
    )
    return member?.side
  }, [contestData])

  const sessionUserPermission = useMemo<
    ContestMemberPermission | undefined
  >(() => {
    const member = contestData?.members.find(
      (m) => m.user?.id === sessionUser?.id
    )
    return member?.permission
  }, [contestData])

  const canTransferInstitution = useMemo(
    () =>
      sessionUserSide === ContestSide.DEFENDANT &&
      contestData?.userRelation !== 'NOMINATED' &&
      contestData?.currentStep === ContestStep.OPEN &&
      contestData?.nominate?.answerMessage !== 'ABORTED' &&
      typeof contestData?.nominate?.answer !== 'string' &&
      typeof contestData?.nominate?.targetInstitutionId !== 'number',
    [sessionUserSide, contestData]
  )

  const canReadRejectedTransfer = useMemo(
    () =>
      sessionUserSide === ContestSide.DEFENDANT &&
      contestData?.nominate?.answerMessage !== 'ABORTED' &&
      contestData?.nominate?.answer === 'false',
    [sessionUserSide, contestData]
  )

  const canReadPendingTransfer = useMemo(
    () =>
      sessionUserSide === ContestSide.DEFENDANT &&
      contestData?.currentStep === ContestStep.OPEN &&
      contestData?.nominate?.answerMessage !== 'ABORTED' &&
      contestData?.nominate?.answer === null &&
      typeof contestData?.nominate?.targetInstitutionId === 'number',
    [sessionUserSide, contestData]
  )

  const canGiveUp = useMemo<boolean>(() => {
    if (!contestData) return false

    if (sessionUserSide === ContestSide.DEFENDANT) {
      return false
    }

    if (contestData?.userRelation === 'NOMINATED') {
      return false
    }

    const notAllowedSteps = [
      ContestStep.INACTIVE,
      ContestStep.OPEN,
      ContestStep.CANCELED,
      ContestStep.ELIGIBLE_FOR_ARBITRATION,
      ContestStep.EXPIRED,
      ContestStep.ABORTED,
      ContestStep.FINISHED
    ]

    if (notAllowedSteps.includes(contestData?.currentStep)) {
      return false
    }

    if (contestData.dropout) {
      return false
    }

    return true
  }, [sessionUserSide, contestData])

  const canPayContest = useMemo<boolean>(() => {
    if (!contestData) return false

    if (sessionUserSide === ContestSide.REQUESTER) {
      return false
    }

    const resend = contestData.resend
    if (!resend) {
      return false
    }

    if (resend.status === ContestResendStatus.COMPLETED) {
      return false
    }

    return true
  }, [sessionUserSide, contestData])

  const hasGiveUpRequest = useMemo<boolean>(() => {
    if (!contestData) return false

    if (sessionUserSide === ContestSide.REQUESTER) {
      return false
    }

    if (contestData.dropout?.status !== ContestDropOutStatus.WAITING) {
      return false
    }

    return true
  }, [sessionUserSide, contestData])

  const [currentTab, setCurrentTab] = useState<number>(-1)

  const [filesToFocus, setFilesToFocus] = useState<ContestFile[]>([])

  useEffect(() => {
    if (contestId) {
      dispatch(getContestById(parseInt(contestId)))
      setCurrentTab(0)
    }
  }, [contestId])

  useEffect(() => {
    if (contestData) {
      const id = parseInt(contestId)

      dispatch(getContestActions(id))
      dispatch(fetchContestFiles(id))
      dispatch(fetchContestMessages(id))
      dispatch(fetchContestAssociations(id))

      if (hasGiveUpRequest) {
        withReactContent(SweetAlert)
          .fire({
            ...confirmationConfig,
            titleText: t('pages:contestDetails.answerGiveUp.title'),
            html: t('pages:contestDetails.answerGiveUp.message'),
            confirmButtonText: t(
              'pages:contestDetails.answerGiveUp.confirmButton'
            ),
            confirmButtonAriaLabel: t(
              'pages:contestDetails.answerGiveUp.confirmButton'
            ),
            showCancelButton: false,
            showDenyButton: true,
            denyButtonText: t('pages:contestDetails.answerGiveUp.cancelButton'),
            denyButtonAriaLabel: t(
              'pages:contestDetails.answerGiveUp.cancelButton'
            )
          })
          .then(({ isConfirmed, isDenied }) => {
            if (isConfirmed) {
              dispatch(
                answerGiveUp({
                  contestId: parseInt(contestId),
                  action: 'ACCEPT'
                })
              )
            } else if (isDenied) {
              dispatch(
                answerGiveUp({
                  contestId: parseInt(contestId),
                  action: 'DECLINE'
                })
              )
            }
          })
      }
    }
  }, [contestData])

  // checks if current user can access this contest
  useEffect(() => {
    if (!contestData) return

    const notAllowedSteps = [ContestStep.INACTIVE, ContestStep.WAITING_BOLETO]

    if (notAllowedSteps.includes(contestData?.currentStep)) {
      browserHistory.goBack()
    }
  }, [contestData])

  function _goToFilesTab(files: ContestFile[]) {
    setCurrentTab(4)
    setFilesToFocus(files)
  }

  function _giveUp() {
    withReactContent(SweetAlert)
      .fire({
        ...confirmationConfig,
        titleText: t('pages:contestDetails.confirmGiveUp.title'),
        html: t('pages:contestDetails.confirmGiveUp.message'),
        confirmButtonText: t(
          'pages:contestDetails.confirmGiveUp.confirmButton'
        ),
        cancelButtonText: t('pages:contestDetails.confirmGiveUp.cancelButton')
      })
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          dispatch(askToGiveUp(parseInt(contestId)))
        }
      })
  }

  function _hasAction(action: ContestUpdateAction): boolean {
    if (!contestData?.data.length) return false

    return contestData.data.findIndex((data) => data.action === action) >= 0
  }

  function _isOpenStep(): boolean {
    if (!contestData) return false

    const status: ContestStep[] = [
      ContestStep.INACTIVE,
      ContestStep.WAITING_BOLETO
    ]

    return status.includes(contestData?.currentStep)
  }

  function _isAnswerStep(): boolean {
    if (!contestData) return false

    const status: ContestStep[] = [ContestStep.OPEN, ContestStep.ANSWER]

    return status.includes(contestData?.currentStep)
  }

  function _isResolvedStep(): boolean {
    if (!contestData) return false

    const status: ContestStep[] = [
      ContestStep.AWAITING_DOCUMENT_D,
      ContestStep.AWAITING_DOCUMENT_R,
      ContestStep.RESOLVED_D,
      ContestStep.RESOLVED_R
    ]

    return status.includes(contestData?.currentStep)
  }

  function _isSignatureStep(): boolean {
    if (!contestData) return false

    const status: ContestStep[] = [
      ContestStep.AWAITING_SUBSCRIBERS,
      ContestStep.AWAITING_SUBSCRIBERS_R,
      ContestStep.AWAITING_SUBSCRIBERS_D,
      ContestStep.AWAITING_SIGNATURE
    ]

    return status.includes(contestData?.currentStep)
  }

  function _isFinishedStep(): boolean {
    if (!contestData) return false

    const finishedStatus: ContestStep[] = [
      ContestStep.ABORTED,
      ContestStep.CANCELED,
      // ContestStep.EXPIRED,
      ContestStep.FINISHED,
      ContestStep.RESOLVED_WITH_SIGNATURE,
      ContestStep.RESOLVED_WITHOUT_SIGNATURE
    ]

    return finishedStatus.includes(contestData?.currentStep)
  }

  document.title = `Disputa ${contestId} - Open Banking Brasil`

  return (
    <ContestDetailsWrapper>
      <Breadcrumb>
        <BreadcrumbItem
          to={CONTEST_LIST_PAGE}
          label={t('pages:contestDetails.breadcrumb.contest')}
        />
        <BreadcrumbItem
          label={t('pages:contestDetails.breadcrumb.contestDetails', {
            contestId
          })}
          isCurrentPage
        />
      </Breadcrumb>

      {getContestByIdInProgress ? (
        <PageLoading />
      ) : (
        contestData && (
          <>
            {sessionUserSide === ContestSide.REQUESTER && (
              <PageTitle>
                {contestData?.requester}
                <span>{t('common:contestSide.REQUESTER')}</span>

                <span>
                  <Tooltip content="Gerar PDF de impressão desta disputa">
                    <button
                      onClick={() =>
                        printContest(
                          {
                            userSide: sessionUserSide,
                            contest: contestData,
                            messages,
                            files,
                            associations
                          },
                          t
                        )
                      }
                    >
                      <PrintSVG />
                    </button>
                  </Tooltip>
                </span>
              </PageTitle>
            )}

            {sessionUserSide === ContestSide.DEFENDANT && (
              <PageTitle>
                {contestData?.defendant}
                <span>{t('common:contestSide.DEFENDANT')}</span>

                <span>
                  <Tooltip content="Gerar PDF de impressão desta disputa">
                    <button
                      onClick={() =>
                        printContest(
                          {
                            userSide: sessionUserSide,
                            contest: contestData,
                            messages,
                            files,
                            associations
                          },
                          t
                        )
                      }
                    >
                      <PrintSVG />
                    </button>
                  </Tooltip>
                </span>
              </PageTitle>
            )}

            <PageSubTitle>
              {t('pages:contestDetails.subTitle', { contestId })}
            </PageSubTitle>

            <ContestProgressBar
              steps={[
                {
                  label: t(`pages:contestDetails.steps.open`),
                  isActive: _isOpenStep(),
                  isCompleted:
                    _hasAction(ContestUpdateAction.PAYMENT) ||
                    _hasAction(ContestUpdateAction.PAYMENT_BOLETO)
                },
                {
                  label: t(`pages:contestDetails.steps.answer`),
                  isActive: _isAnswerStep(),
                  isCompleted: _hasAction(ContestUpdateAction.REPLY),
                  isSkiped:
                    !_hasAction(ContestUpdateAction.REPLY) &&
                    (_isResolvedStep() ||
                      _isSignatureStep() ||
                      _isFinishedStep()),
                  dashed: true
                },
                {
                  label: t(`pages:contestDetails.steps.replication`),
                  isActive: contestData.currentStep === ContestStep.REPLICATION,
                  isCompleted: _hasAction(ContestUpdateAction.REPLICATION),
                  isSkiped:
                    !_hasAction(ContestUpdateAction.REPLICATION) &&
                    (_isResolvedStep() ||
                      _isSignatureStep() ||
                      _isFinishedStep()),
                  dashed: true
                },
                {
                  label: t(`pages:contestDetails.steps.rejoinder`),
                  isActive: contestData.currentStep === ContestStep.REJOINDER,
                  isCompleted: _hasAction(ContestUpdateAction.REJOINDER),
                  isSkiped:
                    !_hasAction(ContestUpdateAction.REJOINDER) &&
                    (_isResolvedStep() ||
                      _isSignatureStep() ||
                      _isFinishedStep()),
                  dashed: true
                },
                {
                  label: t(`pages:contestDetails.steps.resolved`),
                  isActive: _isResolvedStep(),
                  isCompleted: _hasAction(ContestUpdateAction.ACCEPT),
                  isSkiped:
                    !_hasAction(ContestUpdateAction.ACCEPT) &&
                    _isFinishedStep(),
                  dashed: true
                },
                {
                  label: t(`pages:contestDetails.steps.signatures`),
                  isActive: _isSignatureStep(),
                  isCompleted: !!contestData.data.find(
                    (item) => item.step === ContestStep.RESOLVED_WITH_SIGNATURE
                  ),
                  isSkiped:
                    !contestData.data.find(
                      (item) =>
                        item.step === ContestStep.RESOLVED_WITH_SIGNATURE
                    ) && _isFinishedStep(),
                  // isSkiped:
                  //   !(
                  //     contestData.currentStep ===
                  //     ContestStep.RESOLVED_WITH_SIGNATURE
                  //   ) && _isFinishedStep(),
                  dashed: true
                },
                {
                  label: t(`pages:contestDetails.steps.finished`),
                  isActive: false,
                  isCompleted: _isFinishedStep(),
                  dashed: true
                }
              ]}
            />

            <ContestHeading>
              <dt>{t('pages:contestDetails.heading.requester')}</dt>
              <dd>{contestData.requester}</dd>

              <dt>{t('pages:contestDetails.heading.defendant')}</dt>
              <dd>{contestData.defendant}</dd>

              <dt>{t('pages:contestDetails.heading.category')}</dt>
              {!!contestData && contestData.category.length > 30 ? (
                <Tooltip content={contestData.category}>
                  <dd>{contestData.category.substr(0, 30) + '...'}</dd>
                </Tooltip>
              ) : (
                <dd>{contestData.category}</dd>
              )}

              <dt>{t('pages:contestDetails.heading.createdAt')}</dt>
              <dd>{formatDateBR(contestData.createdAt)}</dd>

              <dt>{t('pages:contestDetails.heading.currentStep')}</dt>
              <dd>
                <span data-status={status[contestData.currentStep]}>
                  {t(
                    `pages:contestDetails.heading.status.${
                      status[contestData.currentStep]
                    }`
                  )}
                </span>
              </dd>

              {canGiveUp && (
                <Button
                  type="button"
                  color="white"
                  backgroundColor="blush"
                  small
                  onClick={_giveUp}
                  inProgress={askToGiveUpInProgress}
                >
                  {t('pages:contestDetails.dropOut')}
                </Button>
              )}

              {canPayContest && (
                <Button
                  type="button"
                  color="white"
                  backgroundColor="blush"
                  small
                  onClick={() =>
                    dispatch(openModal(ModalName.CONTEST_RESEND_PAYMENT))
                  }
                >
                  {t('pages:contestDetails.payResend')}
                </Button>
              )}

              {canTransferInstitution && (
                <Button
                  type="button"
                  color="white"
                  backgroundColor="twilightBlue"
                  small
                  onClick={() =>
                    dispatch(openModal(ModalName.CONTEST_TRANSFER_INSTITUTION))
                  }
                >
                  {t('pages:contestDetails.tranferInstitution')}
                </Button>
              )}

              {!canTransferInstitution && canReadPendingTransfer && (
                <Button
                  type="button"
                  color="black"
                  backgroundColor="paleGrey"
                  small
                  onClick={() =>
                    dispatch(
                      openModal(ModalName.CONTEST_TRANSFER_INSTITUTION_STATUS)
                    )
                  }
                >
                  {t('pages:contestDetails.rejectedPendingInstitution')}
                </Button>
              )}

              {!canTransferInstitution && canReadRejectedTransfer && (
                <Button
                  type="button"
                  color="white"
                  backgroundColor="blush"
                  small
                  onClick={() =>
                    dispatch(
                      openModal(ModalName.CONTEST_TRANSFER_INSTITUTION_STATUS)
                    )
                  }
                >
                  {t('pages:contestDetails.rejectedTranferInstitution')}
                </Button>
              )}
            </ContestHeading>

            <Tabs
              tabs={[
                {
                  id: 'timeline',
                  title: t('pages:contestDetails.tabs.timeline.title'),
                  content: (
                    <TimelineTab
                      userSide={sessionUserSide}
                      userPermission={sessionUserPermission}
                      gotoFilesTab={_goToFilesTab}
                    />
                  )
                },
                {
                  id: 'messages',
                  title: t('pages:contestDetails.tabs.messages.title'),
                  marker:
                    messages.length > 0
                      ? messages.length.toString()
                      : undefined,
                  content: (
                    <MessagesTab
                      userSide={sessionUserSide}
                      userPermission={sessionUserPermission}
                    />
                  )
                },
                {
                  id: 'members',
                  title: t('pages:contestDetails.tabs.members.title'),
                  content: <MembersTab userPermission={sessionUserPermission} />
                },
                {
                  id: 'files',
                  title: t('pages:contestDetails.tabs.files.title'),
                  marker:
                    files.length > 0 ? files.length.toString() : undefined,
                  content: (
                    <FilesTab
                      userPermission={sessionUserPermission}
                      focusFiles={filesToFocus}
                    />
                  )
                },
                {
                  id: 'associations',
                  title: t('pages:contestDetails.tabs.associations.title'),
                  content: (
                    <AssociationsTab userPermission={sessionUserPermission} />
                  )
                }
              ]}
              currentTab={currentTab}
              onChange={(currentTab) => {
                setCurrentTab(currentTab)
                setFilesToFocus([])
              }}
            />

            <ContestAcceptModal />
            <ContestAcceptAgreementModal />
            <ContestAnswerModal />
            <ContestPaymentModal />
            <ContestRejoinderModal />
            <ContestReplicationModal />
            <ContestSendAgreementModal />
            <ContestSendNewAgreementModal />
            <ContestSendSubscribersModal />
            <ContestSignaturesModal />
            <ContestReplyInstitutionModal />
            <ContestTransferInstitutionModal />
            <ContestTransferInstitutionStatusModal />
          </>
        )
      )}
    </ContestDetailsWrapper>
  )
}

export const ContestDetailsPage = withAdminLayout(Page)
