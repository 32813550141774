import styled, { css, StyledProps } from 'styled-components'

import { Colors } from 'styles/Theme'

type Color = keyof Colors

type ButtonContainerProps = StyledProps<{
  color: Color
  backgroundColor: Color
  variant: string
  fullWidth: boolean
  showSplash: boolean
  small?: boolean
}>

export const ButtonContainer = styled.button<ButtonContainerProps>`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: ${(props) => (props.fullWidth ? '100%' : 'fit-content')};
  min-width: 160px;

  padding: ${({ theme, small }) =>
    `${theme.spacings[2.5]} ${small ? theme.spacings[8] : theme.spacings[24]}`};

  color: ${({ color, theme }) => theme.colors[color]};

  border: ${({ variant, color, theme }) => {
    if (variant === 'link' || variant === 'solid') {
      return 'none'
    }

    return `2px solid ${theme.colors[color]}`
  }};

  border-radius: 2rem;

  background-color: ${({ variant, backgroundColor, theme }) => {
    if (variant === 'link' || variant === 'outline') {
      return 'transparent'
    }

    return theme.colors[backgroundColor]
  }};

  ${({ showSplash }) =>
    showSplash
      ? css`
          background-color: red;
        `
      : null}
`
