import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { FilesControl } from 'components/filesControl'
import { Flex } from 'components/flex'
import { Form } from 'components/form'
import { Modal, ModalHeader } from 'components/modal'
import { Separator } from 'components/separator'
import { Tooltip } from 'components/tooltip'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'
import { addContestFiles } from 'store/contestFile/actions'

import { ModalName } from 'types/enums/ModalName'

import { formatDateBR } from 'utils/formatUtils'

import { ContestHeading, ModalBody, ModalHeaderContent } from './styled'

export function ContestAddAttachmentModal(): JSX.Element {
  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    modal: { contestAddAttachmentIsOpen },
    contest: { contestData },
    contestFile: { addContestFilesInProgress }
  } = useSelector((state: GlobalState) => state)

  const { contestId } = useParams<{ contestId: string }>()

  function _handleSubmit(data: FormikValues) {
    if (contestData?.id) {
      dispatch(
        addContestFiles({
          contestId: parseInt(contestId),
          documents: data.documents
        })
      )
    }
  }

  return (
    <Modal
      isOpen={contestAddAttachmentIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_ADD_ATTACHMENT))}
    >
      <ModalHeader separator={false}>
        <ModalHeaderContent>
          <h2>{t('contestAddAttachment.title')}</h2>
          <h4>{t('contestAddAttachment.subtitle', { contestId })}</h4>

          <ContestHeading>
            <dt>{t('contestAddAttachment.heading.requester')}</dt>
            <dd>{contestData?.requester}</dd>
            <dt>{t('contestAddAttachment.heading.defendant')}</dt>
            <dd>{contestData?.defendant}</dd>
            <dt>{t('contestAddAttachment.heading.category')}</dt>
            {!!contestData && contestData?.category.length > 30 ? (
              <Tooltip content={contestData?.category}>
                <dd>{contestData?.category.substr(0, 30) + '...'}</dd>
              </Tooltip>
            ) : (
              <dd>{contestData?.category}</dd>
            )}
            <dt>{t('contestAddAttachment.heading.createdAt')}</dt>
            <dd>{!!contestData && formatDateBR(contestData?.createdAt)}</dd>
          </ContestHeading>

          <Separator color="warmGreyThree" />
        </ModalHeaderContent>
      </ModalHeader>

      <ModalBody>
        <Form
          initialValues={{ documents: [] }}
          validationSchema={Yup.object().shape({
            documents: Yup.array().required('required').min(1, 'files.min')
          })}
          onSubmit={_handleSubmit}
        >
          <FilesControl name="documents" />

          <Flex justify="center">
            <Button type="submit" inProgress={addContestFilesInProgress}>
              {t('contestAddAttachment.saveButton')}
            </Button>
          </Flex>
        </Form>
      </ModalBody>
    </Modal>
  )
}
