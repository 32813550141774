import { FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { Form } from 'components/form'
import { Modal, ModalHeader } from 'components/modal'
import { Textarea } from 'components/textarea'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'

import { ModalName } from 'types/enums/ModalName'

import { showSuccess, showError } from 'utils/alertUtils'

import { replyTransferInstitution } from 'services/http/contestService'

import { ModalBody } from './styled'

export function ContestReplyInstitutionModal(): JSX.Element {
  const { t } = useTranslation(['modal', 'common'])

  const history = useHistory()
  const { contestId } = useParams<{ contestId: string }>()

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    modal: { contestReplyInstitutionIsOpen },
    contest: { contestData, nominationAnswer }
  } = useSelector((state: GlobalState) => state)

  const [isLoading, setIsLoading] = useState(false)

  const initialValues = {
    userId: sessionUser?.id,
    contestId,
    message: ''
  }

  const validationSchema = Yup.object().shape({
    message:
      nominationAnswer === 'ACCEPT'
        ? Yup.string()
        : Yup.string().required('required')
  })

  useEffect(() => {
    if (!contestReplyInstitutionIsOpen) {
      const resetForm = document.getElementById('resetForm')
      if (resetForm) resetForm.click()
    } else {
      setIsLoading(true)
      setIsLoading(false)
    }
  }, [contestReplyInstitutionIsOpen])

  async function _handleSubmit(data: FormikValues) {
    try {
      if (!contestData) return
      setIsLoading(true)
      await replyTransferInstitution(
        contestData.id,
        nominationAnswer === 'ACCEPT',
        data.message
      )
      showSuccess('replyContestInstitution')
      dispatch(closeModal(ModalName.CONTEST_REPLY_INSTITUTION))
      if (nominationAnswer === 'ACCEPT') {
        document?.location?.reload?.()
      } else {
        history.push('/contests')
      }
    } catch (err) {
      showError('replyContestInstitution', err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isOpen={contestReplyInstitutionIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_REPLY_INSTITUTION))}
    >
      <ModalHeader separator={false}>
        {nominationAnswer === 'ACCEPT' && (
          <h2>{t('modal:contestReplyInstitution.titleAccept')}</h2>
        )}
        {nominationAnswer === 'DECLINED' && (
          <h2>{t('modal:contestReplyInstitution.titleDecline')}</h2>
        )}
      </ModalHeader>

      <ModalBody>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => _handleSubmit(values)}
        >
          <p style={{ textAlign: 'center', marginBottom: '20px' }}>
            {nominationAnswer === 'ACCEPT' &&
              t('modal:contestReplyInstitution.acceptNotice')}
            {nominationAnswer === 'DECLINED' &&
              t('modal:contestReplyInstitution.declineNotice')}
          </p>

          {nominationAnswer === 'DECLINED' && (
            <Textarea
              name="message"
              placeholder={t('modal:contestReplyInstitution.form.message')}
              style={{
                minHeight: 160
              }}
            />
          )}

          {nominationAnswer === 'ACCEPT' && (
            <Button
              fullWidth
              small
              type="submit"
              backgroundColor="niceBlue"
              inProgress={isLoading}
            >
              {t('modal:contestReplyInstitution.form.SubmitAccept')}
            </Button>
          )}
          {nominationAnswer === 'DECLINED' && (
            <Button
              fullWidth
              small
              type="submit"
              backgroundColor="blush"
              inProgress={isLoading}
            >
              {t('modal:contestReplyInstitution.form.submitDecline')}
            </Button>
          )}
        </Form>
      </ModalBody>
    </Modal>
  )
}
