import { ActionWithoutPayload } from 'types/Action'

import { REDUCE_SIDEBAR, EXPAND_SIDEBAR, TOGGLE_SIDEBAR } from './actionTypes'

export function reduceSidebar(): ActionWithoutPayload {
  return { type: REDUCE_SIDEBAR }
}

export function expandSidebar(): ActionWithoutPayload {
  return { type: EXPAND_SIDEBAR }
}

export function toggleSidebar(): ActionWithoutPayload {
  return { type: TOGGLE_SIDEBAR }
}
