import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/button'
import { Loading } from 'components/loading'

import { GlobalState } from 'store'

import { WelcomeStepWrapper } from './styled'

type WelcomeStepProps = {
  goForward: () => void
}

export function WelcomeStep({ goForward }: WelcomeStepProps): JSX.Element {
  const { t } = useTranslation(['pages', 'common'])

  const { inviteInfo, fetchInviteInfoInProgress } = useSelector(
    (state: GlobalState) => state.user
  )

  return !fetchInviteInfoInProgress ? (
    <WelcomeStepWrapper>
      <p
        dangerouslySetInnerHTML={{
          __html: t('pages:subscriptionByInvite.welcomeStep.message', {
            name: inviteInfo?.email,
            institution: inviteInfo?.institution
          })
        }}
      />

      <Button onClick={goForward}>
        {t('pages:subscriptionByInvite.welcomeStep.nextButton')}
      </Button>
    </WelcomeStepWrapper>
  ) : (
    <Loading />
  )
}
