import { useTranslation } from 'react-i18next'
import { PulseLoader } from 'react-spinners'

import { Tooltip } from 'components/tooltip'

import { actionButtons } from 'config/contest'

import { ContestUpdateAction } from 'types/enums/ContestUpdateAction'

import { ContestActionButtonContainer } from './styled'

type Props = {
  tooltip?: string
  action: ContestUpdateAction
  payload?: unknown
  disabled?: boolean
}

export function ContestActionButton({
  tooltip = '',
  action,
  payload,
  disabled
}: Props): JSX.Element {
  const { t } = useTranslation('common')

  function _handleClick() {
    const { onClick } = actionButtons[action]
    if (onClick) {
      onClick(payload)
    }
  }

  return (
    <Tooltip content={tooltip}>
      <ContestActionButtonContainer
        type="button"
        color={actionButtons[action]?.color}
        backgroundColor={actionButtons[action]?.backgroundColor}
        disabled={disabled || actionButtons[action]?.inProgress}
        onClick={_handleClick}
      >
        {actionButtons[action]?.inProgress ? (
          <PulseLoader
            color={actionButtons[action]?.color}
            size="8px"
            margin="4px"
          />
        ) : (
          t(`contestActionButton.${action}`)
        )}
      </ContestActionButtonContainer>
    </Tooltip>
  )
}
