import { PoliciesContainer } from './styled'

export function PrivacyPolicy(): JSX.Element {
  return (
    <PoliciesContainer>
      <header>
        <h1>POLÍTICA DE PRIVACIDADE</h1>
        <time>22 de julho de 2021</time>
      </header>

      <p>
        Esta Política de Privacidade (&ldquo;<u>Política</u>&rdquo;), proposta
        pela estrutura inicial responsável pela governança do Open Banking
        (&ldquo;<u>Estrutura Inicial</u>&rdquo;)
        <a href="#note-1" id="note-1-ref" role="doc-noteref">
          <sup>1</sup>
        </a>
        , juntamente com os Termos de Uso (tratados ao longo desta Política),
        descreve (i) a forma como são tratados (conforme conceito de{' '}
        <em>tratamento</em> trazido pela Lei nº 13.709/2018) determinados dados
        pessoais (&ldquo;<u>Dados</u>&rdquo;), coletados dos usuários que
        acessam as funcionalidades da Plataforma de Resolução de Disputas do
        Open Banking (&ldquo;<u>Plataforma</u>&rdquo;), (ii) a forma como são
        tratados Dados usados por esses usuários na Plataforma, (iii) os termos
        e as condições aplicáveis a tal tratamento, em conformidade com as leis
        e regulamentações que se aplicam à Estrutura Inicial e suas atividades
        (&ldquo;<u>Legislação Aplicável</u>&rdquo;).
      </p>

      <p>
        O usuário deve ler atentamente os termos aqui apresentados e todos os
        outros avisos que possam aparecer em outras páginas da Plataforma,
        inclusive, os Termos de Uso.
      </p>

      <h2 data-title>Aceite do conteúdo desta Política</h2>
      <ul>
        <li>
          <p>
            O acesso e/ou qualquer outra forma de utilização da Plataforma
            equivalerá ao reconhecimento expresso de que o usuário leu,
            compreendeu e está integralmente de acordo com a presente Política,
            parte integrante dos Termos de Uso disponíveis em{' '}
            <a
              href="https://disputas.openbankingbrasil.org.br/documentos/Termo_de_Uso.pdf"
              target="_blank"
              rel="noreferrer"
            >
              [https://disputas.openbankingbrasil.org.br/documentos/Termo_de_Uso.pdf]
            </a>
            .
          </p>
        </li>
      </ul>

      <h2 data-title>Alterações e atualizações da Política</h2>
      <ul>
        <li>
          <p>
            Esta Política poderá ser modificada a qualquer momento, a critério
            da Estrutura Inicial, mediante a publicação na Plataforma de sua
            versão atualizada.
          </p>
        </li>
        <li>
          <p>
            Para a sua comodidade, a data da última revisão desta Política está
            disposta no topo desta página. Toda vez que o usuário acessar a
            Plataforma, deve ficar atento às novas atualizações desta Política,
            pois o seu acesso e uso à Plataforma estarão vinculados à última
            versão atualizada desta Política.
          </p>
        </li>
      </ul>

      <h2 data-title>O que se encontra nesta Política?</h2>
      <ul>
        <li>
          <p>
            Nesta Política, encontram-se as informações necessárias para
            entender: (i) quando e quais Dados serão tratados no contexto do
            acesso. Registro e uso dos usuários na Plataforma; (ii) a finalidade
            do tratamento de Dados realizado pela Estrutura Inicial; (iii) o
            prazo do tratamento dos Dados; e (iv) com quem serão compartilhados
            os Dados coletados na Plataforma, dentre outras informações
            importantes relacionadas ao tratamento dos Dados.
          </p>
        </li>
      </ul>

      <h2 data-title>4. Tratamento de Dados</h2>
      <ul>
        <li>
          <p>
            <u>Privacidade e segurança</u>
          </p>
          <ul>
            <li>
              <p>
                Nos termos da Legislação Aplicável e de acordo com o disposto
                nesta Política, a Estrutura Inicial protege os direitos de
                privacidade dos usuários que navegam, acessam ou de qualquer
                modo utilizam a Plataforma, independentemente de terem realizado
                seu cadastro ou não, bem como protege os direitos de privacidade
                dos titulares de Dados que têm os Dados tratados na Plataforma.
                Os Dados serão mantidos no mais estrito sigilo, não sendo
                vendidos, trocados e/ou divulgados a quaisquer terceiros, salvo
                nas hipóteses expressamente previstas nesta Política.
              </p>
            </li>
            <li>
              <p>
                Os Dados poderão ser usados na forma e para as finalidades
                previstas nesta Política, pela Estrutura Inicial por seus
                empregados, prepostos e contratados, bem como pelos empregados,
                prepostos e contratados das instituições participantes do Open
                Banking (&ldquo;
                <u>Participantes do Open Banking</u>&rdquo;) e das associações
                que compõem a Estrutura Inicial (&ldquo;<u>Associações</u>
                &rdquo;).
              </p>
            </li>
            <li>
              <p>
                É possível ter acesso ao rol de Participantes do Open Banking no
                Portal do cidadão, no endereço:{' '}
                <a
                  href="https://openbankingbrasil.org.br/quem-participa/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>https://openbankingbrasil.org.br/quem-participa/</u>
                </a>
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <u>
              Quais Dados são tratados, sua finalidade e sua forma de coleta
            </u>
          </p>
          <table>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '60%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr>
                <th>Dados tratados</th>
                <th>Finalidade do tratamento</th>
                <th>Forma de coleta</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center bg-gray">
                  Dados cadastrais do usuário da Plataforma, tais como nome
                  completo, número de inscrição de Cadastro de Pessoa Física
                  (CPF), endereço de e-mail, cargo, dados de acesso e endereço e
                  qual Participante representa
                </td>
                <td>
                  <ul>
                    <li>
                      Identificação do usuário e do Participante do Open Banking
                      para uso da Plataforma;
                    </li>
                    <li>
                      Gerenciamento do registro e dos acessos à Plataforma por
                      parte dos Participantes do Open Banking e de seus
                      representantes;
                    </li>
                    <li>Cumprimento de obrigações legais e regulatórias;</li>
                    <li>
                      Atendimento a requisições do Banco Central do Brasil
                      (&ldquo;BCB&rdquo;) e outras autoridades competentes;
                    </li>
                    <li>
                      Exercício regular de direitos da Estrutura Inicial, dos
                      Participantes do Open Banking e das Associações em
                      processos judiciais, administrativos e arbitrais;
                    </li>
                    <li>Atendimentos relacionados à Plataforma;</li>
                    <li>
                      Para garantir a segurança da Plataforma e dos usuários,
                      bem como para prevenção a fraudes e incidentes;
                    </li>
                    <li>
                      Para pleno funcionamento e melhorias da Plataforma, para
                      fins estatísticos, bem como para outros fins conforme o
                      legítimo interesse da Estrutura Inicial dentro do
                      ecossistema do Open Banking.
                    </li>
                  </ul>
                </td>
                <td className="center">Informado pelo usuário da Plataforma</td>
              </tr>
              <tr>
                <td className="center bg-gray">
                  Dados pessoais de clientes dos Participantes ou de terceiros
                  inseridos nos documentos das disputas na Plataforma
                </td>
                <td>
                  <ul>
                    <li>
                      Resolução das disputas entre Participantes do Open
                      Banking, por meio da Plataforma;
                    </li>
                    <li>Cumprimento de obrigações legais e regulatórias;</li>
                    <li>
                      Atendimento a requisições do Banco Central do Brasil
                      (&ldquo;BCB&rdquo;) e outras autoridades competentes;
                    </li>
                    <li>
                      Exercício regular de direitos da Estrutura Inicial, dos
                      Participantes do Open Banking e das Associações em
                      processos judiciais, administrativos e arbitrais.
                    </li>
                  </ul>
                </td>
                <td className="center">
                  Inserido nos documentos das disputas na Plataforma
                </td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>
              <p>
                As informações relativas aos tratamentos, realizados pelos
                Participantes, de Dados de clientes dos Participantes ou
                terceiros não realizados na Plataforma devem ser obtidas nas
                políticas de privacidade dos próprios Participantes.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <u>Compartilhamento dos Dados</u>
          </p>
          <ul>
            <li>
              <p>
                Em determinados casos, os quais encontram-se listados nesta
                Política, a Estrutura Inicial poderá compartilhar os Dados com/a
                terceiros, conforme listados abaixo:
              </p>
              <ol type="a">
                <li>
                  <p>
                    Fornecedores e/ou prestadores de serviços da Estrutura
                    Inicial, no Brasil ou no exterior, para viabilização do
                    cumprimento das finalidades de utilização dos Dados
                    previstas nesta Política, para a utilização da Plataforma e
                    o correto e seguro armazenamento dos Dados pela Estrutura
                    Inicial;
                  </p>
                </li>
                <li>
                  <p>
                    Autoridades governamentais, judiciárias ou o BCB que possam
                    nos obrigar a divulgar, em razão de Legislação Aplicável ou
                    por ordem administrativa e/ou judicial, a totalidade ou
                    qualquer parte dos Dados;
                  </p>
                </li>
                <li>
                  <p>
                    Quaisquer outros terceiros para quem a Estrutura Inicial
                    seja obrigada a compartilhar os Dados, devido a obrigações
                    previstas na Legislação Aplicável e/ou cuja divulgação seja
                    necessária ou recomendável para a devida, efetiva e
                    eficiente operação da Plataforma e suas funcionalidades; e
                  </p>
                </li>
                <li>
                  <p>
                    Outros Participantes envolvidos no conflito inserido na
                    Plataforma;
                  </p>
                </li>
                <li>
                  <p>
                    No caso de exercício regular de direitos em processos
                    administrativos, judiciais ou arbitrais.
                  </p>
                </li>
              </ol>
            </li>
          </ul>
        </li>
      </ul>

      <h2 data-title>Armazenamento dos Dados</h2>
      <ul>
        <li>
          <p>
            O usuário está ciente de que os Dados coletados serão armazenados no
            Brasil e/ou no exterior por meio de serviço de tecnologia de nuvem (
            <em>cloud</em>) contratado pela Estrutura Inicial junto a empresas
            de renome e que possuem todos os meios para garantir a
            confidencialidade e segurança adequadas dos Dados.
          </p>
        </li>
        <li>
          <p>
            Ao acessar e utilizar a Plataforma, os usuários têm ciência do
            armazenamento dos Dados no formato exposto nesta Política. A
            Estrutura Inicial ressalta que toma todos os cuidados razoavelmente
            necessários para garantir que os Dados sejam tratados de modo seguro
            e nos termos da Legislação Aplicável.
          </p>
        </li>
      </ul>

      <h2 data-title>Prazo de utilização dos Dados</h2>
      <ul>
        <li>
          <p>
            Os Dados serão tratados apenas durante o prazo necessário ao
            cumprimento das finalidades previstas nesta Política.
          </p>
        </li>
      </ul>

      <h2 data-title>Bases legais para tratamento dos Dados</h2>
      <ul>
        <li>
          <p>
            O tratamento dos Dados é fundamentado nas bases legais previstas na
            Legislação Aplicável, de acordo com a finalidade do tratamento,
            incluindo, mas não se limitando às seguintes hipóteses: (i) no
            eventual cumprimento de obrigações legais e regulatórias previstas
            na Legislação Aplicável; (ii) em nossos interesses legítimos (na
            extensão permitida pela Legislação Aplicável); e (iii) para o
            exercício regular de direitos em processos administrativos,
            judiciais ou arbitrais.
          </p>
        </li>
      </ul>

      <h2 data-title>Responsabilidades da Estrutura Inicial</h2>
      <ul>
        <li>
          <p>
            A Estrutura Inicial se compromete perante os usuários e aos
            titulares de Dados a realizar quaisquer tratamentos de Dados,
            inclusive a inserção de <em>cookies</em> em seu dispositivo, em
            conformidade com a presente Política e com a Legislação Aplicável,
            sempre por meio da adoção das melhores medidas de segurança.
          </p>
        </li>
        <li>
          <p>
            É necessário, porém, considerar que, em razão da vulnerabilidade da
            internet, a Estrutura Inicial não será responsável por invasões ou
            intercepções ilegais ou pela violação dos sistemas e base de dados
            por parte de pessoas não autorizadas, tampouco será responsável pela
            indevida utilização de informações obtidas por esses meios.
          </p>
        </li>
      </ul>

      <h2 data-title>Links para sites externos</h2>
      <ul>
        <li>
          <p>
            A Plataforma poderá conter links para outros sites externos de
            terceiros, bem como a Plataforma poderá conter links para
            compartilhamento em mídias sociais e profissionais (como LinkedIn,
            Facebook, Instagram, Twitter e Youtube, por exemplo) e/ou para
            processos de cadastro, autenticação e assinatura (como Docusign, por
            exemplo). Esses sites, mídias sociais/profissionais, plataformas
            e/ou ferramentas podem ter políticas de privacidade e termos de uso
            específicos e diferentes das aqui praticadas. Assim, as informações
            passadas pelo usuário a esses sites quando em visita ou quando
            completar transações se submetem às políticas de privacidade e de
            segurança próprias de obtenção e uso de dados, as quais constam dos
            respectivos sites, sem que caiba qualquer responsabilidade à
            Estrutura Inicial.
          </p>
        </li>
        <li>
          <p>
            Por isso, sempre leia as normas de relacionamento e política de
            privacidade específicas dentro do própria Plataforma do terceiro que
            o usuário estiver acessando ou que colete suas informações.
          </p>
        </li>
        <li>
          <p>
            A ESTRUTURA INICIAL NÃO SE RESPONSABILIZA PELA SEGURANÇA DAS
            INFORMAÇÕES DO USUÁRIO QUANDO ESTE ACESSAR SITES DE TERCEIROS. TAIS
            SITES PODEM POSSUIR SUAS PRÓPRIAS POLÍTICAS DE PRIVACIDADE QUANTO AO
            TRATAMENTO DE DADOS PESSOAIS.
          </p>
        </li>
      </ul>

      <h2 data-title>Mau uso da Plataforma pelo usuário</h2>
      <ul>
        <li>
          <p>
            Caso haja infração por parte do usuário, quebra de qualquer regra de
            segurança ou prática de atividade contrárias à presente Política,
            aos Termos de Uso ou à legislação vigente, que tenha o intuito de
            prejudicar ou causar dano ao funcionamento da Plataforma ou a
            terceiros, por exemplo, tentativas de invadir ou hackear a
            Plataforma, a Estrutura Inicial, para proteção e segurança do
            ambiente virtual da Plataforma e dos demais usuários, poderá adotar
            procedimentos para identificar o usuário, bem como coibir tais
            condutas, por meio medidas judiciais cabíveis, tanto na esfera penal
            como na cível.
          </p>
        </li>
      </ul>

      <h2 data-title>Direitos do usuário e canal de atendimento</h2>
      <ul>
        <li>
          <p>
            O usuário, na qualidade de titular dos Dados, tem direitos em
            relação a tais Dados previstos na Legislação Aplicável.
          </p>
          <ol type="a">
            <li>
              <p>
                <em>
                  <strong>
                    Confirmação da existência de tratamento de Dados
                  </strong>
                </em>
                : O usuário pode requisitar a confirmação da existência de
                tratamento de Dados de sua titularidade no contexto da
                utilização da Plataforma.
              </p>
            </li>
            <li>
              <p>
                <em>
                  <strong>Acesso aos Dados</strong>
                </em>
                : O usuário pode requisitar acesso aos Dados coletados e que
                estejam armazenados em razão do uso da Plataforma.
              </p>
            </li>
            <li>
              <p>
                <em>
                  <strong>
                    Correção de Dados incompletos, inexatos ou desatualizados
                  </strong>
                </em>
                : O usuário poderá, a qualquer momento, alterar, atualizar e
                editar os Dados tratados no contexto da utilização Site.
              </p>
            </li>
            <li>
              <p>
                <em>
                  <strong>Informações sobre uso compartilhado de Dados</strong>
                </em>
                : As informações sobre o compartilhamento de Dados encontram-se
                nesta Política.
              </p>
            </li>
            <li>
              <p>
                <em>
                  <strong>Eliminação dos Dados</strong>
                </em>
                : O usuário poderá solicitar a eliminação de Dados que tenham
                sido coletados em relação à Plataforma. A eliminação dos Dados
                somente poderá ser completa para Dados que não sejam necessários
                para a Estrutura Inicial cumprir com obrigações legais,
                contratuais, para exercício regular de direitos da Estrutura
                Inicial e nas demais hipóteses admitidas legalmente, inclusive
                para a própria operação e funcionamento da Plataforma.
              </p>
            </li>
            <li>
              <p>
                <em>
                  <strong>Revogação do consentimento</strong>
                </em>
                : Se houver tratamento de Dados com base em consentimento, o
                usuário poderá revogar o consentimento, a qualquer momento.
              </p>
            </li>
            <li>
              <p>
                <em>
                  <strong>Portabilidade e peticionamento</strong>
                </em>
                : A Autoridade Nacional de Proteção de Dados (ANPD) irá
                regulamentar o direito de portabilidade e peticionamento.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            Para questões relativas ao tratamento de dados, o usuário poderá
            enviar um e-mail para o canal de atendimento da Estrutura Inicial:{' '}
            <a href="mailto:privacidade@openbankingbr.org">
              privacidade@openbankingbr.org
            </a>
            . Esse e-mail será encaminhado diretamente para a Plataforma de
            Service Desk da Estrutura Inicial{' '}
            <a
              href="https://servicedesk.openbankingbrasil.org.br/servicePortal"
              target="_blank"
              rel="noreferrer"
            >
              https://servicedesk.openbankingbrasil.org.br/servicePortal
            </a>
            , onde será analisado para direcionamento à equipe da Estrutura
            Inicial que poderá auxiliar o usuário na resolução dessas questões.
          </p>
        </li>
        <li>
          <p>
            Encarregado para tratamento de dados responsável pelo canal de
            atendimento da Plataforma: Arthur Nogueira Siqueira Vieira.
          </p>
        </li>
      </ul>

      <h2 data-title>Lei aplicável e Jurisdição</h2>
      <ul>
        <li>
          <p>
            A presente Política será interpretada de acordo com as leis da
            República Federativa do Brasil, incluindo, portanto, a Legislação
            Aplicável. Fica eleito o Foro da Comarca da Capital do Estado de São
            Paulo para dirimir qualquer divergência oriunda da presente
            Política, com renúncia expressa a qualquer outro, por mais
            privilegiado que seja.
          </p>
        </li>
      </ul>

      <h2 data-title>Disposições gerais</h2>
      <ul>
        <li>
          <p>
            Se qualquer disposição desta Política se tornar inválida, ineficaz
            ou inexequível, tal disposição será considerada apartada das demais
            disposições, as quais serão mantidas válidas, eficazes e exequíveis.
          </p>
        </li>
        <li>
          <p>
            As seções dispostas nesta Política são apenas para efeitos de
            referência e de forma alguma definem, limitam, determinam a
            interpretação ou descrevem o âmbito ou extensão da respectiva seção.
          </p>
        </li>
        <li>
          <p>
            A tolerância ou omissão da Estrutura Inicial com relação a qualquer
            descumprimento desta Política pelo usuário ou outros não significa
            renúncia dos direitos da Estrutura Inicial de agir em relação a
            descumprimentos subsequentes ou similares.
          </p>
        </li>
      </ul>
      <span className="end-of-file">******</span>

      <hr />

      <section className="notes" role="doc-endnotes">
        <ol>
          <li id="note-1" role="doc-endnote">
            <p>
              A Estrutura Inicial é regulamentada pela Circular do Banco Central
              do Brasil (&ldquo;BCB&rdquo;) n.º 4.032, de 23 de junho de 2020,
              entre outras normas do BCB, sendo composta pelas seguintes
              associações: ABBC – Associação Brasileira de Bancos; ABCD –
              Associação Brasileira de Crédito Digital; ABECS – Associação
              Brasileira das Empresas de Cartões de Crédito e Serviços;
              ABFINTECHS – Associação Brasileira de Fintechs; ABIPAG –
              Associação Brasileira de Instituições de Pagamentos, ABRANET –
              Associação Brasileira de Internet; CAMARA-E.NET – Câmara
              Brasileira de Comércio Eletrônico; FEBRABAN – Federação Brasileira
              de Bancos e OCB - Organização das Cooperativas Brasileiras. Até a
              criação da estrutura definitiva responsável pela governança do
              Open Banking Brasil, a Estrutura Inicial é a controladora dos
              dados. Essa Estrutura Inicial é representada em nível
              administrativo pela empresa de consultoria Mirow&amp;Co,
              contratada para auxiliar nessa governança. Sem prejuízo, a tomada
              de decisão em relação ao controle dos dados fica a cargo da
              governança do Open Banking Brasil e deve seguir o previsto no
              Regimento Interno da Estrutura Inicial e na regulamentação em
              vigor.
              <a href="#note-1-ref" role="doc-backlink">
                ↩︎
              </a>
            </p>
          </li>
        </ol>
      </section>
    </PoliciesContainer>
  )
}
