import { FormikValues } from 'formik'
import { useEffect, useState, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import LoginImagePNG from 'assets/images/simbolo_open_finance.png'
import LogoPNG from 'assets/images/marca_colorida_open_finance.jpg'

import { Button } from 'components/button'
import { Form } from 'components/form'
import { PasswordInput } from 'components/passwordInput'
import { TextInput } from 'components/textInput'

import { useQuery } from 'hooks/useQuery'

import { browserHistory } from 'navigation/browserHistory'
import { DASHBOARD_PAGE } from 'navigation/paths'

import { getSSOUrl } from 'services/http/loginService'

import { GlobalState } from 'store'
import { login } from 'store/login/actions'
import { openModal } from 'store/modal/actions'

import { ModalName } from 'types/enums/ModalName'

import { ForgetPasswordModal } from './components/forgetPasswordModal'

import {
  LoginWrapper,
  ForgetPasswordLink,
  FormSection,
  LogoSection
} from './styled'
import { showError } from 'utils/alertUtils'

export function LoginPage(): JSX.Element {
  const { t } = useTranslation(['pages', 'messages'])

  const dispatch = useDispatch()

  const query = useQuery()

  const location = useLocation<{ error: string }>()

  const { loggedIn } = useSelector((state: GlobalState) => state.session)
  const { loginInProgress } = useSelector((state: GlobalState) => state.login)

  const [showLoginForm, setShowLoginForm] = useState<boolean>(false)

  const [getSSOUrlInProgress, setGetSSOUrlInProgress] = useState<boolean>(false)

  const initialValues = {
    login: '',
    password: ''
  }

  const validationSchema = Yup.object().shape({
    login: Yup.string().required('required'),
    password: Yup.string().required('required')
  })

  useEffect(() => {
    if (loggedIn) {
      if (query.get('redirectUrl')) {
        browserHistory.push(query.get('redirectUrl') || DASHBOARD_PAGE)
      } else {
        browserHistory.push(DASHBOARD_PAGE)
      }
    }
  }, [loggedIn])

  useEffect(() => {
    if (location?.state?.error) {
      showError('loginSSO', { code: location.state.error })
    }
  }, [location])

  function _handleSubmit(data: FormikValues) {
    dispatch(login({ login: data.login, password: data.password }))
  }

  async function _handleSSOButtonClick() {
    try {
      setGetSSOUrlInProgress(true)

      const response = await getSSOUrl()
      if (response) {
        if (query.get('redirectUrl')) {
          localStorage.setItem(
            'login:redirectUrl',
            query.get('redirectUrl') || DASHBOARD_PAGE
          )
        }

        localStorage.setItem('sso:state', response.state)
        window.location.href = response.authUrl
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      showError('login', error as any)
    } finally {
      setGetSSOUrlInProgress(false)
    }
  }

  document.title = 'Resolução de Disputas - Open Banking Brasil'

  return (
    <LoginWrapper>
      <FormSection>
        <header>
          <h2
            dangerouslySetInnerHTML={{
              __html: t('pages:login.welcomeMessage')
            }}
          />

          <img src={LogoPNG} alt="Open Finance" />
        </header>

        {showLoginForm && (
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => _handleSubmit(values)}
          >
            <TextInput
              name="login"
              placeholder={t('pages:login.form.login')}
              autoComplete="username"
              onInput={(event: KeyboardEvent<HTMLInputElement>) => {
                event.currentTarget.value = event.currentTarget.value.toLowerCase()
              }}
            />

            <PasswordInput
              name="password"
              placeholder={t('pages:login.form.password')}
              autoComplete="off"
              showStrength={false}
            />

            <Button
              tooltip={t('pages:login.form.submitButton')}
              type="submit"
              fullWidth
              inProgress={loginInProgress}
            >
              {t('pages:login.form.submitButton')}
            </Button>

            <ForgetPasswordLink
              type="button"
              onClick={() => dispatch(openModal(ModalName.FORGET_PASSWORD))}
            >
              {t('pages:login.forgetPassword')}
            </ForgetPasswordLink>

            <Button
              type="button"
              variant="link"
              color="niceBlue"
              small
              onClick={() => setShowLoginForm(false)}
            >
              Cancelar
            </Button>
          </Form>
        )}

        {!showLoginForm && (
          <section className="buttons">
            <p>Realizar o acesso utilizando credenciais do Diretório</p>

            <Button
              type="button"
              small
              fullWidth
              inProgress={getSSOUrlInProgress}
              onClick={_handleSSOButtonClick}
            >
              Acessar com o Diretório
            </Button>

            <p>ou</p>

            {/* <Button
              type="button"
              variant="outline"
              color="niceBlue"
              small
              fullWidth
              onClick={() => setShowLoginForm(true)}
            >
              Acessar com usuário local
            </Button> */}

            <p>
              Realizar o acesso utilizando credenciais geradas na plataforma de
              disputas
            </p>

            <Button
              type="button"
              variant="outline"
              color="petrol"
              small
              fullWidth
              onClick={() => setShowLoginForm(true)}
            >
              Acessar com usuário local
            </Button>
          </section>
        )}
      </FormSection>

      <LogoSection>
        <img src={LoginImagePNG} alt="Open Banking Brasil" />
      </LogoSection>

      <ForgetPasswordModal />
    </LoginWrapper>
  )
}
