import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { Flex } from 'components/flex'
import { Form } from 'components/form'
import { PasswordInput } from 'components/passwordInput'

import { withAdminLayout } from 'hoc/withAdminLayout'

import { browserHistory } from 'navigation/browserHistory'

import { GlobalState } from 'store'
import { updatePassword } from 'store/user/actions'

import { passwordRegex } from 'utils/regexUtils'

import { MainContainer } from './styled'
import { BackButton } from 'components/backButton'

function UpdatePassword(): JSX.Element {
  const { t } = useTranslation(['pages'])

  const dispatch = useDispatch()

  const { updatePasswordInProgress } = useSelector(
    (state: GlobalState) => state.user
  )

  const initialValues = {
    currentPassword: '',
    password: '',
    passwordConfirmation: ''
  }

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('required'),
    password: Yup.string()
      .matches(passwordRegex, 'password.rules')
      .required('required'),
    passwordConfirmation: Yup.string()
      .required('required')
      .oneOf([Yup.ref('password')], 'passwordMatch')
  })

  function _resetPassword(data: FormikValues) {
    dispatch(
      updatePassword({
        password: data.password
      })
    )
  }

  document.title = 'Configurações - Open Banking Brasil'

  return (
    <>
      <h4>{t('pages:updatePassword.title')}</h4>

      <MainContainer>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => _resetPassword(values)}
        >
          <Flex direction="column" align="center">
            <PasswordInput
              name="currentPassword"
              placeholder={t('pages:updatePassword.form.currentPassword')}
              autoComplete="off"
              showStrength={false}
            />

            <PasswordInput
              name="password"
              placeholder={t('pages:updatePassword.form.password')}
              autoComplete="new-password"
            />

            <PasswordInput
              name="passwordConfirmation"
              placeholder={t('pages:updatePassword.form.passwordConfirmation')}
              autoComplete="new-password"
              showStrength={false}
            />

            <Button type="submit" inProgress={updatePasswordInProgress}>
              {t('pages:updatePassword.form.submitButton')}
            </Button>

            <BackButton onClick={() => browserHistory.goBack()} />
          </Flex>
        </Form>
      </MainContainer>
    </>
  )
}

export const UpdatePasswordPage = withAdminLayout(UpdatePassword)
