import { animated } from 'react-spring'
import styled from 'styled-components'

export const PopoverContainer = styled(animated.div)`
  position: relative;

  border-radius: 1.25rem;
  border: 1px solid ${({ theme }) => theme.colors.warmGreyThree};

  background-color: ${({ theme }) => theme.colors.white};

  padding: ${({ theme }) => theme.spacings[4]};

  &[data-placement^='top'] > #arrow {
    bottom: ${({ theme }) => `calc(${theme.fontSizes.sm} / -2)`};

    ::before {
      border-top-color: transparent;
      border-left-color: transparent;
    }
  }

  &[data-placement^='bottom'] > #arrow {
    top: ${({ theme }) => `calc(${theme.fontSizes.sm} / -2)`};

    ::before {
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
  }

  &[data-placement^='left'] > #arrow {
    right: ${({ theme }) => `calc(${theme.fontSizes.sm} / -2)`};

    ::before {
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  }

  &[data-placement^='right'] > #arrow {
    left: ${({ theme }) => `calc(${theme.fontSizes.sm} / -2)`};

    &::before {
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }
`

export const Arrow = styled.div`
  position: absolute;
  width: ${({ theme }) => theme.fontSizes.sm};
  height: ${({ theme }) => theme.fontSizes.sm};
  background: inherit;
  visibility: hidden;

  ::before {
    z-index: ${({ theme }) => theme.zIndex.popover - 1};
    position: absolute;
    border: 1px solid ${({ theme }) => theme.colors.warmGreyThree};
    width: ${({ theme }) => theme.fontSizes.sm};
    height: ${({ theme }) => theme.fontSizes.sm};
    background: inherit;
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`
