import styled from 'styled-components'

import Theme from 'styles/Theme'

const marginBottom = Theme.spacings[4]

const listMargin = Theme.spacings[12]

export const PoliciesContainer = styled.section`
  counter-reset: title;

  padding: ${({ theme }) => `${theme.spacings[2]} ${theme.spacings[12]}`};

  header {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: ${marginBottom};

    h1 {
      font-size: ${({ theme }) => theme.fontSizes.xmd};
      margin-bottom: 0;
    }

    time {
      font-weight: bold;
      font-style: normal;
    }
  }

  p {
    text-align: justify;
    margin-bottom: ${marginBottom};
  }

  a {
    color: ${({ theme }) => theme.colors.petrol};

    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSizes.md};
    margin-bottom: ${marginBottom};
  }

  h2[data-title] {
    &::before {
      content: counter(title) '.';
      counter-increment: title;
      margin-right: ${listMargin};
    }
  }

  h2[data-title] + ul {
    counter-reset: listitem;

    list-style: none;
    margin-left: ${listMargin};

    & > li {
      position: relative;

      display: flex;
      flex-direction: column;

      p {
        margin-left: calc(${listMargin} * 1.5);
      }

      &::before {
        content: counter(title) '.' counter(listitem) '.';
        counter-increment: listitem;

        position: absolute;
        left: 0;
      }

      & > ul {
        counter-reset: listitemchild;

        list-style: none;
        margin-left: ${listMargin};

        & > li {
          position: relative;

          display: flex;
          flex-direction: column;

          p {
            margin-left: calc(${listMargin} * 1.5);
          }

          &::before {
            content: counter(title) '.' counter(listitem) '.'
              counter(listitemchild) '.';
            counter-increment: listitemchild;

            position: absolute;
            left: 0;
          }
        }
      }

      ol {
        margin-left: ${listMargin};

        & > li {
          position: relative;

          p {
            margin-left: calc(${listMargin} * 1.5);
          }
        }
      }

      table {
        border: 1px solid;
        border-collapse: collapse;

        /* margin-left: calc(${listMargin} * 1.5); */
        margin-left: calc(${listMargin});
        margin-bottom: ${marginBottom};

        th,
        td {
          border: 1px solid;
        }

        thead {
          tr {
            background-color: #ddd;

            th {
              padding: ${({ theme }) => `${theme.spacings[3]} 0`};
              vertical-align: top;
              text-align: center;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: ${({ theme }) =>
                `${theme.spacings[2]} ${theme.spacings[4]}`};
              vertical-align: top;
            }

            td.center {
              text-align: center;
            }

            td.bg-gray {
              background-color: #fafafa;
            }

            td {
              ul {
                margin-left: calc(${listMargin} / 2);
                list-style-type: disc;

                li {
                  margin-bottom: calc(${marginBottom} / 4);
                }
              }
            }
          }
        }
      }
    }
  }

  span.end-of-file {
    display: block;
    margin-bottom: ${marginBottom};
    font-weight: bold;
    text-align: center;
  }

  section.notes {
    padding-left: 16px;
    font-size: 80%;
  }
`
