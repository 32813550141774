import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import * as contestAssociationService from 'services/http/contestAssociationService'

import { closeModal } from 'store/modal/actions'
import {
  fetchContestAssociations,
  fetchContestAssociationsFailure,
  fetchContestAssociationsSuccess,
  createContestAssociationFailure,
  createContestAssociationSuccess,
  removeContestAssociationFailure,
  removeContestAssociationSuccess
} from 'store/contestAssociation/actions'
import {
  FETCH_CONTEST_ASSOCIATIONS,
  CREATE_CONTEST_ASSOCIATION,
  REMOVE_CONTEST_ASSOCIATION
} from 'store/contestAssociation/actionTypes'

import { Action } from 'types/Action'
import { ContestAssociation } from 'types/contestAssociation/ContestAssociation'
import { ContestAssociationCreate } from 'types/contestAssociation/ContestAssociationCreate'
import { ContestAssociationRemove } from 'types/contestAssociation/ContestAssociationRemove'
import { ModalName } from 'types/enums/ModalName'

import { showError, showSuccess } from 'utils/alertUtils'

function* fetchContestAssociationRequest({
  payload
}: Action<number>): Generator<StrictEffect, void, ContestAssociation[]> {
  try {
    const response = yield call(
      contestAssociationService.fetchContestAssociations,
      payload
    )
    yield put(fetchContestAssociationsSuccess(response))
  } catch (error) {
    yield call(showError, 'fetchContestAssociations', error)
    yield put(fetchContestAssociationsFailure())
  }
}

function* createContestAssociationRequest({
  payload
}: Action<ContestAssociationCreate>): Generator<StrictEffect, void> {
  try {
    yield call(contestAssociationService.createContestAssociation, payload)
    yield call(showSuccess, 'createContestAssociation')
    yield put(closeModal(ModalName.CONTEST_ADD_ASSOCIATION))
    yield put(fetchContestAssociations(payload.contestA))
    yield put(createContestAssociationSuccess())
  } catch (error) {
    yield call(showError, 'createContestAssociation', error)
    yield put(createContestAssociationFailure())
  }
}

function* removeContestAssociationRequest({
  payload
}: Action<ContestAssociationRemove>): Generator<StrictEffect, void> {
  try {
    yield call(contestAssociationService.removeContestAssociation, payload)
    yield call(showSuccess, 'removeContestAssociation')
    yield put(fetchContestAssociations(payload.contestA))
    yield put(removeContestAssociationSuccess())
  } catch (error) {
    yield call(showError, 'removeContestAssociation', error)
    yield put(removeContestAssociationFailure())
  }
}

export function* contestAssociationSagas(): Generator {
  yield all([
    takeLatest(FETCH_CONTEST_ASSOCIATIONS, fetchContestAssociationRequest),
    takeLatest(CREATE_CONTEST_ASSOCIATION, createContestAssociationRequest),
    takeLatest(REMOVE_CONTEST_ASSOCIATION, removeContestAssociationRequest)
  ])
}
