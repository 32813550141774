import { FormikValues } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { AuthHeader } from 'components/authHeader'
import { Button } from 'components/button'
import { FieldSet } from 'components/fieldSet'
import { Flex, FlexItem } from 'components/flex'
import { Form } from 'components/form'
import { MaskedInput } from 'components/maskedInput'
import { PageLoading } from 'components/pageLoading'
import { PasswordInput } from 'components/passwordInput'
import { TextInput } from 'components/textInput'

import { browserHistory } from 'navigation/browserHistory'
import { DASHBOARD_PAGE } from 'navigation/paths'

import { GlobalState } from 'store'
import { getInviteInfo, completeSignUp } from 'store/directory/actions'

import { passwordRegex, usernameRegex, fullNameRegex } from 'utils/regexUtils'
import { validateCPF } from 'utils/validationUtils'

import { DirectorySignUpWrapper } from './styled'

export function DirectorySignUpPage(): JSX.Element {
  const { t } = useTranslation(['pages'])

  const { inviteId } = useParams<{ inviteId: string }>()

  const dispatch = useDispatch()

  const {
    session: { loggedIn },
    directory: { inviteInfo, getInviteInfoInProgress, completeSignUpInProgress }
  } = useSelector((state: GlobalState) => state)

  const initialValues = {
    username: '',
    name: '',
    lastname: '',
    cpf: '',
    email: '',
    password: '',
    passwordConfirmation: ''
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .matches(usernameRegex, 'username')
      .required('required'),
    name: Yup.string().matches(fullNameRegex, 'fullName').required('required'),
    cpf: Yup.string()
      .test('cpf', 'cpf', (value) => validateCPF(value ?? ''))
      .required('required'),
    password: Yup.string()
      .matches(passwordRegex, 'password.rules')
      .required('required'),
    passwordConfirmation: Yup.string()
      .required('required')
      .oneOf([Yup.ref('password')], 'passwordMatch')
  })

  useEffect(() => {
    if (loggedIn) {
      browserHistory.push(DASHBOARD_PAGE)
    } else {
      dispatch(getInviteInfo(inviteId))
    }
  }, [])

  useEffect(() => {
    if (inviteInfo) {
      initialValues.email = inviteInfo.email
    }
  }, [inviteInfo])

  function _handleSubmit(data: FormikValues) {
    const name = data.name.split(' ')[0] ?? ''
    const lastname = data.name.replace(name, '').trim()

    dispatch(
      completeSignUp({
        key: inviteId,
        username: data.username,
        name,
        lastname,
        cpf: data.cpf,
        password: data.password
      })
    )
  }

  document.title = 'Resolução de Disputas - Open Banking Brasil'

  return (
    <DirectorySignUpWrapper>
      <AuthHeader />
      <main>
        <h4>{t('pages:directorySignUp.title')}</h4>

        {getInviteInfoInProgress ? (
          <PageLoading />
        ) : (
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={_handleSubmit}
          >
            <FieldSet title={t('pages:directorySignUp.form.userGroup')}>
              <Flex direction="row" gap={6}>
                <FlexItem grow="8" basis="0">
                  <TextInput
                    name="name"
                    placeholder={t('pages:directorySignUp.form.name')}
                  />
                </FlexItem>
                <FlexItem grow="4" basis="0">
                  <MaskedInput
                    name="cpf"
                    placeholder={t('pages:directorySignUp.form.cpf')}
                    options={{
                      blocks: [3, 3, 3, 2],
                      delimiters: ['.', '.', '-'],
                      numericOnly: true
                    }}
                  />
                </FlexItem>
              </Flex>
              <TextInput
                name="email"
                placeholder={t('pages:directorySignUp.form.email')}
                readOnly
              />
            </FieldSet>

            <FieldSet title={t('pages:directorySignUp.form.authGroup')}>
              <Flex direction="row" gap={6}>
                <FlexItem grow="6" basis="0">
                  <TextInput
                    name="username"
                    placeholder={t('pages:directorySignUp.form.username')}
                  />
                </FlexItem>
                <FlexItem grow="6" basis="0" />
              </Flex>
              <Flex direction="row" gap={6}>
                <FlexItem grow="6" basis="0">
                  <PasswordInput
                    name="password"
                    placeholder={t('pages:directorySignUp.form.password')}
                    autoComplete="new-password"
                  />
                </FlexItem>
                <FlexItem grow="6" basis="0">
                  <PasswordInput
                    name="passwordConfirmation"
                    placeholder={t(
                      'pages:directorySignUp.form.passwordConfirmation'
                    )}
                    autoComplete="new-password"
                    showStrength={false}
                  />
                </FlexItem>
              </Flex>
            </FieldSet>

            <Button type="submit" inProgress={completeSignUpInProgress}>
              {t('pages:directorySignUp.submitButton')}
            </Button>
          </Form>
        )}
      </main>
    </DirectorySignUpWrapper>
  )
}
