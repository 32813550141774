import styled from 'styled-components'

export const ResetPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  overflow-y: auto;

  main {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin: 0 auto;
    padding: ${({ theme }) => `${theme.spacings[10]} 0`};

    width: 70%;

    form {
      margin: auto;

      width: 50%;
      max-width: 480px;

      & > button {
        margin-top: ${({ theme }) => theme.spacings[10]};
      }
    }
  }
`
