import { Pie } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import Theme from 'styles/Theme'

import { ContestSide } from 'types/enums/ContestSide'

import { ChartWrapper } from './styled'

type Item = {
  currentStep: string
  count: number
}

type Props = {
  side: ContestSide
  data: Item[]
}

export function ContestByStatus({ side, data }: Props): JSX.Element {
  const { t } = useTranslation('common')

  return (
    <ChartWrapper>
      <Pie
        options={
          {
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: t(`contestSide.${side}`)
              },
              legend: {
                position: 'bottom'
              },
              colorschemes: {
                scheme: 'brewer.Paired12'
              }
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any
        }
        data={{
          labels: data.map(({ currentStep }) =>
            t(`contestStep.${currentStep}`)
          ),
          datasets: [
            {
              data: data.map(({ count }) => count),
              backgroundColor: [
                Theme.colors.niceBlue,
                Theme.colors.blush,
                Theme.colors.darkAqua,
                Theme.colors.waterBlue,
                Theme.colors.lightTealTwo,
                Theme.colors.fadedOrange,
                Theme.colors.deepSeaBlue,
                Theme.colors.oceanGreen,
                Theme.colors.sandy,
                Theme.colors.viridian,
                Theme.colors.sandyFive,
                Theme.colors.salmon
              ]
            }
          ]
        }}
      />
    </ChartWrapper>
  )
}
