export const maxSize = 30000000 // 30MB

export const allowedExtensions: string[] = [
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.docx',
  '.doc',
  '.xlsx',
  '.xls',
  '.pptx',
  '.ppt',
  '.csv',
  '.tsv',
  '.ods',
  '.pdf',
  '.txt',
  '.log',
  '.xml',
  '.xps',
  '.mp3',
  '.wma',
  '.aac',
  '.dot',
  '.dotx',
  '.msg',
  '.eml',
  '.mht',
  '.mhtml',
  '.pps',
  '.ppsx',
  '.tif',
  '.tiff',
  '.rtf',
  '.odt'
]
