import { ContestStep } from 'types/enums/ContestStep'

export const status = {
  onGoing:
    'OPEN, ANSWER, REPLICATION, REJOINDER, AWAITING SIGNATURE, WAITING_BOLETO, RESOLVED R, RESOLVED D, AWAITING DOCUMENT R, AWAITING DOCUMENT D, AWAITING SUBSCRIBERS, INACTIVE, AWAITING SUBSCRIBERS D, AWAITING SUBSCRIBERS R',
  finished:
    'ELIGIBLE FOR ARBITRATION, EXPIRED, ABORTED, RESOLVED_WITH_SIGNATURE, RESOLVED_WITHOUT_SIGNATURE, FINISHED, CANCELED'
}

export const contestStatus = {
  [ContestStep.INACTIVE]: ContestStep.INACTIVE,
  [ContestStep.WAITING_BOLETO]: ContestStep.INACTIVE,

  [ContestStep.OPEN]: ContestStep.OPEN,
  [ContestStep.ANSWER]: ContestStep.ANSWER,
  [ContestStep.REPLICATION]: ContestStep.REPLICATION,
  [ContestStep.REJOINDER]: ContestStep.REJOINDER,

  [ContestStep.RESOLVED_R]: ContestStep.RESOLVED_R,
  [ContestStep.RESOLVED_D]: ContestStep.RESOLVED_R,

  [ContestStep.AWAITING_DOCUMENT_R]: ContestStep.AWAITING_DOCUMENT_R,
  [ContestStep.AWAITING_DOCUMENT_D]: ContestStep.AWAITING_DOCUMENT_R,

  [ContestStep.AWAITING_SUBSCRIBERS]: ContestStep.AWAITING_SUBSCRIBERS,
  [ContestStep.AWAITING_SUBSCRIBERS_R]: ContestStep.AWAITING_SUBSCRIBERS,
  [ContestStep.AWAITING_SUBSCRIBERS_D]: ContestStep.AWAITING_SUBSCRIBERS,

  [ContestStep.AWAITING_SIGNATURE]: ContestStep.AWAITING_SIGNATURE,

  [ContestStep.ELIGIBLE_FOR_ARBITRATION]: ContestStep.ELIGIBLE_FOR_ARBITRATION,

  [ContestStep.EXPIRED]: ContestStep.EXPIRED,

  [ContestStep.CANCELED]: ContestStep.CANCELED,
  [ContestStep.ABORTED]: ContestStep.CANCELED,

  [ContestStep.FINISHED]: ContestStep.FINISHED,
  [ContestStep.RESOLVED_WITH_SIGNATURE]: ContestStep.FINISHED,
  [ContestStep.RESOLVED_WITHOUT_SIGNATURE]: ContestStep.FINISHED
}
