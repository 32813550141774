import { useField } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SmallArrowSVG } from 'assets/images/small-arrow-left.svg'
import { ReactComponent as SearchSVG } from 'assets/images/search.svg'

import { SelectOption } from 'types/SelectOption'

import { SelectWrapper, SelectContainer, Menu, SelectInput } from './styled'

type SelectProps = {
  name: string
  placeholder: string
  options: SelectOption[]
  onChange?: (value: string | number) => void
  [key: string]: unknown
}

export function Select({
  name,
  placeholder,
  options,
  onChange,
  ...props
}: SelectProps): JSX.Element {
  const { t } = useTranslation(['validation', 'common'])

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [value, setValue] = useState<SelectOption | undefined>(undefined)

  const [field, meta, helpers] = useField({ name, type: 'select' })

  useEffect(() => {
    if (field.value) {
      const selected = options.find((option) => option.value === field.value)
      if (selected && selected !== value) {
        setValue(selected)
      }
    } else {
      setValue(undefined)
    }
  }, [field.value])

  function _handleFocus() {
    setIsOpen(true)
  }

  function _handleBlur() {
    helpers.setTouched(true)
    setIsOpen(false)
  }

  function _handleChange(option: SelectOption) {
    helpers.setValue(option.value)
    setValue(option)
    setIsOpen(false)

    if (onChange) {
      onChange(option.value)
    }
  }

  return (
    <SelectWrapper>
      <SelectContainer isOpen={isOpen} hasError={meta.touched && !!meta.error}>
        <button
          name={name}
          type="button"
          onClick={() => setIsOpen(isOpen)}
          onFocus={_handleFocus}
          disabled={!!props.isDisabled || !!props.isLoading}
        >
          {value ? <span>{value.label}</span> : placeholder}
          <SmallArrowSVG />
        </button>

        {isOpen && (
          <Menu>
            <SelectInput
              classNamePrefix="react-select"
              components={{ DropdownIndicator: SearchSVG }}
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              isSearchable
              autoFocus
              menuIsOpen={isOpen}
              menuPlacement="auto"
              maxMenuHeight={240}
              onBlur={_handleBlur}
              onChange={_handleChange}
              value={value}
              options={options}
              placeholder={placeholder}
              tabSelectsValue={false}
              noOptionsMessage={() => t('common:select.noOptionsMessage')}
              loadingMessage={() => t('common:select.loadingMessage')}
              {...props}
            />
          </Menu>
        )}
      </SelectContainer>
      {meta.touched && !!meta.error && (
        <p>
          {t(`validation:${meta.error}`, {
            field: placeholder
          })}
        </p>
      )}
    </SelectWrapper>
  )
}
