import { Documents } from 'components/documents'
import { Admin } from 'components/admin'
import { Header } from 'components/header'
import { Main } from 'components/main'
import { Notifications } from 'components/notifications'
import { Sidebar } from 'components/sidebar'

import styled from 'styled-components'

const PageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;
`

export function withAdminLayout(
  PageContent: () => JSX.Element
): () => JSX.Element {
  return function Page(): JSX.Element {
    return (
      <PageWrapper>
        <Header />
        <Sidebar />
        <Main>
          <PageContent />
        </Main>
        <Admin />
        <Documents />
        <Notifications />
      </PageWrapper>
    )
  }
}
