import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import * as precedentsService from 'services/http/precedentsService'

import { closeModal } from 'store/modal/actions'
import {
  fetchPrecedentsFailure,
  fetchPrecedentsSuccess,
  createPrecedentFailure,
  createPrecedentSuccess
} from 'store/precedent/actions'
import { FETCH_PRECEDENTS, CREATE_PRECEDENT } from 'store/precedent/actionTypes'

import { Action } from 'types/Action'
import { ModalName } from 'types/enums/ModalName'
import { PagedResult } from 'types/PagedResult'
import { Precedent } from 'types/precedent/Precedent'
import { PrecedentCreate } from 'types/precedent/PrecedentCreate'
import { PrecedentsQuery } from 'types/precedent/PrecedentsQuery'

import { showError, showSuccess } from 'utils/alertUtils'

function* fetchPrecedentsRequest({
  payload
}: Action<PrecedentsQuery>): Generator<
  StrictEffect,
  void,
  PagedResult<Precedent>
> {
  try {
    const response = yield call(precedentsService.fetchPrecedents, payload)
    yield put(fetchPrecedentsSuccess(response))
  } catch (error) {
    yield call(showError, 'fetchPrecedents', error)
    yield put(fetchPrecedentsFailure())
  }
}

function* createPrecedentRequest({
  payload
}: Action<PrecedentCreate>): Generator<StrictEffect, void, Precedent> {
  try {
    const response = yield call(precedentsService.createPrecedent, payload)
    yield call(showSuccess, 'createPrecedent')
    yield put(closeModal(ModalName.PRECEDENT_CREATE))
    yield put(createPrecedentSuccess(response))
  } catch (error) {
    yield call(showError, 'createPrecedent', error)
    yield put(createPrecedentFailure())
  }
}

export function* precedentSagas(): Generator {
  yield all([
    takeLatest(FETCH_PRECEDENTS, fetchPrecedentsRequest),
    takeLatest(CREATE_PRECEDENT, createPrecedentRequest)
  ])
}
