import styled from 'styled-components'

type Props = {
  hasError: boolean
}

export const FilesControlWrapper = styled.div<Props>`
  h4 {
    color: ${({ theme, hasError }) =>
      hasError ? theme.colors.blush : 'inital'};
  }

  & > p {
    color: ${({ theme }) => theme.colors.blush};
  }

  table {
    th,
    td {
      color: ${({ theme, hasError }) =>
        hasError ? theme.colors.blush : 'inital'} !important;
    }

    tr {
      border-color: ${({ theme, hasError }) =>
        hasError ? theme.colors.blush : 'inital'} !important;
    }
  }
`
