import i18n from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import I18NextHttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import commonPtBr from 'i18n/pt-BR/common.json'
import messagesPtBr from 'i18n/pt-BR/messages.json'
import modalPtBr from 'i18n/pt-BR/modal.json'
import pagesPtBr from 'i18n/pt-BR/pages.json'
import validationPtBr from 'i18n/pt-BR/validation.json'

export const resources = {
  'pt-BR': {
    common: commonPtBr,
    messages: messagesPtBr,
    modal: modalPtBr,
    pages: pagesPtBr,
    validation: validationPtBr
  }
} as const

i18n
  .use(I18NextHttpBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'pt-BR',
    fallbackLng: 'pt-BR',
    ns: ['common', 'messages', 'modal', 'pages', 'validation'],
    resources,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  })

export default i18n
