import styled from 'styled-components'

import { ModalBodyContainer } from 'components/modal/styled'

export const ModalBody = styled(ModalBodyContainer)`
  p + p {
    margin-top: ${({ theme }) => theme.spacings[2]};
  }

  form {
    margin-top: ${({ theme }) => theme.spacings[6]};
  }
`
