import { produce } from 'immer'
import { REHYDRATE } from 'redux-persist'

import { Action } from 'types/Action'
import { Session } from 'types/session/Session'
import { Token } from 'types/session/Token'
import { User } from 'types/user/User'

import {
  SET_SESSION_DATA,
  CLEAR_SESSION_DATA,
  SET_TOKEN,
  GET_USER_DATA,
  GET_USER_DATA_FAILURE,
  GET_USER_DATA_SUCCESS
} from './actionTypes'

export type SessionState = {
  user?: User
  token?: Token
  loggedIn: boolean
  getUserInProgress: boolean
}

const INITIAL_STATE: SessionState = {
  user: undefined,
  token: undefined,
  loggedIn: false,
  getUserInProgress: false
}

export function sessionReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): SessionState {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_SESSION_DATA: {
        const data = <Session>action.payload
        draft.user = data.user
        draft.token = data.token
        draft.loggedIn = !!data.token
        break
      }

      case CLEAR_SESSION_DATA: {
        draft.user = undefined
        draft.token = undefined
        draft.loggedIn = false
        break
      }

      case SET_TOKEN: {
        draft.token = <Token>action.payload
        draft.loggedIn = true
        break
      }

      case GET_USER_DATA: {
        draft.getUserInProgress = true
        break
      }

      case GET_USER_DATA_FAILURE: {
        draft.user = undefined
        draft.token = undefined
        draft.loggedIn = false
        draft.getUserInProgress = false
        break
      }

      case GET_USER_DATA_SUCCESS: {
        draft.user = <User>action.payload
        draft.getUserInProgress = false
        break
      }

      case REHYDRATE: {
        const payload = <SessionState>action.payload
        if (payload && payload.token) {
          draft.token = payload.token
          draft.loggedIn = true
          draft.user = undefined
        }
        break
      }

      default: {
        break
      }
    }
  })
}
