export class HttpError401 extends Error {
  status: number
  code: string
  message: string

  constructor() {
    super('Unauthorized access')
    this.status = 401
    this.code = 'UNAUTHORIZED_ACCESS'
    this.message = 'Unauthorized access'
  }
}
