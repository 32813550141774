import styled from 'styled-components'

export const DirectorySignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  main {
    flex: 1;

    margin: 0 auto;

    padding: ${({ theme }) => `${theme.spacings[10]} 0`};

    width: 70%;

    form {
      & > button {
        margin: 0 auto;
        margin-top: ${({ theme }) => theme.spacings[4]};
      }
    }
  }
`
