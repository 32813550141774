import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import FileSaver from 'file-saver'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import XLSX from 'xlsx'

import { Breadcrumb, BreadcrumbItem } from 'components/breadcrumb'
import { Button } from 'components/button'
import { Flex, FlexItem } from 'components/flex'
import { IconButton } from 'components/iconButton'
import { SearchInput } from 'components/searchInput'
import { Table } from 'components/table'
import { Tooltip } from 'components/tooltip'

import { ReactComponent as Diploma } from 'assets/images/diploma.svg'

import { confirmationConfig } from 'config/swal'

import { withAdminLayout } from 'hoc/withAdminLayout'

import { useQuery } from 'hooks/useQuery'

import { browserHistory } from 'navigation/browserHistory'
import { CONTEST_CREATE_PAGE, CONTEST_DETAILS_PAGE } from 'navigation/paths'

import * as contestService from 'services/http/contestService'
import { fetchDirectoryInstitutions } from 'services/http/institutionService'

import { GlobalState } from 'store'
import { searchUserContests } from 'store/contest/actions'

import Theme from 'styles/Theme'

import { ContestSearchResult } from 'types/contest/ContestSearchResult'
import { ContestStep } from 'types/enums/ContestStep'
import { UserType } from 'types/enums/UserType'
import { SelectOption } from 'types/SelectOption'
import { TableColumn } from 'types/table/TableColumn'

import { formatDateBR } from 'utils/formatUtils'

import { DateInput } from './components/dateInput'
import { Select } from './components/select'

import { steps } from './steps'

import { DetailsLink, ExcelIcon } from './styled'

function Page(): JSX.Element {
  const { t } = useTranslation(['pages', 'common'])

  const query = useQuery()

  const dispatch = useDispatch()

  const {
    contest: {
      contestData,
      searchUserContestsResult,
      searchUserContestsInProgress
    },
    session: { user: sessionUser }
  } = useSelector((state: GlobalState) => state)

  const [
    getDirectoryInstitutionsInProgress,
    setGetDirectoryInstitutionsInProgress
  ] = useState<boolean>(false)

  const [institutionsOptions, setInstitutionsOptions] = useState<
    SelectOption[]
  >([])

  const stepsOptions = useMemo<SelectOption[]>(
    () =>
      Object.entries(steps).map(([key, value]) => ({
        label: t(`pages:contestList.contestStep.${key}`),
        value: value
      })),

    []
  )

  const [filter, setFilter] = useState<string | undefined>(undefined)

  const contestTableColumns = useMemo<TableColumn<ContestSearchResult>[]>(
    () => [
      {
        label: t('pages:contestList.table.id'),
        dataKey: 'id',
        sort: true,
        thStyle: { width: '80px' },
        tdStyle: { width: '80px' },
        render: (row) => (
          <Flex
            direction="row"
            align="center"
            justify="space-between"
            gap={1}
            wrap={false}
          >
            <span style={{ wordBreak: 'keep-all' }}>{row.id}</span>
            {row.userRelation === 'NOMINATED' && (
              <Tooltip
                content={t('pages:contestList.table.transferNotice')}
                placement="top"
              >
                <Diploma
                  style={{
                    minWidth: 24,
                    width: 24,
                    marginRight: 5,
                    color: Theme.colors.niceBlue
                  }}
                />
              </Tooltip>
            )}
          </Flex>
        )
      },
      {
        label: t('pages:contestList.table.requester'),
        dataKey: 'requester',
        sort: true,
        thStyle: { minWidth: '60px' },
        tdStyle: { minWidth: '60px' },
        filter: () => (
          <Select
            name="name"
            placeholder={t('pages:contestList.table.requester')}
            value={query.get('requester') ?? undefined}
            options={institutionsOptions}
            isLoading={getDirectoryInstitutionsInProgress}
            onChange={(option) => _updateSearch('requester', option?.value)}
          />
        )
      },
      {
        label: t('pages:contestList.table.defendant'),
        dataKey: 'defendant',
        sort: true,
        thStyle: { minWidth: '60px' },
        tdStyle: { minWidth: '60px' },
        filter: () => (
          <Select
            name="name"
            placeholder={t('pages:contestList.table.defendant')}
            value={query.get('defendant') ?? undefined}
            options={institutionsOptions}
            isLoading={getDirectoryInstitutionsInProgress}
            onChange={(option) => _updateSearch('defendant', option?.value)}
          />
        )
      },
      {
        label: t('pages:contestList.table.createdAt'),
        dataKey: 'createdAt',
        sort: true,
        thStyle: { minWidth: '40px' },
        tdStyle: { minWidth: '40px' },
        render: (row) => formatDateBR(row.createdAt)
      },
      {
        label: t('pages:contestList.table.currentStep'),
        dataKey: 'currentStep',
        sort: true,
        thStyle: { minWidth: '50px' },
        tdStyle: { minWidth: '50px' },
        filter: () => (
          <Select
            name="name"
            placeholder={t('pages:contestList.table.currentStep')}
            value={query.get('step') ?? undefined}
            options={stepsOptions}
            onChange={(option) => _updateSearch('step', option?.value)}
          />
        ),
        render: (row) => t(`common:contestStep.${row.currentStep}`)
      },
      {
        label: t('pages:institutionList.table.actions'),
        dataKey: 'actions',
        thStyle: { minWidth: '30px' },
        tdStyle: { minWidth: '30px' },
        render: (row) => (
          <DetailsLink to={_generateDetailsLink(row)}>+ detalhes</DetailsLink>
        )
      }
    ],
    [
      contestData,
      query.toString(),
      getDirectoryInstitutionsInProgress,
      institutionsOptions,
      stepsOptions
    ]
  )

  const [
    downloadExcelInProgress,
    setDownloadExcelInProgress
  ] = useState<boolean>(false)

  useEffect(() => {
    _getDirectoryInstitutions()
  }, [])

  useEffect(() => {
    if (sessionUser?.id) {
      const page = parseInt(query.get('page') ?? '1')

      if (page < 1) {
        return
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const advanced: any = {}

      if (query.get('requester')?.length) {
        advanced.requester = query.get('requester')
      }

      if (query.get('defendant')?.length) {
        advanced.defendant = query.get('defendant')
      }

      if (query.get('step')?.length) {
        advanced.step = query.get('step')
      }

      dispatch(
        searchUserContests(sessionUser?.id, {
          search: filter,
          page: page,
          limit: 10,
          orderBy: query.get('orderBy') ?? 'id',
          order: query.get('order') ?? 'desc',
          side: query.get('side') ?? undefined,
          targetDate: query.get('targetDate') ?? undefined,
          fromDate: query.get('fromDate') ?? undefined,
          toDate: query.get('toDate') ?? undefined,
          advanced: JSON.stringify(advanced)
        })
      )
    }
  }, [filter, query.toString()])

  async function _getDirectoryInstitutions() {
    try {
      setGetDirectoryInstitutionsInProgress(true)
      const response = await fetchDirectoryInstitutions()

      const options = response
        .map(({ name }) => ({
          label: name,
          value: name
        }))
        .sort((a, b) => {
          if (a.label > b.label) {
            return 1
          }

          if (a.label < b.label) {
            return -1
          }

          return 0
        })

      setInstitutionsOptions(options)
    } catch (error) {
      console.error('failed to fetch directory institutions', error)
    } finally {
      setGetDirectoryInstitutionsInProgress(false)
    }
  }

  function _updateSearch(key: string, value?: string | number) {
    if (value) {
      query.set(key, value.toString())
    } else {
      query.delete(key)
    }
    browserHistory.replace(`${location.pathname}?${query.toString()}`)
  }

  function _canCreateContest(): boolean {
    return sessionUser?.type === UserType.DIRECTORY
  }

  function _generateDetailsLink(contest: ContestSearchResult): string {
    if (
      contest.currentStep === ContestStep.INACTIVE ||
      contest.currentStep === ContestStep.WAITING_BOLETO
    ) {
      return generatePath(CONTEST_CREATE_PAGE, {
        contestId: contest.id
      })
    }

    return generatePath(CONTEST_DETAILS_PAGE, {
      contestId: contest.id
    })
  }

  async function _downloadExcel() {
    try {
      setDownloadExcelInProgress(true)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const advanced: any = {}

      if (query.get('requester')?.length) {
        advanced.requester = query.get('requester')
      }

      if (query.get('defendant')?.length) {
        advanced.defendant = query.get('defendant')
      }

      if (query.get('step')?.length) {
        advanced.step = query.get('step')
      }

      const { data } = await contestService.searchUserContests({
        search: filter,
        page: 1,
        limit: searchUserContestsResult?.pagination.total,
        orderBy: query.get('orderBy') ?? 'id',
        order: query.get('order') ?? 'asc',
        advanced: JSON.stringify(advanced)
      })

      const wb = XLSX.utils.book_new()

      wb.Props = {
        Title: 'Minhas Disputas',
        CreatedDate: new Date()
      }

      wb.SheetNames.push('Minhas disputas')

      const wsData: Array<Array<unknown>> = [
        // header
        [
          'ID',
          'Requerente',
          'Requerida',
          'Data de Ocorrência',
          'Data de abertura',
          'Situação',
          'Descrição'
        ]
      ]
      data.forEach((contest) => {
        let text = ''

        if (contest.text) {
          const doc = new DOMParser().parseFromString(contest.text, 'text/html')
          if (doc.body) {
            text = doc.body.innerText
          }
        }

        wsData.push([
          contest.id,
          contest.requester,
          contest.defendant,
          new Date(contest.occurredAt),
          new Date(contest.createdAt),
          t(`common:contestStep.${contest.currentStep}`),
          text
        ])
      })

      wb.Sheets['Minhas disputas'] = XLSX.utils.aoa_to_sheet(wsData)

      const wbOut = XLSX.writeFile(wb, 'Minhas Disputas.xlsx', {
        bookType: 'xlsx'
      })

      FileSaver.saveAs(wbOut)
    } catch (error) {
    } finally {
      setDownloadExcelInProgress(false)
    }
  }

  document.title = 'Disputas - Open Banking Brasil'

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem
          label={t('pages:contestList.breadcrumb.contest')}
          isCurrentPage
        />
      </Breadcrumb>

      {_canCreateContest() && (
        <Flex direction="row" justify="flex-end">
          <Button
            color="darkAqua"
            type="button"
            variant="outline"
            small
            onClick={() =>
              withReactContent(SweetAlert)
                .fire({
                  ...confirmationConfig,
                  icon: 'warning',
                  titleText: t('pages:contestList.confirmNewContest.title'),
                  html: t('pages:contestList.confirmNewContest.message'),
                  confirmButtonText: t(
                    'pages:contestList.confirmNewContest.confirmButton'
                  ),
                  cancelButtonText: t(
                    'pages:contestList.confirmNewContest.cancelButton'
                  )
                })
                .then(({ isConfirmed }) => {
                  if (isConfirmed) {
                    browserHistory.push(generatePath(CONTEST_CREATE_PAGE))
                  }
                })
            }
          >
            {t('pages:contestList.newContestButton')}
          </Button>
        </Flex>
      )}

      <Flex
        direction="row"
        align="center"
        gap={6}
        style={{ marginTop: Theme.spacings[6] }}
      >
        <FlexItem>
          <SearchInput
            placeholder={t('pages:contestList.search')}
            onClick={(value) => setFilter(value)}
          />
        </FlexItem>

        <FlexItem>
          <DateInput
            placeholder="Data de inicio"
            value={
              query.get('fromDate')
                ? parseISO(query.get('fromDate') as string)
                : undefined
            }
            onChange={(date) => {
              _updateSearch(
                'fromDate',
                date ? formatISO(date, { representation: 'date' }) : undefined
              )
            }}
          />
        </FlexItem>

        <FlexItem>
          <DateInput
            placeholder="Data de fim"
            value={
              query.get('toDate')
                ? parseISO(query.get('toDate') as string)
                : undefined
            }
            onChange={(date) =>
              _updateSearch(
                'toDate',
                date ? formatISO(date, { representation: 'date' }) : undefined
              )
            }
          />
        </FlexItem>

        {/* <FlexItem grow="1">
          <Select
            placeholder="Lado na disputa"
            value={query.get('side') ?? undefined}
            options={[
              {
                label: t(`common:contestSide.${ContestSide.REQUESTER}`),
                value: ContestSide.REQUESTER
              },
              {
                label: t(`common:contestSide.${ContestSide.DEFENDANT}`),
                value: ContestSide.DEFENDANT
              }
            ]}
            isLoading={getDirectoryInstitutionsInProgress}
            onChange={(option) => _updateSearch('side', option?.value)}
          />
        </FlexItem> */}

        <FlexItem>
          <IconButton
            tooltip={'Exportar para excel'}
            onClick={_downloadExcel}
            color="niceBlue"
          >
            {downloadExcelInProgress ? (
              <BounceLoader size="16px" color={Theme.colors.niceBlue} />
            ) : (
              <ExcelIcon />
            )}
          </IconButton>
        </FlexItem>
      </Flex>

      <Table
        columns={contestTableColumns}
        data={searchUserContestsResult?.data ?? []}
        pageCount={searchUserContestsResult?.pagination.lastPage}
        isLoading={searchUserContestsInProgress}
      />
    </>
  )
}

export const ContestListPage = withAdminLayout(Page)
