import styled from 'styled-components'

import { ButtonContainer } from 'components/button/styled'

export const PaymentStepWrapper = styled.div`
  button {
    margin: 0 auto;
  }

  ${ButtonContainer} + ${ButtonContainer} {
    margin-top: ${({ theme }) => theme.spacings[2]};
  }

  form {
    ${ButtonContainer} {
      margin-top: ${({ theme }) => theme.spacings[6]};
    }
  }
`

export const PaymentMethod = styled.h4`
  margin-top: ${({ theme }) => theme.spacings[8]};
  margin-bottom: ${({ theme }) => theme.spacings[2]};
  text-align: center;
`

export const PaymentValuesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;

  margin: ${({ theme }) => `${theme.spacings[12]} auto`};

  span {
    display: flex;
    flex-direction: column;

    div {
      h4 {
        margin-bottom: 0;
      }

      p {
        font-size: ${({ theme }) => theme.fontSizes.xmd};
        font-weight: bold;

        color: ${({ theme }) => theme.colors.pinkishGrey};
      }
    }

    div + div {
      margin-top: ${({ theme }) => theme.spacings[4]};
    }
  }

  span:last-of-type {
    align-items: center;
    justify-content: center;

    padding: ${({ theme }) => `0 ${theme.spacings[10]}`};

    border: 1px solid ${({ theme }) => theme.colors.petrol};
    border-radius: 1.25rem;

    h4 {
      margin-bottom: ${({ theme }) => theme.spacings[2]};
    }

    p {
      font-size: ${({ theme }) => theme.fontSizes.lg};
      font-weight: bold;

      color: ${({ theme }) => theme.colors.petrol};
    }
  }
`

type TabButtonProps = {
  active?: boolean
}

export const TabButton = styled.button<TabButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[1.5]};

  min-width: 120px;

  padding: ${({ theme }) => `${theme.spacings[2.5]} ${theme.spacings[8]}`};

  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.petrol};

  border: ${({ theme }) => `1px solid ${theme.colors.petrol}`};

  border-radius: 1.25rem;

  background-color: ${({ theme, active }) =>
    active ? theme.colors.petrol : 'transparent'};

  &:hover:not(:disabled) {
    opacity: 1;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.petrol};
  }

  svg {
    width: ${({ theme }) => theme.fontSizes.md};
    height: ${({ theme }) => theme.fontSizes.md};

    color: inherit;
  }
`

export const BoletoDisclaimer = styled.span`
  display: block;

  width: fit-content;
  max-width: 80%;

  font-size: ${({ theme }) => theme.fontSizes.md};

  color: ${({ theme }) => theme.colors.lightBlue};

  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 1.25rem;

  background-color: ${({ theme }) => theme.colors.duckEggBlueTwo};

  margin: ${({ theme }) => `${theme.spacings[8]} auto`};
  padding: ${({ theme }) => theme.spacings[10]};
`

export const BoletoLink = styled.a`
  display: flex;
  justify-content: center;

  margin-top: 16px;

  font-size: ${({ theme }) => theme.fontSizes.xmd};

  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`
