import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { FieldSet } from 'components/fieldSet'
import { Form } from 'components/form'
import { Flex, FlexItem } from 'components/flex'
import { MaskedInput } from 'components/maskedInput'
import { TextInput } from 'components/textInput'

import { GlobalState } from 'store'
import { updateUser } from 'store/user/actions'

export function UserForm(): JSX.Element {
  const { t } = useTranslation(['pages'])

  const dispatch = useDispatch()

  const { userInfo, updateUserInProgress } = useSelector(
    (state: GlobalState) => state.user
  )

  const initialValues = {
    id: userInfo?.id,
    username: userInfo?.username ?? '',
    name: userInfo?.name ?? '',
    lastname: userInfo?.lastname ?? '',
    cpf: userInfo?.cpf ?? ''
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string(),
    name: Yup.string(),
    lastname: Yup.string(),
    cpf: Yup.string()
  })

  function _handleSubmit(data: FormikValues) {
    dispatch(
      updateUser({
        id: data.id,
        username: data.username,
        name: data.name,
        lastname: data.lastname,
        cpf: data.cpf
      })
    )
  }

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => _handleSubmit(values)}
    >
      <FieldSet title={t('pages:settings.userForm.title')}>
        <Flex gap={6}>
          <FlexItem grow="6">
            <TextInput
              name="name"
              placeholder={t('pages:settings.userForm.name')}
            />
          </FlexItem>
          <FlexItem grow="6">
            <TextInput
              name="lastname"
              placeholder={t('pages:settings.userForm.lastname')}
            />
          </FlexItem>
        </Flex>
        <Flex gap={6}>
          <FlexItem grow="6">
            <MaskedInput
              name="cpf"
              placeholder={t('pages:settings.userForm.cpf')}
              options={{
                delimiters: ['.', '.', '-'],
                blocks: [3, 3, 3, 2],
                numericOnly: true
              }}
              disabled
            />
          </FlexItem>
        </Flex>
      </FieldSet>

      <Flex justify="flex-end">
        <Button type="submit" small inProgress={updateUserInProgress}>
          {t('pages:settings.userForm.submitButton')}
        </Button>
      </Flex>
    </Form>
  )
}
