import styled from 'styled-components'

import { ModalBodyContainer } from 'components/modal/styled'

export const ModalBody = styled(ModalBodyContainer)`
  margin: 0 auto;
`

export const Text = styled.p`
  margin: 0 auto 30px;
  text-align: center;
  font-size: 16px;
`
