import { useField } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CheckThinSVG } from 'assets/images/check-thin.svg'
import { ReactComponent as CloseThinSVG } from 'assets/images/close-thin.svg'
import { ReactComponent as EyeSVG } from 'assets/images/eye.svg'
import { ReactComponent as InfoSVG } from 'assets/images/info.svg'

import { Popover } from 'components/popover'

import { Colors } from 'styles/Theme'

import {
  PasswordInputContainer,
  PasswordStrength,
  PopoverContent
} from './styled'

type PasswordInputProps = {
  name: string
  placeholder: string
  readOnly?: boolean
  disabled?: boolean
  showStrength?: boolean
  [key: string]: unknown
}

type PasswordRule = {
  description: string
  check: (value: string) => boolean
}

export function PasswordInput({
  name,
  placeholder,
  readOnly,
  disabled,
  showStrength = true,
  ...rest
}: PasswordInputProps): JSX.Element {
  const { t } = useTranslation(['validation'])

  const [field, meta] = useField(name)
  const [rules] = useState<PasswordRule[]>([
    {
      description: t('validation:password.length', { length: 8 }),
      check: (value) => value.length >= 8
    },
    {
      description: t('validation:password.number'),
      check: (value) => /[0-9]+/.test(value)
    },
    {
      description: t('validation:password.lowercase'),
      check: (value) => /[a-z]+/.test(value)
    },
    {
      description: t('validation:password.uppercase'),
      check: (value) => /[A-Z]+/.test(value)
    },
    {
      description: t('validation:password.specialCharacter'),
      check: (value) => /[\W_\s]+/.test(value)
    }
  ])

  const [showPassword, setShowPassword] = useState<boolean>(false)

  function _getCorrectRules() {
    return rules.filter(({ check }) => field.value && check(field.value))
  }

  function _getColor(): keyof Colors {
    if (_getCorrectRules().length < 3) {
      return 'blush'
    }

    if (_getCorrectRules().length > 4) {
      return 'oceanGreen'
    }

    return 'fadedOrange'
  }

  function _renderPopoverContent() {
    return (
      <PopoverContent color={_getColor()}>
        {rules.map(({ description, check }, i) => (
          <li data-success={field.value && check(field.value)} key={i}>
            {field.value && check(field.value) ? (
              <CheckThinSVG />
            ) : (
              <CloseThinSVG />
            )}
            {description}
          </li>
        ))}
      </PopoverContent>
    )
  }

  return (
    <PasswordInputContainer hasError={meta.touched && !!meta.error}>
      <input
        id={name}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        type={showPassword ? 'text' : 'password'}
        {...field}
        {...rest}
      />
      <button
        tabIndex={-1}
        type="button"
        onClick={() => setShowPassword(!showPassword)}
      >
        <EyeSVG />
      </button>
      {meta.touched && !!meta.error && (
        <p>
          {t(`validation:${meta.error}`, {
            field: placeholder
          })}
        </p>
      )}
      {showStrength && (
        <PasswordStrength color={_getColor()}>
          <ul>
            <li data-filled={_getCorrectRules().length >= 1} />
            <li data-filled={_getCorrectRules().length >= 2} />
            <li data-filled={_getCorrectRules().length >= 3} />
            <li data-filled={_getCorrectRules().length >= 4} />
            <li data-filled={_getCorrectRules().length >= 5} />
          </ul>
          <span>
            {_getCorrectRules().length === 1 && t('validation:password.weak')}
            {_getCorrectRules().length === 2 && t('validation:password.weak')}
            {_getCorrectRules().length === 3 && t('validation:password.medium')}
            {_getCorrectRules().length === 4 && t('validation:password.medium')}
            {_getCorrectRules().length === 5 && t('validation:password.strong')}
            <Popover placement="auto" content={_renderPopoverContent()}>
              <InfoSVG tabIndex={-1} />
            </Popover>
          </span>
        </PasswordStrength>
      )}
    </PasswordInputContainer>
  )
}
