import styled from 'styled-components'

type AdminWrapperProps = {
  isOpen: boolean
}

export const AdminWrapper = styled.aside<AdminWrapperProps>`
  z-index: ${({ theme }) => theme.zIndex.overlay};

  position: absolute;
  top: ${({ theme }) => theme.misc.header.height};
  right: ${({ theme, isOpen }) =>
    isOpen ? '0' : `calc(0px - ${theme.misc.notificationsMenu.width})`};

  padding: ${({ theme }) => `${theme.spacings[6]} ${theme.spacings[4]}`};

  height: ${({ theme }) => `calc(100% - ${theme.misc.header.height})`};
  width: ${({ theme }) => theme.misc.notificationsMenu.width};

  background-color: ${({ theme }) => theme.colors.paleGrey};

  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);

  transition: 0.3s ease-in-out;

  & > button {
    display: block;
    margin-left: auto;
    color: ${({ theme }) => theme.colors.niceBlue};

    svg {
      width: ${({ theme }) => theme.fontSizes.xmd};
    }
  }

  & > header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: ${({ theme }) => theme.spacings[2]};
    margin-bottom: ${({ theme }) => theme.spacings[6]};

    h2 {
      font-family: ${({ theme }) => theme.fonts.gothamHTF};
      font-weight: normal;
      margin-bottom: 0;
    }
  }

  ul {
    list-style: none;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;

      border-bottom: 1px solid ${({ theme }) => theme.colors.warmGreyThree};

      color: ${({ theme }) => theme.colors.greyishBrownSix};

      svg {
        width: ${({ theme }) => theme.fontSizes.lg};
        color: inherit;
      }

      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: ${({ theme }) => theme.spacings[2]};
        padding: ${({ theme }) => `${theme.spacings[3]} ${theme.spacings[1]}`};
        font-size: ${({ theme }) => theme.fontSizes.sm};
        color: inherit;
      }

      &:hover {
        color: ${({ theme }) => theme.colors.petrol};
        transition: 0.3s ease;
      }
    }
  }
`
