import styled from 'styled-components'
import Select from 'react-select'

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings[8]};

  & > p {
    position: absolute;
    top: 105%;

    color: ${({ theme }) => theme.colors.blush};
    margin: ${({ theme }) => `0 ${theme.spacings[4]}`};
  }
`

type SelectContainerProps = {
  isOpen: boolean
  hasError?: boolean
}

export const SelectContainer = styled.div<SelectContainerProps>`
  position: relative;
  width: 100%;

  button {
    display: inline;
    position: relative;

    text-align: left;

    width: 100%;

    padding: ${({ theme }) => `${theme.spacings[2.5]} ${theme.spacings[4]}`};

    color: ${({ theme }) => theme.colors.pinkishGrey};

    border: 1px solid
      ${({ theme, isOpen, hasError }) => {
        if (hasError) {
          return theme.colors.blush
        }

        if (isOpen) {
          return theme.colors.niceBlue
        }

        return theme.colors.warmGreyThree
      }};
    border-radius: 2rem;

    background-color: ${({ theme }) => theme.colors.white};

    box-shadow: ${({ theme, isOpen, hasError }) => {
      if (!isOpen) {
        return 'none'
      }

      if (hasError) {
        return `0 0 0 1px ${theme.colors.blush}`
      }

      return `0 0 0 1px ${theme.colors.niceBlue}`
    }};

    span {
      color: ${({ theme }) => theme.colors.greyishBrownFour};
    }

    svg {
      position: absolute;
      top: ${({ theme }) => `calc(50% - ${theme.fontSizes.sm} / 2)`};
      right: ${({ theme }) => theme.spacings[4]};

      height: ${({ theme }) => theme.fontSizes.sm};

      color: ${({ theme, isOpen, hasError }) => {
        if (hasError) {
          return theme.colors.blush
        }

        if (isOpen) {
          return theme.colors.niceBlue
        }
        return 'inherit'
      }};

      transform: ${({ isOpen }) =>
        isOpen ? 'rotate(-270deg)' : 'rotate(-90deg)'};

      transition: 0.3s;
    }
  }
`

export const Menu = styled.div`
  z-index: ${({ theme }) => theme.zIndex.dropdown};

  position: absolute;
  left: 2.5%;

  width: 95%;

  margin-top: ${({ theme }) => theme.spacings[1]};
  padding: ${({ theme }) => `${theme.spacings[3]} ${theme.spacings[2]}`};

  border: 1px solid ${({ theme }) => theme.colors.warmGreyThree};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.colors.white};

  box-shadow: 4px 5px 8px 0 rgba(64, 64, 64, 0.33);
`

export const SelectInput = styled(Select)`
  .react-select__control {
    color: ${({ theme }) => theme.colors.greyishBrownFour};

    border: 1px solid ${({ theme }) => theme.colors.warmGreyThree};
    border-radius: 2rem;

    background-color: ${({ theme }) => theme.colors.white};

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.warmGreyThree};
    }

    &--is-focused {
      z-index: 1;
      /* box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.niceBlue}; */
      /* border-color: ${({ theme }) => theme.colors.niceBlue}; */
      box-shadow: none;
      border-color: ${({ theme }) => theme.colors.warmGreyThree};
    }

    .react-select__value-container {
      display: ${({ isSearchable }) => (!isSearchable ? 'none' : 'flex')};
      font-family: ${(props) => props.theme.fonts.gothamHTF};
      font-size: ${(props) => props.theme.fontSizes.md};
      color: ${({ theme }) => theme.colors.greyishBrownFour};
    }

    .react-select__indicators {
      color: ${({ theme }) => theme.colors.niceBlue};
      padding: ${({ theme }) => theme.spacings[2]};

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    .react-select__indicator-separator {
      visibility: hidden;
    }
  }

  .react-select__menu {
    position: relative;

    margin: ${({ theme }) => `${theme.spacings[1]} 0`};

    border: none;
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.white};

    .react-select__option {
      font-family: ${(props) => props.theme.fonts.gothamHTF};
      font-size: ${(props) => props.theme.fontSizes.sm};
      color: ${({ theme }) => theme.colors.darkAqua};

      border-bottom: 1px solid ${({ theme }) => theme.colors.warmGreyThree};

      cursor: pointer;

      &--is-selected {
        background-color: rgba(192, 192, 192, 0.33);
      }

      &--is-focused:not(.react-select__option--is-selected),
      &:hover:not(.react-select__option--is-selected) {
        background-color: rgba(192, 192, 192, 0.33);
      }
    }
  }
`
