import { useState } from 'react'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BounceLoader } from 'react-spinners'

import { ReactComponent as CloseThinSVG } from 'assets/images/close-thin.svg'
import { ReactComponent as Download } from 'assets/images/download.svg'

import { GlobalState } from 'store'
import { closeAdminMenu } from 'store/admin/actions'
import * as adminService from 'services/http/adminService'
import Theme from 'styles/Theme'

import { AdminWrapper } from './styled'

export function Admin(): JSX.Element {
  const [isExportDBInProgress, setIsExportDBInProgress] = useState(false)
  const { t } = useTranslation('common')

  const dispatch = useDispatch()

  const { isOpen } = useSelector((state: GlobalState) => state.admin)

  async function downloadContests() {
    try {
      setIsExportDBInProgress(true)

      const data = await adminService.exportContests()
      const wb = XLSX.utils.book_new()

      wb.Props = {
        Title: 'Disputas',
        CreatedDate: new Date()
      }

      wb.SheetNames.push('Disputas')

      const wsData: Array<Array<unknown>> = [
        // header
        [
          'ID',
          'DATA CRIAÇÃO',
          'SERVIÇO',
          'CENÁRIO',
          'CATEGORIA',
          'STATUS',
          'ABERTURA',
          'RESPOSTA',
          'RÉPLICA',
          'TRÉPLICA',
          'DESISTIDA',
          'REENVIADA',
          'ACEITA EM',
          'ACORDO ENVIADO'
        ]
      ]

      data.forEach((contest) => {
        wsData.push([
          contest.ID,
          contest['DATA CRIACAO'],
          contest['SERVIÇO'],
          contest['CENÁRIO'],
          contest.CATEGORIA,
          contest.STATUS,
          contest.ABERTURA,
          contest.RESPOSTA,
          contest.REPLICA,
          contest.TREPLICA,
          contest.DESISTIDA,
          contest.REENVIADA,
          contest['ACEITA EM'],
          contest['ACORDO ENVIADO']
        ])
      })

      wb.Sheets.Disputas = XLSX.utils.aoa_to_sheet(wsData)

      const wbOut = XLSX.writeFile(wb, 'Disputas.xlsx', {
        bookType: 'xlsx'
      })

      FileSaver.saveAs(wbOut)
    } catch (error) {
    } finally {
      setIsExportDBInProgress(false)
    }
  }

  return (
    <AdminWrapper isOpen={isOpen} tabIndex={-1}>
      <button
        tabIndex={isOpen ? 0 : -1}
        onClick={() => dispatch(closeAdminMenu())}
      >
        <CloseThinSVG />
      </button>
      <header>
        <h2>{t('adminMenu.title')}</h2>
      </header>

      <ul>
        <li>
          <button
            type="button"
            tabIndex={isOpen ? 0 : -1}
            disabled={isExportDBInProgress}
            onClick={() => downloadContests()}
          >
            {isExportDBInProgress ? (
              <BounceLoader size="16px" color={Theme.colors.niceBlue} />
            ) : (
              <Download />
            )}

            <span>{t('adminMenu.downloadContests')}</span>
          </button>
        </li>
      </ul>
    </AdminWrapper>
  )
}
