import { FormikValues } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Yup from 'yup'

import { Button } from 'components/button'
// import { CreatableSelect } from 'components/creatableSelect'
import { Form } from 'components/form'
import { Modal, ModalHeader } from 'components/modal'
import { TextInput } from 'components/textInput'

import { confirmationConfig } from 'config/swal'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'
// import { searchUser } from 'store/user/actions'
import { inviteUser } from 'store/institution/actions'

import { ModalName } from 'types/enums/ModalName'
// import { SelectOption } from 'types/SelectOption'

import { ModalBody } from './styled'

export function InstitutionAddMemberModal(): JSX.Element {
  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    modal: { institutionAddMemberIsOpen },
    // user: { searchUserInProgress, searchUserResult },
    institution: { institution, inviteUserInProgress }
  } = useSelector((state: GlobalState) => state)

  const initialValues = {
    email: ''
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('email').required('required')
  })

  // const userOptions = useMemo<SelectOption[]>(
  //   () =>
  //     searchUserResult?.data.map(({ email }) => ({
  //       label: email,
  //       value: email
  //     })) ?? [],
  //   [searchUserResult]
  // )

  useEffect(() => {
    if (institutionAddMemberIsOpen) {
      // _searchUsers('')
    } else {
      const resetForm = document.getElementById('resetForm')
      if (resetForm) resetForm.click()
    }
  }, [institutionAddMemberIsOpen])

  // function _searchUsers(search: string) {
  //   dispatch(
  //     searchUser({
  //       page: 1,
  //       limit: 5,
  //       order: 'email'
  //     })
  //   )
  // }

  function _handleSubmit(data: FormikValues) {
    withReactContent(SweetAlert)
      .fire({
        ...confirmationConfig,
        icon: 'warning',
        title: t('institutionAddMember.confirmation.title'),
        html: (
          <span
            dangerouslySetInnerHTML={{
              __html: t('institutionAddMember.confirmation.message')
            }}
          />
        ),
        confirmButtonText: t('institutionAddMember.confirmation.confirmButton'),
        cancelButtonText: t('institutionAddMember.confirmation.cancelButton')
      })
      .then(({ isConfirmed }) => {
        if (isConfirmed && institution?.id) {
          dispatch(
            inviteUser({
              institutionId: institution.id,
              email: data.email.toLowerCase()
            })
          )
        }
      })
  }

  return (
    <Modal
      isOpen={institutionAddMemberIsOpen}
      onClose={() => dispatch(closeModal(ModalName.INSTITUTION_ADD_MEMBER))}
    >
      <ModalHeader separator={false}>
        <h2>{t('institutionAddMember.title')}</h2>
      </ModalHeader>

      <ModalBody>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => _handleSubmit(values)}
        >
          {/* <CreatableSelect
            name="email"
            placeholder={t('institutionAddMember.form.email')}
            options={userOptions}
            isLoading={searchUserInProgress}
            onChangeFilter={(search: string) => _searchUsers(search)}
            formatCreateLabel={(value: string) => (
              <span>
                {t('institutionAddMember.form.selectCreateLabel')}{' '}
                <strong>{value}</strong>
              </span>
            )}
            maxMenuHeight={120}
          /> */}

          <TextInput
            name="email"
            placeholder={t('institutionAddMember.form.email')}
          />

          <Button
            fullWidth
            small
            type="submit"
            inProgress={inviteUserInProgress}
          >
            {t('institutionAddMember.form.submitButton')}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}
