import styled from 'styled-components'

export const LoginWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  height: 100%;
`

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 50%;

  header {
    h2 {
      color: ${({ theme }) => theme.colors.sandy};
      text-align: center;
    }
    

    img {
      display: flex;
      width: 300px;           
      margin: 0 auto;      
    }
  }

  section.buttons {
    justify-self: flex-end;

    margin-top: ${({ theme }) => theme.spacings[14]};

    width: 50%;

    p {
      text-align: center;
    }

    button {
      display: block;
      margin: 0 auto;
    }

    button + button,
    button + p,
    p + button,
    p + p {
      margin-top: ${({ theme }) => theme.spacings[4]};
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 50%;

    margin: ${({ theme }) => `${theme.spacings[10]} auto ${theme.spacings[2]}`};

    button + button {
      margin-top: ${({ theme }) => theme.spacings[4]};
    }
  }
`

export const ForgetPasswordLink = styled.button`
  font-family: ${({ theme }) => theme.fonts.bloggerSans};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.pinkishGrey};

  border: none;
  background-color: transparent;

  transition: 0.3s;

  &:focus {
    z-index: 1;
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.petrol};
    border-color: ${(props) => props.theme.colors.petrol};
  }

  :hover {
    filter: brightness(90%);
    text-decoration: underline;
  }
`

export const LogoSection = styled.div`

  

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colors.white} 0 51%, ${theme.colors.deepSeaBlue} 45%, ${theme.colors.twilightBlue})`};

  img {
    display: block;

    max-height: 70vh;
    max-width: 70%;
    /* width: 460px; */

    /* margin-left: 20%; */
  }
`
