import { Login } from 'types/login/Login'
import { SSORequest } from 'types/login/SSORequest'
import { Session } from 'types/session/Session'
import { Token } from 'types/session/Token'

import { GET, POST } from 'utils/httpUtils'

const LOGIN_PATH = '/subscription/login'
const LOGOUT_PATH = '/subscription/logout'
const SSO_PATH = '/subscription/sso'

export async function login(params: Login): Promise<Session> {
  try {
    const { token, user } = await POST<Login, Session>(LOGIN_PATH, params)
    return Promise.resolve({
      token: token,
      user: {
        id: user.id,
        username: user.username,
        name: user.name,
        lastname: user.lastname,
        email: user.email,
        cpf: user.cpf,
        status: user.status,
        type: user.type
      }
    })
  } catch (error) {
    return Promise.reject(error)
  }
}


export async function logout(): Promise<void> {
  try {
    await GET(LOGOUT_PATH)
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getSSOUrl(): Promise<SSORequest> {
  try {
    const response = await GET<{
      authUrl: string
      // eslint-disable-next-line camelcase
      code_verifier: string
      state: string
      nonce: string
    }>(`${SSO_PATH}/getUrl`)

    return Promise.resolve({
      authUrl: response.authUrl,
      codeVerifier: response.code_verifier,
      state: response.state,
      nonce: response.nonce
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function validateSSO(data: {
  response: string
  state: string
}): Promise<Token> {
  try {
    const { token } = await GET<{ token: Token }>(`${SSO_PATH}`, data)
    return Promise.resolve(token)
  } catch (error) {
    return Promise.reject(error)
  }
}
