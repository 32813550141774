import { Placement } from '@popperjs/core'
import Tippy from '@tippyjs/react/headless'
import { ReactElement, useState } from 'react'
import { config, useSpring } from 'react-spring'

import { TooltipContainer, Arrow } from './styled'

type TooltipProps = {
  content: string | ReactElement
  children: ReactElement
  placement?: Placement
}

export function Tooltip({
  content,
  children,
  placement = 'auto'
}: TooltipProps): JSX.Element {
  const [arrowEl, setArrowEl] = useState<HTMLElement | null>(null)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const styles = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'scale(1)' : 'scale(0.8)',
    config: config.wobbly
  })

  return content ? (
    <Tippy
      render={(attrs) => (
        <TooltipContainer tabIndex={-1} style={styles} {...attrs}>
          {content}
          <Arrow id="arrow" ref={setArrowEl} />
        </TooltipContainer>
      )}
      placement={placement}
      animation
      onShow={() => setIsVisible(true)}
      onHide={() => setIsVisible(false)}
      popperOptions={{
        modifiers: [{ name: 'arrow', options: { element: arrowEl } }]
      }}
    >
      {children}
    </Tippy>
  ) : (
    children
  )
}
