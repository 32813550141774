import styled from 'styled-components'

import { Colors } from 'styles/Theme'

type PasswordInputContainerProps = {
  hasError?: boolean
}

export const PasswordInputContainer = styled.div<PasswordInputContainerProps>`
  position: relative;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacings[8]};

  & > input {
    width: 100%;

    padding: ${({ theme }) => `${theme.spacings[2.5]} ${theme.spacings[4]}`};
    padding-right: ${({ theme }) => theme.spacings[10]};

    margin-bottom: ${(props) => props.theme.spacings[2]};

    color: ${(props) => props.theme.colors.greyishBrownFour};

    border: 1px solid
      ${({ theme, hasError }) => {
        if (hasError) {
          return theme.colors.blush
        }

        return theme.colors.warmGreyThree
      }};
    border-radius: 2rem;

    background-color: ${({ theme }) => theme.colors.white};

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    &::placeholder {
      color: ${(props) => props.theme.colors.pinkishGrey};
      opacity: 1; /* Firefox */
    }

    /* Internet Explorer 10-11, Microsoft Edge */
    &:-ms-input-placeholder {
      color: ${(props) => props.theme.colors.pinkishGrey};
    }

    &:focus {
      z-index: 1;
      box-shadow: 0 0 0 1px
        ${({ theme, hasError }) => {
          if (hasError) {
            return theme.colors.blush
          }

          return theme.colors.niceBlue
        }};
      border-color: ${({ theme, hasError }) => {
        if (hasError) {
          return theme.colors.blush
        }

        return theme.colors.niceBlue
      }};
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  & > input:focus + button {
    /* visibility: visible; */
    color: ${({ theme, hasError }) =>
      hasError ? theme.colors.blush : theme.colors.niceBlue};
  }

  & > button {
    /* visibility: hidden; */

    position: absolute;
    top: ${({ theme }) => theme.spacings[2.5]};
    right: ${({ theme }) => theme.spacings[4]};

    padding: 0;
    line-height: 0;

    color: ${({ theme, hasError }) =>
      hasError ? theme.colors.blush : theme.colors.warmGreyThree};

    svg {
      height: ${({ theme }) => theme.fontSizes.xmd};
    }
  }

  & > p {
    /* position: absolute;
    top: 105%; */

    color: ${({ theme }) => theme.colors.blush};
    margin: ${({ theme }) => `0 ${theme.spacings[4]}`};
  }
`

type PasswordStrengthProps = {
  color: keyof Colors
}

export const PasswordStrength = styled.div<PasswordStrengthProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[2]};

  width: 100%;

  margin-top: ${(props) => props.theme.spacings[2]};
  padding: ${({ theme }) => `0 ${theme.spacings[4]}`};

  ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    gap: ${({ theme }) => theme.spacings[4]};

    list-style: none;

    li {
      height: ${({ theme }) => theme.fontSizes.xxsm};

      border: 2px solid ${({ theme }) => theme.colors.pinkishGrey};
      border-radius: ${({ theme }) => theme.fontSizes.sm};

      background-color: ${({ theme }) => theme.colors.pinkishGrey};
    }

    li[data-filled='true'] {
      border: 2px solid ${({ theme, color }) => theme.colors[color]};

      background-color: ${({ theme, color }) => theme.colors[color]};
    }
  }

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    color: ${({ theme, color }) => theme.colors[color]};

    svg {
      margin-left: ${({ theme }) => theme.spacings[2]};
      color: ${({ theme }) => theme.colors.waterBlue};
      height: ${({ theme }) => theme.fontSizes.sm};
    }
  }
`

type PopoverContentProps = {
  color: keyof Colors
}

export const PopoverContent = styled.ul<PopoverContentProps>`
  list-style: none;

  li {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacings[2]};

    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme, color }) => theme.colors[color]};

    svg {
      height: ${({ theme }) => theme.fontSizes.sm};
    }
  }

  li + li {
    margin-top: ${({ theme }) => theme.spacings[1.5]};
  }

  li[data-success='true'] {
    color: ${({ theme }) => theme.colors.oceanGreen};
  }
`
