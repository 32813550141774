import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'
import { ModalName } from 'types/enums/ModalName'

import { OPEN_MODAL, CLOSE_MODAL } from './actionTypes'

export type ModalState = {
  [ModalName.FORGET_PASSWORD]: boolean

  [ModalName.INSTITUTION_ADD_MEMBER]: boolean
  [ModalName.INSTITUTION_EDIT_MEMBER]: boolean

  [ModalName.CONTEST_ADD_MEMBER]: boolean
  [ModalName.CONTEST_EDIT_MEMBER]: boolean
  [ModalName.CONTEST_ADD_ATTACHMENT]: boolean
  [ModalName.CONTEST_ADD_ATTACHMENT_NOTE]: boolean
  [ModalName.CONTEST_ANSWER]: boolean
  [ModalName.CONTEST_REPLICATION]: boolean
  [ModalName.CONTEST_REJOINDER]: boolean
  [ModalName.CONTEST_ACCEPT]: boolean
  [ModalName.CONTEST_SEND_AGREEMENT]: boolean
  [ModalName.CONTEST_SEND_NEW_AGREEMENT]: boolean
  [ModalName.CONTEST_ACCEPT_AGREEMENT]: boolean
  [ModalName.CONTEST_SEND_SUBSCRIBERS]: boolean
  [ModalName.CONTEST_SIGNATURES]: boolean
  [ModalName.CONTEST_ADD_ASSOCIATION]: boolean
  [ModalName.CONTEST_RESEND_PAYMENT]: boolean
  [ModalName.CONTEST_TRANSFER_INSTITUTION]: boolean
  [ModalName.CONTEST_TRANSFER_INSTITUTION_STATUS]: boolean
  [ModalName.CONTEST_REPLY_INSTITUTION]: boolean

  [ModalName.DIRECTORY_INVITE]: boolean

  [ModalName.PRECEDENT_CREATE]: boolean
  [ModalName.PRECEDENT_DETAILS]: boolean
  [ModalName.PRECEDENT_REVIEW]: boolean
}

const INITIAL_STATE: ModalState = {
  [ModalName.FORGET_PASSWORD]: false,

  [ModalName.INSTITUTION_ADD_MEMBER]: false,
  [ModalName.INSTITUTION_EDIT_MEMBER]: false,

  [ModalName.CONTEST_ADD_MEMBER]: false,
  [ModalName.CONTEST_EDIT_MEMBER]: false,
  [ModalName.CONTEST_ADD_ATTACHMENT]: false,
  [ModalName.CONTEST_ADD_ATTACHMENT_NOTE]: false,
  [ModalName.CONTEST_ANSWER]: false,
  [ModalName.CONTEST_REPLICATION]: false,
  [ModalName.CONTEST_REJOINDER]: false,
  [ModalName.CONTEST_ACCEPT]: false,
  [ModalName.CONTEST_SEND_AGREEMENT]: false,
  [ModalName.CONTEST_SEND_NEW_AGREEMENT]: false,
  [ModalName.CONTEST_ACCEPT_AGREEMENT]: false,
  [ModalName.CONTEST_SEND_SUBSCRIBERS]: false,
  [ModalName.CONTEST_SIGNATURES]: false,
  [ModalName.CONTEST_ADD_ASSOCIATION]: false,
  [ModalName.CONTEST_RESEND_PAYMENT]: false,
  [ModalName.CONTEST_TRANSFER_INSTITUTION]: false,
  [ModalName.CONTEST_TRANSFER_INSTITUTION_STATUS]: false,
  [ModalName.CONTEST_REPLY_INSTITUTION]: false,

  [ModalName.DIRECTORY_INVITE]: false,

  [ModalName.PRECEDENT_CREATE]: false,
  [ModalName.PRECEDENT_DETAILS]: false,
  [ModalName.PRECEDENT_REVIEW]: false
}

export function modalReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): ModalState {
  return produce(state, (draft) => {
    switch (action.type) {
      case OPEN_MODAL: {
        const name = <ModalName>action.payload
        draft[name] = true
        break
      }

      case CLOSE_MODAL: {
        const name = <ModalName>action.payload
        draft[name] = false
        break
      }

      case CLEAR_SESSION_DATA: {
        return INITIAL_STATE
      }

      default: {
        break
      }
    }
  })
}
