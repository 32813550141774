export const OPEN_NOTIFICATION_MENU = '@notifications/OPEN_NOTIFICATION_MENU'
export const CLOSE_NOTIFICATION_MENU = '@notifications/CLOSE_NOTIFICATION_MENU'

export const GET_NOTIFICATIONS = '@notifications/GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_FAILURE =
  '@notifications/GET_NOTIFICATIONS_FAILURE'
export const GET_NOTIFICATIONS_SUCCESS =
  '@notifications/GET_NOTIFICATIONS_SUCCESS'

export const MARK_AS_READ = '@notifications/MARK_AS_READ'
export const MARK_AS_READ_FAILURE = '@notifications/MARK_AS_READ_FAILURE'
export const MARK_AS_READ_SUCCESS = '@notifications/MARK_AS_READ_SUCCESS'
