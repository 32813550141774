import styled from 'styled-components'

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  flex-basis: 100%; // fill all available width

  height: ${({ theme }) => theme.misc.header.height};
  min-height: ${({ theme }) => theme.misc.header.height};

  padding: ${({ theme }) => `0 ${theme.spacings[8]}`};

  color: ${({ theme }) => theme.colors.white};

  background-color: ${({ theme }) => theme.colors.petrol};

  img {
    width: 222px;    
  }

  nav {
    a {
      color: ${({ theme }) => theme.colors.white};
      line-height: 0;
    }

    button {
      position: relative;
      color: ${({ theme }) => theme.colors.white};
      line-height: 0;

      &[data-count]:not([data-count='0']) {
        &::after {
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: ${({ theme }) => theme.fontSizes.xsm};
          font-weight: bold;

          content: attr(data-count);

          position: absolute;
          top: -8px;
          right: -8px;

          height: 22px;
          width: 22px;

          border-radius: 50%;

          line-height: 0;

          color: ${({ theme }) => theme.colors.petrol};
          background-color: ${({ theme }) => theme.colors.sandy};
        }
      }
    }

    a + a,
    a + button,
    button + a,
    button + button {
      margin-left: ${({ theme }) => theme.spacings[4]};
    }

    svg {
      height: ${({ theme }) => theme.fontSizes.lg};
    }
  }
`
