import { Form, Formik, FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { BackButton } from 'components/backButton'
import { Button } from 'components/button'
import { FieldSet } from 'components/fieldSet'
import { Flex, FlexItem } from 'components/flex'
import { MaskedInput } from 'components/maskedInput'
import { TextInput } from 'components/textInput'
import { PasswordInput } from 'components/passwordInput'
import { RadioInput } from 'components/radioInput'

import { GlobalState } from 'store'

import { formatCnpj } from 'utils/formatUtils'

import { AccountStepWrapper, InstitutionName } from './styled'
import { Representant } from 'types/enums/Representant'

type AccountStepProps = {
  initialValues: FormikValues
  validationSchema: Yup.AnySchema
  goBack: () => void
  goForward: (values: FormikValues) => void
}

export function AccountStep({
  initialValues,
  validationSchema,
  goBack,
  goForward
}: AccountStepProps): JSX.Element {
  const { t } = useTranslation(['pages'])

  const { inviteInfo } = useSelector((state: GlobalState) => state.user)

  return (
    <AccountStepWrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validateOnMount={false}
        validationSchema={validationSchema}
        onSubmit={(values) => goForward(values)}
      >
        {({ values }) => (
          <Form>
            <FieldSet
              title={t('pages:subscriptionByInvite.accountStep.form.userGroup')}
            >
              <Flex direction="row" gap={6}>
                <FlexItem grow="8" basis="0">
                  <TextInput
                    name="username"
                    placeholder={t(
                      'pages:subscriptionByInvite.accountStep.form.username'
                    )}
                  />
                </FlexItem>
                <FlexItem grow="4" basis="0" />
              </Flex>

              <Flex direction="row" gap={6}>
                <FlexItem grow="8" basis="0">
                  <TextInput
                    name="name"
                    placeholder={t(
                      'pages:subscriptionByInvite.accountStep.form.name'
                    )}
                  />
                </FlexItem>
                <FlexItem grow="4" basis="0">
                  <MaskedInput
                    name="cpf"
                    placeholder={t(
                      'pages:subscriptionByInvite.accountStep.form.cpf'
                    )}
                    options={{
                      blocks: [3, 3, 3, 2],
                      delimiters: ['.', '.', '-'],
                      numericOnly: true
                    }}
                  />
                </FlexItem>
              </Flex>

              <TextInput
                name="email"
                placeholder={t(
                  'pages:subscriptionByInvite.accountStep.form.email'
                )}
                readOnly
              />
            </FieldSet>

            <FieldSet
              title={t(
                'pages:subscriptionByInvite.accountStep.form.institutionGroup'
              )}
            >
              <InstitutionName>
                {`${inviteInfo?.institution ?? ''} - CNPJ: ${formatCnpj(
                  inviteInfo?.cnpj ?? ''
                )}`}
              </InstitutionName>

              <Flex justify="center">
                <FlexItem>
                  <RadioInput
                    name="representant"
                    options={[
                      {
                        label: 'Sou representante interno',
                        value: Representant.INNER
                      },
                      {
                        label: 'Sou representante externo',
                        value: Representant.OUTER
                      }
                    ]}
                  />
                </FlexItem>
              </Flex>

              {values.representant === Representant.OUTER && (
                <Flex direction="row" gap={6}>
                  <FlexItem grow="8" basis="0">
                    <TextInput
                      name="institutionName"
                      placeholder={t(
                        'pages:subscriptionByInvite.accountStep.form.institution'
                      )}
                    />
                  </FlexItem>
                  <FlexItem grow="4" basis="0">
                    <MaskedInput
                      name="institutionCnpj"
                      placeholder={t(
                        'pages:subscriptionByInvite.accountStep.form.cnpj'
                      )}
                      options={{
                        blocks: [2, 3, 3, 4, 2],
                        delimiters: ['.', '.', '/', '-'],
                        numericOnly: true
                      }}
                    />
                  </FlexItem>
                </Flex>
              )}

              <TextInput
                name="jobDescription"
                placeholder={t(
                  'pages:subscriptionByInvite.accountStep.form.occupation'
                )}
              />
            </FieldSet>

            <FieldSet title="Dados de acesso">
              <Flex direction="row" gap={6}>
                <FlexItem grow="6" basis="0">
                  <PasswordInput
                    name="password"
                    placeholder={t(
                      'pages:subscriptionByInvite.accountStep.form.password'
                    )}
                    autoComplete="new-password"
                  />
                </FlexItem>
                <FlexItem grow="6" basis="0">
                  <TextInput
                    name="passwordConfirmation"
                    type="password"
                    placeholder={t(
                      'pages:subscriptionByInvite.accountStep.form.passwordConfirmation'
                    )}
                    autoComplete="new-password"
                  />
                </FlexItem>
              </Flex>
            </FieldSet>

            <Button type="submit">
              {t('pages:subscriptionByInvite.accountStep.nextButton')}
            </Button>
            <BackButton onClick={() => goBack()} />
          </Form>
        )}
      </Formik>
    </AccountStepWrapper>
  )
}
