import { produce } from 'immer'

import { Action } from 'types/Action'

import { REDUCE_SIDEBAR, EXPAND_SIDEBAR, TOGGLE_SIDEBAR } from './actionTypes'

export type SidebarState = {
  sidebarIsReduced: boolean
}

const INITIAL_STATE: SidebarState = {
  sidebarIsReduced: false
}

export function sidebarReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): SidebarState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REDUCE_SIDEBAR: {
        draft.sidebarIsReduced = true
        break
      }

      case EXPAND_SIDEBAR: {
        draft.sidebarIsReduced = false
        break
      }

      case TOGGLE_SIDEBAR: {
        draft.sidebarIsReduced = !state.sidebarIsReduced
        break
      }

      default: {
        break
      }
    }
  })
}
