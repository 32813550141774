import './i18n'

import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { Bounce } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'

import { AppRouter } from 'navigation/AppRouter'
import { browserHistory } from 'navigation/browserHistory'

import { persistor, store } from 'store'

import GlobalStyles from 'styles/GlobalStyles'
import Theme from 'styles/Theme'
import Toast from 'styles/Toast'

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={Theme}>
          <GlobalStyles />
          <Router history={browserHistory}>
            <Toast autoClose={7000} transition={Bounce} closeButton={false} />
            <AppRouter />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
