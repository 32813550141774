import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Breadcrumb, BreadcrumbItem } from 'components/breadcrumb'
import { Button } from 'components/button'
import { Flex } from 'components/flex'
import { Loading } from 'components/loading'
import { Table } from 'components/table'
import { SearchInput } from 'components/searchInput'

import { withAdminLayout } from 'hoc/withAdminLayout'

import { useQuery } from 'hooks/useQuery'

import { getPrecedentFileUrl } from 'services/http/precedentsService'

import { GlobalState } from 'store'
import { openModal } from 'store/modal/actions'
import { fetchPrecedents } from 'store/precedent/actions'

import { ModalName } from 'types/enums/ModalName'
import { UserType } from 'types/enums/UserType'
import { Precedent } from 'types/precedent/Precedent'
import { TableColumn } from 'types/table/TableColumn'

import { showError } from 'utils/alertUtils'

import { PrecedentCreateModal } from './components/precedentCreateModal'
import { PrecedentDetailsModal } from './components/precedentDetailsModal'
import { PrecedentReviewModal } from './components/precedentReviewModal'

import {
  PrecedentsListWrapper,
  Filename,
  Tags,
  DetailsLink,
  Status
} from './styled'

function Page(): JSX.Element {
  const { t } = useTranslation('pages')

  const query = useQuery()

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    precedent: { precedents, fetchPrecedentsInProgress }
  } = useSelector((state: GlobalState) => state)

  const [filter, setFilter] = useState<string | undefined>(undefined)

  const [downloadFileInProgress, setDownloadFileInProgress] = useState<boolean>(
    false
  )

  const [selectedPrecedent, setSelectedPrecedent] = useState<
    Precedent | undefined
  >(undefined)

  const canSeeReview = useMemo(
    () =>
      sessionUser?.type === UserType.PRECEDENT ||
      sessionUser?.type === UserType.PRECEDENT_REVIEWER,
    [sessionUser]
  )
  const canReviewPrecedent = useMemo(
    () => sessionUser?.type === UserType.PRECEDENT_REVIEWER,
    [sessionUser]
  )
  const canAddPrecedent = useMemo(
    () => sessionUser?.type === UserType.PRECEDENT,
    [sessionUser]
  )

  const columns = useMemo<TableColumn<Precedent>[]>(
    () => [
      {
        label: t('precedentList.table.description'),
        dataKey: 'description',
        render: (row) =>
          row.description.length > 50 ? (
            <span>{row.description.substr(0, 50) + '...'}</span>
          ) : (
            <span>{row.description}</span>
          )
      },
      {
        label: t('precedentList.table.file'),
        dataKey: 'filename',
        render: (row) => (
          <Filename onClick={() => _downloadFile(row.key, row.filename)}>
            {downloadFileInProgress ? <Loading /> : row.filename}
          </Filename>
        )
      },
      {
        label: t('precedentList.table.tags'),
        dataKey: 'tags',
        render: (row) => (
          <Tags>
            {row.tags.map((tag: string, index: number) => (
              <span key={index}>{tag}</span>
            ))}
          </Tags>
        )
      },
      ...(canSeeReview
        ? [
            {
              label: t('precedentList.table.status'),
              dataKey: 'status',
              render: (row: Precedent) =>
                row.status ? (
                  <Status approved={row.status.approve}>
                    <span>{row.status.approve ? 'Aprovado' : 'Rejeitado'}</span>
                  </Status>
                ) : (
                  <Status>
                    <span>Aguardando revisão</span>
                  </Status>
                )
            }
          ]
        : []),
      {
        label: t('precedentList.table.action'),
        dataKey: 'actions',
        thStyle: { width: '120px' },
        tdStyle: { width: '120px' },
        render: (row) => (
          <>
            {canReviewPrecedent && !row.status && (
              <DetailsLink
                onClick={() => {
                  setSelectedPrecedent(row)
                  dispatch(openModal(ModalName.PRECEDENT_REVIEW))
                }}
              >
                Revisar
              </DetailsLink>
            )}
            <DetailsLink
              onClick={() => {
                setSelectedPrecedent(row)
                dispatch(openModal(ModalName.PRECEDENT_DETAILS))
              }}
            >
              Detalhes
            </DetailsLink>
          </>
        )
      }
    ],
    []
  )

  const getPrecedents = useCallback(() => {
    dispatch(
      fetchPrecedents({
        search: filter,
        page: parseInt(query.get('page') ?? '1'),
        limit: 10
      })
    )
  }, [filter, query.toString()])

  useEffect(() => {
    getPrecedents()
  }, [getPrecedents])

  async function _downloadFile(key: string, filename: string) {
    try {
      setDownloadFileInProgress(true)

      const url = await getPrecedentFileUrl(key)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.target = '_blank'
      downloadLink.download = filename

      document.body.appendChild(downloadLink)

      downloadLink.click()

      downloadLink.parentNode?.removeChild(downloadLink)
    } catch (error) {
      showError('downloadPrecedentFile', error)
    } finally {
      setDownloadFileInProgress(false)
    }
  }

  document.title = 'Precedentes - Open Banking Brasil'

  return (
    <PrecedentsListWrapper>
      <Breadcrumb>
        <BreadcrumbItem
          label={t('precedentList.breadcrumb.precedents')}
          isCurrentPage
        />
      </Breadcrumb>

      <Flex justify="space-between">
        <SearchInput
          placeholder={t('precedentList.search')}
          onClick={(value) => setFilter(value)}
        />

        {canAddPrecedent && (
          <Button
            small
            variant="outline"
            color="niceBlue"
            onClick={() => dispatch(openModal(ModalName.PRECEDENT_CREATE))}
          >
            {t('precedentList.addButton')}
          </Button>
        )}
      </Flex>

      <Table
        columns={columns}
        data={precedents.data ?? []}
        pageCount={precedents.pagination.lastPage}
        isLoading={fetchPrecedentsInProgress}
      />

      <PrecedentCreateModal />
      <PrecedentDetailsModal precedent={selectedPrecedent} />
      <PrecedentReviewModal
        precedent={selectedPrecedent}
        onSubmit={getPrecedents}
      />
    </PrecedentsListWrapper>
  )
}

export const PrecedentListPage = withAdminLayout(Page)
