import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ReactComponent as CheckSVG } from 'assets/images/check-thin.svg'
import { ReactComponent as ClockSVG } from 'assets/images/clock.svg'

import { Button } from 'components/button'
import { Loading } from 'components/loading'
import { Modal, ModalHeader } from 'components/modal'
import { Separator } from 'components/separator'
import { Tooltip } from 'components/tooltip'

import { getSignatureDetails } from 'services/http/contestService'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'

import { ContestSignatures } from 'types/contest/ContestSignatures'
import { ModalName } from 'types/enums/ModalName'

import { ContestHeading, ModalBody, ModalHeaderContent } from './styled'

export function ContestSignaturesModal(): JSX.Element {
  const { t } = useTranslation(['modal', 'common'])

  const dispatch = useDispatch()

  const { contestId } = useParams<{ contestId: string }>()

  const {
    modal: { contestSignaturesIsOpen },
    contest: { contestData }
  } = useSelector((state: GlobalState) => state)

  const [signatures, setSignatures] = useState<ContestSignatures | undefined>(
    undefined
  )

  const [
    getSignaturesInProgress,
    setGetSignaturesInProgress
  ] = useState<boolean>(false)

  useEffect(() => {
    if (contestSignaturesIsOpen) {
      _getSignatureDetails()
    }
  }, [contestSignaturesIsOpen])

  async function _getSignatureDetails() {
    setGetSignaturesInProgress(true)
    try {
      const result = await getSignatureDetails(parseInt(contestId))
      setSignatures(result)
    } finally {
      setGetSignaturesInProgress(false)
    }
  }

  function _hasSigned(email: string): boolean {
    if (!signatures?.details) return false

    const detail = signatures.details.find((item) => item.email === email)
    if (detail) {
      return detail.status === 'completed'
    }

    return false
  }

  return (
    <Modal
      isOpen={contestSignaturesIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_SIGNATURES))}
    >
      <ModalHeader separator={false}>
        <ModalHeaderContent>
          <h2>{t('modal:contestSignatures.title')}</h2>
          <h4>
            {t('modal:contestSignatures.subtitle', {
              contestId: contestData?.id
            })}
          </h4>

          <ContestHeading>
            <dl>
              <dt>{t('modal:contestSignatures.heading.requester')}</dt>
              <dd>{contestData?.requester}</dd>
              <dt>{t('modal:contestSignatures.heading.defendant')}</dt>
              <dd>{contestData?.defendant}</dd>
              <dt>{t('modal:contestSignatures.heading.category')}</dt>
              {!!contestData && contestData?.category.length > 30 ? (
                <Tooltip content={contestData?.category}>
                  <dd>{contestData?.category.substr(0, 30) + '...'}</dd>
                </Tooltip>
              ) : (
                <dd>{contestData?.category}</dd>
              )}
            </dl>
          </ContestHeading>

          <Separator color="warmGreyThree" />
        </ModalHeaderContent>
      </ModalHeader>

      {getSignaturesInProgress ? (
        <Loading />
      ) : (
        <ModalBody>
          {signatures && (
            <section>
              <div>
                <h4>
                  {contestData?.requester}{' '}
                  <span>({t('common:contestSide.REQUESTER')})</span>
                </h4>
                <h5>{t('modal:contestSignatures.procurator')}</h5>
                <ul>
                  {signatures.signers.requester.main.map(
                    ({ name, email }, index) => (
                      <li key={index}>
                        <span data-success={_hasSigned(email)}>
                          {_hasSigned(email) ? <CheckSVG /> : <ClockSVG />}
                        </span>
                        {name}
                      </li>
                    )
                  )}
                </ul>
                <h5>{t('modal:contestSignatures.witness')}</h5>
                <ul>
                  {signatures.signers.requester.witness.map(
                    ({ name, email }, index) => (
                      <li key={index}>
                        <span data-success={_hasSigned(email)}>
                          {_hasSigned(email) ? <CheckSVG /> : <ClockSVG />}
                        </span>
                        {name}
                      </li>
                    )
                  )}
                </ul>
              </div>

              <div>
                <h4>
                  {contestData?.defendant}{' '}
                  <span>({t('common:contestSide.DEFENDANT')})</span>
                </h4>

                <h5>{t('modal:contestSignatures.procurator')}</h5>
                <ul>
                  {signatures.signers.defendant.main.map(
                    ({ name, email }, index) => (
                      <li key={index}>
                        <span data-success={_hasSigned(email)}>
                          {_hasSigned(email) ? <CheckSVG /> : <ClockSVG />}
                        </span>
                        {name}
                      </li>
                    )
                  )}
                </ul>

                <h5>{t('modal:contestSignatures.witness')}</h5>
                <ul>
                  {signatures.signers.defendant.witness.map(
                    ({ name, email }, index) => (
                      <li key={index}>
                        <span data-success={_hasSigned(email)}>
                          {_hasSigned(email) ? <CheckSVG /> : <ClockSVG />}
                        </span>
                        {name}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </section>
          )}

          <Button
            type="button"
            color="white"
            backgroundColor="niceBlue"
            small
            onClick={() => dispatch(closeModal(ModalName.CONTEST_SIGNATURES))}
          >
            {t('modal:contestSignatures.closeButton')}
          </Button>
        </ModalBody>
      )}
    </Modal>
  )
}
