import { FormikValues } from 'formik'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { FilesControl } from 'components/filesControl'
import { Form } from 'components/form'
import { Modal, ModalHeader } from 'components/modal'
import { Separator } from 'components/separator'
import { Tabs } from 'components/tabs'
import { TextEditor } from 'components/textEditor'

import { confirmationConfig } from 'config/swal'

import { GlobalState } from 'store'
import { replyContest } from 'store/contest/actions'
import { closeModal } from 'store/modal/actions'

import { ContestUpdateAction } from 'types/enums/ContestUpdateAction'
import { ModalName } from 'types/enums/ModalName'

import { formatDateBR } from 'utils/formatUtils'

import { ContestHeading, ModalBody, ModalHeaderContent } from './styled'

export function ContestAnswerModal(): JSX.Element {
  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    modal: { contestAnswerIsOpen },
    contest: { contestData, replyContestInProgress }
  } = useSelector((state: GlobalState) => state)

  const { contestId } = useParams<{ contestId: string }>()

  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const initialValues = {
    text: '',
    documents: []
  }

  const validationSchema = Yup.object().shape({
    text: Yup.string().required('required')
  })

  function _handleSubmit(data: FormikValues) {
    if (contestData?.id) {
      withReactContent(SweetAlert)
        .fire({
          ...confirmationConfig,
          icon: 'warning',
          title: t('contestAnswer.confirmation.title'),
          html: (
            <span
              dangerouslySetInnerHTML={{
                __html: t('contestAnswer.confirmation.message')
              }}
            />
          ),
          confirmButtonText: t('contestAnswer.confirmation.confirmButton'),
          cancelButtonText: t('contestAnswer.confirmation.cancelButton')
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            dispatch(
              replyContest({
                contestId: contestData.id,
                action: ContestUpdateAction.REPLY,
                data: {
                  text: data.text
                },
                documents: data.documents
              })
            )
          }
        })
    }
  }

  return (
    <Modal
      isOpen={contestAnswerIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_ANSWER))}
    >
      <ModalHeader separator={false}>
        <ModalHeaderContent>
          <h2>{t('contestAnswer.title')}</h2>
          <h4>{t('contestAnswer.subtitle', { contestId })}</h4>

          <ContestHeading>
            <dl>
              <dt>{t('contestAnswer.heading.requester')}</dt>
              <dd>{contestData?.requester}</dd>
              <dt>{t('contestAnswer.heading.defendant')}</dt>
              <dd>{contestData?.defendant}</dd>
              <dt>{t('contestAnswer.heading.replyDate')}</dt>
              <dd>{formatDateBR(new Date().toISOString())}</dd>
            </dl>

            <Button
              small
              type="button"
              onClick={() => submitButtonRef.current?.click()}
              inProgress={replyContestInProgress}
            >
              {t('contestAnswer.heading.saveButton')}
            </Button>
          </ContestHeading>

          <Separator color="warmGreyThree" />
        </ModalHeaderContent>
      </ModalHeader>

      <ModalBody>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
        >
          <Tabs
            tabs={[
              {
                id: 'description',
                title: t('contestAnswer.form.tabs.description.title'),
                content: (
                  <TextEditor
                    name="text"
                    placeholder={t('contestAnswer.form.tabs.description.text')}
                    height={200}
                  />
                )
              },
              {
                id: 'attachments',
                title: t('contestAnswer.form.tabs.attachments.title'),
                content: <FilesControl name="documents" />
              }
            ]}
            small
          />

          <button ref={submitButtonRef} type="submit" hidden />
        </Form>
      </ModalBody>
    </Modal>
  )
}
