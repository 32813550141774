import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import { browserHistory } from 'navigation/browserHistory'
import { LOGIN_PAGE } from 'navigation/paths'

import * as userService from 'services/http/userService'

import { closeModal } from 'store/modal/actions'
import { clearSessionData } from 'store/session/actions'
import {
  fetchUserInfoFailure,
  fetchUserInfoSuccess,
  searchUserFailure,
  searchUserSuccess,
  fetchInviteInfoFailure,
  fetchInviteInfoSuccess,
  requestPasswordResetFailure,
  requestPasswordResetSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  subscribeByInviteFailure,
  subscribeByInviteSuccess,
  updatePasswordFailure,
  updatePasswordSuccess,
  updateUserAddressFailure,
  updateUserAddressSuccess,
  updateUserFailure,
  updateUserSuccess
} from 'store/user/actions'
import {
  FETCH_USER_INFO,
  FETCH_INVITE_INFO,
  SUBSCRIBE_BY_INVITE,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  UPDATE_USER,
  UPDATE_PASSWORD,
  UPDATE_USER_ADDRESS,
  SEARCH_USER
} from 'store/user/actionTypes'

import { Action } from 'types/Action'
import { ModalName } from 'types/enums/ModalName'
import { PagedResult } from 'types/PagedResult'
import { SearchUserQuery } from 'types/user/SearchUserQuery'
import { User } from 'types/user/User'
import { UserInviteInfo } from 'types/user/UserInviteInfo'
import { UserSubscription } from 'types/user/UserSubscription'
import { UserPasswordReset } from 'types/user/UserPasswordReset'
import { UserPasswordUpdate } from 'types/user/UserPasswordUpdate'
import { UserRequestPasswordReset } from 'types/user/UserRequestPasswordReset'
import { UserUpdate } from 'types/user/UserUpdate'
import { UserUpdateAddress } from 'types/user/UserUpdateAddress'

import { showError, showSuccess } from 'utils/alertUtils'

function* fetchUserInfoRequest({
  payload
}: Action<number>): Generator<StrictEffect, void, User> {
  try {
    const response = yield call(userService.fetchUserInfo, payload)
    yield put(fetchUserInfoSuccess(response))
  } catch (error) {
    yield call(showError, 'fetchUserInfo', error)
    yield call(browserHistory.push, LOGIN_PAGE)
    yield put(fetchUserInfoFailure())
  }
}

function* searchUserRequest({
  payload
}: Action<SearchUserQuery>): Generator<StrictEffect, void, PagedResult<User>> {
  try {
    const response = yield call(userService.searchUser, payload)
    yield put(searchUserSuccess(response))
  } catch (error) {
    yield call(showError, 'searchUser', error)
    yield put(searchUserFailure())
  }
}

function* fetchInviteInfoRequest({
  payload
}: Action<string>): Generator<StrictEffect, void, UserInviteInfo> {
  try {
    const response = yield call(userService.fetchUserInviteInfo, payload)
    yield put(fetchInviteInfoSuccess(response))
  } catch (error) {
    yield call(showError, 'fetchInviteInfo', error)
    yield call(browserHistory.push, LOGIN_PAGE)
    yield put(fetchInviteInfoFailure())
  }
}

function* subscribeByInviteRequest({
  payload
}: Action<UserSubscription & { inviteId: string }>): Generator<
  StrictEffect,
  void,
  User
> {
  try {
    const { inviteId, ...rest } = payload
    const response = yield call(userService.subscribeByInvite, inviteId, rest)
    yield call(browserHistory.push, LOGIN_PAGE)
    yield call(showSuccess, 'subscriptionByInvite')
    yield put(subscribeByInviteSuccess(response))
  } catch (error) {
    yield call(showError, 'subscriptionByInvite', error)
    yield put(subscribeByInviteFailure())
  }
}

function* requestPasswordReset({
  payload
}: Action<UserRequestPasswordReset>): Generator<StrictEffect, void, string> {
  try {
    const response = yield call(userService.requestPasswordReset, payload)
    yield call(showSuccess, 'resetPassword')
    yield put(closeModal(ModalName.FORGET_PASSWORD))
    yield put(requestPasswordResetSuccess(response))
  } catch (error) {
    yield call(showError, 'resetPassword', error)
    yield put(requestPasswordResetFailure())
  }
}

function* resetPasswordRequest({
  payload
}: Action<UserPasswordReset>): Generator<StrictEffect, void, User> {
  try {
    const response = yield call(userService.resetPassword, payload)
    yield call(browserHistory.push, LOGIN_PAGE)
    yield call(showSuccess, 'updatePassword')
    yield put(resetPasswordSuccess(response))
  } catch (error) {
    yield call(showError, 'updatePassword', error)
    yield put(resetPasswordFailure())
  }
}

function* updateUserRequest({
  payload
}: Action<UserUpdate>): Generator<StrictEffect, void, User> {
  try {
    const response = yield call(userService.updateUser, payload)
    yield call(showSuccess, 'updateUser')
    yield put(updateUserSuccess(response))
  } catch (error) {
    yield call(showError, 'updateUser', error)
    yield put(updateUserFailure())
  }
}

function* updateUserAddressRequest({
  payload
}: Action<UserUpdateAddress>): Generator<StrictEffect, void, User> {
  try {
    const response = yield call(userService.updateUserAddress, payload)
    yield call(showSuccess, 'updateUserAddress')
    yield put(updateUserAddressSuccess(response))
  } catch (error) {
    yield call(showError, 'updateUserAddress', error)
    yield put(updateUserAddressFailure())
  }
}

function* updatePasswordRequest({
  payload
}: Action<UserPasswordUpdate>): Generator<StrictEffect, void, User> {
  try {
    const response = yield call(userService.updatePassword, payload)
    yield put(clearSessionData())
    yield call(browserHistory.push, LOGIN_PAGE)
    yield call(showSuccess, 'updatePassword')
    yield put(updatePasswordSuccess(response))
  } catch (error) {
    yield call(showError, 'updatePassword', error)
    yield put(updatePasswordFailure())
  }
}

export function* userSagas(): Generator {
  yield all([
    takeLatest(FETCH_USER_INFO, fetchUserInfoRequest),
    takeLatest(SEARCH_USER, searchUserRequest),
    takeLatest(FETCH_INVITE_INFO, fetchInviteInfoRequest),
    takeLatest(SUBSCRIBE_BY_INVITE, subscribeByInviteRequest),
    takeLatest(REQUEST_PASSWORD_RESET, requestPasswordReset),
    takeLatest(RESET_PASSWORD, resetPasswordRequest),
    takeLatest(UPDATE_USER, updateUserRequest),
    takeLatest(UPDATE_USER_ADDRESS, updateUserAddressRequest),
    takeLatest(UPDATE_PASSWORD, updatePasswordRequest)
  ])
}
