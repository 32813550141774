import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import * as contestMessageService from 'services/http/contestMessageService'

import {
  fetchContestMessagesFailure,
  fetchContestMessagesSuccess,
  createContestMessageFailure,
  createContestMessageSuccess
} from 'store/contestMessage/actions'
import {
  FETCH_CONTEST_MESSAGES,
  CREATE_CONTEST_MESSAGE
} from 'store/contestMessage/actionTypes'

import { Action } from 'types/Action'
import { ContestMessage } from 'types/contestMessage/ContestMessage'
import { ContestMessageCreate } from 'types/contestMessage/ContestMessageCreate'

import { showError } from 'utils/alertUtils'

function* fetchContestMessagesRequest({
  payload
}: Action<number>): Generator<StrictEffect, void, ContestMessage[]> {
  try {
    const response = yield call(
      contestMessageService.fetchContestMessages,
      payload
    )
    yield put(fetchContestMessagesSuccess(response))
  } catch (error) {
    yield call(showError, 'fetchContestMessages', error)
    yield put(fetchContestMessagesFailure())
  }
}

function* createContestMessageRequest({
  payload
}: Action<ContestMessageCreate>): Generator<
  StrictEffect,
  void,
  ContestMessage[]
> {
  try {
    const response = yield call(
      contestMessageService.createContestMessages,
      payload
    )
    yield put(createContestMessageSuccess(response))
  } catch (error) {
    yield call(showError, 'createContestMessage', error)
    yield put(createContestMessageFailure())
  }
}

export function* contestMessageSagas(): Generator {
  yield all([takeLatest(FETCH_CONTEST_MESSAGES, fetchContestMessagesRequest)])
  yield all([takeLatest(CREATE_CONTEST_MESSAGE, createContestMessageRequest)])
}
