import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { Modal, ModalHeader, ModalBody } from 'components/modal'
import { FilesControl } from 'components/filesControl'
import { Flex } from 'components/flex'
import { Form } from 'components/form'
import { TagsInput } from 'components/tagsInput'
import { Textarea } from 'components/textarea'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'
import { createPrecedent } from 'store/precedent/actions'

import { ModalName } from 'types/enums/ModalName'

export function PrecedentCreateModal(): JSX.Element {
  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    modal: { precedentCreateIsOpen },
    precedent: { createPrecedentInProgress }
  } = useSelector((state: GlobalState) => state)

  const initialValues = {
    description: '',
    tags: '',
    documents: []
  }

  const validationSchema = Yup.object().shape({
    description: Yup.string().required('required'),
    tags: Yup.string().required('required'),
    documents: Yup.array().required('required').length(1, 'files.length')
  })

  function _handleSubmit(data: FormikValues) {
    dispatch(
      createPrecedent({
        description: data.description,
        tags: data.tags,
        document: data.documents[0]
      })
    )
  }

  return (
    <Modal
      isOpen={precedentCreateIsOpen}
      onClose={() => dispatch(closeModal(ModalName.PRECEDENT_CREATE))}
    >
      <ModalHeader separator={false}>
        <h2>{t('precedentCreate.title')}</h2>
      </ModalHeader>

      <ModalBody>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
        >
          <Flex direction="column" align="center">
            <Textarea
              name="description"
              placeholder={t('precedentCreate.form.description')}
              rows={6}
            />

            <TagsInput
              name="tags"
              placeholder={t('precedentCreate.form.tags')}
            />

            <FilesControl name="documents" multiple={false} />

            <Button small type="submit" inProgress={createPrecedentInProgress}>
              {t('precedentCreate.submitButton')}
            </Button>
          </Flex>
        </Form>
      </ModalBody>
    </Modal>
  )
}
