import styled from 'styled-components'

type TagsInputContainerProps = {
  hasError?: boolean
}

export const TagsInputContainer = styled.div<TagsInputContainerProps>`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings[8]};

  & > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacings[2]};

    width: 100%;

    padding: ${({ theme }) => `${theme.spacings[2.5]} ${theme.spacings[4]}`};

    color: ${({ theme }) => theme.colors.greyishBrownFour};

    border: 1px solid
      ${({ theme, hasError }) => {
        if (hasError) {
          return theme.colors.blush
        }

        return theme.colors.warmGreyThree
      }};

    border-radius: 2rem;

    background-color: ${({ theme }) => theme.colors.white};

    &:focus-within {
      z-index: 1;
      box-shadow: 0 0 0 1px
        ${({ theme, hasError }) => {
          if (hasError) {
            return theme.colors.blush
          }

          return theme.colors.niceBlue
        }};
      border-color: ${({ theme, hasError }) => {
        if (hasError) {
          return theme.colors.blush
        }

        return theme.colors.niceBlue
      }};
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: ${({ theme }) => theme.spacings[2]};

      list-style-type: none;

      li {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        font-size: ${({ theme }) => theme.fontSizes.sm};

        padding: ${({ theme }) => `${theme.spacings[1]} ${theme.spacings[2]}`};

        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.niceBlue};
        border-radius: 1.25rem;

        span {
          font-size: 90%;
          white-space: nowrap; // does not break word
          margin-right: ${({ theme }) => theme.spacings[1]};
        }

        button {
          line-height: 0;
          color: inherit;

          svg {
            width: ${({ theme }) => theme.fontSizes.xxsm};
          }

          &:hover {
            color: ${({ theme }) => theme.colors.blush};
          }
        }
      }
    }

    input {
      flex: 1;

      border: 0;
      background-color: transparent;

      &::placeholder {
        color: ${({ theme }) => theme.colors.pinkishGrey};
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: ${({ theme }) => theme.colors.pinkishGrey};
      }

      &:disabled,
      &:read-only {
        border-color: ${({ theme }) => theme.colors.paleGrey};
        background-color: ${({ theme }) => theme.colors.paleGreyThree};
      }
    }
  }

  & > p {
    position: absolute;
    top: 105%;

    color: ${({ theme }) => theme.colors.blush};
    margin: ${({ theme }) => `0 ${theme.spacings[4]}`};
  }
`
