import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { browserHistory } from 'navigation/browserHistory'
import { LOGIN_PAGE } from 'navigation/paths'

import { store } from 'store'
import { clearSessionData } from 'store/session/actions'

import { HttpError } from 'types/errors/HttpError'
import { HttpError401 } from 'types/errors/HttpError401'
import { HttpError422 } from 'types/errors/HttpError422'
import { HttpResponse } from 'types/HttpResponse'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json'
  },
  withCredentials: true
})

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const { token } = store.getState().session
  if (token) {
    config.headers.Authorization = `${token.type} ${token.token}`
  }
  return config
})

instance.interceptors.response.use(
  (response: AxiosResponse<HttpResponse>) => {
    if (response.data.code === 'SUCCESS') {
      return Promise.resolve(response.data.data)
    } else if (!response.data.code) {
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response.data)
    }
  },
  (error) => {
    const { status, data } = error.response

    if (!data) return Promise.reject(error)

    switch (status) {
      case 401:
        store.dispatch(clearSessionData())
        browserHistory.push(LOGIN_PAGE)
        return Promise.reject(new HttpError401())

      case 422:
        return Promise.reject(new HttpError422(data))

      default:
        return Promise.reject(
          new HttpError(status, data.code, data.message, data?.detail)
        )
    }
  }
)

export const client = instance

export async function GET<TReturn>(
  url: string,
  params?: { [key: string]: unknown },
  headers?: { [key: string]: unknown }
): Promise<TReturn> {
  try {
    const response = await instance.get<null, TReturn>(url, { params, headers })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function POST<TParams, TReturn>(
  url: string,
  body?: TParams,
  headers?: { [key: string]: string }
): Promise<TReturn> {
  try {
    const response = await instance.post<TParams, TReturn>(url, body, {
      headers
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function PUT<TParams, TReturn>(
  url: string,
  body?: TParams,
  headers?: { [key: string]: string }
): Promise<TReturn> {
  try {
    const response = await instance.put<TParams, TReturn>(url, body, {
      headers
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function DELETE<TParams, TReturn>(
  url: string,
  data?: TParams,
  headers?: { [key: string]: string }
): Promise<TReturn> {
  try {
    const response = await instance.delete<TParams, TReturn>(url, {
      data,
      headers
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}
