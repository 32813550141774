import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR'
import { useField } from 'formik'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'

import { DateInputContainer } from './styled'

type DateInputProps = {
  name: string
  placeholder: string
  [key: string]: unknown
}

registerLocale('pt-BR', ptBR)

export function DateInput({
  name,
  placeholder,
  ...rest
}: DateInputProps): JSX.Element {
  const { t } = useTranslation(['validation'])

  const [field, meta, helpers] = useField({ name, type: 'date' })

  return (
    <DateInputContainer hasError={meta.touched && !!meta.error}>
      <DatePicker
        id={name}
        name={name}
        locale="pt-BR"
        className="datePicker"
        popperClassName="datePickerPopper"
        showPopperArrow={false}
        dateFormat="P"
        placeholderText={placeholder}
        selected={field.value}
        onChange={(date) => helpers.setValue(date)}
        {...rest}
        tabIndex={rest.readOnly || rest.disabled ? -1 : 0}
        autoComplete="off"
      />
      {meta.touched && !!meta.error && (
        <p>
          {t(`validation:${meta.error}`, {
            field: placeholder
          })}
        </p>
      )}
    </DateInputContainer>
  )
}
