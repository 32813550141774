import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as DownloadSVG } from 'assets/images/download.svg'

import { Button } from 'components/button'
import { Loading } from 'components/loading'
import { Modal, ModalHeader, ModalBody } from 'components/modal'
import { Flex } from 'components/flex'

import { getPrecedentFileUrl } from 'services/http/precedentsService'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'

import { ModalName } from 'types/enums/ModalName'
import { Precedent } from 'types/precedent/Precedent'
import { UserType } from 'types/enums/UserType'

import { showError } from 'utils/alertUtils'

import {
  Filename,
  TextBox,
  Tags,
  Rejected,
  RejectedDescription
} from './styled'

type Props = {
  precedent?: Precedent
}

export function PrecedentDetailsModal({ precedent }: Props): JSX.Element {
  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    modal: { precedentDetailsIsOpen }
  } = useSelector((state: GlobalState) => state)

  const isRejected = useMemo(
    () =>
      typeof precedent?.status?.approve !== 'undefined' &&
      !precedent?.status?.approve,
    [precedent]
  )

  const canSeeReview = useMemo(
    () =>
      (sessionUser?.type === UserType.PRECEDENT && isRejected) ||
      (sessionUser?.type === UserType.PRECEDENT_REVIEWER && isRejected),
    [sessionUser, isRejected]
  )

  const [downloadFileInProgress, setDownloadFileInProgress] = useState<boolean>(
    false
  )

  async function _downloadFile(key: string, filename: string) {
    try {
      setDownloadFileInProgress(true)

      const url = await getPrecedentFileUrl(key)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.target = '_blank'
      downloadLink.download = filename

      document.body.appendChild(downloadLink)

      downloadLink.click()

      downloadLink.parentNode?.removeChild(downloadLink)
    } catch (error) {
      showError('downloadPrecedentFile', error)
    } finally {
      setDownloadFileInProgress(false)
    }
  }

  return (
    <Modal
      isOpen={precedentDetailsIsOpen}
      onClose={() => dispatch(closeModal(ModalName.PRECEDENT_DETAILS))}
    >
      <ModalHeader separator={false}>
        <h2>{t('precedentDetails.title')}</h2>
        {canSeeReview && (
          <Rejected>
            <span>{t('precedentDetails.rejected')}</span>
          </Rejected>
        )}
      </ModalHeader>

      {precedent && (
        <ModalBody>
          <Filename
            onClick={() => _downloadFile(precedent.key, precedent.filename)}
          >
            {downloadFileInProgress ? (
              <Loading />
            ) : (
              <>
                <DownloadSVG />
                <span>{precedent.filename}</span>
              </>
            )}
          </Filename>

          <TextBox>
            <p>{precedent.description}</p>
          </TextBox>

          <Tags>
            {precedent.tags.map((tag, index) => (
              <span key={index}>{tag}</span>
            ))}
          </Tags>

          {canSeeReview && (
            <RejectedDescription>
              <h3>{t('precedentDetails.rejectedDescription')}</h3>
              <p>{precedent?.status?.message}</p>
            </RejectedDescription>
          )}

          <Flex justify="center">
            <Button
              small
              onClick={() => dispatch(closeModal(ModalName.PRECEDENT_DETAILS))}
            >
              {t('precedentDetails.closeButton')}
            </Button>
          </Flex>
        </ModalBody>
      )}
    </Modal>
  )
}
