import styled from 'styled-components'

import { ModalBodyContainer } from 'components/modal/styled'

export const ContestHeading = styled.dl`
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  row-gap: ${({ theme }) => theme.spacings[1.5]};

  margin-bottom: ${({ theme }) => theme.spacings[4]};

  dt {
    font-size: ${({ theme }) => theme.fontSizes.xsm};
    color: ${({ theme }) => theme.colors.warmGrey};
  }

  dd {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.greyishBrownFive};
  }
`

export const ModalHeaderContent = styled.div`
  display: block;
  width: 100%;

  h2 {
    margin-bottom: ${({ theme }) => theme.spacings[1]};
    text-align: center;
  }

  h4 {
    color: ${({ theme }) => theme.colors.niceBlue};
    margin-bottom: ${({ theme }) => theme.spacings[4]};
    text-align: center;
  }
`

export const ModalBody = styled(ModalBodyContainer)`
  & > button {
    margin: 0 auto;
  }
`
