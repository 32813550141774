import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'
import { DashboardData } from 'types/dashboard/DashboardData'

import { GET_DATA, GET_DATA_FAILURE, GET_DATA_SUCCESS } from './actionTypes'

export type DashboardState = {
  data?: DashboardData
  getDataInProgress: boolean
}

const INITIAL_STATE: DashboardState = {
  data: undefined,
  getDataInProgress: false
}

export function dashboardReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): DashboardState {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_DATA: {
        draft.getDataInProgress = true
        break
      }

      case GET_DATA_FAILURE: {
        draft.data = undefined
        draft.getDataInProgress = false
        break
      }

      case GET_DATA_SUCCESS: {
        draft.data = <DashboardData>action.payload
        draft.getDataInProgress = false
        break
      }

      case CLEAR_SESSION_DATA: {
        return INITIAL_STATE
      }

      default: {
        break
      }
    }
  })
}
