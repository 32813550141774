import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { FieldSet } from 'components/fieldSet'
import { Form } from 'components/form'
import { Flex, FlexItem } from 'components/flex'
import { TextInput } from 'components/textInput'

import { browserHistory } from 'navigation/browserHistory'
import { SETTINGS_UPDATE_PASSWORD_PAGE } from 'navigation/paths'

import { GlobalState } from 'store'

import { UserType } from 'types/enums/UserType'

export function AuthForm(): JSX.Element {
  const { t } = useTranslation(['pages'])

  const { user } = useSelector((state: GlobalState) => state.session)

  const initialValues = {
    username: user?.username ?? '',
    email: user?.email ?? ''
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('required'),
    email: Yup.string().email('email').required('required')
  })

  function _handleSubmit(data: FormikValues) {
    console.log(data)
  }

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => _handleSubmit(values)}
    >
      <FieldSet title={t('pages:settings.authForm.title')}>
        <Flex gap={6}>
          <FlexItem grow="1" basis="0">
            <TextInput
              name="username"
              placeholder={t('pages:settings.userForm.username')}
              disabled
            />
          </FlexItem>
          <FlexItem grow="1" basis="0" />
        </Flex>
        <Flex>
          <FlexItem grow="1">
            <TextInput
              name="email"
              placeholder={t('pages:settings.authForm.email')}
              disabled
            />
          </FlexItem>
        </Flex>
      </FieldSet>

      <Flex>
        <Button
          color="niceBlue"
          variant="outline"
          type="submit"
          small
          disabled={user?.type === UserType.DIRECTORY}
          onClick={() => browserHistory.push(SETTINGS_UPDATE_PASSWORD_PAGE)}
        >
          {t('pages:settings.authForm.changePasswordButton')}
        </Button>
      </Flex>

      <Flex justify="flex-end">
        <Button type="submit" small disabled>
          {t('pages:settings.authForm.submitButton')}
        </Button>
      </Flex>
    </Form>
  )
}
