import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'
import { Institution } from 'types/institution/Institution'
import { InstitutionSearchResult } from 'types/institution/InstitutionSearchResult'
import { PagedResult } from 'types/PagedResult'

import {
  FETCH_INSTITUTION,
  FETCH_INSTITUTION_FAILURE,
  FETCH_INSTITUTION_SUCCESS,
  SEARCH_INSTITUTION,
  SEARCH_INSTITUTION_FAILURE,
  SEARCH_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION,
  UPDATE_INSTITUTION_FAILURE,
  UPDATE_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION_ADDRESS,
  UPDATE_INSTITUTION_ADDRESS_FAILURE,
  UPDATE_INSTITUTION_ADDRESS_SUCCESS,
  INVITE_USER,
  INVITE_USER_FAILURE,
  INVITE_USER_SUCCESS,
  REMOVE_USER,
  REMOVE_USER_FAILURE,
  REMOVE_USER_SUCCESS,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_FAILURE,
  UPDATE_USER_ROLE_SUCCESS
} from './actionTypes'

export type InstitutionState = {
  institution?: Institution
  fetchInstitutionInProgress: boolean
  searchInstitutionResult?: PagedResult<InstitutionSearchResult>
  searchInstitutionInProgress: boolean
  updateInstitutionInProgress: boolean
  updateInstitutionAddressInProgress: boolean
  inviteUserInProgress: boolean
  removeUserInProgress: boolean
  updateUserRoleInProgress: boolean
}

const INITIAL_STATE: InstitutionState = {
  institution: undefined,
  fetchInstitutionInProgress: false,
  searchInstitutionResult: undefined,
  searchInstitutionInProgress: false,
  updateInstitutionInProgress: false,
  updateInstitutionAddressInProgress: false,
  inviteUserInProgress: false,
  removeUserInProgress: false,
  updateUserRoleInProgress: false
}

export function institutionReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): InstitutionState {
  return produce(state, (draft) => {
    switch (action.type) {
      case FETCH_INSTITUTION: {
        draft.fetchInstitutionInProgress = true
        break
      }

      case FETCH_INSTITUTION_FAILURE: {
        draft.fetchInstitutionInProgress = false
        break
      }

      case FETCH_INSTITUTION_SUCCESS: {
        draft.institution = <Institution>action.payload
        draft.fetchInstitutionInProgress = false
        break
      }

      case SEARCH_INSTITUTION: {
        draft.searchInstitutionInProgress = true
        break
      }

      case SEARCH_INSTITUTION_FAILURE: {
        draft.searchInstitutionInProgress = false
        break
      }

      case SEARCH_INSTITUTION_SUCCESS: {
        draft.searchInstitutionResult = <PagedResult<InstitutionSearchResult>>(
          action.payload
        )
        draft.searchInstitutionInProgress = false
        break
      }

      case UPDATE_INSTITUTION: {
        draft.updateInstitutionInProgress = true
        break
      }

      case UPDATE_INSTITUTION_FAILURE: {
        draft.updateInstitutionInProgress = false
        break
      }

      case UPDATE_INSTITUTION_SUCCESS: {
        draft.updateInstitutionInProgress = false
        break
      }

      case UPDATE_INSTITUTION_ADDRESS: {
        draft.updateInstitutionAddressInProgress = true
        break
      }

      case UPDATE_INSTITUTION_ADDRESS_FAILURE: {
        draft.updateInstitutionAddressInProgress = false
        break
      }

      case UPDATE_INSTITUTION_ADDRESS_SUCCESS: {
        draft.updateInstitutionAddressInProgress = false
        break
      }

      case INVITE_USER: {
        draft.inviteUserInProgress = true
        break
      }

      case INVITE_USER_FAILURE: {
        draft.inviteUserInProgress = false
        break
      }

      case INVITE_USER_SUCCESS: {
        draft.institution = <Institution>action.payload
        draft.inviteUserInProgress = false
        break
      }

      case REMOVE_USER: {
        draft.removeUserInProgress = true
        break
      }

      case REMOVE_USER_FAILURE: {
        draft.removeUserInProgress = false
        break
      }

      case REMOVE_USER_SUCCESS: {
        draft.institution = <Institution>action.payload
        draft.removeUserInProgress = false
        break
      }

      case UPDATE_USER_ROLE: {
        draft.updateUserRoleInProgress = true
        break
      }

      case UPDATE_USER_ROLE_FAILURE: {
        draft.updateUserRoleInProgress = false
        break
      }

      case UPDATE_USER_ROLE_SUCCESS: {
        draft.institution = <Institution>action.payload
        draft.updateUserRoleInProgress = false
        break
      }

      case CLEAR_SESSION_DATA: {
        draft.institution = undefined
        draft.fetchInstitutionInProgress = false
        draft.searchInstitutionResult = undefined
        draft.searchInstitutionInProgress = false
        draft.updateInstitutionInProgress = false
        draft.updateInstitutionAddressInProgress = false
        draft.inviteUserInProgress = false
        draft.removeUserInProgress = false
        draft.updateUserRoleInProgress = false
        break
      }

      default: {
        break
      }
    }
  })
}
