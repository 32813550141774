export const SEARCH_USER_CONTESTS = '@contest/SEARCH_USER_CONTESTS'
export const SEARCH_USER_CONTESTS_SUCCESS =
  '@contest/SEARCH_USER_CONTESTS_SUCCESS'
export const SEARCH_USER_CONTESTS_FAILURE =
  '@contest/SEARCH_USER_CONTESTS_FAILURE'

export const CREATE_CONTEST = '@contest/CREATE_CONTEST'
export const CREATE_CONTEST_SUCCESS = '@contest/CREATE_CONTEST_SUCCESS'
export const CREATE_CONTEST_FAILURE = '@contest/CREATE_CONTEST_FAILURE'

export const ADD_CONTEST_MEMBER = '@contest/ADD_CONTEST_MEMBER'
export const ADD_CONTEST_MEMBER_SUCCESS = '@contest/ADD_CONTEST_MEMBER_SUCCESS'
export const ADD_CONTEST_MEMBER_FAILURE = '@contest/ADD_CONTEST_MEMBER_FAILURE'

export const UPDATE_CONTEST_MEMBER_PERMISSION =
  '@contest/UPDATE_CONTEST_MEMBER_PERMISSION'
export const UPDATE_CONTEST_MEMBER_PERMISSION_SUCCESS =
  '@contest/UPDATE_CONTEST_MEMBER_PERMISSION_SUCCESS'
export const UPDATE_CONTEST_MEMBER_PERMISSION_FAILURE =
  '@contest/UPDATE_CONTEST_MEMBER_PERMISSION_FAILURE'

export const REMOVE_CONTEST_MEMBER = '@contest/REMOVE_CONTEST_MEMBER'
export const REMOVE_CONTEST_MEMBER_SUCCESS =
  '@contest/REMOVE_CONTEST_MEMBER_SUCCESS'
export const REMOVE_CONTEST_MEMBER_FAILURE =
  '@contest/REMOVE_CONTEST_MEMBER_FAILURE'

export const PAY_CONTEST = '@contest/PAY_CONTEST'
export const PAY_CONTEST_SUCCESS = '@contest/PAY_CONTEST_SUCCESS'
export const PAY_CONTEST_FAILURE = '@contest/PAY_CONTEST_FAILURE'

export const PAY_CONTEST_BOLETO = '@contest/PAY_CONTEST_BOLETO'
export const PAY_CONTEST_BOLETO_SUCCESS = '@contest/PAY_CONTEST_BOLETO_SUCCESS'
export const PAY_CONTEST_BOLETO_FAILURE = '@contest/PAY_CONTEST_BOLETO_FAILURE'

export const GET_CONTEST_BY_ID = '@contest/GET_CONTEST_BY_ID'
export const GET_CONTEST_BY_ID_SUCCESS = '@contest/GET_CONTEST_BY_ID_SUCCESS'
export const GET_CONTEST_BY_ID_FAILURE = '@contest/GET_CONTEST_BY_ID_FAILURE'

export const FETCH_CONTEST_CATEGORIES = '@contest/FETCH_CONTEST_CATEGORIES'
export const FETCH_CONTEST_CATEGORIES_SUCCESS =
  '@contest/FETCH_CONTEST_CATEGORIES_SUCCESS'
export const FETCH_CONTEST_CATEGORIES_FAILURE =
  '@contest/FETCH_CONTEST_CATEGORIES_FAILURE'

export const GET_CONTEST_ACTIONS = '@contest/GET_CONTEST_ACTIONS'
export const GET_CONTEST_ACTIONS_SUCCESS =
  '@contest/GET_CONTEST_ACTIONS_SUCCESS'
export const GET_CONTEST_ACTIONS_FAILURE =
  '@contest/GET_CONTEST_ACTIONS_FAILURE'

export const REPLY_CONTEST = '@contest/REPLY_CONTEST'
export const REPLY_CONTEST_SUCCESS = '@contest/REPLY_CONTEST_SUCCESS'
export const REPLY_CONTEST_FAILURE = '@contest/REPLY_CONTEST_FAILURE'

export const DECLINE_CONTEST = '@contest/DECLINE_CONTEST'
export const DECLINE_CONTEST_SUCCESS = '@contest/DECLINE_CONTEST_SUCCESS'
export const DECLINE_CONTEST_FAILURE = '@contest/DECLINE_CONTEST_FAILURE'

export const ACCEPT_CONTEST = '@contest/ACCEPT_CONTEST'
export const ACCEPT_CONTEST_SUCCESS = '@contest/ACCEPT_CONTEST_SUCCESS'
export const ACCEPT_CONTEST_FAILURE = '@contest/ACCEPT_CONTEST_FAILURE'

export const SEND_AGREEMENT = '@contest/SEND_AGREEMENT'
export const SEND_AGREEMENT_SUCCESS = '@contest/SEND_AGREEMENT_SUCCESS'
export const SEND_AGREEMENT_FAILURE = '@contest/SEND_AGREEMENT_FAILURE'

export const ACCEPT_AGREEMENT = '@contest/ACCEPT_AGREEMENT'
export const ACCEPT_AGREEMENT_SUCCESS = '@contest/ACCEPT_AGREEMENT_SUCCESS'
export const ACCEPT_AGREEMENT_FAILURE = '@contest/ACCEPT_AGREEMENT_FAILURE'

export const SEND_SUBSCRIBERS = '@contest/SEND_SUBSCRIBERS'
export const SEND_SUBSCRIBERS_SUCCESS = '@contest/SEND_SUBSCRIBERS_SUCCESS'
export const SEND_SUBSCRIBERS_FAILURE = '@contest/SEND_SUBSCRIBERS_FAILURE'

export const ASK_TO_GIVE_UP = '@contest/ASK_TO_GIVE_UP'
export const ASK_TO_GIVE_UP_SUCCESS = '@contest/ASK_TO_GIVE_UP_SUCCESS'
export const ASK_TO_GIVE_UP_FAILURE = '@contest/ASK_TO_GIVE_UP_FAILURE'

export const ANSWER_GIVE_UP = '@contest/ANSWER_GIVE_UP'
export const ANSWER_GIVE_UP_SUCCESS = '@contest/ANSWER_GIVE_UP_SUCCESS'
export const ANSWER_GIVE_UP_FAILURE = '@contest/ANSWER_GIVE_UP_FAILURE'

export const DESIST = '@contest/DESIST'
export const DESIST_SUCCESS = '@contest/DESIST_SUCCESS'
export const DESIST_FAILURE = '@contest/DESIST_FAILURE'

export const ABORT = '@contest/ABORT'
export const ABORT_SUCCESS = '@contest/ABORT_SUCCESS'
export const ABORT_FAILURE = '@contest/ABORT_FAILURE'

export const RESEND = '@contest/RESEND'
export const RESEND_SUCCESS = '@contest/RESEND_SUCCESS'
export const RESEND_FAILURE = '@contest/RESEND_FAILURE'

export const GET_PRICES = '@contest/GET_PRICES'
export const GET_PRICES_SUCCESS = '@contest/GET_PRICES_SUCCESS'
export const GET_PRICES_FAILURE = '@contest/GET_PRICES_FAILURE'

export const RESEND_PAYMENT = '@contest/RESEND_PAYMENT'
export const RESEND_PAYMENT_SUCCESS = '@contest/RESEND_PAYMENT_SUCCESS'
export const RESEND_PAYMENT_FAILURE = '@contest/RESEND_PAYMENT_FAILURE'

export const DELETE_CONTEST = '@contest/DELETE_CONTEST'
export const DELETE_CONTEST_SUCCESS = '@contest/DELETE_CONTEST_SUCCESS'
export const DELETE_CONTEST_FAILURE = '@contest/DELETE_CONTEST_FAILURE'

export const ANSWER_CONTEST_INSTITUTION_NOMINATION =
  '@contest/ANSWER_CONTEST_INSTITUTION_NOMINATION'
