import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as CloseThinSVG } from 'assets/images/close-thin.svg'
import { ReactComponent as FilePdfSVG } from 'assets/images/file-pdf.svg'

import { GlobalState } from 'store'
import { closeDocumentsMenu } from 'store/documents/actions'

import { DocumentsWrapper } from './styled'

export function Documents(): JSX.Element {
  const { t } = useTranslation('common')

  const dispatch = useDispatch()

  const { isOpen } = useSelector((state: GlobalState) => state.documents)

  return (
    <DocumentsWrapper isOpen={isOpen} tabIndex={-1}>
      <button
        tabIndex={isOpen ? 0 : -1}
        onClick={() => dispatch(closeDocumentsMenu())}
      >
        <CloseThinSVG />
      </button>
      <header>
        <h2>{t('documentsMenu.title')}</h2>
      </header>

      <ul>
        <li>
          <FilePdfSVG />
          <a
            href="https://ob-public-files.s3.amazonaws.com/Regulamento_de_Resolucao_de_Disputas.pdf"
            target="_blank"
            rel="noreferrer"
            tabIndex={isOpen ? 0 : -1}
          >
            {t('documentsMenu.regulation')}
          </a>
        </li>
        <li>
          <FilePdfSVG />
          <a
            href="https://disputas.openbankingbrasil.org.br/documentos/Manual_Plataforma_Disputas.pdf"
            target="_blank"
            rel="noreferrer"
            tabIndex={isOpen ? 0 : -1}
          >
            {t('documentsMenu.manual')}
          </a>
        </li>
        <li>
          <FilePdfSVG />
          <a
            href="https://disputas.openbankingbrasil.org.br/documentos/Termo_de_Uso.pdf"
            target="_blank"
            rel="noreferrer"
            tabIndex={isOpen ? 0 : -1}
          >
            {t('documentsMenu.term')}
          </a>
        </li>
        <li>
          <FilePdfSVG />
          <a
            href="https://disputas.openbankingbrasil.org.br/documentos/Politica_de_privacidade.pdf"
            target="_blank"
            rel="noreferrer"
            tabIndex={isOpen ? 0 : -1}
          >
            {t('documentsMenu.privacyPolicies')}
          </a>
        </li>
      </ul>
    </DocumentsWrapper>
  )
}
