/* eslint-disable @typescript-eslint/no-explicit-any */
import { DirectoryInstitutionsResult } from 'types/institution/DirectoryInstitutionsResult'
import { Institution } from 'types/institution/Institution'
import { InstitutionInvite } from 'types/institution/InstitutionInvite'
import { InstitutionSearchResult } from 'types/institution/InstitutionSearchResult'
import { InstitutionUpdate } from 'types/institution/InstitutionUpdate'
import { InstitutionUpdateAddress } from 'types/institution/InstitutionUpdateAddress'
import { InstitutionUpdateUserRole } from 'types/institution/InstitutionUpdateUserRole'
import { SearchInstitutionQuery } from 'types/institution/SearchInstitutionQuery'
import { PagedResult } from 'types/PagedResult'

import { GET, POST, PUT } from 'utils/httpUtils'

const INSTITUTION_PATH = '/subscription/institution'

export async function fetchInstitution(cnpj: string): Promise<Institution> {
  try {
    const response = await GET<{ institution: Institution }>(
      `${INSTITUTION_PATH}/${cnpj}`
    )
    return Promise.resolve({
      ...response.institution,
      users: response.institution.users.map((user: any) => ({
        id: user.id,
        name: user.name,
        lastname: user.lastname,
        email: user.email,
        cpf: user.cpf,
        createdAt: user.created_at,
        status: user.status,
        role: user.role,
        type: user.type
      }))
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function searchInstitution(
  params: SearchInstitutionQuery
): Promise<PagedResult<InstitutionSearchResult>> {
  try {
    const response = await GET<{ result: any }>(
      `${INSTITUTION_PATH}/search`,
      params
    )
    return Promise.resolve({
      pagination: {
        total: response.result.meta?.total ?? response.result.data.lenght,
        perPage: response.result.meta?.per_page ?? response.result.data.lenght,
        firstPage: response.result.meta?.first_page ?? 1,
        lastPage: response.result.meta?.last_page ?? 1,
        currentPage: response.result.meta?.current_page ?? 1
      },
      data: response.result.data
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateInstitution(
  data: InstitutionUpdate
): Promise<Institution> {
  try {
    const response = await PUT<InstitutionUpdate, Institution>(
      `${INSTITUTION_PATH}/updateInfo`,
      data
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateInstitutionAddress(
  data: InstitutionUpdateAddress
): Promise<Institution> {
  try {
    const response = await PUT<InstitutionUpdateAddress, Institution>(
      `${INSTITUTION_PATH}/updateAddress`,
      data
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function inviteUser(
  data: InstitutionInvite
): Promise<Institution> {
  try {
    const response = await POST<
      InstitutionInvite,
      { institution: Institution; user: any }
    >(`${INSTITUTION_PATH}/invite`, data)
    return Promise.resolve({
      ...response.institution,
      users: response.institution.users.map((user: any) => ({
        id: user.id,
        name: user.name,
        lastname: user.lastname,
        email: user.email,
        cpf: user.cpf,
        createdAt: user.created_at,
        status: user.status,
        role: user.role,
        type: user.type
      }))
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function removeUser(data: {
  cnpj: string
  email: string
}): Promise<Institution> {
  try {
    const response = await POST<
      { cnpj: string; email: string },
      { institution: Institution }
    >(`${INSTITUTION_PATH}/unassociate`, data)
    return Promise.resolve({
      ...response.institution,
      users: response.institution.users.map((user: any) => ({
        id: user.id,
        name: user.name,
        lastname: user.lastname,
        email: user.email,
        cpf: user.cpf,
        createdAt: user.created_at,
        status: user.status,
        role: user.role,
        type: user.type
      }))
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateUserRole(
  data: InstitutionUpdateUserRole
): Promise<Institution> {
  try {
    const response = await PUT<
      InstitutionUpdateUserRole,
      { institution: Institution }
    >(`${INSTITUTION_PATH}/associate`, data)
    return Promise.resolve({
      ...response.institution,
      users: response.institution.users.map((user: any) => ({
        id: user.id,
        name: user.name,
        lastname: user.lastname,
        email: user.email,
        cpf: user.cpf,
        createdAt: user.created_at,
        status: user.status,
        role: user.role,
        type: user.type
      }))
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchDirectoryInstitutions(
  filterByUser = false
): Promise<DirectoryInstitutionsResult[]> {
  try {
    const { institutions } = await GET<{
      institutions: DirectoryInstitutionsResult[]
    }>(`${INSTITUTION_PATH}/directory`, {
      filter: filterByUser
    })

    return Promise.resolve(institutions)
  } catch (error) {
    return Promise.reject(error)
  }
}
