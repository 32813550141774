import { FormikValues } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { AuthHeader } from 'components/authHeader'
import { Button } from 'components/button'
import { Flex } from 'components/flex'
import { Form } from 'components/form'
import { PasswordInput } from 'components/passwordInput'

import { useQuery } from 'hooks/useQuery'

import { browserHistory } from 'navigation/browserHistory'
import { DASHBOARD_PAGE } from 'navigation/paths'

import { GlobalState } from 'store'
import { resetPassword } from 'store/user/actions'

import { passwordRegex } from 'utils/regexUtils'

import { ResetPasswordWrapper } from './styled'

export function ResetPasswordPage(): JSX.Element {
  const { t } = useTranslation(['pages'])

  const query = useQuery()

  const dispatch = useDispatch()

  const { loggedIn } = useSelector((state: GlobalState) => state.session)
  const { resetPasswordInProgress } = useSelector(
    (state: GlobalState) => state.user
  )

  const initialValues = {
    password: '',
    passwordConfirmation: ''
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(passwordRegex, 'password.rules')
      .required('required'),
    passwordConfirmation: Yup.string()
      .required('required')
      .oneOf([Yup.ref('password')], 'passwordMatch')
  })

  useEffect(() => {
    const resetId = query.get('resetId')
    if (loggedIn || resetId == null) {
      browserHistory.push(DASHBOARD_PAGE)
    }
  }, [])

  function _resetPassword(data: FormikValues) {
    const resetId = query.get('resetId')

    if (!resetId) return

    dispatch(
      resetPassword({
        resetId,
        password: data.password
      })
    )
  }

  document.title = 'Resolução de Disputas - Open Banking Brasil'

  return (
    <ResetPasswordWrapper>
      <AuthHeader />
      <main>
        <h4>{t('pages:resetPassword.title')}</h4>

        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => _resetPassword(values)}
        >
          <Flex direction="column" align="center">
            <PasswordInput
              name="password"
              placeholder={t('pages:resetPassword.form.password')}
              autoComplete="new-password"
            />

            <PasswordInput
              name="passwordConfirmation"
              placeholder={t('pages:resetPassword.form.passwordConfirmation')}
              autoComplete="new-password"
              showStrength={false}
            />

            <Button type="submit" inProgress={resetPasswordInProgress}>
              {t('pages:resetPassword.submitButton')}
            </Button>
          </Flex>
        </Form>
      </main>
    </ResetPasswordWrapper>
  )
}
