import styled from 'styled-components'

export const FieldSetWrapper = styled.fieldset`
  border: none;

  legend {
    font-family: ${({ theme }) => theme.fonts.bloggerSans};
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    margin-bottom: ${({ theme }) => theme.spacings[4]};

    color: ${({ theme }) => theme.colors.graphite};
  }

  & + fieldset {
    margin-top: ${({ theme }) => theme.spacings[4]};
  }
`
