import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'
import sessionStorage from 'redux-persist/lib/storage/session'

import { adminReducer } from 'store/admin/reducer'
import { contestReducer } from 'store/contest/reducer'
import { contestAssociationReducer } from 'store/contestAssociation/reducer'
import { contestFileReducer } from 'store/contestFile/reducer'
import { contestMessageReducer } from 'store/contestMessage/reducer'
import { dashboardReducer } from 'store/dashboard/reducer'
import { directoryReducer } from './directory/reducer'
import { documentsReducer } from 'store/documents/reducer'
import { institutionReducer } from 'store/institution/reducer'
import { loginReducer } from 'store/login/reducer'
import { precedentReducer } from 'store/precedent/reducer'
import { sessionReducer } from 'store/session/reducer'
import { userReducer } from 'store/user/reducer'

// ui reducers
import { modalReducer } from 'store/modal/reducer'
import { notificationReducer } from 'store/notification/reducer'
import { sidebarReducer } from 'store/sidebar/reducer'

const sessionPersistConfig = {
  key: 'session',
  whitelist: ['token'],
  storage: sessionStorage
}

const sidebarPersistConfig = {
  key: 'sidebar',
  storage: localStorage
}

export const rootReducer = combineReducers({
  session: persistReducer(sessionPersistConfig, sessionReducer),
  admin: adminReducer,
  contest: contestReducer,
  contestAssociation: contestAssociationReducer,
  contestFile: contestFileReducer,
  contestMessage: contestMessageReducer,
  dashboard: dashboardReducer,
  directory: directoryReducer,
  documents: documentsReducer,
  institution: institutionReducer,
  login: loginReducer,
  precedent: precedentReducer,
  user: userReducer,

  // ui reducers
  modal: modalReducer,
  notification: notificationReducer,
  sidebar: persistReducer(sidebarPersistConfig, sidebarReducer)
})
