import { useEffect, useRef, ChangeEvent, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'

import { ReactComponent as PaperPlaneSVG } from 'assets/images/paper-plane.svg'

import { Loading } from 'components/loading'

import { GlobalState } from 'store'
import {
  changeNewMessage,
  fetchContestMessages,
  createContestMessage
} from 'store/contestMessage/actions'

import Theme from 'styles/Theme'

import { ContestMessage } from 'types/contestMessage/ContestMessage'
import { ContestMemberPermission } from 'types/enums/ContestMemberPermission'
import { ContestSide } from 'types/enums/ContestSide'

import { formatDateTimeBR } from 'utils/formatUtils'

import { MessagesContainer, InputContainer } from './styled'

type Props = {
  userSide?: ContestSide
  userPermission?: ContestMemberPermission
}

export function MessagesTab({ userSide, userPermission }: Props): JSX.Element {
  const { t } = useTranslation(['common'])

  const containerRef = useRef<HTMLDivElement>(null)

  const { contestId } = useParams<{ contestId: string }>()

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    contestMessage: {
      newMessage,
      messages,
      fetchContestMessagesInProgress,
      createContestMessageInProgress
    }
  } = useSelector((state: GlobalState) => state)

  useEffect(() => {
    if (contestId) {
      dispatch(fetchContestMessages(parseInt(contestId)))
    }

    const interval = setInterval(() => {
      if (contestId) {
        dispatch(fetchContestMessages(parseInt(contestId)))
      }
    }, 1000 * 60 * 0.25) // refreshing mesagens

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      const parent = containerRef.current.parentElement
      if (parent) {
        parent.scrollTop = parent.scrollHeight
      }
    }

    const main = document.body.querySelector('main')
    if (main) {
      main.scrollTop = main.scrollHeight
    }
  }, [messages.length])

  function _handleChange(event: ChangeEvent<HTMLInputElement>) {
    dispatch(changeNewMessage(event.target.value))
  }

  function _handleKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    if (!newMessage.length) return

    const { key } = event
    if (key === 'Enter') {
      if (contestId && sessionUser) {
        dispatch(
          createContestMessage({
            contestId: parseInt(contestId),
            text: newMessage
          })
        )
      }
    }
  }

  function _handleClick() {
    if (!newMessage.length) return

    if (contestId && sessionUser) {
      dispatch(
        createContestMessage({
          contestId: parseInt(contestId),
          text: newMessage
        })
      )
    }
  }

  function _renderMessage(message: ContestMessage): JSX.Element {
    let side = ''

    if (message.side === ContestSide.REQUESTER) {
      side = t('common:contestSide.REQUESTER')
    }

    if (message.side === ContestSide.DEFENDANT) {
      side = t('common:contestSide.DEFENDANT')
    }

    return (
      <li data-side={message.side === userSide ? 'right' : 'left'}>
        <h4>
          {message.institution} - {side}
        </h4>
        <h5>{message.user}</h5>
        <time dateTime={message.createdAt}>
          {formatDateTimeBR(message.createdAt)}
        </time>
        <p>{message.text}</p>
      </li>
    )
  }

  return (
    <MessagesContainer ref={containerRef}>
      <ul>
        {fetchContestMessagesInProgress && !messages.length ? (
          <Loading />
        ) : (
          messages.map((message) => _renderMessage(message))
        )}
      </ul>
      <InputContainer>
        {userPermission !== ContestMemberPermission.READ ? (
          <input
            type="text"
            placeholder={''}
            value={newMessage}
            onChange={_handleChange}
            onKeyUp={_handleKeyPress}
          />
        ) : (
          <input
            type="text"
            placeholder={''}
            value={''}
            disabled
            onChange={() => undefined}
            onKeyUp={() => undefined}
          />
        )}
        <button
          type="button"
          disabled={!newMessage || createContestMessageInProgress}
          onClick={_handleClick}
        >
          {createContestMessageInProgress ? (
            <BounceLoader size="16px" color={Theme.colors.white} />
          ) : (
            <PaperPlaneSVG />
          )}
        </button>
      </InputContainer>
    </MessagesContainer>
  )
}
