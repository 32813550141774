/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'

import isBefore from 'date-fns/isBefore'
import addDays from 'date-fns/addDays'
import subDays from 'date-fns/subDays'

import { ReactComponent as SearchSVG } from 'assets/images/search.svg'
import { ReactComponent as SumSVG } from 'assets/images/sum.svg'
import { ReactComponent as HourglassSVG } from 'assets/images/hourglass.svg'
import { ReactComponent as CheckSVG } from 'assets/images/check-thin.svg'

import { Card } from 'components/card'
import { Flex, FlexItem } from 'components/flex'
import { PageLoading } from 'components/pageLoading'

import { status, contestStatus } from 'config/dashboard'

import { withAdminLayout } from 'hoc/withAdminLayout'

import { CONTEST_DETAILS_PAGE, CONTEST_LIST_PAGE } from 'navigation/paths'

import { GlobalState } from 'store'
import { getData } from 'store/dashboard/actions'

import { ContestSide } from 'types/enums/ContestSide'
import { SelectOption } from 'types/SelectOption'

import {
  formatDateBR,
  formatDateTimeBR,
  formatDistanceFromNow
} from 'utils/formatUtils'

import { ContestByStatus } from './components/contestByStatus'
import { Histogram } from './components/histogram'
import { Select } from '../contestList/components/select'

import {
  DashboardWrapper,
  DashboardMainContainer,
  Box,
  CardContent,
  Institution,
  UpdatingTable,
  ExpiringList
} from './styled'

function Dashboard(): JSX.Element {
  const { t } = useTranslation(['pages', 'common'])

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    dashboard: { data, getDataInProgress }
  } = useSelector((state: GlobalState) => state)

  const intervalOptions = useMemo<SelectOption[]>(
    () => [
      {
        label: 'Última semana',
        value: 7
      },
      {
        label: 'Último mês',
        value: 30
      },
      {
        label: 'Últimos 3 meses',
        value: 90
      },
      {
        label: 'Últimos 6 meses',
        value: 180
      },
      {
        label: 'Desde o início',
        value: 0
      }
    ],
    []
  )

  const [selectedInterval, setSelectedInterval] = useState<number | undefined>(
    7
  )

  useEffect(() => {
    if (sessionUser) {
      dispatch(
        getData({
          start: selectedInterval
            ? subDays(new Date(), selectedInterval).toISOString().substr(0, 10)
            : '2021-01-01',
          end: addDays(new Date(), 1).toISOString().substr(0, 10)
        })
      )
    }
  }, [selectedInterval])

  function _normalizeStatusData(data: any[]) {
    const map = new Map()

    data.forEach((item: any) => {
      const key = (contestStatus as any)[item.currentStep]

      const value = map.get(key)
      if (value) {
        map.set(key, value + item.count)
      } else {
        map.set(key, item.count)
      }
    })

    return Array.from(map, ([currentStep, count]) => ({ currentStep, count }))
  }

  function _normalizeHistogramData(data: any[]) {
    const map = new Map()

    data.forEach((item: any) => {
      const value = map.get(item.slaDate)
      if (value) {
        map.set(item.slaDate, { ...value, [item.side]: item.qtd })
      } else {
        map.set(item.slaDate, { [item.side]: item.qtd })
      }
    })

    return Array.from(map, ([slaDate, value]) => ({ slaDate, ...value }))
  }

  function search(side: ContestSide, step?: string): string {
    const fromDate = selectedInterval
      ? subDays(new Date(), selectedInterval).toISOString().substr(0, 10)
      : '2021-01-01'

    const toDate = addDays(new Date(), 1).toISOString().substr(0, 10)

    const qs = new URLSearchParams()

    qs.set('fromDate', fromDate)
    qs.set('toDate', toDate)
    qs.set('side', side)

    if (step) {
      qs.set('step', step)
    }

    return qs.toString()
  }

  document.title = 'Dashboard - Open Banking Brasil'

  return (
    <DashboardWrapper>
      <Flex direction="row" justify="space-between">
        <FlexItem>
          <Institution
            dangerouslySetInnerHTML={{
              __html: t('dashboard.greetings', { name: sessionUser?.name })
            }}
          />
        </FlexItem>
        <FlexItem grow="0" basis="260px" shrink="0">
          <Select
            placeholder="Selecione um intervalo"
            options={intervalOptions}
            value={selectedInterval}
            onChange={(opt) => {
              if (opt?.value) {
                setSelectedInterval(parseInt(opt.value.toString()))
              } else {
                setSelectedInterval(undefined)
              }
            }}
            isClearable={false}
          />
        </FlexItem>
      </Flex>

      {getDataInProgress || !data ? (
        <PageLoading />
      ) : (
        data && (
          <DashboardMainContainer>
            <section>
              <Box>
                <h4>Disputas como requerente</h4>
                <Flex direction="row" wrap gap={6}>
                  <FlexItem grow="4" basis="120px">
                    <Link
                      to={() => ({
                        pathname: CONTEST_LIST_PAGE,
                        search: search(ContestSide.REQUESTER)
                      })}
                    >
                      <Card
                        color="sandy"
                        borderColor="darkAqua"
                        backgroundColor="petrol"
                      >
                        <CardContent>
                          <SumSVG />
                          <span>
                            Abertas
                            <strong>{data.openRequester.count}</strong>
                          </span>
                        </CardContent>
                      </Card>
                    </Link>
                  </FlexItem>
                  <FlexItem grow="4" basis="120px">
                    <Link
                      to={() => ({
                        pathname: CONTEST_LIST_PAGE,
                        search: search(ContestSide.REQUESTER, status.onGoing)
                      })}
                    >
                      <Card
                        color="petrol"
                        borderColor="sandy"
                        backgroundColor="sandy"
                      >
                        <CardContent>
                          <HourglassSVG />
                          <span>
                            Em Andamento
                            <strong>{data.onGoingRequester.count}</strong>
                          </span>
                        </CardContent>
                      </Card>
                    </Link>
                  </FlexItem>
                  <FlexItem grow="4" basis="120px">
                    <Link
                      to={() => ({
                        pathname: CONTEST_LIST_PAGE,
                        search: search(ContestSide.REQUESTER, status.finished)
                      })}
                    >
                      <Card
                        color="white"
                        borderColor="graphite"
                        backgroundColor="graphite"
                      >
                        <CardContent>
                          <CheckSVG />
                          <span>
                            Encerradas
                            <strong>{data.finishedRequester.count}</strong>
                          </span>
                        </CardContent>
                      </Card>
                    </Link>
                  </FlexItem>
                </Flex>
              </Box>

              <Box>
                <h4>Disputas como requerida</h4>
                <Flex direction="row" wrap gap={6}>
                  <FlexItem grow="4" basis="120px">
                    <Link
                      to={() => ({
                        pathname: CONTEST_LIST_PAGE,
                        search: search(ContestSide.DEFENDANT)
                      })}
                    >
                      <Card
                        color="sandy"
                        borderColor="petrol"
                        backgroundColor="petrol"
                      >
                        <CardContent>
                          <SumSVG />
                          <span>
                            Abertas
                            <strong>{data.openDefendant.count}</strong>
                          </span>
                        </CardContent>
                      </Card>
                    </Link>
                  </FlexItem>
                  <FlexItem grow="4" basis="120px">
                    <Link
                      to={() => ({
                        pathname: CONTEST_LIST_PAGE,
                        search: search(ContestSide.DEFENDANT, status.onGoing)
                      })}
                    >
                      <Card
                        color="petrol"
                        borderColor="sandy"
                        backgroundColor="sandy"
                      >
                        <CardContent>
                          <HourglassSVG />
                          <span>
                            Em Andamento
                            <strong>{data.onGoingDefendant.count}</strong>
                          </span>
                        </CardContent>
                      </Card>
                    </Link>
                  </FlexItem>
                  <FlexItem grow="4" basis="120px">
                    <Link
                      to={() => ({
                        pathname: CONTEST_LIST_PAGE,
                        search: search(ContestSide.DEFENDANT, status.finished)
                      })}
                    >
                      <Card
                        color="white"
                        borderColor="graphite"
                        backgroundColor="graphite"
                      >
                        <CardContent>
                          <CheckSVG />
                          <span>
                            Encerradas
                            <strong>{data.finishedDefendant.count}</strong>
                          </span>
                        </CardContent>
                      </Card>
                    </Link>
                  </FlexItem>
                </Flex>
              </Box>
            </section>

            <section>
              <Box grow="1">
                <h4 style={{ textAlign: 'center' }}>Disputas por status</h4>
                <ContestByStatus
                  side={ContestSide.REQUESTER}
                  data={_normalizeStatusData(data.currentStatusRequester)}
                />
              </Box>
              <Box grow="1">
                <h4 style={{ textAlign: 'center' }}>Disputas por status</h4>
                <ContestByStatus
                  side={ContestSide.DEFENDANT}
                  data={_normalizeStatusData(data.currentStatusdefendant)}
                />
              </Box>
              <Box grow="2">
                <h4>Últimas atualizações</h4>
                <UpdatingTable>
                  <thead>
                    <tr>
                      <th>{t('pages:dashboard.lastUpdates.table.id')}</th>
                      <th>{t('pages:dashboard.lastUpdates.table.action')}</th>
                      <th>
                        {t('pages:dashboard.lastUpdates.table.currentStep')}
                      </th>
                      <th>
                        {t('pages:dashboard.lastUpdates.table.updatedAt')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.lastUpdate.map(
                      ({ id, action, currentStep, updatedAt }: any) => (
                        <tr key={id}>
                          <td>
                            <Link
                              to={generatePath(CONTEST_DETAILS_PAGE, {
                                contestId: id
                              })}
                            >
                              {id}
                            </Link>
                          </td>
                          <td>
                            {t(`pages:dashboard.lastUpdates.actions.${action}`)}
                          </td>
                          <td>{t(`common:contestStep.${currentStep}`)}</td>
                          <td>{formatDateTimeBR(updatedAt)}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </UpdatingTable>
              </Box>
            </section>

            <section>
              <Box grow="1">
                <h4>Disputas próximas ao vencimento</h4>
                <ExpiringList>
                  {data.expiringSla.map(
                    ({ id, currentStep, slaEndAt }: any, index: number) => (
                      <li key={index}>
                        <Link
                          to={generatePath(CONTEST_DETAILS_PAGE, {
                            contestId: id
                          })}
                        >
                          <SearchSVG />
                        </Link>
                        <div>
                          <strong>#{id}</strong>
                          <p>
                            {t(
                              `pages:dashboard.expiringSla.status.${currentStep}`
                            )}
                          </p>
                          <p>
                            ({formatDateBR(slaEndAt)}){' - '}
                            {isBefore(new Date(), new Date(slaEndAt))
                              ? 'Expira '
                              : 'Expirado '}
                            {formatDistanceFromNow(slaEndAt)}
                          </p>
                        </div>
                      </li>
                    )
                  )}
                </ExpiringList>
              </Box>

              <Box grow="3">
                <h4>Disputas próximas ao vencimento</h4>
                <Histogram data={_normalizeHistogramData(data.histogram)} />
              </Box>
            </section>
          </DashboardMainContainer>
        )
      )}
    </DashboardWrapper>
  )
}

export const DashboardPage = withAdminLayout(Dashboard)
