import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ReactComponent as EditSVG } from 'assets/images/edit.svg'
import { ReactComponent as TrashSVG } from 'assets/images/trash.svg'

import { Breadcrumb, BreadcrumbItem } from 'components/breadcrumb'
import { Button } from 'components/button'
import { Flex } from 'components/flex'
import { IconButton } from 'components/iconButton'
import { Separator } from 'components/separator'
import { Table } from 'components/table'
import { Tooltip } from 'components/tooltip'

import { confirmationConfig } from 'config/swal'

import { withAdminLayout } from 'hoc/withAdminLayout'

import { browserHistory } from 'navigation/browserHistory'
import {
  INSTITUTION_LIST_PAGE,
  INSTITUTION_UPDATE_PAGE
} from 'navigation/paths'

import { GlobalState } from 'store'
import { fetchInstitution, removeUser } from 'store/institution/actions'
import { openModal } from 'store/modal/actions'

import Theme from 'styles/Theme'

import { ModalName } from 'types/enums/ModalName'
import { UserRole } from 'types/enums/UserRole'
import { InstitutionUser } from 'types/institution/InstitutionUser'
import { TableColumn } from 'types/table/TableColumn'

import { formatCnpj, formatDateBR } from 'utils/formatUtils'

import { InstitutionAddMemberModal } from './components/institutionAddMemberModal'
import { InstitutionEditMemberModal } from './components/institutionEditMemberModal'

import {
  PageTitle,
  Heading,
  TableHeading,
  OwnerIcon,
  IconDisabled
} from './styled'

// TODO: verificar os textos para owner/substitute
// TODO: refatorar owner -> admin / substitute -> edit
function Page(): JSX.Element {
  const { t } = useTranslation(['pages', 'common'])

  const { cnpj } = useParams<{ cnpj: string }>()

  const dispatch = useDispatch()

  const {
    institution: {
      institution,
      fetchInstitutionInProgress,
      removeUserInProgress
    },
    session: { user }
  } = useSelector((state: GlobalState) => state)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [institutionUserToEdit, setInstitutionUserToEdit] = useState<any>(
    undefined
  )

  const members = useMemo<InstitutionUser[]>(() => {
    if (!institution) return []

    const result: InstitutionUser[] = []

    const owners = institution.users
      .filter((user) => user.role === UserRole.ADMIN)
      .sort((a, b) => {
        const nameA = `${a.name} ${a.lastname}`
        const nameB = `${b.name} ${b.lastname}`

        if (nameA > nameB) {
          return 1
        }

        if (nameA < nameB) {
          return -1
        }

        return 0
      })

    if (owners.length) {
      result.push(...owners)
    }

    const substitutes = institution.users
      .filter((user) => user.role === UserRole.EDIT)
      .sort((a, b) => {
        const nameA = `${a.name} ${a.lastname}`
        const nameB = `${b.name} ${b.lastname}`

        if (nameA > nameB) {
          return 1
        }

        if (nameA < nameB) {
          return -1
        }

        return 0
      })

    if (substitutes.length) {
      result.push(...substitutes)
    }

    const rest = institution.users
      .filter((user) => user.role === UserRole.READ)
      .sort((a, b) => {
        const nameA = `${a.name} ${a.lastname}`
        const nameB = `${b.name} ${b.lastname}`

        if (nameA > nameB) {
          return 1
        }

        if (nameA < nameB) {
          return -1
        }

        return 0
      })

    if (rest.length) {
      result.push(...rest)
    }

    return result
  }, [institution?.users])

  const columns = useMemo<TableColumn<InstitutionUser>[]>(
    () => [
      {
        label: t('pages:institutionDetails.table.name'),
        dataKey: '',
        render: (row) => `${row.name ?? ''} ${row.lastname ?? ''}`
      },
      {
        label: t('pages:institutionDetails.table.email'),
        dataKey: 'email'
      },
      {
        label: t('pages:institutionDetails.table.role'),
        dataKey: '',
        thStyle: { textAlign: 'center', width: '15%' },
        tdStyle: { textAlign: 'center', width: '15%' },
        render: (row) => {
          if (row.role === UserRole.ADMIN) {
            return <OwnerIcon />
          }

          if (row.role === UserRole.EDIT) {
            return 'Edição'
          }

          return 'Leitura'
        }
      },
      {
        label: t('pages:institutionDetails.table.status'),
        dataKey: 'status',
        thStyle: { width: '10%' },
        tdStyle: { width: '10%' },
        render: (row) => t(`common:userStatus.${row.status}`)
      },
      {
        label: t('pages:institutionDetails.table.createdAt'),
        dataKey: '',
        thStyle: { width: '15%' },
        tdStyle: { width: '15%' },
        render: (row) => formatDateBR(row.createdAt)
      },
      {
        label: t('pages:institutionDetails.table.actions.title'),
        dataKey: 'actions',
        thStyle: { width: '10%' },
        tdStyle: { width: '10%' },
        render: (row) => (
          <Flex direction="row" gap={2}>
            {_renderActions(row)}
          </Flex>
        )
      }
    ],
    [institution]
  )

  useEffect(() => {
    if (cnpj) {
      dispatch(fetchInstitution(cnpj))
    }
  }, [cnpj])

  function _sessionUserIsOwner(): boolean {
    if (!user) return false

    const institutionUser = institution?.users.find(({ id }) => id === user.id)

    return institutionUser?.role === UserRole.ADMIN
  }

  function _sessionUserIsSubstitute(): boolean {
    if (!user) return false

    const institutionUser = institution?.users.find(({ id }) => id === user.id)

    return institutionUser?.role === UserRole.EDIT
  }

  function _sessionUserIsOwnerOrSubstitute(): boolean {
    return _sessionUserIsOwner() || _sessionUserIsSubstitute()
  }

  function _removeMember(email: string) {
    withReactContent(SweetAlert)
      .fire({
        ...confirmationConfig,
        icon: 'warning',
        titleText: t('pages:institutionDetails.confirmRemove.title'),
        html: t('pages:institutionDetails.confirmRemove.message'),
        confirmButtonText: t(
          'pages:institutionDetails.confirmRemove.confirmButton'
        ),
        cancelButtonText: t(
          'pages:institutionDetails.confirmRemove.cancelButton'
        )
      })
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          dispatch(removeUser(cnpj, email))
        }
      })
  }

  function _renderActions(row: InstitutionUser) {
    if (_sessionUserIsOwner()) {
      return row.role === UserRole.ADMIN ? (
        <>
          <Tooltip
            content={t('pages:institutionDetails.table.actions.ownerLock')}
          >
            <IconDisabled>
              <EditSVG />
            </IconDisabled>
          </Tooltip>
          <Tooltip
            content={t('pages:institutionDetails.table.actions.ownerLock')}
          >
            <IconDisabled>
              <TrashSVG />
            </IconDisabled>
          </Tooltip>
        </>
      ) : (
        <>
          <IconButton
            tooltip={t('pages:institutionDetails.table.actions.edit')}
            color="niceBlue"
            onClick={() => {
              setInstitutionUserToEdit({
                cnpj,
                email: row.email,
                role: row.role
              })
              dispatch(openModal(ModalName.INSTITUTION_EDIT_MEMBER))
            }}
          >
            <EditSVG />
          </IconButton>
          <IconButton
            tooltip={t('pages:institutionDetails.table.actions.remove')}
            color="blush"
            onClick={() => _removeMember(row.email)}
          >
            {removeUserInProgress ? (
              <BounceLoader size="16px" color={Theme.colors.blush} />
            ) : (
              <TrashSVG />
            )}
          </IconButton>
        </>
      )
    }

    if (_sessionUserIsSubstitute()) {
      return row.role === UserRole.ADMIN ? (
        <>
          <Tooltip
            content={t('pages:institutionDetails.table.actions.ownerLock')}
          >
            <IconDisabled>
              <EditSVG />
            </IconDisabled>
          </Tooltip>
          <Tooltip
            content={t('pages:institutionDetails.table.actions.ownerLock')}
          >
            <IconDisabled>
              <TrashSVG />
            </IconDisabled>
          </Tooltip>
        </>
      ) : row.role === UserRole.EDIT ? (
        <>
          <Tooltip
            content={t('pages:institutionDetails.table.actions.cantEdit')}
          >
            <IconDisabled>
              <EditSVG />
            </IconDisabled>
          </Tooltip>
          <Tooltip
            content={t('pages:institutionDetails.table.actions.cantRemove')}
          >
            <IconDisabled>
              <TrashSVG />
            </IconDisabled>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip
            content={t('pages:institutionDetails.table.actions.cantEdit')}
          >
            <IconDisabled>
              <EditSVG />
            </IconDisabled>
          </Tooltip>
          <IconButton
            tooltip={t('pages:institutionDetails.table.actions.remove')}
            color="blush"
            onClick={() => dispatch(removeUser(cnpj, row.email))}
          >
            {removeUserInProgress ? (
              <BounceLoader size="16px" color={Theme.colors.blush} />
            ) : (
              <TrashSVG />
            )}
          </IconButton>
        </>
      )
    }

    return (
      <>
        <Tooltip content={t('pages:institutionDetails.table.actions.cantEdit')}>
          <IconDisabled>
            <EditSVG />
          </IconDisabled>
        </Tooltip>
        <Tooltip
          content={t('pages:institutionDetails.table.actions.cantRemove')}
        >
          <IconDisabled>
            <TrashSVG />
          </IconDisabled>
        </Tooltip>
      </>
    )
  }

  document.title = 'Instituição - Open Banking Brasil'

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem
          to={INSTITUTION_LIST_PAGE}
          label={t('pages:institutionDetails.breadcrumb.institution')}
        />
        <BreadcrumbItem
          label={t('pages:institutionDetails.breadcrumb.institutionDetails')}
          isCurrentPage
        />
      </Breadcrumb>
      <PageTitle>{t('pages:institutionDetails.title')}</PageTitle>
      <Heading>
        <span>
          {institution?.name} - CNPJ:{' '}
          {institution?.cnpj && formatCnpj(institution.cnpj)}
        </span>
        {_sessionUserIsOwnerOrSubstitute() && (
          <Button
            type="button"
            small
            onClick={() =>
              browserHistory.push(
                generatePath(INSTITUTION_UPDATE_PAGE, { cnpj })
              )
            }
          >
            {t('pages:institutionDetails.updateButton')}
          </Button>
        )}
      </Heading>
      <Separator />

      <TableHeading>
        <h3>{t('pages:institutionDetails.table.title')}</h3>
        {_sessionUserIsOwner() && (
          <Button
            type="button"
            variant="outline"
            color="niceBlue"
            small
            onClick={() =>
              dispatch(openModal(ModalName.INSTITUTION_ADD_MEMBER))
            }
          >
            {t('pages:institutionDetails.table.addUserButton')}
          </Button>
        )}
      </TableHeading>

      <Table
        columns={columns}
        data={members}
        pagination={false}
        isLoading={fetchInstitutionInProgress}
      />

      <InstitutionAddMemberModal />

      <InstitutionEditMemberModal initialValues={institutionUserToEdit ?? {}} />
    </>
  )
}

export const InstitutionDetailsPage = withAdminLayout(Page)
