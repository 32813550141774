import { Action, ActionWithoutPayload } from 'types/Action'
import { ContestNotification } from 'types/contestNotification/ContestNotification'

import {
  OPEN_NOTIFICATION_MENU,
  CLOSE_NOTIFICATION_MENU,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_AS_READ,
  MARK_AS_READ_FAILURE,
  MARK_AS_READ_SUCCESS
} from './actionTypes'

export function openNotificationMenu(): ActionWithoutPayload {
  return { type: OPEN_NOTIFICATION_MENU }
}

export function closeNotificationMenu(): ActionWithoutPayload {
  return { type: CLOSE_NOTIFICATION_MENU }
}

export function getNotifications(): ActionWithoutPayload {
  return { type: GET_NOTIFICATIONS }
}

export function getNotificationsFailure(): ActionWithoutPayload {
  return { type: GET_NOTIFICATIONS_FAILURE }
}

export function getNotificationsSuccess(
  payload: ContestNotification[]
): Action<ContestNotification[]> {
  return { type: GET_NOTIFICATIONS_SUCCESS, payload }
}

export function markAsRead(payload: number): Action<number> {
  return { type: MARK_AS_READ, payload }
}

export function markAsReadFailure(): ActionWithoutPayload {
  return { type: MARK_AS_READ_FAILURE }
}

export function markAsReadSuccess(
  payload: ContestNotification[]
): Action<ContestNotification[]> {
  return { type: MARK_AS_READ_SUCCESS, payload }
}
