/* eslint-disable @typescript-eslint/no-explicit-any */
import { PagedResult } from 'types/PagedResult'
import { Precedent } from 'types/precedent/Precedent'
import { PrecedentCreate } from 'types/precedent/PrecedentCreate'
import { PrecedentReview } from 'types/precedent/PrecedentReview'
import { PrecedentsQuery } from 'types/precedent/PrecedentsQuery'

import { GET, POST, PUT } from 'utils/httpUtils'

const PATH = '/contest/precedents'

export async function fetchPrecedents(
  params: PrecedentsQuery
): Promise<PagedResult<Precedent>> {
  try {
    const { precedents } = await GET<{ precedents: any }>(PATH, params)

    return Promise.resolve({
      pagination: {
        total: precedents.meta?.total ?? precedents.data.lenght,
        perPage: precedents.meta?.per_page ?? precedents.data.lenght,
        firstPage: precedents.meta?.first_page ?? 1,
        lastPage: precedents.meta?.last_page ?? 1,
        currentPage: precedents.meta?.current_page ?? 1
      },
      data: precedents.data.map((item: any) => ({
        id: item.id,
        description: item.description,
        key: item.key,
        filename: item.filename,
        tags: item.tags ?? [],
        status: item?.status,
        createdAt: item.created_at
      }))
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getPrecedentFileUrl(key: string): Promise<string> {
  try {
    const response = await GET<string>(`${PATH}/${key}`)

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function createPrecedent(
  data: PrecedentCreate
): Promise<Precedent> {
  try {
    const { description, tags, document } = data

    const formData = new FormData()

    formData.append('description', description)
    formData.append('tags', tags)

    if (document) {
      formData.append('document', document)
    }

    const response = await POST<FormData, any>(PATH, formData)

    return Promise.resolve({
      id: response.id,
      description: response.description,
      key: response.key,
      filename: response.filename,
      tags: response.tags ?? [],
      status: response.status,
      createdAt: response.created_at
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function reviewPrecedent({
  approve,
  id,
  message,
  tags
}: PrecedentReview): Promise<void> {
  try {
    await PUT<Omit<PrecedentReview, 'id'>, any>(`${PATH}/${id}`, {
      approve,
      message,
      tags
    })

    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}
