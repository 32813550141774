import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { FieldSet } from 'components/fieldSet'
import { Form } from 'components/form'
import { Flex, FlexItem } from 'components/flex'
import { MaskedInput } from 'components/maskedInput'
import { TextInput } from 'components/textInput'

import { GlobalState } from 'store'
import { updateUserAddress } from 'store/user/actions'
import { Select } from 'components/select'

import { State } from 'types/enums/State'

import { toArray } from 'utils/enumUtils'

export function AddressForm(): JSX.Element {
  const { t } = useTranslation(['pages'])

  const dispatch = useDispatch()

  const { userInfo, updateUserAddressInProgress } = useSelector(
    (state: GlobalState) => state.user
  )

  const initialValues = {
    user: {
      id: userInfo?.id
    },
    cep: userInfo?.address?.cep ?? '',
    street: userInfo?.address?.street ?? '',
    number: userInfo?.address?.number ?? '',
    complement: userInfo?.address?.complement ?? '',
    neighborhood: userInfo?.address?.neighborhood ?? '',
    city: userInfo?.address?.city ?? '',
    state: userInfo?.address?.state ?? ''
  }

  const validationSchema = Yup.object().shape({
    cep: Yup.string().required('required'),
    street: Yup.string().required('required'),
    number: Yup.string().required('required'),
    complement: Yup.string(),
    neighborhood: Yup.string().required('required'),
    city: Yup.string().required('required'),
    state: Yup.string().required('required')
  })

  function _handleSubmit(data: FormikValues) {
    dispatch(
      updateUserAddress({
        user: {
          id: data.user.id
        },
        address: {
          cep: data.cep,
          street: data.street,
          number: data.number,
          complement: data.complement,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state
        }
      })
    )
  }

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => _handleSubmit(values)}
    >
      <FieldSet title={t('pages:settings.addressForm.title')}>
        <Flex gap={6}>
          <FlexItem grow="6" basis="0">
            <MaskedInput
              name="cep"
              placeholder={t('pages:settings.addressForm.cep')}
              options={{ delimiters: ['-'], blocks: [5, 3], numericOnly: true }}
            />
          </FlexItem>
          <FlexItem grow="6" basis="0" />
        </Flex>
        <Flex gap={6}>
          <FlexItem grow="1">
            <TextInput
              name="street"
              placeholder={t('pages:settings.addressForm.street')}
            />
          </FlexItem>
        </Flex>
        <Flex gap={6}>
          <FlexItem grow="6">
            <TextInput
              name="number"
              placeholder={t('pages:settings.addressForm.number')}
            />
          </FlexItem>
          <FlexItem grow="6">
            <TextInput
              name="complement"
              placeholder={t('pages:settings.addressForm.complement')}
            />
          </FlexItem>
        </Flex>
        <Flex gap={6}>
          <FlexItem grow="1">
            <TextInput
              name="neighborhood"
              placeholder={t('pages:settings.addressForm.neighborhood')}
            />
          </FlexItem>
        </Flex>
        <Flex gap={6}>
          <FlexItem grow="6">
            <TextInput
              name="city"
              placeholder={t('pages:settings.addressForm.city')}
            />
          </FlexItem>
        </Flex>
        <Flex gap={6}>
          <FlexItem grow="6" basis="0">
            <Select
              name="state"
              placeholder={t('pages:settings.addressForm.state')}
              options={toArray(State)}
            />
          </FlexItem>
          <FlexItem grow="6" basis="0" />
        </Flex>
      </FieldSet>

      <Flex justify="flex-end">
        <Button type="submit" small inProgress={updateUserAddressInProgress}>
          {t('pages:settings.addressForm.submitButton')}
        </Button>
      </Flex>
    </Form>
  )
}
