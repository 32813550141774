/* eslint-disable @typescript-eslint/no-explicit-any */
import FormData from 'form-data'

import { Contest } from 'types/contest/Contest'
import { ContestActionsResult } from 'types/contest/ContestActionsResult'
import { ContestCategoriesResult } from 'types/contest/ContestCategoriesResult'
import { ContestCreate } from 'types/contest/ContestCreate'
import { ContestGiveUpAnswer } from 'types/contest/ContestGiveUpAnswer'
import { ContestInviteMember } from 'types/contest/ContestInviteMember'
import { ContestMember } from 'types/contest/ContestMember'
import { ContestPrice } from 'types/contest/ContestPrice'
import { ContestRemoveMember } from 'types/contest/ContestRemoveMember'
import { ContestResendPayment } from 'types/contest/ContestResendPayment'
import { ContestResendPaymentResponse } from 'types/contest/ContestResendPaymentResponse'
import { ContestSearchResult } from 'types/contest/ContestSearchResult'
import { ContestSignatures } from 'types/contest/ContestSignatures'
import { ContestUpdate } from 'types/contest/ContestUpdate'
import { ContestUpdateMemberPermission } from 'types/contest/ContestUpdateMemberPermission'
import { SearchUserContestsQuery } from 'types/contest/SearchUserContestsQuery'
import { PagedResult } from 'types/PagedResult'

import { DELETE, GET, POST, PUT } from 'utils/httpUtils'

const CONTEST_PATH = '/contest'

export async function searchUserContests(
  params: SearchUserContestsQuery
): Promise<PagedResult<ContestSearchResult>> {
  try {
    const response = await GET<{ contests: any }>(
      `${CONTEST_PATH}/user`,
      params
    )
    return Promise.resolve({
      pagination: {
        total: response.contests.meta.total,
        perPage: response.contests.meta.per_page,
        firstPage: response.contests.meta.first_page,
        lastPage: response.contests.meta.last_page,
        currentPage: response.contests.meta.current_page
      },
      data: response.contests.data.map((contest: any) => ({
        id: contest.id,
        requester: contest.requester,
        defendant: contest.defendant,
        type: contest.type,
        category: contest.category,
        currentStep: contest.current_step,
        occurredAt: contest.occurred_at,
        createdAt: contest.created_at,
        text: contest.text,
        userRelation: contest.userRelation
      }))
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

function mapResponseToContest(contest: any): Contest {
  return {
    id: contest.id,
    ownerId: contest.owner_id,
    requesterId: contest.requester_id,
    requester: contest.requester,
    defendantId: contest.defendant_id,
    defendant: contest.defendant,
    userRelation: contest.userRelation,
    type: contest.type,
    service: contest.service,
    scene: contest.scene,
    category: contest.category,
    description: contest.description,
    currentStep: contest.current_step,
    currentSla: contest.current_sla,
    slaStart: contest.sla_start_at,
    slaEnd: contest.sla_end_at,
    occurredAt: contest.occurred_at,
    createdAt: contest.created_at,
    updatedAt: contest.updated_at,
    nominate: {
      answer: contest?.nominate?.answer,
      answerMessage: contest?.nominate?.answer_message,
      answeredBy: contest?.nominate?.answered_by,
      contestId: contest?.nominate?.contest_id,
      createdAt: contest?.nominate?.created_at,
      id: contest?.nominate?.id,
      message: contest?.nominate?.message,
      originalInstitutuionId: contest?.nominate?.original_institutuion_id,
      requestedBy: contest?.nominate?.requested_by,
      targetInstitutionId: contest?.nominate?.target_institution_id,
      targetInstitutionName: contest?.nominate?.target_institution_name,
      updatedAt: contest?.nominate?.updated_at
    },
    members: contest.members.map((member: any) => ({
      id: member.id,
      user: {
        ...member.user,
        id: member.user_id
      },
      side: member.side,
      permission: member.permission,
      createdAt: member.created_at
    })),
    data: contest.data.map((data: any) => ({
      id: data.id,
      contestId: data.contest_id,
      user: {
        id: data.user_id,
        name: data.user?.name,
        lastname: data.user?.lastname
      },
      step: data.step,
      action: data.action,
      actionResult: data.action_result,
      side: data.side,
      text: data.text,
      files: data.files.map((file: any) => ({
        id: file.id,
        contestDataId: data.id,
        side: file.side,
        addedBy: {
          id: file.added_by,
          name: data.user?.name,
          lastname: data.user?.lastname
        },
        fileUrl: file.file_url,
        filename: file.filename,
        notes: file.notes,
        storageKey: file.storage_key,
        mutable: file.mutable,
        createdAt: file.created_at
      })),
      createdAt: data.created_at
    })),
    dropout: contest.dropout
      ? {
          id: contest.dropout.id,
          status: contest.dropout.status,
          createdAt: contest.dropout.created_at
        }
      : undefined,
    resend: contest.resend
      ? {
          id: contest.resend.id,
          userId: contest.resend.user_id,
          paymentId: contest.resend.payment_id ?? undefined,
          payment: contest.resend.payment
            ? {
                id: contest.resend.payment.id,
                userId: contest.resend.user_id,
                type: contest.resend.payment.type,
                boletoUrl: contest.resend.payment.boleto_url,
                createdAt: contest.resend.payment.created_at
              }
            : undefined,
          status: contest.resend.status,
          createdAt: contest.resend.created_at,
          updatedAt: contest.resend.created_at
        }
      : undefined
  }
}

export async function getContestById(contestId: number): Promise<Contest> {
  try {
    const { contest } = await GET<{ contest: any }>(
      `${CONTEST_PATH}/id/${contestId}`
    )
    return Promise.resolve(mapResponseToContest(contest))
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function createContest(data: ContestCreate): Promise<Contest> {
  try {
    const { documents, ...rest } = data

    const formData = new FormData()

    Object.entries(rest).forEach(([key, value]) => {
      formData.append(key, value)
    })

    documents?.forEach((doc) => {
      formData.append('documents[]', doc)
    })

    const { contest } = await POST<FormData, { contest: any }>(
      `${CONTEST_PATH}/create`,
      formData
    )
    return Promise.resolve(mapResponseToContest(contest))
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateContest(
  updateData: ContestUpdate<unknown>
): Promise<Contest> {
  try {
    const { contestId, documents, ...rest } = updateData

    const formData = new FormData()

    Object.entries(rest).forEach(([key, value]) => {
      if (typeof value === 'object') {
        formData.append(key, JSON.stringify(value))
      } else {
        formData.append(key, value)
      }
    })

    documents?.forEach((doc) => {
      formData.append('documents[]', doc)
    })

    const { contest } = await PUT<FormData, { contest: any }>(
      `${CONTEST_PATH}/update/${contestId.toString()}`,
      formData
    )

    return Promise.resolve(mapResponseToContest(contest))
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function addContestMember(
  data: ContestInviteMember
): Promise<ContestMember[]> {
  try {
    const { members } = await POST<
      ContestInviteMember,
      { members: ContestMember[] }
    >(`${CONTEST_PATH}/member`, data)
    return Promise.resolve(
      members.map((member: any) => ({
        id: member.id,
        user: {
          ...member.user,
          id: member.user_id
        },
        side: member.side,
        permission: member.permission,
        createdAt: member.created_at
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateContestMemberPermission(
  data: ContestUpdateMemberPermission
): Promise<ContestMember[]> {
  try {
    const { members } = await PUT<
      ContestUpdateMemberPermission,
      { members: ContestMember[] }
    >(`${CONTEST_PATH}/member`, data)
    return Promise.resolve(
      members.map((member: any) => ({
        id: member.id,
        user: {
          ...member.user,
          id: member.user_id
        },
        side: member.side,
        permission: member.permission,
        createdAt: member.created_at
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function removeContestMember(
  data: ContestRemoveMember
): Promise<ContestMember[]> {
  try {
    const { members } = await DELETE<
      ContestRemoveMember,
      { members: ContestMember[] }
    >(`${CONTEST_PATH}/member`, data)
    return Promise.resolve(
      members.map((member: any) => ({
        id: member.id,
        user: {
          ...member.user,
          id: member.user_id
        },
        side: member.side,
        permission: member.permission,
        createdAt: member.created_at
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchContestCategories(): Promise<
  ContestCategoriesResult[]
> {
  try {
    const response = await GET<ContestCategoriesResult[]>(
      `${CONTEST_PATH}/categories`
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getContestActions(
  contestId: number
): Promise<ContestActionsResult[]> {
  try {
    const { actions } = await GET<{ actions: any[] }>(
      `${CONTEST_PATH}/actions/${contestId}`
    )
    return Promise.resolve(
      actions.map((action) => ({
        id: action.id,
        side: action.side,
        contestType: action.contest_type,
        currentStatus: action.current_status,
        nextStatus: action.next_status,
        actionName: action.action_name,
        sla: action.sla,
        createdAt: action.created_at,
        updatedAt: action.updated_at
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function askToGiveUp(contestId: number): Promise<void> {
  try {
    await POST<undefined, { message: string }>(
      `${CONTEST_PATH}/dropout/${contestId}`
    )

    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function answerGiveUpRequest(
  data: ContestGiveUpAnswer
): Promise<void> {
  try {
    const { contestId } = data

    await PUT<ContestGiveUpAnswer, { message: string }>(
      `${CONTEST_PATH}/dropout/${contestId}`,
      data
    )

    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getPrices(): Promise<ContestPrice> {
  try {
    const response = await GET<ContestPrice>(`${CONTEST_PATH}/prices`)

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getSignatureDetails(
  contestId: number
): Promise<ContestSignatures> {
  try {
    const response = await GET<any>(`${CONTEST_PATH}/signatures/${contestId}`)

    return Promise.resolve({
      id: response.id,
      contestId: response.contest_id,
      status: response.status,
      signers: JSON.parse(response.signers),
      details: JSON.parse(response.details) ?? [],
      createdAt: response.created_at,
      updatedAt: response.updated_at
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function payResend(
  data: ContestResendPayment
): Promise<ContestResendPaymentResponse> {
  try {
    const response = await POST<
      ContestResendPayment,
      ContestResendPaymentResponse
    >(`${CONTEST_PATH}/resend/payment`, data)

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function deleteContest(contestId: number): Promise<void> {
  try {
    await DELETE<never, any>(`${CONTEST_PATH}/id/${contestId}`)

    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function listRelatedInstitutions(
  defendantId: number
): Promise<any[]> {
  try {
    const { result } = await GET<{ result: any[] }>(
      `/subscription/institution/conglomerate?id=${defendantId}`
    )

    return Promise.resolve(result)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function requestTransferInstitution(
  contestId: number,
  targetInstitutionId: number,
  message: string
): Promise<void> {
  try {
    await POST<any, any>(`${CONTEST_PATH}/nominate/${contestId}`, {
      targetInstitutionId,
      message
    })

    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function replyTransferInstitution(
  contestId: number,
  approve: boolean,
  message: string
): Promise<void> {
  try {
    await PUT<any, any>(`${CONTEST_PATH}/nominate/${contestId}`, {
      approve,
      message
    })

    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}
