import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { HttpError } from 'types/errors/HttpError'

type AlertProps = {
  type: 'ERROR' | 'SUCCESS'
  functionName: string
  code: string
}

function Container({ type, functionName, code }: AlertProps): JSX.Element {
  const { t } = useTranslation('messages')
  return (
    <span>
      <strong>{t(`messages:types.${type}`)} </strong>
      {t(`messages:${functionName}.${code}`)}
    </span>
  )
}

type Obj = {
  code: string
}

export type ErrorData = string | Error | Obj

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function showError(functionName: string, error: ErrorData | any): void {
  if (error instanceof HttpError) {
    toast.error(
      <Container type="ERROR" functionName={functionName} code={error.code} />
    )
  } else if (typeof error === 'object' && Object.keys(error).includes('code')) {
    toast.error(
      <Container
        type="ERROR"
        functionName={functionName}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        code={(error as any).code}
      />
    )
  } else {
    toast.error(error)
  }
}

export function showSuccess(functionName: string): void {
  toast.success(
    <Container type="SUCCESS" functionName={functionName} code={'SUCCESS'} />
  )
}
