import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Separator } from 'components/separator'

import { withAdminLayout } from 'hoc/withAdminLayout'

import { AddressForm } from './components/addressForm'
import { AuthForm } from './components/authForm'
import { Institution } from './components/institution'
import { UserForm } from './components/userForm'

import { GlobalState } from 'store'
import { fetchUserInfo } from 'store/user/actions'

import { MainContainer } from './styled'

function Settings(): JSX.Element {
  const { t } = useTranslation(['pages'])

  const dispatch = useDispatch()

  const { user } = useSelector((state: GlobalState) => state.session)

  useEffect(() => {
    if (user?.id) {
      dispatch(fetchUserInfo(user.id))
    }
  }, [])

  document.title = 'Configurações - Open Banking Brasil'

  return (
    <>
      <h4>{t('pages:settings.title')}</h4>
      <Separator />

      <MainContainer>
        <UserForm />
        <Separator color="warmGrey" />
        <AuthForm />
        <Separator color="warmGrey" />
        <AddressForm />
        <Separator color="warmGrey" />
        <Institution />
      </MainContainer>
    </>
  )
}

export const SettingsPage = withAdminLayout(Settings)
