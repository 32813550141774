import styled from 'styled-components'

export const DashboardWrapper = styled.div`
  position: relative;
`

export const DashboardMainContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[6]};

  & > section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    gap: ${({ theme }) => theme.spacings[6]};
  }
`

type BoxProps = {
  grow?: string
  basis?: string
}

export const Box = styled.div<BoxProps>`
  flex-grow: ${({ grow }) => grow || '1'};
  flex-basis: ${({ basis }) => basis || '0'};

  padding: ${({ theme }) => theme.spacings[4]};

  /* background-color: ${({ theme }) => theme.colors.paleGrey}; */

  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.paleGrey};
  border-radius: 1.25rem;

  h4 {
    width: 100%;
  }
`

export const Institution = styled.h2`
  color: ${({ theme }) => theme.colors.niceBlue};

  span {
    color: ${({ theme }) => theme.colors.graphite};
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[4]};

  svg {
    color: inherit;
    width: ${({ theme }) => theme.fontSizes.xxlg};
  }

  span {
    font-size: ${({ theme }) => theme.fontSizes.sm};

    strong {
      display: block;
      font-family: ${({ theme }) => theme.fonts.bloggerSans};
      font-size: ${({ theme }) => theme.fontSizes.xxlg};
      font-weight: bold;
    }
  }
`

export const UpdatingTable = styled.table`
  width: 100%;
  min-width: 240px;
  border-collapse: collapse;

  thead {
    tr {
      th {
        font-size: ${({ theme }) => theme.fontSizes.sm};
        padding: ${({ theme }) => `${theme.spacings[2]} ${theme.spacings[1]}`};
        text-align: left;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: rgba(192, 192, 192, 0.2);
      }

      td {
        font-size: ${({ theme }) => theme.fontSizes.sm};
        padding: ${({ theme }) => `${theme.spacings[2]} ${theme.spacings[1]}`};
        color: ${({ theme }) => theme.colors.blackTwo};
        text-align: left;

        a {
          color: ${({ theme }) => theme.colors.niceBlue};

          &:hover {
            text-decoration: underline;
            transition: 0.3s ease;
          }
        }
      }
    }
  }
`

export const ExpiringList = styled.ul`
  min-width: 260px;
  list-style: none;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacings[2]};

    a {
      color: ${({ theme }) => theme.colors.niceBlue};

      svg {
        width: ${({ theme }) => theme.fontSizes.md};
      }
    }
  }

  li + li {
    margin-top: ${({ theme }) => theme.spacings[4]};
  }
`
