import styled, { StyledProps } from 'styled-components'

import { Colors } from 'styles/Theme'

type Color = keyof Colors

type IconButtonContainerProps = StyledProps<{
  color: Color
}>

export const IconButtonContainer = styled.button<IconButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color, theme }) => theme.colors[color]};

  svg {
    color: inherit;
    width: ${({ theme }) => theme.fontSizes.md};
  }
`
