import styled from 'styled-components'

import { ModalBodyContainer } from 'components/modal/styled'

import { Scroll } from 'styles/Helpers'

export const ContestHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[6]};

  dl {
    flex: 1;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    row-gap: ${({ theme }) => theme.spacings[1.5]};

    margin-bottom: ${({ theme }) => theme.spacings[4]};

    dt {
      font-size: ${({ theme }) => theme.fontSizes.xsm};
      color: ${({ theme }) => theme.colors.warmGrey};
    }

    dd {
      font-size: ${({ theme }) => theme.fontSizes.sm};
      color: ${({ theme }) => theme.colors.greyishBrownFive};
    }
  }
`

export const ModalHeaderContent = styled.div`
  display: block;
  width: 100%;

  h2 {
    margin-bottom: ${({ theme }) => theme.spacings[6]};
    text-align: center;
  }
`

export const ModalBody = styled(ModalBodyContainer)`
  padding: 0;

  form {
    fieldset {
      position: relative;

      button[data-remove] {
        position: absolute;
        top: -32px;
        right: 8px;

        svg {
          width: ${({ theme }) => theme.fontSizes.md};
          color: ${({ theme }) => theme.colors.blush};
        }
      }
    }

    & > section {
      max-height: 50vh;

      ${Scroll}

      padding: ${({ theme }) => theme.spacings[4]};
      border: 1px solid ${({ theme }) => theme.colors.paleGrey};

      button + fieldset {
        margin-top: ${({ theme }) => theme.spacings[6]};
      }
    }

    & > button {
      margin: 0 auto;
      margin-top: ${({ theme }) => theme.spacings[6]};
    }
  }
`
