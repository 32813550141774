import { PropsWithChildren } from 'react'

import { Spacings } from 'styles/Theme'

import { FlexWrapper, FlexItemWrapper } from './styled'

type FlexProps = PropsWithChildren<{
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch'
  wrap?: boolean
  gap?: keyof Spacings
  [key: string]: unknown
}>

export function Flex({
  direction = 'row',
  justify = 'flex-start',
  align = 'flex-start',
  wrap = true,
  gap,
  children,
  ...props
}: FlexProps): JSX.Element {
  return (
    <FlexWrapper
      flexDirection={direction}
      justifyContent={justify}
      alignItems={align}
      flexWrap={wrap}
      gap={gap}
      {...props}
    >
      {children}
    </FlexWrapper>
  )
}

type FlexItemProps = PropsWithChildren<{
  grow?:
    | '0'
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
  basis?: string
  shrink?: string
  order?: number
}>

export function FlexItem({
  grow = '0',
  basis = 'auto',
  shrink = '1',
  order,
  children
}: FlexItemProps): JSX.Element {
  return (
    <FlexItemWrapper
      flexGrow={grow}
      flexBasis={basis}
      flexShrink={shrink}
      order={order}
    >
      {children}
    </FlexItemWrapper>
  )
}
