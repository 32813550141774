import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { FieldSet } from 'components/fieldSet'
import { Form } from 'components/form'
import { Flex, FlexItem } from 'components/flex'
import { Select } from 'components/select'
import { MaskedInput } from 'components/maskedInput'
import { TextInput } from 'components/textInput'

import { GlobalState } from 'store'
import { updateInstitutionAddress } from 'store/institution/actions'

import { State } from 'types/enums/State'
import { UserRole } from 'types/enums/UserRole'

import { toArray } from 'utils/enumUtils'

export function AddressForm(): JSX.Element {
  const { t } = useTranslation(['pages'])

  const { cnpj } = useParams<{ cnpj: string }>()

  const dispatch = useDispatch()

  const {
    institution: { institution, updateInstitutionAddressInProgress },
    session: { user }
  } = useSelector((state: GlobalState) => state)

  const initialValues = {
    cnpj,
    cep: institution?.address?.cep ?? '',
    street: institution?.address?.street ?? '',
    number: institution?.address?.number ?? '',
    complement: institution?.address?.complement ?? '',
    neighborhood: institution?.address?.neighborhood ?? '',
    city: institution?.address?.city ?? '',
    state: institution?.address?.state ?? ''
  }

  const validationSchema = Yup.object().shape({
    cep: Yup.string().required('required'),
    street: Yup.string().required('required'),
    number: Yup.string().required('required'),
    complement: Yup.string(),
    neighborhood: Yup.string().required('required'),
    city: Yup.string().required('required'),
    state: Yup.string().required('required')
  })

  function _sessionUserIsOwner(): boolean {
    if (!user) return false

    const institutionUser = institution?.users.find(({ id }) => id === user.id)

    return institutionUser?.role === UserRole.ADMIN
  }

  function _handleSubmit(data: FormikValues) {
    dispatch(
      updateInstitutionAddress({
        cnpj: data.cnpj,
        address: {
          cep: data.cep,
          street: data.street,
          number: data.number,
          complement: data.complement,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state
        }
      })
    )
  }

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => _handleSubmit(values)}
    >
      <FieldSet title={t('pages:institutionUpdate.addressForm.title')}>
        <Flex gap={6}>
          <FlexItem grow="6" basis="0">
            <MaskedInput
              name="cep"
              placeholder={t('pages:institutionUpdate.addressForm.cep')}
              options={{
                delimiters: ['-'],
                blocks: [5, 3],
                numericOnly: true
              }}
            />
          </FlexItem>
          <FlexItem grow="6" basis="0" />
        </Flex>
        <Flex gap={6}>
          <FlexItem grow="1">
            <TextInput
              name="street"
              placeholder={t('pages:institutionUpdate.addressForm.street')}
            />
          </FlexItem>
        </Flex>
        <Flex gap={6}>
          <FlexItem grow="6">
            <TextInput
              name="number"
              placeholder={t('pages:institutionUpdate.addressForm.number')}
            />
          </FlexItem>
          <FlexItem grow="6">
            <TextInput
              name="complement"
              placeholder={t('pages:institutionUpdate.addressForm.complement')}
            />
          </FlexItem>
        </Flex>
        <Flex gap={6}>
          <FlexItem grow="1">
            <TextInput
              name="neighborhood"
              placeholder={t(
                'pages:institutionUpdate.addressForm.neighborhood'
              )}
            />
          </FlexItem>
        </Flex>
        <Flex gap={6}>
          <FlexItem grow="6">
            <TextInput
              name="city"
              placeholder={t('pages:institutionUpdate.addressForm.city')}
            />
          </FlexItem>
        </Flex>
        <Flex gap={6}>
          <FlexItem grow="6" basis="0">
            <Select
              name="state"
              placeholder={t('pages:institutionUpdate.addressForm.state')}
              options={toArray(State)}
            />
          </FlexItem>
          <FlexItem grow="6" basis="0" />
        </Flex>
      </FieldSet>

      <Flex justify="flex-end">
        <Button
          type="submit"
          small
          inProgress={updateInstitutionAddressInProgress}
          disabled={!_sessionUserIsOwner()}
        >
          {t('pages:institutionUpdate.addressForm.submitButton')}
        </Button>
      </Flex>
    </Form>
  )
}
