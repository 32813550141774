import styled from 'styled-components'

export const SignUpHeaderWrapper = styled.header`
  z-index: ${({ theme }) => theme.zIndex.sticky};
  position: sticky;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 70px;
  min-height: 70px;

  background-color: ${({ theme }) => theme.colors.petrol};

  img {
    height: 40px;
  }
`
