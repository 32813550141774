import { useState } from 'react'
import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { Modal, ModalHeader } from 'components/modal'
import { Flex } from 'components/flex'
import { Form } from 'components/form'
import { Select } from 'components/select'
import { Textarea } from 'components/textarea'
import { TagsInput } from 'components/tagsInput'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'

import { ModalName } from 'types/enums/ModalName'
import { Precedent } from 'types/precedent/Precedent'

import { reviewPrecedent } from 'services/http/precedentsService'

import { ModalBody } from './styled'
import { showSuccess, showError } from 'utils/alertUtils'

type Props = {
  precedent?: Precedent
  onSubmit?: () => void
}

export function PrecedentReviewModal({
  precedent,
  onSubmit
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)
  const [isApproved, setIsApproved] = useState<boolean | null>(null)

  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    modal: { precedentReviewIsOpen }
  } = useSelector((state: GlobalState) => state)

  const initialValues = {
    id: null,
    approve: null,
    message: ''
  }

  const validationSchema = Yup.object().shape({
    approve: Yup.string().nullable().required('required'),
    message: Yup.string().when('approve', {
      is: (approve: string) => approve === 'false',
      then: Yup.string().required('required')
    })
  })

  async function _handleSubmit(data: FormikValues) {
    try {
      if (!precedent) return
      setIsLoading(true)
      await reviewPrecedent({
        id: precedent.id,
        approve: data?.approve === 'true',
        message: data?.message,
        tags: data?.tags
      })
      showSuccess('reviewPrecedent')
      dispatch(closeModal(ModalName.PRECEDENT_REVIEW))
      onSubmit?.()
      console.log(data)
    } catch (err) {
      showError('reviewPrecedent', err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isOpen={precedentReviewIsOpen}
      onClose={() => {
        dispatch(closeModal(ModalName.PRECEDENT_REVIEW))
        setIsApproved(false)
      }}
    >
      <ModalHeader separator={false}>
        <h2>{t('precedentReview.title')}</h2>
      </ModalHeader>

      <ModalBody>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
          key={`precedentReview-${precedentReviewIsOpen}`}
        >
          <Flex direction="column" align="center">
            <Select
              placeholder="Parecer"
              name="approve"
              options={[
                { label: 'Aprovar', value: 'true' },
                { label: 'Rejeitar', value: 'false' }
              ]}
              onChange={(val) => setIsApproved(val === 'true')}
            />

            <div
              style={{ display: !isApproved ? 'none' : 'flex', width: '100%' }}
            >
              <TagsInput
                name="tags"
                placeholder="Tags"
                initialValue={precedent?.tags}
              />
            </div>

            <Textarea
              name="message"
              placeholder={t('precedentReview.form.message')}
              rows={6}
              style={{ display: isApproved ? 'none' : 'flex' }}
            />

            <Button small type="submit" inProgress={isLoading}>
              {t('precedentReview.submitButton')}
            </Button>
          </Flex>
        </Form>
      </ModalBody>
    </Modal>
  )
}
