import { Editor } from '@tinymce/tinymce-react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { TextEditorWrapper } from './styled'

type TextEditorProps = {
  name: string
  placeholder: string
  height?: number
  [key: string]: unknown // workaround to accept html attributes
}

export function TextEditor({
  name,
  placeholder,
  height = 500,
  ...rest
}: TextEditorProps): JSX.Element {
  const { t } = useTranslation(['validation'])

  const [field, meta, helpers] = useField({ name, type: 'text' })

  return (
    <TextEditorWrapper hasError={meta.touched && !!meta.error}>
      <Editor
        apiKey="rv7v9emjbbxonks7cm04urwyx2eq6u5hfzbgxr6zk9pipa35"
        init={{
          height,
          language: 'pt_BR',
          plugins:
            'print preview importcss searchreplace autolink save visualchars fullscreen link table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars',
          mobile: {
            plugins:
              'print preview importcss searchreplace autolink save directionality visualchars fullscreen link table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars'
          },
          menubar: 'file edit view insert format tools table help',
          toolbar:
            'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap | fullscreen  preview save print | link anchor',
          image_advtab: true,
          importcss_append: true,
          image_caption: true,
          quickbars_selection_toolbar:
            'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
          toolbar_mode: 'sliding',
          contextmenu: 'link image imagetools table configur'
        }}
        // init={{
        //   height,
        //   language: 'pt_BR',
        //   plugins:
        //     'print preview importcss searchreplace autolink save visualchars fullscreen image link media table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars code',
        //   mobile: {
        //     plugins:
        //       'print preview importcss searchreplace autolink save directionality visualchars fullscreen image link media table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars code'
        //   },
        //   menubar: 'file edit view insert format tools table help',
        //   toolbar:
        //     'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap | fullscreen  preview save print | insertfile image media link anchor | code',
        //   image_advtab: true,
        //   importcss_append: true,
        //   image_caption: true,
        //   quickbars_selection_toolbar:
        //     'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        //   toolbar_mode: 'sliding',
        //   contextmenu: 'link image imagetools table configur'
        // }}
        value={field.value}
        onEditorChange={(value: string) => helpers.setValue(value)}
        {...rest}
      />
      {meta.touched && !!meta.error && (
        <p>
          {t(`validation:${meta.error}`, {
            field: placeholder
          })}
        </p>
      )}
    </TextEditorWrapper>
  )
}
