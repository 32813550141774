import { FormikValues } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ReactComponent as CommentSVG } from 'assets/images/comment.svg'
import { ReactComponent as DownloadSVG } from 'assets/images/download.svg'
import { ReactComponent as TrashSVG } from 'assets/images/trash.svg'

import { Button } from 'components/button'
import { Flex } from 'components/flex'
import { IconButton } from 'components/iconButton'
import { Table } from 'components/table'
import { Tooltip } from 'components/tooltip'

import { confirmationConfig } from 'config/swal'

import { getContestFileUrl } from 'services/http/contestFileService'

import { GlobalState } from 'store'
import { openModal } from 'store/modal/actions'
import {
  fetchContestFiles,
  removeContestFiles
} from 'store/contestFile/actions'

import Theme from 'styles/Theme'

import { ContestFile } from 'types/contestFile/ContestFile'
import { ContestMemberPermission } from 'types/enums/ContestMemberPermission'
import { ModalName } from 'types/enums/ModalName'
import { TableColumn } from 'types/table/TableColumn'

import { showError } from 'utils/alertUtils'
import { formatDateBR } from 'utils/formatUtils'

import { ContestAddAttachmentModal } from '../contestAddAttachmentModal'
import { ContestAddAttachmentNoteModal } from '../contestAddAttachmentNoteModal'

import { FilesTabWrapper } from './styled'

type Props = {
  userPermission?: ContestMemberPermission
  focusFiles?: ContestFile[]
}

export function FilesTab({
  userPermission,
  focusFiles = []
}: Props): JSX.Element {
  const { t } = useTranslation(['pages'])

  const dispatch = useDispatch()

  const { contestId } = useParams<{ contestId: string }>()

  const {
    session: { user: sessionUser },
    contestFile: {
      files,
      fetchContestFilesInProgress,
      removeContestFilesInProgress
    }
  } = useSelector((state: GlobalState) => state)

  const columns = useMemo<TableColumn<ContestFile>[]>(
    () => [
      {
        label: '',
        dataKey: 'id',
        thStyle: { display: 'none' },
        tdStyle: { display: 'none' }
      },
      {
        label: t('pages:contestDetails.tabs.files.table.filename'),
        dataKey: 'filename',
        thStyle: { width: '25%' },
        tdStyle: { width: '25%', wordBreak: 'break-word' }
      },
      {
        label: t('pages:contestDetails.tabs.files.table.createdAt'),
        dataKey: 'createdAt',
        thStyle: { width: '10%' },
        tdStyle: { width: '10%' },
        render: (row) => formatDateBR(row.createdAt)
      },
      {
        label: t('pages:contestDetails.tabs.files.table.addedBy'),
        dataKey: 'addedBy',
        thStyle: { width: '20%' },
        tdStyle: { width: '20%' },
        render: (row) =>
          `${row.addedBy?.name ?? ''} ${row.addedBy?.lastname ?? ''}`
      },
      {
        label: t('pages:contestDetails.tabs.files.table.notes'),
        dataKey: 'notes',
        render: (row) => {
          if (row.notes?.length > 50) {
            return (
              <Tooltip content={row.notes}>
                <span>{row.notes.substr(0, 50) + '...'}</span>
              </Tooltip>
            )
          }
          return <span>{row.notes}</span>
        }
      },
      {
        label: t('pages:contestCreate.membersStep.table.actions.title'),
        dataKey: 'action',
        thStyle: { width: '10%' },
        tdStyle: { width: '10%' },
        render: (row) => _renderActions(row)
      }
    ],
    []
  )

  const [downloadFileInProgress, setDownloadFileInProgress] = useState<boolean>(
    false
  )

  const [noteToUpdate, setNoteToUpdate] = useState<FormikValues>({})

  useEffect(() => {
    if (contestId) {
      dispatch(fetchContestFiles(parseInt(contestId)))
    }
  }, [contestId])

  useEffect(() => {
    if (focusFiles.length) {
      focusFiles.forEach((file) => {
        const row = document.querySelector(`tr[data-rowid="${file.id}"]`)
        if (row) {
          row.classList.add('focus')
        }
      })
    }
  }, [focusFiles, files])

  function _fileWasAddedBySessionUser(file: ContestFile): boolean {
    return file.addedBy.id === sessionUser?.id
  }

  function _updateNotes(file: ContestFile) {
    setNoteToUpdate({
      contestId,
      key: file.storageKey,
      notes: file.notes ?? ''
    })

    dispatch(openModal(ModalName.CONTEST_ADD_ATTACHMENT_NOTE))
  }

  function _removeFile(key: string) {
    withReactContent(SweetAlert)
      .fire({
        ...confirmationConfig,
        icon: 'warning',
        titleText: t('pages:contestDetails.tabs.files.confirmRemove.title'),
        html: t('pages:contestDetails.tabs.files.confirmRemove.message'),
        confirmButtonText: t(
          'pages:contestDetails.tabs.files.confirmRemove.confirmButton'
        ),
        cancelButtonText: t(
          'pages:contestDetails.tabs.files.confirmRemove.cancelButton'
        )
      })
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          dispatch(removeContestFiles(key))
        }
      })
  }

  async function _downloadFile(key: string) {
    try {
      setDownloadFileInProgress(true)

      const { url, file } = await getContestFileUrl(key)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.target = '__blank'
      downloadLink.download = file.filename

      document.body.appendChild(downloadLink)

      downloadLink.click()

      downloadLink.parentNode?.removeChild(downloadLink)
    } catch (error) {
      showError('downloadContestFile', error)
    } finally {
      setDownloadFileInProgress(false)
    }
  }

  function _renderActions(row: ContestFile) {
    return (
      <Flex direction="row" gap={2}>
        <IconButton
          tooltip={t('pages:contestDetails.tabs.files.table.actions.download')}
          color="niceBlue"
          onClick={() => _downloadFile(row.storageKey)}
          disabled={downloadFileInProgress}
        >
          {downloadFileInProgress ? (
            <BounceLoader size="16px" color={Theme.colors.niceBlue} />
          ) : (
            <DownloadSVG />
          )}
        </IconButton>

        {_fileWasAddedBySessionUser(row) && (
          <IconButton
            tooltip={t('pages:contestDetails.tabs.files.table.actions.addNote')}
            color="greyishBrownFive"
            onClick={() => _updateNotes(row)}
          >
            <CommentSVG />
          </IconButton>
        )}

        {_fileWasAddedBySessionUser(row) && row.mutable && (
          <IconButton
            tooltip={t('pages:contestDetails.tabs.files.table.actions.remove')}
            color="blush"
            onClick={() => _removeFile(row.storageKey)}
            disabled={removeContestFilesInProgress}
          >
            {removeContestFilesInProgress ? (
              <BounceLoader size="16px" color={Theme.colors.blush} />
            ) : (
              <TrashSVG />
            )}
          </IconButton>
        )}
      </Flex>
    )
  }

  return (
    <FilesTabWrapper>
      <Flex direction="row" justify="flex-end">
        {userPermission !== ContestMemberPermission.READ && (
          <Button
            small
            type="button"
            color="niceBlue"
            variant="outline"
            onClick={() =>
              dispatch(openModal(ModalName.CONTEST_ADD_ATTACHMENT))
            }
          >
            {t('pages:contestDetails.tabs.files.addFileButton')}
          </Button>
        )}
      </Flex>

      <Table
        columns={columns}
        data={files ?? []}
        pagination={false}
        isLoading={fetchContestFilesInProgress}
      />

      <ContestAddAttachmentModal />

      <ContestAddAttachmentNoteModal initialValues={noteToUpdate} />
    </FilesTabWrapper>
  )
}
