import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'
import { ContestAssociation } from 'types/contestAssociation/ContestAssociation'

import {
  FETCH_CONTEST_ASSOCIATIONS,
  FETCH_CONTEST_ASSOCIATIONS_FAILURE,
  FETCH_CONTEST_ASSOCIATIONS_SUCCESS,
  CREATE_CONTEST_ASSOCIATION,
  CREATE_CONTEST_ASSOCIATION_FAILURE,
  CREATE_CONTEST_ASSOCIATION_SUCCESS,
  REMOVE_CONTEST_ASSOCIATION,
  REMOVE_CONTEST_ASSOCIATION_FAILURE,
  REMOVE_CONTEST_ASSOCIATION_SUCCESS
} from './actionTypes'

export type ContestAssociationState = {
  associations: ContestAssociation[]
  fetchContestAssociationInProgress: boolean
  createContestAssociationInProgress: boolean
  removeContestAssociationInProgress: boolean
}

const INITIAL_STATE: ContestAssociationState = {
  associations: [],
  fetchContestAssociationInProgress: false,
  createContestAssociationInProgress: false,
  removeContestAssociationInProgress: false
}

export function contestAssociationReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): ContestAssociationState {
  return produce(state, (draft) => {
    switch (action.type) {
      case FETCH_CONTEST_ASSOCIATIONS: {
        draft.fetchContestAssociationInProgress = true
        break
      }

      case FETCH_CONTEST_ASSOCIATIONS_FAILURE: {
        draft.fetchContestAssociationInProgress = false
        break
      }

      case FETCH_CONTEST_ASSOCIATIONS_SUCCESS: {
        draft.associations = <ContestAssociation[]>action.payload
        draft.fetchContestAssociationInProgress = false
        break
      }

      case CREATE_CONTEST_ASSOCIATION: {
        draft.createContestAssociationInProgress = true
        break
      }

      case CREATE_CONTEST_ASSOCIATION_FAILURE: {
        draft.createContestAssociationInProgress = false
        break
      }

      case CREATE_CONTEST_ASSOCIATION_SUCCESS: {
        draft.createContestAssociationInProgress = false
        break
      }

      case REMOVE_CONTEST_ASSOCIATION: {
        draft.removeContestAssociationInProgress = true
        break
      }

      case REMOVE_CONTEST_ASSOCIATION_FAILURE: {
        draft.removeContestAssociationInProgress = false
        break
      }

      case REMOVE_CONTEST_ASSOCIATION_SUCCESS: {
        draft.removeContestAssociationInProgress = false
        break
      }

      case CLEAR_SESSION_DATA: {
        return INITIAL_STATE
      }

      default: {
        break
      }
    }
  })
}
