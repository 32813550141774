import styled from 'styled-components'

import { ReactComponent as CrownSVG } from 'assets/images/crown.svg'
import { ReactComponent as LockSVG } from 'assets/images/lock.svg'

export const PageTitle = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacings[1]};
`

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: ${({ theme }) => theme.spacings[2]};

  span {
    font-size: ${({ theme }) => theme.fontSizes.xmd};
    color: ${({ theme }) => theme.colors.niceBlue};
  }
`

export const TableHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: ${({ theme }) => theme.spacings[4]};
  margin-bottom: ${({ theme }) => theme.spacings[4]};
`

export const OwnerIcon = styled(CrownSVG)`
  color: ${({ theme }) => theme.colors.sand};
  width: ${({ theme }) => theme.fontSizes.md};
`

export const LockIcon = styled(LockSVG)`
  color: ${({ theme }) => theme.colors.blush};
  width: ${({ theme }) => theme.fontSizes.md};
`

export const IconDisabled = styled.span`
  color: ${({ theme }) => theme.colors.pinkishGrey};
  width: ${({ theme }) => theme.fontSizes.md};
  cursor: not-allowed;
`
