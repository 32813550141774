import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'
import { Contest } from 'types/contest/Contest'
import { ContestActionsResult } from 'types/contest/ContestActionsResult'
import { ContestCategoriesResult } from 'types/contest/ContestCategoriesResult'
import { ContestMember } from 'types/contest/ContestMember'
import { ContestNominationAnswer } from 'types/contest/ContestNomination'
import { ContestPrice } from 'types/contest/ContestPrice'
import { ContestSearchResult } from 'types/contest/ContestSearchResult'
import { PagedResult } from 'types/PagedResult'

import {
  SEARCH_USER_CONTESTS,
  SEARCH_USER_CONTESTS_FAILURE,
  SEARCH_USER_CONTESTS_SUCCESS,
  CREATE_CONTEST,
  CREATE_CONTEST_FAILURE,
  CREATE_CONTEST_SUCCESS,
  ADD_CONTEST_MEMBER,
  ADD_CONTEST_MEMBER_FAILURE,
  ADD_CONTEST_MEMBER_SUCCESS,
  UPDATE_CONTEST_MEMBER_PERMISSION,
  UPDATE_CONTEST_MEMBER_PERMISSION_FAILURE,
  UPDATE_CONTEST_MEMBER_PERMISSION_SUCCESS,
  REMOVE_CONTEST_MEMBER,
  REMOVE_CONTEST_MEMBER_FAILURE,
  REMOVE_CONTEST_MEMBER_SUCCESS,
  PAY_CONTEST,
  PAY_CONTEST_FAILURE,
  PAY_CONTEST_SUCCESS,
  PAY_CONTEST_BOLETO,
  PAY_CONTEST_BOLETO_FAILURE,
  PAY_CONTEST_BOLETO_SUCCESS,
  GET_CONTEST_BY_ID,
  GET_CONTEST_BY_ID_FAILURE,
  GET_CONTEST_BY_ID_SUCCESS,
  FETCH_CONTEST_CATEGORIES,
  FETCH_CONTEST_CATEGORIES_FAILURE,
  FETCH_CONTEST_CATEGORIES_SUCCESS,
  GET_CONTEST_ACTIONS,
  GET_CONTEST_ACTIONS_FAILURE,
  GET_CONTEST_ACTIONS_SUCCESS,
  REPLY_CONTEST,
  REPLY_CONTEST_FAILURE,
  REPLY_CONTEST_SUCCESS,
  DECLINE_CONTEST,
  DECLINE_CONTEST_FAILURE,
  DECLINE_CONTEST_SUCCESS,
  ACCEPT_CONTEST,
  ACCEPT_CONTEST_FAILURE,
  ACCEPT_CONTEST_SUCCESS,
  SEND_AGREEMENT,
  SEND_AGREEMENT_FAILURE,
  SEND_AGREEMENT_SUCCESS,
  ACCEPT_AGREEMENT,
  ACCEPT_AGREEMENT_FAILURE,
  ACCEPT_AGREEMENT_SUCCESS,
  SEND_SUBSCRIBERS,
  SEND_SUBSCRIBERS_FAILURE,
  SEND_SUBSCRIBERS_SUCCESS,
  ASK_TO_GIVE_UP,
  ASK_TO_GIVE_UP_FAILURE,
  ASK_TO_GIVE_UP_SUCCESS,
  ANSWER_GIVE_UP,
  ANSWER_GIVE_UP_FAILURE,
  ANSWER_GIVE_UP_SUCCESS,
  DESIST,
  DESIST_FAILURE,
  DESIST_SUCCESS,
  ABORT,
  ABORT_FAILURE,
  ABORT_SUCCESS,
  RESEND,
  RESEND_FAILURE,
  RESEND_SUCCESS,
  GET_PRICES,
  GET_PRICES_FAILURE,
  GET_PRICES_SUCCESS,
  RESEND_PAYMENT,
  RESEND_PAYMENT_FAILURE,
  RESEND_PAYMENT_SUCCESS,
  DELETE_CONTEST,
  DELETE_CONTEST_FAILURE,
  DELETE_CONTEST_SUCCESS,
  ANSWER_CONTEST_INSTITUTION_NOMINATION
} from './actionTypes'

export type ContestState = {
  searchUserContestsResult?: PagedResult<ContestSearchResult>
  searchUserContestsInProgress: boolean
  contestData?: Contest
  createContestInProgress: boolean
  addContestMemberInProgress: boolean
  updateContestMemberPermissionInProgress: boolean
  removeContestMemberInProgress: boolean
  payContestInProgress: boolean
  payContestBoletoInProgress: boolean
  getContestByIdInProgress: boolean
  contestCategories: ContestCategoriesResult[]
  fetchContestCategoriesInProgress: boolean
  availableActions: ContestActionsResult[]
  getContestActionsInProgress: boolean
  replyContestInProgress: boolean
  declineContestInProgress: boolean
  acceptContestInProgress: boolean
  sendAgreementInProgress: boolean
  acceptAgreementInProgress: boolean
  sendSubscribersInProgress: boolean
  askToGiveUpInProgress: boolean
  answerGiveUpInProgress: boolean
  desistInProgress: boolean
  abortInProgress: boolean
  resendInProgress: boolean
  contestPrice?: ContestPrice
  getPricesInProgress: boolean
  resendPaymentInProgress: boolean
  deleteContestInProgress: boolean
  nominationAnswer: ContestNominationAnswer
}

const INITIAL_STATE: ContestState = {
  searchUserContestsResult: undefined,
  searchUserContestsInProgress: false,
  contestData: undefined,
  createContestInProgress: false,
  addContestMemberInProgress: false,
  updateContestMemberPermissionInProgress: false,
  removeContestMemberInProgress: false,
  payContestInProgress: false,
  payContestBoletoInProgress: false,
  getContestByIdInProgress: false,
  contestCategories: [],
  fetchContestCategoriesInProgress: false,
  availableActions: [],
  getContestActionsInProgress: false,
  replyContestInProgress: false,
  declineContestInProgress: false,
  acceptContestInProgress: false,
  sendAgreementInProgress: false,
  acceptAgreementInProgress: false,
  sendSubscribersInProgress: false,
  askToGiveUpInProgress: false,
  answerGiveUpInProgress: false,
  desistInProgress: false,
  abortInProgress: false,
  resendInProgress: false,
  contestPrice: undefined,
  getPricesInProgress: false,
  resendPaymentInProgress: false,
  deleteContestInProgress: false,
  nominationAnswer: null
}

export function contestReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): ContestState {
  return produce(state, (draft) => {
    switch (action.type) {
      case SEARCH_USER_CONTESTS: {
        draft.searchUserContestsInProgress = true
        break
      }

      case SEARCH_USER_CONTESTS_FAILURE: {
        draft.searchUserContestsResult = undefined
        draft.searchUserContestsInProgress = false
        break
      }

      case SEARCH_USER_CONTESTS_SUCCESS: {
        draft.searchUserContestsResult = <PagedResult<ContestSearchResult>>(
          action.payload
        )
        draft.searchUserContestsInProgress = false
        break
      }

      case CREATE_CONTEST: {
        draft.createContestInProgress = true
        break
      }

      case CREATE_CONTEST_FAILURE: {
        draft.createContestInProgress = false
        break
      }

      case CREATE_CONTEST_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.createContestInProgress = false
        break
      }

      case ADD_CONTEST_MEMBER: {
        draft.addContestMemberInProgress = true
        break
      }

      case ADD_CONTEST_MEMBER_FAILURE: {
        draft.addContestMemberInProgress = false
        break
      }

      case ADD_CONTEST_MEMBER_SUCCESS: {
        draft.contestData = <Contest>{
          ...state.contestData,
          members: <ContestMember[]>action.payload
        }
        draft.addContestMemberInProgress = false
        break
      }

      case UPDATE_CONTEST_MEMBER_PERMISSION: {
        draft.updateContestMemberPermissionInProgress = true
        break
      }

      case UPDATE_CONTEST_MEMBER_PERMISSION_FAILURE: {
        draft.updateContestMemberPermissionInProgress = false
        break
      }

      case UPDATE_CONTEST_MEMBER_PERMISSION_SUCCESS: {
        draft.contestData = <Contest>{
          ...state.contestData,
          members: <ContestMember[]>action.payload
        }
        draft.updateContestMemberPermissionInProgress = false
        break
      }

      case REMOVE_CONTEST_MEMBER: {
        draft.removeContestMemberInProgress = true
        break
      }

      case REMOVE_CONTEST_MEMBER_FAILURE: {
        draft.removeContestMemberInProgress = false
        break
      }

      case REMOVE_CONTEST_MEMBER_SUCCESS: {
        draft.contestData = <Contest>{
          ...state.contestData,
          members: <ContestMember[]>action.payload
        }
        draft.removeContestMemberInProgress = false
        break
      }

      case PAY_CONTEST: {
        draft.payContestInProgress = true
        break
      }

      case PAY_CONTEST_FAILURE: {
        draft.payContestInProgress = false
        break
      }

      case PAY_CONTEST_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.payContestInProgress = false
        break
      }

      case PAY_CONTEST_BOLETO: {
        draft.payContestBoletoInProgress = true
        break
      }

      case PAY_CONTEST_BOLETO_FAILURE: {
        draft.payContestBoletoInProgress = false
        break
      }

      case PAY_CONTEST_BOLETO_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.payContestBoletoInProgress = false
        break
      }

      case GET_CONTEST_BY_ID: {
        draft.getContestByIdInProgress = true
        break
      }

      case GET_CONTEST_BY_ID_FAILURE: {
        draft.contestData = undefined
        draft.getContestByIdInProgress = false
        break
      }

      case GET_CONTEST_BY_ID_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.getContestByIdInProgress = false
        break
      }

      case FETCH_CONTEST_CATEGORIES: {
        draft.fetchContestCategoriesInProgress = true
        break
      }

      case FETCH_CONTEST_CATEGORIES_FAILURE: {
        draft.contestCategories = []
        draft.fetchContestCategoriesInProgress = false
        break
      }

      case FETCH_CONTEST_CATEGORIES_SUCCESS: {
        draft.contestCategories = <ContestCategoriesResult[]>action.payload
        draft.fetchContestCategoriesInProgress = false
        break
      }

      case GET_CONTEST_ACTIONS: {
        draft.getContestActionsInProgress = true
        break
      }

      case GET_CONTEST_ACTIONS_FAILURE: {
        draft.availableActions = []
        draft.getContestActionsInProgress = false
        break
      }

      case GET_CONTEST_ACTIONS_SUCCESS: {
        draft.availableActions = <ContestActionsResult[]>action.payload
        draft.getContestActionsInProgress = false
        break
      }

      case REPLY_CONTEST: {
        draft.replyContestInProgress = true
        break
      }

      case REPLY_CONTEST_FAILURE: {
        draft.replyContestInProgress = false
        break
      }

      case REPLY_CONTEST_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.replyContestInProgress = false
        break
      }

      case DECLINE_CONTEST: {
        draft.declineContestInProgress = true
        break
      }

      case DECLINE_CONTEST_FAILURE: {
        draft.declineContestInProgress = false
        break
      }

      case DECLINE_CONTEST_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.declineContestInProgress = false
        break
      }

      case ACCEPT_CONTEST: {
        draft.acceptContestInProgress = true
        break
      }

      case ACCEPT_CONTEST_FAILURE: {
        draft.acceptContestInProgress = false
        break
      }

      case ACCEPT_CONTEST_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.acceptContestInProgress = false
        break
      }

      case SEND_AGREEMENT: {
        draft.sendAgreementInProgress = true
        break
      }

      case SEND_AGREEMENT_FAILURE: {
        draft.sendAgreementInProgress = false
        break
      }

      case SEND_AGREEMENT_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.sendAgreementInProgress = false
        break
      }

      case ACCEPT_AGREEMENT: {
        draft.acceptAgreementInProgress = true
        break
      }

      case ACCEPT_AGREEMENT_FAILURE: {
        draft.acceptAgreementInProgress = false
        break
      }

      case ACCEPT_AGREEMENT_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.acceptAgreementInProgress = false
        break
      }

      case SEND_SUBSCRIBERS: {
        draft.sendSubscribersInProgress = true
        break
      }

      case SEND_SUBSCRIBERS_FAILURE: {
        draft.sendSubscribersInProgress = false
        break
      }

      case SEND_SUBSCRIBERS_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.sendSubscribersInProgress = false
        break
      }

      case ASK_TO_GIVE_UP: {
        draft.askToGiveUpInProgress = true
        break
      }

      case ASK_TO_GIVE_UP_FAILURE: {
        draft.askToGiveUpInProgress = false
        break
      }

      case ASK_TO_GIVE_UP_SUCCESS: {
        draft.askToGiveUpInProgress = false
        break
      }

      case ANSWER_GIVE_UP: {
        draft.answerGiveUpInProgress = true
        break
      }

      case ANSWER_GIVE_UP_FAILURE: {
        draft.answerGiveUpInProgress = false
        break
      }

      case ANSWER_GIVE_UP_SUCCESS: {
        draft.answerGiveUpInProgress = false
        break
      }

      case DESIST: {
        draft.desistInProgress = true
        break
      }

      case DESIST_FAILURE: {
        draft.desistInProgress = false
        break
      }

      case DESIST_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.desistInProgress = false
        break
      }

      case ABORT: {
        draft.abortInProgress = true
        break
      }

      case ABORT_FAILURE: {
        draft.abortInProgress = false
        break
      }

      case ABORT_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.abortInProgress = false
        break
      }

      case RESEND: {
        draft.resendInProgress = true
        break
      }

      case RESEND_FAILURE: {
        draft.resendInProgress = false
        break
      }

      case RESEND_SUCCESS: {
        draft.contestData = <Contest>action.payload
        draft.resendInProgress = false
        break
      }

      case GET_PRICES: {
        draft.getPricesInProgress = true
        break
      }

      case GET_PRICES_FAILURE: {
        draft.getPricesInProgress = false
        break
      }

      case GET_PRICES_SUCCESS: {
        draft.contestPrice = <ContestPrice>action.payload
        draft.getPricesInProgress = false
        break
      }

      case RESEND_PAYMENT: {
        draft.resendPaymentInProgress = true
        break
      }

      case RESEND_PAYMENT_FAILURE: {
        draft.resendPaymentInProgress = false
        break
      }

      case RESEND_PAYMENT_SUCCESS: {
        draft.resendPaymentInProgress = false
        break
      }

      case DELETE_CONTEST: {
        draft.deleteContestInProgress = true
        break
      }

      case DELETE_CONTEST_FAILURE: {
        draft.deleteContestInProgress = false
        break
      }

      case DELETE_CONTEST_SUCCESS: {
        draft.deleteContestInProgress = false
        break
      }

      case ANSWER_CONTEST_INSTITUTION_NOMINATION: {
        draft.nominationAnswer = <ContestNominationAnswer>action.payload
        break
      }

      case CLEAR_SESSION_DATA: {
        draft.searchUserContestsResult = undefined
        draft.searchUserContestsInProgress = false
        draft.contestData = undefined
        draft.createContestInProgress = false
        draft.addContestMemberInProgress = false
        draft.updateContestMemberPermissionInProgress = false
        draft.removeContestMemberInProgress = false
        draft.payContestInProgress = false
        draft.payContestBoletoInProgress = false
        draft.getContestByIdInProgress = false
        draft.contestCategories = []
        draft.fetchContestCategoriesInProgress = false
        draft.availableActions = []
        draft.getContestActionsInProgress = false
        draft.replyContestInProgress = false
        draft.declineContestInProgress = false
        draft.acceptContestInProgress = false
        draft.sendAgreementInProgress = false
        draft.acceptAgreementInProgress = false
        draft.sendSubscribersInProgress = false
        draft.askToGiveUpInProgress = false
        draft.answerGiveUpInProgress = false
        draft.contestPrice = undefined
        draft.desistInProgress = false
        draft.abortInProgress = false
        draft.resendInProgress = false
        draft.resendPaymentInProgress = false
        draft.deleteContestInProgress = false
        break
      }

      default: {
        break
      }
    }
  })
}
