import { Form, Formik, FormikValues } from 'formik'
import { useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { CreditCard } from 'components/creditCard'
import { Flex, FlexItem } from 'components/flex'
import { MaskedInput } from 'components/maskedInput'
import { TextInput } from 'components/textInput'

type Props = {
  inProgress: boolean
  onSubmit: (data: FormikValues) => void
}

export function CreditCardForm({ inProgress, onSubmit }: Props): JSX.Element {
  const { t } = useTranslation('common')

  const [cardType, setCardType] = useState<string>('')
  const [cardNumber, setCardNumber] = useState<string>('')
  const [cardExpiration, setCardExpiration] = useState<string>('')

  const initialValues = {
    name: '',
    number: '',
    expiration: '',
    cvv: ''
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('required'),
    number: Yup.string().required('required'),
    expiration: Yup.string().required('required'),
    cvv: Yup.string().required('required')
  })

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnBlur
      validateOnChange
      validateOnMount={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form>
          <Flex direction="column" align="center" gap={6}>
            <Flex direction="row" gap={12} justify="center" align="center">
              <FlexItem>
                <CreditCard
                  type={cardType}
                  name={values.name}
                  number={cardNumber}
                  expiration={cardExpiration}
                  cvv={values.cvv}
                />
              </FlexItem>
              <FlexItem basis="360px">
                <TextInput
                  name="name"
                  placeholder={t('creditCardForm.name')}
                  autoComplete="off"
                />

                <MaskedInput
                  name="number"
                  placeholder={t('creditCardForm.number')}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setCardNumber(event.target.value)
                  }}
                  options={{
                    creditCard: true,
                    onCreditCardTypeChanged: (type) => {
                      setCardType(type)
                    }
                  }}
                  autoComplete="off"
                />

                <Flex gap={6} wrap={false}>
                  <FlexItem grow="6">
                    <MaskedInput
                      name="expiration"
                      placeholder={t('creditCardForm.expiration')}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setCardExpiration(event.target.value)
                      }}
                      options={{ date: true, datePattern: ['m', 'y'] }}
                      autoComplete="off"
                    />
                  </FlexItem>
                  <FlexItem grow="6">
                    <MaskedInput
                      name="cvv"
                      placeholder={t('creditCardForm.cvv')}
                      options={{ blocks: [3], numericOnly: true }}
                      autoComplete="off"
                    />
                  </FlexItem>
                </Flex>
              </FlexItem>
            </Flex>

            <Button type="submit" inProgress={inProgress}>
              {t('creditCardForm.submitButton')}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
