import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SearchSVG } from 'assets/images/search.svg'

import { SelectOption } from 'types/SelectOption'

import { SelectInput } from './styled'

type SelectProps = {
  placeholder: string
  value?: string | number
  options: SelectOption[]
  onChange: (option: SelectOption | null) => void
  [key: string]: unknown
}

export function Select({
  placeholder,
  value,
  options,
  onChange,
  ...props
}: SelectProps): JSX.Element {
  const { t } = useTranslation(['validation', 'common'])

  const [selectedOption, setSelectedOption] = useState<
    SelectOption | undefined
  >(undefined)

  useEffect(() => {
    if (value) {
      const option = options.find(
        (opt) => opt.value.toString() === value.toString()
      )

      if (option) {
        setSelectedOption(option)
      } else {
        setSelectedOption(undefined)
      }
    } else {
      setSelectedOption(undefined)
    }
  }, [value, options])

  return (
    <SelectInput
      classNamePrefix="react-select"
      components={{ DropdownIndicator: SearchSVG }}
      isSearchable
      isClearable
      menuPlacement="bottom"
      // maxMenuHeight={240}
      onChange={onChange}
      value={selectedOption}
      options={options}
      placeholder={placeholder}
      noOptionsMessage={() => t('common:select.noOptionsMessage')}
      loadingMessage={() => t('common:select.loadingMessage')}
      {...props}
    />
  )
}
