import { Action, ActionWithoutPayload } from 'types/Action'
import { ContestMessage } from 'types/contestMessage/ContestMessage'
import { ContestMessageCreate } from 'types/contestMessage/ContestMessageCreate'

import {
  CHANGE_NEW_MESSAGE,
  FETCH_CONTEST_MESSAGES,
  FETCH_CONTEST_MESSAGES_FAILURE,
  FETCH_CONTEST_MESSAGES_SUCCESS,
  CREATE_CONTEST_MESSAGE,
  CREATE_CONTEST_MESSAGE_FAILURE,
  CREATE_CONTEST_MESSAGE_SUCCESS
} from './actionTypes'

export function changeNewMessage(message: string): Action<string> {
  return { type: CHANGE_NEW_MESSAGE, payload: message }
}

export function fetchContestMessages(contestId: number): Action<number> {
  return { type: FETCH_CONTEST_MESSAGES, payload: contestId }
}

export function fetchContestMessagesFailure(): ActionWithoutPayload {
  return { type: FETCH_CONTEST_MESSAGES_FAILURE }
}

export function fetchContestMessagesSuccess(
  payload: ContestMessage[]
): Action<ContestMessage[]> {
  return { type: FETCH_CONTEST_MESSAGES_SUCCESS, payload }
}

export function createContestMessage(
  payload: ContestMessageCreate
): Action<ContestMessageCreate> {
  return { type: CREATE_CONTEST_MESSAGE, payload }
}

export function createContestMessageFailure(): ActionWithoutPayload {
  return { type: CREATE_CONTEST_MESSAGE_FAILURE }
}

export function createContestMessageSuccess(
  payload: ContestMessage[]
): Action<ContestMessage[]> {
  return { type: CREATE_CONTEST_MESSAGE_SUCCESS, payload }
}
