export const FETCH_CONTEST_FILES = '@contestFile/FETCH_CONTEST_FILES'
export const FETCH_CONTEST_FILES_SUCCESS =
  '@contestFile/FETCH_CONTEST_FILES_SUCCESS'
export const FETCH_CONTEST_FILES_FAILURE =
  '@contestFile/FETCH_CONTEST_FILES_FAILURE'

export const ADD_CONTEST_FILES = '@contestFile/ADD_CONTEST_FILES'
export const ADD_CONTEST_FILES_SUCCESS =
  '@contestFile/ADD_CONTEST_FILES_SUCCESS'
export const ADD_CONTEST_FILES_FAILURE =
  '@contestFile/ADD_CONTEST_FILES_FAILURE'

export const REMOVE_CONTEST_FILES = '@contestFile/REMOVE_CONTEST_FILES'
export const REMOVE_CONTEST_FILES_SUCCESS =
  '@contestFile/REMOVE_CONTEST_FILES_SUCCESS'
export const REMOVE_CONTEST_FILES_FAILURE =
  '@contestFile/REMOVE_CONTEST_FILES_FAILURE'

export const UPDATE_CONTEST_FILE_NOTES =
  '@contestFile/UPDATE_CONTEST_FILE_NOTES'
export const UPDATE_CONTEST_FILE_NOTES_SUCCESS =
  '@contestFile/UPDATE_CONTEST_FILE_NOTES_SUCCESS'
export const UPDATE_CONTEST_FILE_NOTES_FAILURE =
  '@contestFile/UPDATE_CONTEST_FILE_NOTES_FAILURE'
