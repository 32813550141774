import React from 'react'

import { ReactComponent as DashboardSVG } from 'assets/images/dashboard.svg'
import { ReactComponent as ContestSVG } from 'assets/images/contest.svg'
import { ReactComponent as InstitutionSVG } from 'assets/images/institution.svg'
import { ReactComponent as SettingsSVG } from 'assets/images/settings.svg'
import { ReactComponent as PrecedentSVG } from 'assets/images/precedent.svg'

import {
  DASHBOARD_PAGE,
  CONTEST_LIST_PAGE,
  INSTITUTION_LIST_PAGE,
  SETTINGS_PAGE,
  PRECEDENTS_PAGE
} from 'navigation/paths'

export type Link = {
  to: string
  title: string
  icon?: React.FC
  exact?: boolean
  children?: Link[]
}

export const links: Link[] = [
  {
    to: DASHBOARD_PAGE,
    title: 'common:sidebar.dashboard',
    icon: DashboardSVG,
    exact: true
  },
  {
    to: CONTEST_LIST_PAGE,
    title: 'common:sidebar.contest.contest',
    icon: ContestSVG
  },
  {
    to: INSTITUTION_LIST_PAGE,
    title: 'common:sidebar.institution',
    icon: InstitutionSVG
  },
  {
    to: SETTINGS_PAGE,
    title: 'common:sidebar.settings',
    icon: SettingsSVG
  },
  {
    to: PRECEDENTS_PAGE,
    title: 'common:sidebar.precedent',
    icon: PrecedentSVG
  }
]
