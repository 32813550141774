import { CompleteSignUp } from 'types/directory/CompleteSignUp'
import { GenerateInvite } from 'types/directory/GenerateInvite'
import { InviteInfo } from 'types/directory/InviteInfo'

import { GET, POST } from 'utils/httpUtils'

const PATH = '/subscription/directory'

export async function generateInvite(data: GenerateInvite): Promise<string> {
  try {
    const { message } = await POST<GenerateInvite, { message: string }>(
      `${PATH}/invite`,
      data
    )
    return Promise.resolve(message)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getInviteInfo(inviteId: string): Promise<InviteInfo> {
  try {
    const result = await GET<InviteInfo>(`${PATH}/user?key=${inviteId}`)
    return Promise.resolve(result)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function completeSignUp(data: CompleteSignUp): Promise<string> {
  try {
    const { message } = await POST<CompleteSignUp, { message: string }>(
      `${PATH}/complete`,
      data
    )
    return Promise.resolve(message)
  } catch (error) {
    return Promise.reject(error)
  }
}
