import styled from 'styled-components'

import { Scroll } from 'styles/Helpers'

export const BasicInfoStepWrapper = styled.div`
  form > button {
    margin: 0 auto;
  }

  form > button + button {
    margin-top: ${({ theme }) => theme.spacings[2]};
  }
`

export const Field = styled.div`
  width: 100%;

  padding: ${({ theme }) => `${theme.spacings[2.5]} ${theme.spacings[4]}`};
  margin-bottom: ${({ theme }) => theme.spacings[8]};

  /* color: ${({ theme }) => theme.colors.pinkishGrey}; */
  color: ${({ theme }) => theme.colors.greyishBrownFour};

  border: 1px solid ${({ theme }) => theme.colors.paleGrey};
  border-radius: 2rem;

  background-color: ${({ theme }) => theme.colors.paleGreyThree};
`

export const TextEditorField = styled.div`
  width: 100%;

  min-height: 80px;
  max-height: 500px;

  ${Scroll};

  padding: ${({ theme }) => `${theme.spacings[2.5]} ${theme.spacings[4]}`};
  margin-bottom: ${({ theme }) => theme.spacings[8]};

  /* color: ${({ theme }) => theme.colors.pinkishGrey}; */
  color: ${({ theme }) => theme.colors.greyishBrownFour};

  border: 1px solid ${({ theme }) => theme.colors.paleGrey};
  border-radius: 0.25rem;

  background-color: ${({ theme }) => theme.colors.paleGreyThree};
`
