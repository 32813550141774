/* eslint-disable @typescript-eslint/no-explicit-any */
import { PagedResult } from 'types/PagedResult'
import { Token } from 'types/session/Token'
import { SearchUserQuery } from 'types/user/SearchUserQuery'
import { User } from 'types/user/User'
import { UserInviteInfo } from 'types/user/UserInviteInfo'
import { UserPasswordReset } from 'types/user/UserPasswordReset'
import { UserPasswordUpdate } from 'types/user/UserPasswordUpdate'
import { UserRequestPasswordReset } from 'types/user/UserRequestPasswordReset'
import { UserSubscription } from 'types/user/UserSubscription'
import { UserUpdate } from 'types/user/UserUpdate'
import { UserUpdateAddress } from 'types/user/UserUpdateAddress'

import { GET, POST, PUT } from 'utils/httpUtils'

const USER_PATH = '/subscription/user'

export async function fetchUserInfo(userId: number): Promise<User> {
  try {
    const response = await GET<{ user: User }>(`${USER_PATH}/${userId}`)
    return Promise.resolve(response.user)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function searchUser(
  params: SearchUserQuery
): Promise<PagedResult<User>> {
  try {
    const response = await GET<{ result: any }>(`${USER_PATH}/search`, params)
    return Promise.resolve({
      pagination: {
        total: response.result.meta.total,
        perPage: response.result.meta.per_page,
        firstPage: response.result.meta.first_page,
        lastPage: response.result.meta.last_page,
        currentPage: response.result.meta.current_page
      },
      data: response.result.data
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchUserInviteInfo(
  inviteId: string
): Promise<UserInviteInfo> {
  try {
    const response = await GET<UserInviteInfo>(
      `${USER_PATH}/invite/${inviteId}`
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function subscribeByInvite(
  inviteId: string,
  data: UserSubscription
): Promise<User> {
  try {
    const response = await POST<UserSubscription, User>(
      `${USER_PATH}/invite/${inviteId}`,
      data
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function requestPasswordReset(
  data: UserRequestPasswordReset
): Promise<string> {
  try {
    const response = await POST<UserRequestPasswordReset, string>(
      `${USER_PATH}/resetPassword`,
      data
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function resetPassword(data: UserPasswordReset): Promise<User> {
  try {
    const response = await POST<UserPasswordReset, User>(
      `${USER_PATH}/newPassword`,
      data
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateUser(data: UserUpdate): Promise<User> {
  try {
    const response = await PUT<UserUpdate, User>(
      `${USER_PATH}/updateInfo`,
      data
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateUserAddress(
  data: UserUpdateAddress
): Promise<User> {
  try {
    const response = await PUT<UserUpdateAddress, User>(
      `${USER_PATH}/updateAddress`,
      data
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updatePassword(data: UserPasswordUpdate): Promise<User> {
  try {
    const response = await PUT<UserPasswordUpdate, User>(
      `${USER_PATH}/updatePassword`,
      data
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getUserByToken(token: Token): Promise<User> {
  try {
    const user = await GET<User>(`${USER_PATH}/byToken`, undefined, {
      Authorization: `${token.type} ${token.token}`
    })
    return Promise.resolve({
      id: user.id,
      username: user.username,
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      cpf: user.cpf,
      status: user.status,
      type: user.type
    })
  } catch (error) {
    return Promise.reject(error)
  }
}
