import styled from 'styled-components'

type TextEditorWrapperProps = {
  hasError?: boolean
}

export const TextEditorWrapper = styled.div<TextEditorWrapperProps>`
  width: 100%;

  .tox-tinymce {
    color: ${({ theme }) => theme.colors.greyishBrownFour};
    border: 1px solid
      ${({ theme, hasError }) => {
        if (hasError) {
          return theme.colors.blush
        }

        return theme.colors.warmGreyThree
      }};
    background-color: ${({ theme }) => theme.colors.white};
  }

  & > p {
    color: ${({ theme }) => theme.colors.blush};
    margin: ${({ theme }) => `${theme.spacings[1]} ${theme.spacings[4]}`};
  }
`
