import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import { browserHistory } from 'navigation/browserHistory'
import { LOGIN_PAGE } from 'navigation/paths'

import * as service from 'services/http/directoryService'

import { closeModal } from 'store/modal/actions'
import {
  completeSignUpFailure,
  completeSignUpSuccess,
  generateInviteFailure,
  generateInviteSuccess,
  getInviteInfoFailure,
  getInviteInfoSuccess
} from 'store/directory/actions'
import {
  COMPLETE_SIGN_UP,
  GENERATE_INVITE,
  GET_INVITE_INFO
} from 'store/directory/actionTypes'

import { Action } from 'types/Action'
import { ModalName } from 'types/enums/ModalName'

import { showError, showSuccess } from 'utils/alertUtils'
import { GenerateInvite } from 'types/directory/GenerateInvite'
import { InviteInfo } from 'types/directory/InviteInfo'
import { CompleteSignUp } from 'types/directory/CompleteSignUp'

function* generateInviteRequest({
  payload
}: Action<GenerateInvite>): Generator<StrictEffect, void, string> {
  try {
    yield call(service.generateInvite, payload)
    yield call(showSuccess, 'generateInvite')
    yield put(closeModal(ModalName.DIRECTORY_INVITE))
    yield put(generateInviteSuccess())
  } catch (error) {
    yield call(showError, 'generateInvite', error)
    yield put(generateInviteFailure())
  }
}

function* getInviteInfoRequest({
  payload
}: Action<string>): Generator<StrictEffect, void, InviteInfo> {
  try {
    const response = yield call(service.getInviteInfo, payload)
    yield put(getInviteInfoSuccess(response))
  } catch (error) {
    yield call(showError, 'getInviteInfo', error)
    yield put(getInviteInfoFailure())
  }
}

function* completeSignUpRequest({
  payload
}: Action<CompleteSignUp>): Generator<StrictEffect, void, string> {
  try {
    yield call(service.completeSignUp, payload)
    yield call(showSuccess, 'completeSignUp')
    yield call(browserHistory.push, LOGIN_PAGE)
    yield put(completeSignUpSuccess())
  } catch (error) {
    yield call(showError, 'completeSignUp', error)
    yield put(completeSignUpFailure())
  }
}

export function* directorySagas(): Generator {
  yield all([
    takeLatest(GENERATE_INVITE, generateInviteRequest),
    takeLatest(GET_INVITE_INFO, getInviteInfoRequest),
    takeLatest(COMPLETE_SIGN_UP, completeSignUpRequest)
  ])
}
