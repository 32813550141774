/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContestMessage } from 'types/contestMessage/ContestMessage'
import { ContestMessageCreate } from 'types/contestMessage/ContestMessageCreate'

import { GET, POST } from 'utils/httpUtils'

const PATH = '/contest/messages'

export async function fetchContestMessages(
  contestId: number
): Promise<ContestMessage[]> {
  try {
    const { messages } = await GET<{ messages: any[] }>(`${PATH}/${contestId}`)
    return Promise.resolve(
      messages.map((message) => ({
        id: message.id,
        text: message.text,
        side: message.side,
        user: `${message.name} ${message.lastname}`,
        institution: message.institution,
        createdAt: message.created_at
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function createContestMessages(
  data: ContestMessageCreate
): Promise<ContestMessage[]> {
  try {
    const { messages } = await POST<ContestMessageCreate, { messages: any[] }>(
      `${PATH}`,
      data
    )
    return Promise.resolve(
      messages.map((message) => ({
        id: message.id,
        text: message.text,
        side: message.side,
        user: `${message.name} ${message.lastname}`,
        institution: message.institution,
        createdAt: message.created_at
      }))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
