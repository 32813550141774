export const GENERATE_INVITE = '@directory/GENERATE_INVITE'
export const GENERATE_INVITE_SUCCESS = '@directory/GENERATE_INVITE_SUCCESS'
export const GENERATE_INVITE_FAILURE = '@directory/GENERATE_INVITE_FAILURE'

export const GET_INVITE_INFO = '@directory/GET_INVITE_INFO'
export const GET_INVITE_INFO_SUCCESS = '@directory/GET_INVITE_INFO_SUCCESS'
export const GET_INVITE_INFO_FAILURE = '@directory/GET_INVITE_INFO_FAILURE'

export const COMPLETE_SIGN_UP = '@directory/COMPLETE_SIGN_UP'
export const COMPLETE_SIGN_UP_SUCCESS = '@directory/COMPLETE_SIGN_UP_SUCCESS'
export const COMPLETE_SIGN_UP_FAILURE = '@directory/COMPLETE_SIGN_UP_FAILURE'
