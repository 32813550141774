import { FormikValues } from 'formik'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { Button } from 'components/button'
// import { CreatableSelect } from 'components/creatableSelect'
import { Form } from 'components/form'
import { Modal, ModalHeader } from 'components/modal'
import { Select } from 'components/select'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'
import { searchUser } from 'store/user/actions'
import { addContestMember } from 'store/contest/actions'

import { ContestMemberPermission } from 'types/enums/ContestMemberPermission'
import { ContestSide } from 'types/enums/ContestSide'
import { ModalName } from 'types/enums/ModalName'
import { SelectOption } from 'types/SelectOption'

import { ModalBody } from './styled'

export function ContestAddMemberModal(): JSX.Element {
  const { t } = useTranslation(['modal', 'common'])

  const { contestId } = useParams<{ contestId: string }>()

  const dispatch = useDispatch()

  const {
    session: { user: sessionUser },
    modal: { contestAddMemberIsOpen },
    user: { searchUserInProgress, searchUserResult },
    contest: { contestData, addContestMemberInProgress }
  } = useSelector((state: GlobalState) => state)

  const sessionUserSide = useMemo<ContestSide | undefined>(() => {
    const member = contestData?.members.find(
      (m) => m.user?.id === sessionUser?.id
    )
    return member?.side
  }, [contestData])

  const initialValues = {
    userId: sessionUser?.id,
    contestId,
    email: '',
    permission: ''
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('email').required('required'),
    permission: Yup.string().required('required')
  })

  const userOptions = useMemo<SelectOption[]>(
    () =>
      [...(searchUserResult?.data ?? [])]
        .map(({ name, lastname, email }) => ({
          label: `${name ? `${name} ${lastname} - ` : ''}${email}`,
          value: email
        }))
        .filter(
          ({ value }) =>
            contestData?.members.findIndex(
              (member) => member.user?.email === value
            ) === -1
        ),
    [searchUserResult]
  )

  useEffect(() => {
    if (contestAddMemberIsOpen) {
      _searchUsers('')
    } else {
      const resetForm = document.getElementById('resetForm')
      if (resetForm) resetForm.click()
    }
  }, [contestAddMemberIsOpen])

  function _searchUsers(search: string) {
    const institutionId =
      sessionUserSide === ContestSide.REQUESTER
        ? contestData?.requesterId
        : contestData?.defendantId

    if (institutionId) {
      dispatch(
        searchUser({
          institutionId,
          search,
          page: 1,
          limit: 10,
          order: 'email'
        })
      )
    }
  }

  function _handleSubmit(data: FormikValues) {
    dispatch(
      addContestMember({
        contestId: data.contestId,
        invitedUser: data.email,
        permission: data.permission
      })
    )
  }

  return (
    <Modal
      isOpen={contestAddMemberIsOpen}
      onClose={() => dispatch(closeModal(ModalName.CONTEST_ADD_MEMBER))}
    >
      <ModalHeader separator={false}>
        <h2>{t('modal:contestAddMember.title')}</h2>
      </ModalHeader>

      <ModalBody>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => _handleSubmit(values)}
        >
          {/* <CreatableSelect
            name="email"
            placeholder={t('modal:contestAddMember.form.email')}
            options={userOptions}
            isLoading={searchUserInProgress}
            onChangeFilter={(search: string) => _searchUsers(search)}
            formatCreateLabel={(value: string) => (
              <span>
                {t('modal:contestAddMember.form.selectCreateLabel')}{' '}
                <strong>{value}</strong>
              </span>
            )}
            maxMenuHeight={120}
          /> */}
          <Select
            name="email"
            placeholder={t('modal:contestAddMember.form.email')}
            options={userOptions}
            isLoading={searchUserInProgress}
            onChangeFilter={(search: string) => _searchUsers(search)}
            maxMenuHeight={120}
          />

          <Select
            name="permission"
            placeholder={t('modal:contestAddMember.form.permission')}
            options={[
              {
                label: t('common:contestMemberPermissions.EDIT'),
                value: ContestMemberPermission.EDIT
              },
              {
                label: t('common:contestMemberPermissions.READ'),
                value: ContestMemberPermission.READ
              }
            ]}
          />

          <Button
            fullWidth
            small
            type="submit"
            inProgress={addContestMemberInProgress}
          >
            {t('modal:contestAddMember.form.submitButton')}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}
