const colors = {
  white: '#ffffff',
  whiteTwo: '#f1f1f1',
  whiteFour: '#f5eeee',

  pinkishGrey: '#c0c0c0', // placeholders
  warmGreyThree: '#707070', // input borders

  warmGrey: '#999999',
  graphite: '#3c3c3c',
  greyishBrown: '#3b3b3b',
  greyishBrownFour: '#434343', // input color
  greyishBrownFive: '#424242',
  greyishBrownSix: '#4b4b4b',
  greyishBrownEight: '#505050',
  paleGrey: '#ecf0f1',
  paleGreyThree: '#f8f9fa',

  niceBlue: '#147d96', // button background
  deepSeaBlue: '#045e7a',
  twilightBlue: '#0a5770',
  petrol: '#005f78', // signup header background
  waterBlue: '#148bdb',
  lightBlue: '#5dbceb',
  darkAqua: '#055f78',
  darkAquaTwo: '#056079',

  oceanGreen: '#35967b',
  hospitalGreen: '#a1e2b5',

  beige: '#e2dca1',
  beigeTwo: '#f9f6ec',
  sand: '#e9ca75',
  sandy: '#ffe191',
  sandyThree: '#ebcb74',
  sandyFour: '#edd082',

  lightTeal: '#a1e2d0',
  lightTealTwo: '#72e1c2',
  duckEggBlue: '#ecfbf7',
  duckEggBlueTwo: '#ddeefb',
  duckEggBlueFour: '#f0f7fd',

  paleBlueTwo: '#e4f2fe',

  blush: '#ee7474',

  fadedOrange: '#ed994b',

  black: '#000000',
  blackTwo: '#141414',

  // greyishBrownTwo: '#444444',
  // greyishBrownThree: '#414141',
  // lightKhaki: '#f3dc9f',
  // sandyTwo: '#edd184',
  viridian: '#208b6e',

  // greyishBrownSeven: '#494949',
  // brownishGrey: '#5e5e5e',
  sandyFive: '#efd07e',
  // warmGreyTwo: '#767676',
  // lightSage: '#ecfbed',
  salmon: '#f57070'
  // lightBlueGrey13: rgba(180, 223, 234, 0.13),

  // midBlue: '#21849c',
  // duckEggBlue16: rgba(211, 230, 248, 0.16),
  // sandySix: '#f1d68d',
  // paleGreyTwo: '#fdfdfe',
  // paleBlue: '#f2f9fe',
  // duckEggBlueThree: '#f8fbfe',
  // dirtyBlue: '#3b92a7',
  // white64: rgba(250, 250, 250, 0.64),
  // whiteThree: '#efefef',
  // greyish: '#a3a3a3',
}

const fonts = {
  bloggerSans: 'BloggerSans, sans-serif',
  gothamHTF: 'GothamHTF, sans-serif'
}

const fontSizes = {
  xxsm: '0.5rem',
  xsm: '0.65rem',
  sm: '0.875rem',
  md: '1rem',
  xmd: '1.25rem',
  lg: '1.5rem',
  xlg: '1.75rem',
  xxlg: '2rem'
}

// Inspired by Tailwind CSS, if you need a spacing of 40px, divide it by 4. That'll give you 10
const spacings = {
  0: '0',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem'
}

const zIndex = {
  dropdown: 1000,
  sticky: 1100,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  tooltip: 1800
}

const misc = {
  header: {
    height: '70px'
  },
  sidebar: {
    width: '244px',
    reducedWidth: '56px'
  },
  notificationsMenu: {
    width: '300px'
  }
}

export type Colors = typeof colors

export type Fonts = typeof fonts

export type FontSizes = typeof fontSizes

export type Spacings = typeof spacings

export type ZIndex = typeof zIndex

export type Misc = typeof misc

export default {
  colors,
  fonts,
  fontSizes,
  spacings,
  zIndex,
  misc
}
