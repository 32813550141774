/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export class HttpError extends Error {
  status: number
  code: string
  message: string
  detail: any

  constructor(status: number, code: string, message: string, detail?: any) {
    super(message)
    this.status = status
    this.code = code
    this.message = message
    this.detail = detail
  }
}
