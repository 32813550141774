import styled, { css } from 'styled-components'

const CardFace = css`
  font-family: monospace;

  color: ${({ theme }) => theme.colors.white};

  user-select: none;

  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  backface-visibility: hidden;
  transform-style: preserve-3d;

  border: 1px ${({ theme }) => theme.colors.sand};
  border-radius: 0.65rem;

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
`

export const CardFront = styled.div`
  ${CardFace}

  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  padding: 2rem;

  background-image: linear-gradient(290deg, #e9cd76 0 32%, #c5a252 33% 100%);
  transform: rotateY(0);

  div:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      display: flex;
      align-content: center;
      padding: ${({ theme }) => `${theme.spacings[1]} ${theme.spacings[2]}`};
      border-radius: 0.4rem;
      background-color: ${({ theme }) => theme.colors.white};

      svg {
        fill: ${({ theme }) => theme.colors.pinkishGrey};
        width: ${({ theme }) => theme.fontSizes.xxlg};
      }
    }

    span:last-of-type {
      display: inline-block;
      background-color: transparent;
      height: 3.5rem;

      svg {
        max-height: 3.5rem;
        width: 3.5rem;
      }
    }
  }

  div:nth-child(2) {
    font-size: ${({ theme }) => theme.fontSizes.lg};
    font-weight: bold;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }

  div:nth-child(3) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span:last-of-type {
      font-size: ${({ theme }) => theme.fontSizes.md};
      font-weight: bold;
    }
  }
`

export const CardBack = styled.div`
  ${CardFace}

  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background-color: #c5a252;
  transform: rotateY(-180deg);

  div:nth-child(1) {
    margin-top: 24px;

    height: 36px;
    width: 100%;

    background-color: ${({ theme }) => theme.colors.black};
  }

  div:nth-child(2) {
    margin: 1.5rem 2rem;
    padding: 0.65rem;
    color: ${({ theme }) => theme.colors.pinkishGrey};
    background-color: ${({ theme }) => theme.colors.white};
    text-align: right;
  }
`

export const CardContainer = styled.div`
  width: 340px;
  height: 215px;

  perspective: 600px;

  &:hover {
    ${CardFront} {
      transform: rotateY(180deg);
    }

    ${CardBack} {
      transform: rotateY(0deg);
    }
  }
`
