/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PageLoading } from 'components/pageLoading'

import { useQuery } from 'hooks/useQuery'

import { browserHistory } from 'navigation/browserHistory'
import { LOGIN_PAGE, DASHBOARD_PAGE } from 'navigation/paths'

import { validateSSO } from 'services/http/loginService'

import { GlobalState } from 'store'
import { setToken } from 'store/session/actions'

export function SSOCallbackPage(): JSX.Element {
  const query = useQuery()

  const dispatch = useDispatch()

  const { loggedIn, user } = useSelector((state: GlobalState) => state.session)

  useEffect(() => {
    const response = query.get('response')
    const state = localStorage.getItem('sso:state')

    if (!response || !state) {
      browserHistory.push(LOGIN_PAGE)
    } else {
      validate({ response, state })
    }
  }, [])

  useEffect(() => {
    if (loggedIn && user) {
      const redirectUrl = localStorage.getItem('login:redirectUrl')
      if (redirectUrl) {
        localStorage.removeItem('login:redirectUrl')
        browserHistory.push(redirectUrl)
      } else {
        browserHistory.push(DASHBOARD_PAGE)
      }
    }
  }, [loggedIn, user])

  async function validate(params: any) {
    try {
      const token = await validateSSO(params)
      if (token) {
        dispatch(setToken(token))
      }
    } catch (error) {
      const redirectUrl = localStorage.getItem('login:redirectUrl')
      localStorage.removeItem('login:redirectUrl')
      browserHistory.push({
        pathname: LOGIN_PAGE,
        search: redirectUrl ? `?redirectUrl=${redirectUrl}` : undefined,
        state: {
          error: (error as any).code ?? 'SSO_ERROR'
        }
      })
      // browserHistory.push(LOGIN_PAGE, {
      //   error: (error as any).code ?? 'SSO_ERROR'
      // })
    } finally {
      localStorage.removeItem('sso:state')
    }
  }

  return <PageLoading />
}
