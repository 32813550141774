import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function useQuery(): URLSearchParams {
  const search = useLocation().search

  const params = useMemo(() => {
    return new URLSearchParams(search)
  }, [useLocation()])

  return params
}
