import { ReactNode } from 'react'

import { MainWrapper } from './styled'

type MainProps = {
  children: ReactNode
}

export function Main({ children }: MainProps): JSX.Element {
  return <MainWrapper>{children}</MainWrapper>
}
