import { useEffect } from 'react'

import { usePagination } from 'hooks/usePagination'
import { useQuery } from 'hooks/useQuery'

import { browserHistory } from 'navigation/browserHistory'

import { PaginationContainer } from './styled'

type PaginationProps = {
  pageCount?: number
}

export function Pagination({ pageCount }: PaginationProps): JSX.Element {
  const query = useQuery()

  const currentPage = parseInt(query.get('page') ?? '1')

  const {
    startPages,
    middlePages,
    endPages,
    showStartEllipsis,
    showEndEllipsis
  } = usePagination({
    page: currentPage,
    pageCount
  })

  useEffect(() => {
    if (currentPage < 1) {
      query.set('page', '1')
    }

    if (pageCount && currentPage > pageCount) {
      query.set('page', pageCount.toString())
    }

    if (query.toString().length) {
      browserHistory.replace(`${location.pathname}?${query.toString()}`)
    } else {
      browserHistory.replace(location.pathname)
    }
  }, [currentPage, pageCount])

  function _renderItem(page: number): JSX.Element {
    const itemProps: { [key: string]: string | number | boolean } = {
      type: 'button',
      'data-link': true
    }

    if (currentPage === page) {
      itemProps.className = 'active'
      itemProps['aria-label'] = `Current Page, Page ${page}`
      itemProps['aria-current'] = true
    } else {
      itemProps['aria-label'] = `Goto Page ${page}`
    }

    return (
      <li key={page}>
        <button
          {...itemProps}
          onClick={() => {
            query.set('page', page.toString())
            browserHistory.push(`${location.pathname}?${query.toString()}`)
          }}
        >
          {page}
        </button>
      </li>
    )
  }

  function _changePage(inc: number) {
    const newPage = parseInt(query.get('page') ?? '1') + inc

    query.set('page', newPage.toString())

    browserHistory.push(`${location.pathname}?${query.toString()}`)
  }

  return (
    <PaginationContainer role="navigation" aria-label="Pagination navigation">
      <ol>
        <li>
          <button
            type="button"
            onClick={() => _changePage(-1)}
            disabled={currentPage <= 1}
            aria-label="Goto Previous Page"
          >
            {'<<'} Anterior
          </button>
        </li>
        {startPages.map((page) => _renderItem(page))}

        {showStartEllipsis && (
          <li>
            <span>●●●</span>
          </li>
        )}

        {middlePages.map((page) => _renderItem(page))}

        {showEndEllipsis && (
          <li>
            <span>●●●</span>
          </li>
        )}

        {endPages.map((page) => _renderItem(page))}
        <li>
          <button
            type="button"
            onClick={() => _changePage(1)}
            disabled={currentPage >= (pageCount ?? 1)}
            aria-label="Goto Next Page"
          >
            Próxima {'>>'}
          </button>
        </li>
      </ol>
    </PaginationContainer>
  )
}
