import { ReactNode } from 'react'

import { Colors } from 'styles/Theme'

import { CardContainer } from './styled'

type Color = keyof Colors

type CardProps = {
  color: Color
  borderColor: Color
  backgroundColor: Color
  children: ReactNode
}

export function Card({
  color,
  borderColor,
  backgroundColor,
  children
}: CardProps): JSX.Element {
  return (
    <CardContainer
      color={color}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      {children}
    </CardContainer>
  )
}
