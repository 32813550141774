export const FETCH_CONTEST_ASSOCIATIONS =
  '@contestMessage/FETCH_CONTEST_ASSOCIATIONS'
export const FETCH_CONTEST_ASSOCIATIONS_SUCCESS =
  '@contestMessage/FETCH_CONTEST_ASSOCIATIONS_SUCCESS'
export const FETCH_CONTEST_ASSOCIATIONS_FAILURE =
  '@contestMessage/FETCH_CONTEST_ASSOCIATIONS_FAILURE'

export const CREATE_CONTEST_ASSOCIATION =
  '@contestMessage/CREATE_CONTEST_ASSOCIATION'
export const CREATE_CONTEST_ASSOCIATION_SUCCESS =
  '@contestMessage/CREATE_CONTEST_ASSOCIATION_SUCCESS'
export const CREATE_CONTEST_ASSOCIATION_FAILURE =
  '@contestMessage/CREATE_CONTEST_ASSOCIATION_FAILURE'

export const REMOVE_CONTEST_ASSOCIATION =
  '@contestMessage/REMOVE_CONTEST_ASSOCIATION'
export const REMOVE_CONTEST_ASSOCIATION_SUCCESS =
  '@contestMessage/REMOVE_CONTEST_ASSOCIATION_SUCCESS'
export const REMOVE_CONTEST_ASSOCIATION_FAILURE =
  '@contestMessage/REMOVE_CONTEST_ASSOCIATION_FAILURE'
