import { Action, ActionWithoutPayload } from 'types/Action'
import { CompleteSignUp } from 'types/directory/CompleteSignUp'
import { GenerateInvite } from 'types/directory/GenerateInvite'
import { InviteInfo } from 'types/directory/InviteInfo'

import {
  GENERATE_INVITE,
  GENERATE_INVITE_FAILURE,
  GENERATE_INVITE_SUCCESS,
  GET_INVITE_INFO,
  GET_INVITE_INFO_FAILURE,
  GET_INVITE_INFO_SUCCESS,
  COMPLETE_SIGN_UP,
  COMPLETE_SIGN_UP_FAILURE,
  COMPLETE_SIGN_UP_SUCCESS
} from './actionTypes'

export function generateInvite(
  payload: GenerateInvite
): Action<GenerateInvite> {
  return { type: GENERATE_INVITE, payload }
}

export function generateInviteFailure(): ActionWithoutPayload {
  return { type: GENERATE_INVITE_FAILURE }
}

export function generateInviteSuccess(): ActionWithoutPayload {
  return { type: GENERATE_INVITE_SUCCESS }
}

export function getInviteInfo(payload: string): Action<string> {
  return { type: GET_INVITE_INFO, payload }
}

export function getInviteInfoFailure(): ActionWithoutPayload {
  return { type: GET_INVITE_INFO_FAILURE }
}

export function getInviteInfoSuccess(payload: InviteInfo): Action<InviteInfo> {
  return { type: GET_INVITE_INFO_SUCCESS, payload }
}

export function completeSignUp(
  payload: CompleteSignUp
): Action<CompleteSignUp> {
  return { type: COMPLETE_SIGN_UP, payload }
}

export function completeSignUpFailure(): ActionWithoutPayload {
  return { type: COMPLETE_SIGN_UP_FAILURE }
}

export function completeSignUpSuccess(): ActionWithoutPayload {
  return { type: COMPLETE_SIGN_UP_SUCCESS }
}
