import styled from 'styled-components'

type RadioInputContainerProps = {
  hasError?: boolean
}

export const RadioInputContainer = styled.div.attrs({
  role: 'group',
  'aria-labelledby': 'radio-group'
})<RadioInputContainerProps>`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings[8]};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  label {
    position: relative;

    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacings[2]};

    color: ${({ theme }) => theme.colors.greyishBrownFour};

    cursor: pointer;

    // hide the browser's default radio button
    input {
      position: absolute;
      opacity: 0;
      cursor: inherit;

      &:checked + span {
        border-color: ${({ theme }) => theme.colors.niceBlue};

        &::after {
          background-color: ${({ theme }) => theme.colors.niceBlue};
        }
      }
    }

    span {
      position: relative;

      order: -1;

      height: ${({ theme }) => theme.fontSizes.md};
      width: ${({ theme }) => theme.fontSizes.md};

      border: 1px solid ${({ theme }) => theme.colors.warmGreyThree};
      border-radius: 50%;

      transition: 0.3s;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;

        height: ${({ theme }) => theme.fontSizes.xxsm};
        width: ${({ theme }) => theme.fontSizes.xxsm};

        border-radius: 50%;
        background: none;

        transform: translate(-50%, -50%);

        transition: 0.3s;
      }
    }
  }

  p {
    position: absolute;
    top: 105%;

    color: ${({ theme }) => theme.colors.blush};
    margin: ${({ theme }) => `0 ${theme.spacings[4]}`};
  }
`
