import { produce } from 'immer'

import { CLEAR_SESSION_DATA } from 'store/session/actionTypes'

import { Action } from 'types/Action'

import { OPEN_ADMIN_MENU, CLOSE_ADMIN_MENU } from './actionTypes'

export type AdminState = {
  isOpen: boolean
}

const INITIAL_STATE: AdminState = {
  isOpen: false
}

export function adminReducer(
  state = INITIAL_STATE,
  action: Action<unknown>
): AdminState {
  return produce(state, (draft) => {
    switch (action.type) {
      case OPEN_ADMIN_MENU: {
        draft.isOpen = true
        break
      }

      case CLOSE_ADMIN_MENU: {
        draft.isOpen = false
        break
      }

      case CLEAR_SESSION_DATA: {
        return INITIAL_STATE
      }

      default: {
        break
      }
    }
  })
}
