import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'

import { login } from 'services/http/loginService'

import { LOGIN } from 'store/login/actionTypes'
import { loginFailure, loginSuccess } from 'store/login/actions'
import { setSessionData } from 'store/session/actions'

import { Action } from 'types/Action'
import { Login } from 'types/login/Login'
import { Session } from 'types/session/Session'

import { showError } from 'utils/alertUtils'

function* loginRequest({
  payload
}: Action<Login>): Generator<StrictEffect, void, Session> {
  try {
    const response = yield call(login, payload)
    yield put(
      setSessionData({
        token: response.token,
        user: {
          id: response.user.id,
          username: response.user.username,
          name: response.user.name,
          lastname: response.user.lastname,
          email: response.user.email,
          cpf: response.user.cpf,
          status: response.user.status,
          type: response.user.type
        }
      })
    )
    yield put(loginSuccess(response))
  } catch (error) {
    yield call(showError, 'login', error)
    yield put(loginFailure())
  }
}

export function* loginSagas(): Generator {
  yield all([takeLatest(LOGIN, loginRequest)])
}
