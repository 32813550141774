import { store } from 'store'

import { UserType } from 'types/enums/UserType'

export function canAccessContestCreatePage(): boolean {
  const {
    session: { user }
  } = store.getState()

  return user?.type === UserType.DIRECTORY
}
