import { TermsContainer } from './styled'

export function TermsOfUse(): JSX.Element {
  return (
    <TermsContainer>
      <header>
        <h1>TERMOS DE USO</h1>
        <time>22 de julho de 2021</time>
      </header>

      <p>
        Este documento &ldquo;Termos de Uso&rdquo; (&ldquo;<u>Termos</u>&rdquo;
        ou &ldquo;
        <u>Termos de Uso</u>
        &rdquo;) é proposto pela estrutura inicial responsável pela governança
        do Open Banking no Brasil (&ldquo;<u>Estrutura Inicial</u>&rdquo;),
        juntamente com a Política de Privacidade (aqui referida), e descreve as
        disposições aplicáveis no acesso e no uso de funcionalidades da
        Plataforma de Resolução de Disputas do Open Banking (&ldquo;
        <u>Plataforma</u>&rdquo;), por todos os usuários da Plataforma, em
        conformidade com as leis e regulamentações aplicáveis (&ldquo;
        <u>Legislação Aplicável</u>&rdquo;).
      </p>

      <p>
        Ao acessar e usar a Plataforma, o usuário concorda com as previsões
        destes Termos e da Política de Privacidade. Portanto, recomenda-se ao
        usuário que leia atentamente os Termos de Uso, a Política de Privacidade
        e todos os outros avisos que possam ser exibidos na Plataforma ou em
        outras páginas a ela relativas.
      </p>

      <br />

      <h2 data-title>Sobre a Plataforma</h2>
      <ul>
        <li>
          <p>
            A Plataforma visa contém ferramentas e funcionalidades para
            propiciar a resolução de disputas entre os participantes do Open
            Banking (&ldquo;<u>Participantes</u>&rdquo;).
          </p>
        </li>
      </ul>

      <h2 data-title>Privacidade dos Usuários</h2>
      <ul>
        <li>
          <p>
            A Estrutura Inicial respeita e protege a privacidade dos usuários.
            Para informações a respeito de como a Estrutura Inicial trata dados
            pessoais, solicita-se consultar a Política de Privacidade disponível
            em{' '}
            <a
              href="https://disputas.openbankingbrasil.org.br/documentos/Politica_de_privacidade.pdf"
              target="_blank"
              rel="noreferrer"
            >
              [https://disputas.openbankingbrasil.org.br/documentos/Politica_de_privacidade.pdf]
            </a>
            , a qual é parte integrante destes Termos de Uso e deverá ser lida
            integral e atentamente por todos os usuários.
          </p>
        </li>
      </ul>

      <h2 data-title>Aceite dos Termos de Uso</h2>
      <ul>
        <li>
          <p>
            O acesso e/ou qualquer outra forma de utilização da Plataforma
            equivalerá ao reconhecimento expresso de que o usuário leu,
            compreendeu e está integralmente de acordo com os presentes Termos,
            inclusive com a Política de Privacidade.
          </p>
        </li>
      </ul>

      <h2 data-title>Alterações e atualizações aos Termos de Uso</h2>
      <ul>
        <li>
          <p>
            Estes Termos de Uso podem ser modificados, a qualquer momento, a
            critério da Estrutura Inicial, mediante sua divulgação atualizada na
            Plataforma.
          </p>
        </li>
        <li>
          <p>
            A data da última revisão destes Termos está disposta no topo desta
            página. Recomendamos que, toda vez que o usuário acessar a
            Plataforma, deve ficar atento às novas atualizações destes Termos,
            pois o acesso e uso à Plataforma estarão vinculados à última versão
            atualizada destes Termos.
          </p>
        </li>
      </ul>

      <h2 data-title>Informações contidas na Plataforma</h2>
      <ul>
        <li>
          <p>
            As informações serão mantidas atualizadas na Plataforma, completas e
            precisas, tanto quanto possível. Para esse fim, procuram-se corrigir
            o mais rápido possível eventuais imprecisões ou omissões das
            informações contidas na Plataforma.
          </p>
        </li>
        <li>
          <p>
            Busca-se assegurar que as informações fornecidas sejam precisas,
            completas, atuais e que o uso não tenha interrupções nem erros. No
            entanto, não se pode garantir a operação da Plataforma em tempo
            integral, na medida em que esta depende dos serviços prestados por
            terceiros, como empresas de telecomunicações e provedores de acesso
            à rede mundial de computadores. Além disso, serão suspensos o uso e
            o acesso à Plataforma em caso fortuito ou força maior, por exemplo,
            em caso de ataques cibernéticos e outros que possam prejudicar as
            funcionalidades da Plataforma e colocar em risco a sua segurança ou
            funcionamento.
          </p>
        </li>
      </ul>

      <h2 data-title>Funcionalidades da Plataforma</h2>
      <ul>
        <li>
          <p>
            Na Plataforma, após o cadastro, o usuário ficará vinculado a um
            Participante e terá disponíveis funcionalidades para atuar nas
            disputas em que a instituição Participante a que estiver vinculado
            seja parte ou nas disputas das instituições Participantes de um
            conglomerado.
          </p>
        </li>
        <li>
          <p>
            As funcionalidades da Plataforma, disponíveis aos usuários, são:
          </p>
          <ol type="i">
            <li>
              <p>
                Atuação nas disputas, podendo o usuário: gerar disputas,
                responder disputas, desistir das disputas e realizar o
                acompanhamento de suas disputas;
              </p>
            </li>
            <li>
              <p>
                Troca de mensagens com outros usuários envolvidos nas disputas;
              </p>
            </li>
            <li>
              <p>
                Inserção, na Plataforma, de arquivos relacionados às disputas;
              </p>
            </li>
            <li>
              <p>
                Assinatura, por meio de certificado digital, de acordos firmados
                em razão das disputas, utilizando-se da ferramenta Docusign;
              </p>
            </li>
            <li>
              <p>
                Consulta à lista de precedentes, com decisões de arbitragens
                relacionadas às disputas do Open Banking;
              </p>
            </li>
            <li>
              <p>
                Emissão de relatórios sobre as disputas e ações da Plataforma;
              </p>
            </li>
            <li>
              <p>
                Realização de pagamentos para a geração de disputas ou outros
                relacionados às disputas.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            Para a demonstração dos Participantes do Open Banking, a Plataforma
            poderá utilizar as marcas desses Participantes.
          </p>
        </li>
        <li>
          <p>
            As informações inseridas na Plataforma serão privadas aos
            Participantes envolvidos nas disputas e aos usuários que representam
            esses Participantes.
          </p>
        </li>
        <li>
          <p>
            Para mais informações sobre o mecanismo de resolução de disputas
            entre Participantes do Open Banking, acesse o Regulamento do
            Mecanismo de Resolução de Disputas, disponível no{' '}
            <a
              href="https://ob-public-files.s3.amazonaws.com/Regulamento_de_Resolucao_de_Disputas.pdf"
              target="_blank"
              rel="noreferrer"
            >
              [https://ob-public-files.s3.amazonaws.com/Regulamento_de_Resolucao_de_Disputas.pdf]
            </a>
            .
          </p>
        </li>
      </ul>

      <h2 data-title>Responsabilidades do usuário</h2>
      <ul>
        <li>
          <p>O usuário deverá:</p>
          <ol type="a">
            <li>
              <p>
                Providenciar o próprio acesso à Internet e pagar todas as taxas
                de serviço eventualmente cobradas por terceiros com relação a
                tal acesso;
              </p>
            </li>
            <li>
              <p>
                Providenciar todo o equipamento necessário para efetuar sua
                conexão à Internet;
              </p>
            </li>
            <li>
              <p>
                Manter o ambiente de computador, celular e/ou outro dispositivo
                eletrônico (conforme aplicável) seguro, com uso de ferramentas
                disponíveis como antivírus e <em>firewall</em>, entre outras,
                atualizadas, de modo a contribuir na prevenção de riscos
                eletrônicos;
              </p>
            </li>
            <li>
              <p>
                Fornecer informações verdadeiras e atualizadas no momento de
                cadastro, bem como ao fornecer os demais dados e informações por
                meio da Plataforma, garantindo que tem legitimidade para
                realizar a referida inclusão.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            É vedado ao usuário, no contexto de sua utilização da Plataforma,
            entre outros:
          </p>
          <ol type="a">
            <li>
              <p>
                Violar qualquer legislação municipal, estadual, federal ou
                internacional que seja integrada ao ordenamento jurídico
                brasileiro, ou ainda, que, por qualquer razão legal, deva ser no
                Brasil aplicada;
              </p>
            </li>
            <li>
              <p>Praticar atos contrários à moral e aos bons costumes;</p>
            </li>
            <li>
              <p>
                Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer
                outra forma tornar disponível qualquer conteúdo que seja ilegal,
                incluindo, mas sem se limitar a, conteúdo que seja ofensivo à
                honra e à privacidade de terceiros, pornográfico, obsceno,
                difamatório ou calunioso, vulgar, preconceituoso, racista,
                discriminatório, que faça apologia ao crime ou de qualquer forma
                censurável, ou que possa gerar qualquer responsabilidade civil
                ou criminal de acordo com a Legislação Aplicável;
              </p>
            </li>
            <li>
              <p>
                Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer
                forma tornar disponível qualquer conteúdo que viole quaisquer
                direitos de terceiro, incluindo Direitos de Propriedade
                Intelectual (conforme termo definido abaixo), de privacidade ou
                sigilo;
              </p>
            </li>
            <li>
              <p>
                Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer
                forma tornar disponível qualquer tipo de anúncio, propaganda ou
                material promocional não solicitado ou não autorizado pela
                Plataforma, tais como mensagens não solicitadas ou mensagens
                enviadas em massa (conhecidos como &ldquo;junk mail&rdquo; ou
                &ldquo;spam&rdquo;);
              </p>
            </li>
            <li>
              <p>
                Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer
                forma tornar disponível qualquer conteúdo que contenha vírus ou
                qualquer outro código, arquivo ou programa de computador com o
                propósito de interromper, destruir ou limitar a funcionalidade
                de qualquer software, hardware ou equipamento;
              </p>
            </li>
            <li>
              <p>Ameaçar, coagir, constranger demais usuários;</p>
            </li>
            <li>
              <p>Violar direitos de sigilo e privacidade de terceiros; e</p>
            </li>
            <li>
              <p>
                Praticar quaisquer atos que direta ou indiretamente, no todo ou
                em parte, possam causar prejuízo à Estrutura Inicial, à
                Plataforma, ao Open Banking, aos Participantes, a outros
                usuários ou a qualquer terceiro.
              </p>
            </li>
          </ol>
        </li>
      </ul>

      <h2 data-title>Propriedade intelectual</h2>
      <ul>
        <li>
          <p>Direitos da Estrutura Inicial</p>
          <ul>
            <li>
              <p>
                Este Site contém nome de domínio, textos, fotografias, imagens,
                vídeos, marcas, logomarcas, sinais distintivos, layouts,
                código-fonte, gráficos, know-how, som entre outros elementos e
                materiais que se encontram protegidos por direitos de
                propriedade industrial e direitos autorais (&ldquo;
                <u>Direitos de Propriedade Intelectual</u>&rdquo;). Todos os
                Direitos de Propriedade Intelectual aqui presentes pertencem à
                Estrutura Inicial ou foram licenciados à Estrutura Inicial.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>Direitos dos Participantes</p>
          <ul>
            <li>
              <p>
                Nas disputas, os usuários poderão inserir materiais, arquivos,
                peças, obras, etc., bem como prestar informações e declarações
                (&ldquo;Obras dos Participantes&rdquo;), sendo que o direito de
                propriedade intelectual sobre essas Obras dos Participantes
                pertence ao respectivo Participante ou foi a ele licenciado.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>Uso das informações e materiais da Plataforma:</p>
          <ul>
            <li>
              <p>
                Ao acessar a Plataforma o usuário declara que irá respeitar os
                Direitos de Propriedade Intelectual da Estrutura Inicial e os
                direitos de propriedade intelectual sobre as Obras dos
                Participantes, bem como de todos os direitos referentes a
                terceiros que porventura estejam, ou estiveram, de alguma forma
                disponíveis na Plataforma. O simples acesso à Plataforma não
                confere ao usuário qualquer direito ou expectativa de direito
                sobre qualquer Direito de Propriedade Intelectual presente na
                Plataforma ou sobre os direitos de propriedade intelectual sobre
                as Obras dos Participantes.
              </p>
            </li>
            <li>
              <p>
                A reprodução de qualquer conteúdo da Plataforma é proibida,
                salvo mediante prévia autorização por escrito da Estrutura
                Inicial. Em nenhum dos casos o usuário adquirirá qualquer
                Direito de Propriedade Intelectual sobre referido conteúdo. No
                caso das Obras dos Participantes, o usuário poderá usá-las,
                exclusivamente, para fins de resolução das disputas entre os
                Participantes.
              </p>
            </li>
            <li>
              <p>
                Em caso de violação pelo usuário de qualquer Direito de
                Propriedade Intelectual da Estrutura Inicial ou qualquer direito
                de propriedade intelectual sobre as Obras dos Participantes, o
                usuário assume toda e qualquer responsabilidade, de caráter
                civil e/ou criminal advindos de referida violação.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <h2 data-title>Lei aplicável e Jurisdição</h2>
      <ul>
        <li>
          <p>
            Os presentes Termos serão interpretados de acordo com as leis da
            República Federativa do Brasil. Fica eleito o Foro da Comarca da
            Capital do Estado de São Paulo para dirimir qualquer divergência
            oriunda dos presentes Termos, com renúncia expressa a qualquer
            outro, por mais privilegiado que seja.
          </p>
        </li>
      </ul>

      <h2 data-title>Disposições gerais</h2>
      <ul>
        <li>
          <p>
            Se qualquer disposição destes Termos se tornar inválida, ineficaz ou
            inexequível, tal disposição será considerada apartada das demais
            disposições, as quais serão mantidas válidas, eficazes e exequíveis.
          </p>
        </li>
        <li>
          <p>
            As seções destes Termos são apenas para efeitos de referência e de
            forma alguma definem, limitam, determinam a interpretação ou
            descrevem o âmbito ou extensão da respectiva seção.
          </p>
        </li>
        <li>
          <p>
            A tolerância ou omissão da Estrutura Inicial com relação a qualquer
            descumprimento destes Termos pelo usuário ou por outros não
            significa renúncia dos direitos da Estrutura Inicial de agir em
            relação a descumprimentos subsequentes ou similares.
          </p>
        </li>
      </ul>
      <span className="end-of-file">******</span>
    </TermsContainer>
  )
}
