export enum ContestUpdateAction {
  ABORT = 'ABORT',
  ACCEPT = 'ACCEPT',
  ACCEPT_DOCUMENT = 'ACCEPT DOCUMENT',
  CONFIRM_AWARE = 'CONFIRM AWARE',
  DECLINE = 'DECLINE',
  DESIST = 'DESIST',
  PAYMENT = 'PAYMENT',
  PAYMENT_BOLETO = 'PAYMENT_BOLETO',
  REPLY = 'REPLY',
  REPLICATION = 'REPLICATION',
  REJOINDER = 'REJOINDER',
  RESEND = 'RESEND',
  SUBMIT_DOCUMENT = 'SUBMIT DOCUMENT',
  NEW_DOCUMENT = 'NEW DOCUMENT',
  SUBMIT_SUBSCRIBERS = 'SUBMIT SUBSCRIBERS'
}
