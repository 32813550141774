import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as CloseThinSVG } from 'assets/images/close-thin.svg'

import { Loading } from 'components/loading'

import { GlobalState } from 'store'
import {
  closeNotificationMenu,
  getNotifications,
  markAsRead
} from 'store/notification/actions'

import { formatDateTimeBR } from 'utils/formatUtils'

import { NotificationsWrapper } from './styled'

export function Notifications(): JSX.Element {
  const { t } = useTranslation('common')

  const dispatch = useDispatch()

  const { isOpen, notifications, getNotificationsInProgress } = useSelector(
    (state: GlobalState) => state.notification
  )

  useEffect(() => {
    dispatch(getNotifications())

    const interval = setInterval(() => {
      dispatch(getNotifications())
    }, 1000 * 60 * 5)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <NotificationsWrapper isOpen={isOpen} tabIndex={-1}>
      <button
        tabIndex={isOpen ? 0 : -1}
        onClick={() => dispatch(closeNotificationMenu())}
      >
        <CloseThinSVG />
      </button>
      <header>
        <h2>{t('notificationsMenu.title')}</h2>
      </header>
      {getNotificationsInProgress && !notifications.length ? (
        <Loading />
      ) : notifications.length ? (
        <ul>
          {notifications.map(({ id, text, createdAt }, index) => (
            <li key={index}>
              <header>
                <time>{formatDateTimeBR(createdAt)}</time>
                <button
                  tabIndex={isOpen ? 0 : -1}
                  onClick={() => dispatch(markAsRead(id))}
                >
                  <CloseThinSVG />
                </button>
              </header>
              <p dangerouslySetInnerHTML={{ __html: text }} />
            </li>
          ))}
        </ul>
      ) : (
        <p>{t('notificationsMenu.noData')}</p>
      )}
    </NotificationsWrapper>
  )
}
