import { FormikValues } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Button } from 'components/button'
import { Form } from 'components/form'
import { Modal, ModalHeader } from 'components/modal'
import { Textarea } from 'components/textarea'

import { GlobalState } from 'store'
import { closeModal } from 'store/modal/actions'
import { updateContesFileNotes } from 'store/contestFile/actions'

import { ModalName } from 'types/enums/ModalName'

import { ModalBody } from './styled'

type Props = {
  initialValues: FormikValues
}

export function ContestAddAttachmentNoteModal({
  initialValues
}: Props): JSX.Element {
  const { t } = useTranslation('modal')

  const dispatch = useDispatch()

  const {
    modal: { contestAddAttachmentNoteIsOpen },
    contestFile: { updateContestFileNotesInProgress }
  } = useSelector((state: GlobalState) => state)

  const validationSchema = Yup.object().shape({
    contestId: Yup.string().required('required'),
    key: Yup.string().required('required'),
    notes: Yup.mixed().required('required')
  })

  useEffect(() => {
    if (!contestAddAttachmentNoteIsOpen) {
      const resetForm = document.getElementById('resetForm')
      if (resetForm) resetForm.click()
    }
  }, [contestAddAttachmentNoteIsOpen])

  function _handleSubmit(data: FormikValues) {
    dispatch(
      updateContesFileNotes({
        contestId: data.contestId,
        key: data.key,
        notes: data.notes
      })
    )
  }

  return (
    <Modal
      isOpen={contestAddAttachmentNoteIsOpen}
      onClose={() =>
        dispatch(closeModal(ModalName.CONTEST_ADD_ATTACHMENT_NOTE))
      }
    >
      <ModalHeader separator={false}>
        <h2>{t('contestAddAttachmentNote.title')}</h2>
      </ModalHeader>

      <ModalBody>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => _handleSubmit(values)}
        >
          <Textarea
            name="notes"
            placeholder={t('contestAddAttachmentNote.form.notes')}
            rows={6}
          />

          <Button type="submit" inProgress={updateContestFileNotesInProgress}>
            {t('contestAddAttachmentNote.form.submitButton')}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}
