import { SeparatorWrapper } from './styled'

import { Colors, Spacings } from 'styles/Theme'

type SeparatorProps = {
  color?: keyof Colors
  marginBottom?: keyof Spacings
}

export function Separator({
  color = 'niceBlue',
  marginBottom = 6
}: SeparatorProps): JSX.Element {
  return <SeparatorWrapper color={color} marginBottom={marginBottom} />
}
