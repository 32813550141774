import { Button } from 'components/button'
import { Modal, ModalHeader } from 'components/modal'
import { Flex } from 'components/flex'

import { ModalBody, Text } from './styled'

export interface ContestTransferInstitutionConfirmModalProps {
  isOpen: boolean
  onAccept: () => unknown
  onClose: () => unknown
}

export function ContestTransferInstitutionConfirmModal({
  isOpen,
  onAccept,
  onClose
}: ContestTransferInstitutionConfirmModalProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader separator={false}>
        <h2>Confirmação de envio de nomeação</h2>
      </ModalHeader>

      <ModalBody>
        <Text>
          Ao confirmar, esta ação não poderá ser desfeita. Ao enviar a
          requisição de nomeação de instituição, não será possível nomear outra
          instituição para essa disputa mesmo que a requisitada não aceite.
          Deseja continuar?
        </Text>

        <Flex direction="row" align="center" justify="center" gap={4}>
          <Button
            type="button"
            color="white"
            backgroundColor="niceBlue"
            small
            onClick={onAccept}
          >
            Enviar nomeação
          </Button>
          <Button
            type="button"
            color="white"
            backgroundColor="blush"
            small
            onClick={onClose}
          >
            Cancelar
          </Button>
        </Flex>
      </ModalBody>
    </Modal>
  )
}
