import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router-dom'

import { Breadcrumb, BreadcrumbItem } from 'components/breadcrumb'
import { Separator } from 'components/separator'

import { withAdminLayout } from 'hoc/withAdminLayout'

import { browserHistory } from 'navigation/browserHistory'
import {
  INSTITUTION_LIST_PAGE,
  INSTITUTION_DETAILS_PAGE
} from 'navigation/paths'

import { GlobalState } from 'store'
import { fetchInstitution } from 'store/institution/actions'

import { UserRole } from 'types/enums/UserRole'

import { AddressForm } from './components/addressForm'
import { InstitutionForm } from './components/institutionForm'

import { MainContainer } from './styled'

function Page(): JSX.Element {
  const { t } = useTranslation(['pages'])

  const { cnpj } = useParams<{ cnpj: string }>()

  const dispatch = useDispatch()

  const {
    institution: { institution },
    session: { user }
  } = useSelector((state: GlobalState) => state)

  useEffect(() => {
    if (cnpj) {
      dispatch(fetchInstitution(cnpj))
    }
  }, [])

  useEffect(() => {
    if (institution) {
      if (!_sessionUserisOwnerOrSubstitute()) {
        browserHistory.goBack()
      }
    }
  }, [institution, user])

  function _sessionUserisOwnerOrSubstitute(): boolean {
    if (!user) return false

    const institutionUser = institution?.users.find(({ id }) => id === user.id)

    return (
      institutionUser?.role === UserRole.ADMIN ||
      institutionUser?.role === UserRole.EDIT
    )
  }

  document.title = 'Atualizar Instituição - Open Banking Brasil'

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem
          to={INSTITUTION_LIST_PAGE}
          label={t('pages:institutionUpdate.breadcrumb.institution')}
        />
        <BreadcrumbItem
          to={generatePath(INSTITUTION_DETAILS_PAGE, { cnpj })}
          label={t('pages:institutionUpdate.breadcrumb.institutionDetails')}
        />
        <BreadcrumbItem
          label={t('pages:institutionUpdate.breadcrumb.institutionUpdate')}
          isCurrentPage
        />
      </Breadcrumb>

      {/* <h4>Configurações</h4> */}
      <Separator />

      <MainContainer>
        <InstitutionForm />
        <Separator color="warmGrey" />
        <AddressForm />
      </MainContainer>
    </>
  )
}

export const InstitutionUpdatePage = withAdminLayout(Page)
