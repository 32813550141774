// account pages
import { LoginPage } from 'pages/login'
import { ResetPasswordPage } from 'pages/resetPassword'
import { SubscriptionByInvitePage } from 'pages/subscriptionByInvite'

// application home
import { DashboardPage } from 'pages/dashboard'

// contest pages
import { ContestCreatePage } from 'pages/contestCreate'
import { ContestListPage } from 'pages/contestList'
import { ContestDetailsPage } from 'pages/contestDetails'

// institution pages
import { InstitutionListPage } from 'pages/institutionList'
import { InstitutionDetailsPage } from 'pages/institutionDetails'
import { InstitutionUpdatePage } from 'pages/institutionUpdate'

// user account update pages
import { SettingsPage } from 'pages/settings'
import { UpdatePasswordPage } from 'pages/updatePassword'

import { PrecedentListPage } from 'pages/precedentList'

import { SSOPage } from 'pages/sso'
import { SSOCallbackPage } from 'pages/ssoCallback'
import { DirectorySignUpPage } from 'pages/directorySignUp'

import { NotFoundPage } from 'pages/notFound'

import { canAccessContestCreatePage } from 'utils/routeUtils'

import {
  LOGIN_PAGE,
  SUBSCRIPTION_BY_INVITE_PAGE,
  RESET_PASSWORD_PAGE,
  DASHBOARD_PAGE,
  CONTEST_LIST_PAGE,
  CONTEST_DETAILS_PAGE,
  CONTEST_CREATE_PAGE,
  INSTITUTION_LIST_PAGE,
  INSTITUTION_DETAILS_PAGE,
  INSTITUTION_UPDATE_PAGE,
  SETTINGS_PAGE,
  SETTINGS_UPDATE_PASSWORD_PAGE,
  PRECEDENTS_PAGE,
  SSO,
  SSO_CALLBACK,
  DIRECTORY_SIGN_UP
} from './paths'

type Route = {
  path: string
  exact?: boolean
  isPublic?: boolean
  // roles,
  // permissions,
  validation?: () => boolean
  page: () => JSX.Element
}

export const routes: Route[] = [
  // public pages (login, signup and reset password)
  {
    path: LOGIN_PAGE,
    page: LoginPage,
    isPublic: true
  },
  {
    path: SUBSCRIPTION_BY_INVITE_PAGE,
    page: SubscriptionByInvitePage,
    isPublic: true
  },
  {
    path: RESET_PASSWORD_PAGE,
    page: ResetPasswordPage,
    isPublic: true
  },

  // sso redirect page
  {
    path: SSO,
    page: SSOPage,
    exact: true,
    isPublic: true
  },
  // sso callback page
  {
    path: SSO_CALLBACK,
    page: SSOCallbackPage,
    exact: true,
    isPublic: true
  },

  // onboarding for directory users
  {
    path: DIRECTORY_SIGN_UP,
    page: DirectorySignUpPage,
    isPublic: true
  },

  // application home page
  {
    path: DASHBOARD_PAGE,
    page: DashboardPage,
    exact: true
  },

  // constest pages
  {
    path: CONTEST_LIST_PAGE,
    page: ContestListPage,
    exact: true
  },
  {
    path: CONTEST_CREATE_PAGE,
    page: ContestCreatePage,
    exact: true,
    validation: canAccessContestCreatePage
  },
  {
    path: CONTEST_DETAILS_PAGE,
    page: ContestDetailsPage,
    exact: true
  },

  // instituion pages
  {
    path: INSTITUTION_LIST_PAGE,
    page: InstitutionListPage,
    exact: true
  },
  {
    path: INSTITUTION_DETAILS_PAGE,
    page: InstitutionDetailsPage,
    exact: true
  },
  {
    path: INSTITUTION_UPDATE_PAGE,
    page: InstitutionUpdatePage,
    exact: true
  },

  // settings page
  {
    path: SETTINGS_PAGE,
    page: SettingsPage,
    exact: true
  },
  {
    path: SETTINGS_UPDATE_PASSWORD_PAGE,
    page: UpdatePasswordPage,
    exact: true
  },

  // precedents page
  {
    path: PRECEDENTS_PAGE,
    page: PrecedentListPage,
    exact: true
  },

  // handle not found pages
  {
    path: '*',
    page: NotFoundPage
  }
]
