import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as ClipSVG } from 'assets/images/clip.svg'

import { Button } from 'components/button'
import { ContestActionButton } from 'components/contestActionbutton'
import { Loading } from 'components/loading'

import { timelineHeadingIcons, timelineItemIcons } from 'config/contest'

import { GlobalState } from 'store'
import { openModal } from 'store/modal/actions'

import { ContestData } from 'types/contest/ContestData'
import { ContestFile } from 'types/contestFile/ContestFile'
import { ContestMemberPermission } from 'types/enums/ContestMemberPermission'
import { ContestSide } from 'types/enums/ContestSide'
import { ContestStep } from 'types/enums/ContestStep'
import { ContestUpdateAction } from 'types/enums/ContestUpdateAction'
import { ModalName } from 'types/enums/ModalName'

import { formatDateBR, formatDateTimeBR } from 'utils/formatUtils'

import {
  TimelineWrapper,
  TimelineContainer,
  TimelineItemHeading,
  TimelineItemContent,
  Actions,
  TransferInstitutionNotice
} from './styled'
import { answerContestNomination } from 'store/contest/actions'

type Props = {
  userSide?: ContestSide
  userPermission?: ContestMemberPermission
  gotoFilesTab: (files: ContestFile[]) => void
}

export function TimelineTab({
  userSide,
  userPermission,
  gotoFilesTab
}: Props): JSX.Element {
  const { t } = useTranslation(['pages', 'common'])

  const dispatch = useDispatch()

  const {
    contest: { contestData, availableActions, getContestActionsInProgress }
  } = useSelector((state: GlobalState) => state)

  const timelineData = useMemo<ContestData[]>(
    () =>
      [...(contestData?.data ?? [])].sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1
        }

        if (a.createdAt < b.createdAt) {
          return 1
        }

        return 0
      }),
    [contestData]
  )

  function _renderAvailableActions() {
    if (getContestActionsInProgress) {
      return (
        <Actions>
          <Loading />
        </Actions>
      )
    }

    const actions = availableActions
      .filter((action) => action.side === userSide)
      .filter(
        (action) => action.actionName !== ContestUpdateAction.NEW_DOCUMENT
      )

    if (userPermission !== ContestMemberPermission.READ && actions.length) {
      return (
        <Actions>
          {actions.map((action) => (
            <ContestActionButton key={action.id} action={action.actionName} />
          ))}

          {contestData?.currentStep === ContestStep.AWAITING_SIGNATURE && (
            <Button
              type="button"
              color="white"
              backgroundColor="niceBlue"
              small
              onClick={() => dispatch(openModal(ModalName.CONTEST_SIGNATURES))}
            >
              {t(`pages:contestDetails.tabs.timeline.checkSignatures`)}
            </Button>
          )}
        </Actions>
      )
    }

    return null
  }

  function _renderTimelineHeading() {
    if (!contestData) return null

    const { id, requester, defendant, currentStep, userRelation } = contestData
    const Icon = timelineHeadingIcons[contestData.currentStep]

    const base = 'pages:contestDetails.tabs.timeline.heading'
    const title = `${base}.${userSide}.${currentStep}.title`
    const disclaimer = `${base}.${userSide}.${currentStep}.disclaimer`

    const tranferInstitutionOrigin = contestData.nominate?.targetInstitutionName
    const tranferInstitutionMessage = contestData.nominate?.message

    if (userRelation === 'NOMINATED')
      return (
        <TransferInstitutionNotice>
          <p>
            A instituição <span>{defendant}</span> deseja nomear a sua
            instituição{' '}
            <span>{tranferInstitutionOrigin?.toLocaleUpperCase()}</span> como{' '}
            <span>REQUERIDA</span> dessa disputa.
          </p>
          <h3>Justificativa</h3>
          <p>{tranferInstitutionMessage}</p>
          <Actions>
            <Button
              type="button"
              color="white"
              backgroundColor="niceBlue"
              small
              onClick={() => {
                dispatch(answerContestNomination('ACCEPT'))
                dispatch(openModal(ModalName.CONTEST_REPLY_INSTITUTION))
              }}
            >
              Aceitar nomeação
            </Button>
            <Button
              type="button"
              color="white"
              backgroundColor="blush"
              small
              onClick={() => {
                dispatch(answerContestNomination('DECLINED'))
                dispatch(openModal(ModalName.CONTEST_REPLY_INSTITUTION))
              }}
            >
              Recusar nomeação
            </Button>
          </Actions>
        </TransferInstitutionNotice>
      )

    return (
      <header>
        <div>
          <i>{Icon}</i>
          <h4
            dangerouslySetInnerHTML={{
              __html: t(title, {
                contestId: id,
                requester,
                defendant
              })
            }}
          />
        </div>
        <time dateTime={contestData?.updatedAt}>
          {formatDateTimeBR(contestData?.updatedAt)}
        </time>
        <p
          dangerouslySetInnerHTML={{
            __html: t(disclaimer, {
              sla: contestData.currentSla,
              deadline: formatDateBR(contestData.slaEnd)
            })
          }}
        />

        {_renderAvailableActions()}
      </header>
    )
  }

  function _renderTimelineItem(item: ContestData) {
    if (!contestData) return null

    const { id, text, files, user, createdAt } = item

    let action = ''
    let side = ''
    if (item.action && item.side) {
      action = item.action
      side = item.side
    } else if (text === 'Solved with signatures') {
      action = 'AGREEMENT_SIGNED'
      side = ContestSide.REQUESTER
    } else if (text === 'Boleto processado') {
      action = 'PAYMENT_BOLETO_CONFIRMED'
      side = ContestSide.REQUESTER
    } else if (text === 'Boleto do reenvio processado') {
      action = 'PAY_RESEND'
      side = ContestSide.DEFENDANT
    } else if (text === 'Signed Document') {
      action = 'SIGNED_DOCUMENT'
      side = ''
    } else {
      action = 'CREATED'
      side = ContestSide.REQUESTER
    }

    const Icon = timelineItemIcons[action]

    const title = `pages:contestDetails.tabs.timeline.itemHeading.${userSide}.${action}${
      side ? '.' + side : ''
    }`

    const actionsWithContent = [
      'CREATED',
      ContestUpdateAction.REPLY,
      ContestUpdateAction.REPLICATION,
      ContestUpdateAction.REJOINDER
    ]

    const hiddenActions = ['AGREEMENT_SIGNED']

    return (
      !hiddenActions.includes(action) && (
        <li key={id}>
          {actionsWithContent.includes(action) && !!text && (
            <TimelineItemContent data-myside={userSide === side}>
              <i>{Icon}</i>
              <h3>
                {t(
                  `pages:contestDetails.tabs.timeline.itemHeading.actions.${action}`
                )}
              </h3>
              {side === ContestSide.REQUESTER && (
                <h4>
                  {contestData.requester}{' '}
                  <span>({t('common:contestSide.REQUESTER')})</span>
                </h4>
              )}
              {side === ContestSide.DEFENDANT && (
                <h4>
                  {contestData.defendant}{' '}
                  <span>({t('common:contestSide.DEFENDANT')})</span>
                </h4>
              )}
              <h5>
                {user.name ?? ''} {user.lastname ?? ''}
              </h5>
              <span>
                <time>{formatDateTimeBR(createdAt)}</time>
                {!!files?.length && (
                  <span role="button" onClick={() => gotoFilesTab(files)}>
                    <ClipSVG /> {files.length}{' '}
                    {t('pages:contestDetails.tabs.timeline.item.attachments')}
                  </span>
                )}
              </span>
              <p dangerouslySetInnerHTML={{ __html: text }} />
            </TimelineItemContent>
          )}

          <TimelineItemHeading>
            <div>
              <i>{Icon}</i>
              <h4
                dangerouslySetInnerHTML={{
                  __html: t(title, {
                    contestId: contestData?.id,
                    requester: contestData?.requester,
                    defendant: contestData?.defendant
                  })
                }}
              />
            </div>
            <time dateTime={createdAt}>{formatDateTimeBR(createdAt)}</time>

            {action === 'SIGNED_DOCUMENT' && !!files?.length && (
              <Button type="button" small onClick={() => gotoFilesTab(files)}>
                Documento assinado
              </Button>
            )}
          </TimelineItemHeading>
        </li>
      )
    )
  }

  return (
    <TimelineWrapper>
      {contestData && (
        <TimelineContainer>
          {_renderTimelineHeading()}
          <ul>{timelineData.map((item) => _renderTimelineItem(item))}</ul>
        </TimelineContainer>
      )}
    </TimelineWrapper>
  )
}
