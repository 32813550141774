import { Action, ActionWithoutPayload } from 'types/Action'
import { Session } from 'types/session/Session'
import { Token } from 'types/session/Token'
import { User } from 'types/user/User'

import {
  SET_SESSION_DATA,
  CLEAR_SESSION_DATA,
  SET_TOKEN,
  GET_USER_DATA,
  GET_USER_DATA_FAILURE,
  GET_USER_DATA_SUCCESS
} from './actionTypes'

export function setSessionData(payload: Session): Action<Session> {
  return { type: SET_SESSION_DATA, payload }
}

export function clearSessionData(): ActionWithoutPayload {
  return { type: CLEAR_SESSION_DATA }
}

export function setToken(payload: Token): Action<Token> {
  return { type: SET_TOKEN, payload }
}

export function getUserData(payload: Token): Action<Token> {
  return { type: GET_USER_DATA, payload }
}

export function getUserDataFailure(): ActionWithoutPayload {
  return { type: GET_USER_DATA_FAILURE }
}

export function getUserDataSuccess(payload: User): Action<User> {
  return { type: GET_USER_DATA_SUCCESS, payload }
}
